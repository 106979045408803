<div class="content">
  <mat-toolbar class="mat-elevation-z8">
    <mat-toolbar-row>
      <a routerLink="/Login">
        <img class="home-button" src="../../../assets/img/ellos-logo-colorful.png" alt="ellos-logo" />
      </a>

      <span class="example-spacer"></span>

      <app-translate class="spacer"></app-translate>
      <div class="timezone spacer">
        <div class="button-menu" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px">
          <span class="menu-item"> <fa-icon [icon]="faGlobeAfrica"></fa-icon>{{ 'toolbar.timezone' | translate }} </span>
          <span class="menu-item">GMT&nbsp;</span>
        </div>
      </div>
      <div class="icon-question">
        <a href="#">
          <fa-icon [icon]="faQuestionCircle"></fa-icon>
        </a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div fxLayout="row" fxLayoutAlign="start start">
    <div class="arrow" fxLayout="column">
      <button mat-icon-button routerLink="/Certificate" *ngIf="!isCertificate">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <!-- form -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="form" fxLayout="column" fxFlex="90">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="90">
      <div class="footer-infor" fxLayout="row">
        <div class="redes-sociais" fxLayout="column" fxFlex="50">
          <div fxLayout="row">
            <a href="https://www.instagram.com/camaraarabebrasileira/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
            <a href="https://www.facebook.com/CamaraArabeBrasileira/" target="_blank"><fa-icon [icon]="faFacebookSquare"></fa-icon></a>
            <a href="https://twitter.com/CAMARAARABE" target="_blank"><fa-icon [icon]="faTwitterSquare"></fa-icon></a>
            <a href="https://www.linkedin.com/company/camaraarabebrasileira/" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
            <a href="https://www.youtube.com/user/CamaraArabeTV" target="_blank"><fa-icon [icon]="faYoutube"></fa-icon></a>
          </div>
        </div>
        <div class="text" fxLayout="column" fxFlex="50">
          <p>Câmara de Comércio Árabe Brasileira | {{ year }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
