import { CompanyExportationNotification } from 'src/app/modules/certificates/interfaces/exportation-notification.interface';
import * as NotificationTypeAction from '../actions/notifications.action';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export interface CompanyExportationNotificationState {
  companyExportationNotification: CompanyExportationNotification[];
}

export const initialState: CompanyExportationNotificationState = { companyExportationNotification: [] };

const _companyExportationNotification = createReducer(
  initialState,
  on(NotificationTypeAction.AddNotification, (state, { _companyExportationNotification }) => {
    return {
      ...state,
      companyExportationNotification: _companyExportationNotification
    };
  }),
  on(NotificationTypeAction.RemoveById, (state, { id }) => {
    return {
      ...state,
      companyExportationNotification: [...state.companyExportationNotification].filter((notification) => notification.idNotification !== id)
    };
  })
);

export function companyExportationNotificationReducer(state = initialState, action: Action) {
  return _companyExportationNotification(state, action);
}

const getCompanyExportationNotificationState = createFeatureSelector<CompanyExportationNotificationState>('companyExportationNotification');

export const getCompanyExportationNotification = createSelector(
  getCompanyExportationNotificationState,
  (state: CompanyExportationNotificationState) => state.companyExportationNotification
);
