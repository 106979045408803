<div mat-dialog-title>
  <div fxLayout="row" fxLayoutAlign="space-between center ">
    <span></span>
    <h1 *ngIf="!isEditMode">
      {{ 'easy-trade.new-trade.dialog.no-ellos-company-title' | translate }}
    </h1>
    <h1 *ngIf="isEditMode">
      {{ 'easy-trade.new-trade.dialog.no-ellos-company-title-edit' | translate }}
    </h1>
    <button mat-icon-button mat-dialog-close [disabled]="submiting">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider class="divider"></mat-divider>
</div>

<div mat-dialog-content>
  <form [formGroup]="companyForm" fxLayout="column" *ngIf="!submiting" fxLayoutAlign="start start" autocomplete="off">
    <mat-form-field color="accent" appearance="outline" fxFill>
      <mat-label>
        <mat-label>{{ 'easy-trade.new-trade.dialog.company-name' | translate }}</mat-label>
      </mat-label>
      <input matInput type="text" formControlName="name" required="true" maxlength="55" />
      <mat-error *ngIf="companyForm.controls.name.hasError('whitespace')">
        {{ 'shared.white-space-validator' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field color="accent" appearance="outline" fxFill>
      <mat-label>
        <mat-label>{{ 'easy-trade.new-trade.dialog.address' | translate }}</mat-label>
      </mat-label>
      <input matInput type="text" formControlName="street" [required]="requiredAddress" maxlength="55" />
      <!-- Substitua o "50" pelo número máximo de caracteres permitidos -->
      <mat-error *ngIf="companyForm.controls.street.hasError('whitespace')">
        {{ 'shared.white-space-validator' | translate }}
      </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
      <mat-form-field color="accent" appearance="outline" fxFlex="100">
        <mat-label>
          <mat-label>{{ 'easy-trade.new-trade.dialog.city' | translate }}</mat-label>
        </mat-label>
        <input matInput type="text" formControlName="city" maxlength="55" />
        <mat-error *ngIf="companyForm.controls.city.hasError('whitespace')">
          {{ 'shared.white-space-validator' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="outline" fxFlex="50">
        <mat-label>
          <mat-label>{{ 'easy-trade.new-trade.dialog.po-box' | translate }}</mat-label>
        </mat-label>
        <input matInput type="text" formControlName="poBox" [required]="requiredPoBox" (keyup)="limitNumbers()" />
        <mat-error *ngIf="companyForm.controls.poBox.hasError('whitespace')">
          {{ 'shared.white-space-validator' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
      <mat-form-field color="accent" appearance="outline" fxFlex="100">
        <mat-label>
          <mat-label>{{ 'easy-trade.new-trade.dialog.complement' | translate }}</mat-label>
        </mat-label>
        <input matInput type="text" formControlName="complement" maxlength="55" />
        <mat-error *ngIf="companyForm.controls.complement.hasError('whitespace')">
          {{ 'shared.white-space-validator' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
      <mat-select-country
        *ngIf="predefinedDestinationCountries.length > 0"
        [label]="'settings.companies.country' | translate"
        formControlName="countryAlpha3"
        [required]="true"
        [countries]="predefinedDestinationCountries"
        color="accent"
        appearance="outline"
        fxFlex="50"
      ></mat-select-country>

      <mat-form-field color="accent" appearance="outline" fxFlex="50">
        <mat-label>
          <mat-label>{{ 'easy-trade.new-trade.dialog.numero-vat' | translate }}</mat-label>
        </mat-label>
        <input matInput type="text" formControlName="vatNumber" [required]="requiredVatNumber" (keyup)="limitNumbers()" />
        <mat-error *ngIf="companyForm.controls.vatNumber.hasError('whitespace')">
          {{ 'shared.white-space-validator' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<mat-divider></mat-divider>

<div mat-dialog-actions class="dialog-content" fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close [disabled]="submiting">
    {{ 'easy-trade.new-trade.dialog.cancel-button' | translate }}
  </button>
  <button mat-button (click)="submit()" [disabled]="companyForm.invalid || submiting" type="submit">
    {{ 'easy-trade.new-trade.dialog.confirm-button' | translate }}
  </button>
</div>
