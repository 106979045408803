import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { changeError } from '../actions/error.action';
import { ErrorState } from '../interfaces/error-state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ActionTypes } from 'src/app/core/models/actionTypes';
import EllosAction from '../interfaces/ellosAction.interface';
import { CompanyService } from './../services/company.service';
import { CompanyChangeSuccessValidation } from './../actions/company.action';

@Injectable()
export class CompanyEffects {
  constructor(private actions$: Actions, private companyService: CompanyService, private readonly dialog: MatDialog, private store: Store<ErrorState>) {}

  @Effect()
  validateCompany$ = this.actions$.pipe(
    ofType<EllosAction>(ActionTypes.ChangeCompany),
    switchMap((action) =>
      this.companyService.validateCompany(action?.payload?.id).pipe(
        map(() => {
          return CompanyChangeSuccessValidation();
        }),
        catchError((err) => {
          let msg: Array<string> = [];

          msg = err.error.errors.map((e) => {
            if (e.includes('FantasyNameInvalid')) {
              return 'validation.invalid-fantasy-name';
            }
            if (e.includes('TraderTypeInvalid')) {
              return 'validation.invalid-trader-type';
            }
            if (e.includes('TimezoneInvalid')) {
              return 'validation.invalid-timezone';
            }
            if (e.includes('CnpjInvalid')) {
              return 'validation.invalid-cnpj';
            }
            if (e.includes('StateRegistryInvalid')) {
              return 'validation.invalid-state-registry';
            }
            if (e.includes('SegmentInvalid')) {
              return 'validation.invalid-segment';
            }
            if (e.includes('PrimaryChapterInvalid')) {
              return 'validation.invalid-primary-chapter';
            }
            if (e.includes('ZipCodeInvalid')) {
              return 'validation.invalid-zipCode';
            }
            if (e.includes('StreetInvalid')) {
              return 'validation.invalid-street';
            }
            if (e.includes('CityInvalid')) {
              return 'validation.invalid-city';
            }
            if (e.includes('DistrictInvalid')) {
              return 'validation.invalid-district';
            }
            if (e.includes('StateInvalid')) {
              return 'validation.invalid-state';
            }
            if (e.includes('CountryInvalid')) {
              return 'validation.invalid-country';
            }
            if (e.includes('ContactEmailInvalid')) {
              return 'validation.invalid-contact-email';
            }
            if (e.includes('PhoneNumberInvalid')) {
              return 'validation.invalid-phone-number';
            }
            if (e.includes('NameInvalid')) {
              return 'validation.invalid-name';
            }
            if (e.includes('NumberInvalid')) {
              return 'validation.invalid-number';
            }
            if (e.includes('StreetOrPoBoxInvalid')) {
              return 'validation.invalid-street-or-pobox';
            }
          });
          return of(
            changeError({
              errorState: {
                additionalInfoMore: msg
              }
            })
          );
        })
      )
    )
  );
}
