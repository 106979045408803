export enum CurrencyEnum {
  REAL = 1,
  DOLLAR = 2,
  EURO = 3
}

export enum IncotermEnum {
  EXW = 'Exw',
  FCA = 'Fca',
  FAS = 'Fas',
  FOB = 'Fob',
  CPT = 'Cpt',
  CIP = 'Cip',
  CFR = 'Cfr',
  CIF = 'Cif',
  DAP = 'Dap',
  DPU = 'Dpu',
  DDP = 'Ddp'
  // DDU = 'Ddu',
  // DEQ = 'Deq',
  // DES = 'Des'
}

export enum IncotermNumberEnum {
  CIF = 0,
  CIP = 1,
  CFR = 2,
  CPT = 3,
  DDP = 4,
  DDU = 5,
  DEQ = 6,
  DES = 7,
  FOB = 8,
  EXW = 9,
  FCA = 10,
  FAS = 11,
  DAP = 12,
  DPU = 13
}

export enum TransshipmentMethodEnum {
  plane = 'Plane',
  ship = 'Ship'
}
