import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime, switchMap } from 'rxjs/operators';
import Language from 'src/app/core/interfaces/language.interface';
import { NcmService } from '../../services/ncm.service';
import { Ncm } from '../../models/ncm.interface';
import { NcmAndRequirements } from '../../models/ncm-and-requirements';
@Component({
  selector: 'app-certificate-ncm-autocomplete',
  templateUrl: './certificate-ncm-autocomplete.component.html',
  styleUrls: ['./certificate-ncm-autocomplete.component.scss']
})
export class CertificateNcmAutocompleteComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  inputSearch = new FormControl();
  language$: Observable<Language>;
  destroy$ = new Subject();
  selectedLangCode?: string;
  ncms: Ncm[] = [];
  loading?: boolean;
  private _selectedNcm?: Ncm;
  @Input() isUpdateNameNcm?: boolean = false;
  @Input() nameNcmDefault?: string = '';
  @Output() selectedNcm$ = new EventEmitter<Ncm>();
  @Output() emitNameUpdate$ = new EventEmitter<boolean>();
  @Input() cleanNcm?: boolean;

  @Input() defaultNcm?: Ncm;
  @Input() listNcmSeleted: NcmAndRequirements[] = [];
  @Input() disabled: boolean = false;
  @ViewChild('searchInput') searchInput?: ElementRef;
  @Input() isDue: boolean = false;

  @Input() urlNcmService?: string;

  constructor(private readonly ncmService: NcmService, langStore: Store<{ language: Language }>) {
    this.language$ = langStore.pipe(select('language'));
  }

  async ngOnInit(): Promise<any> {
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      if (lang) {
        this.selectedLangCode = lang.code;
        await this.getNcms();
      }
    });
  }

  extractFirstElementNcm(): Ncm {
    return {
      id: this.listNcmSeleted[0].ncms.id,
      ncmCode: this.listNcmSeleted[0].ncms.ncmCode,
      description: this.listNcmSeleted[0].ncms.description,
      validations: this.listNcmSeleted[0].ncms.validations
    };
  }

  checkNcms() {
    if (this.haveNcmsPendingToAdded()) {
      this.ncms = [];
      for (let index = 0; index < this.listNcmSeleted?.length; index++) {
        if (this.listNcmSeleted[index].ncms.pendingToAdd) {
          this.ncms?.push({
            id: this.listNcmSeleted[index].ncms.id,
            ncmCode: this.listNcmSeleted[index].ncms.ncmCode,
            description: this.listNcmSeleted[index].ncms.description,
            validations: this.listNcmSeleted[index].ncms.validations
          });
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleUpdateNameNcmChange(changes);
    this.handleCleanNcmChange(changes);
    this.handleDisabledChange(changes);
  }

  private handleUpdateNameNcmChange(changes: SimpleChanges) {
    if (this.searchInput) {
      this.searchInput!.nativeElement.value = changes?.nameNcmDefault?.currentValue || '';
      this.emitNameUpdate$.emit(false);
    }
  }

  private handleCleanNcmChange(changes: SimpleChanges) {
    if (changes.cleanNcm?.currentValue) {
      this.cleanFields();
    }
  }

  private handleDisabledChange(changes: SimpleChanges) {
    const disabledChange = changes.disabled;
    if (disabledChange) {
      if (disabledChange.currentValue) {
        this.inputSearch.disable();
      } else {
        this.inputSearch.enable();
      }
    }
  }

  ngAfterViewInit() {
    this.inputFilter();
  }

  inputFilter() {
    if (this.searchInput?.nativeElement) {
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(
          debounceTime(800),
          switchMap(async () => this.getNcms())
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  updateInpuCrmValue() {
    let ncm = this.ncms.find((n: Ncm) => n.ncmCode == this._selectedNcm?.ncmCode);
    this.searchInput!.nativeElement.value = ncm ? ncm.ncmCode + ' - ' + ncm.description : '';
  }

  displayFn(ncm: Ncm): string {
    return ncm ? ncm.ncmCode + ' - ' + ncm.description : '';
  }

  setNcm(ncm: Ncm) {
    this.selectedNcm$.emit(ncm);
  }

  async getNcms() {
    if (this.selectedLangCode) {
      if (!this.haveNcmsPendingToAdded()) {
        try {
          this.ncms = await this.ncmService.getNcmPaginated({
            NcmCode: this.inputSearch.value && isNaN(this.inputSearch.value.toString().trim()) ? this._selectedNcm?.ncmCode : this.inputSearch.value?.trim(),
            LanguageCode: this.selectedLangCode,
            PageIndex: 0,
            PageSize: 10
          });
        } catch (e) {
          this.ncms = [];
        }
      }
    }
  }

  haveNcmsPendingToAdded(): boolean {
    let haveElementToAdded = false;
    for (let index = 0; index < this.listNcmSeleted?.length; index++) {
      if (this.listNcmSeleted[index].ncms.pendingToAdd) {
        haveElementToAdded = true;
      }
    }
    return haveElementToAdded;
  }

  cleanFields() {
    this.inputSearch = new FormControl();
    this.cleanNcm = false;

    if (this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.value = '';
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
