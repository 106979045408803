<div mat-dialog-title align="center">
  <h1>{{ 'settings.companies.delete-company' | translate }}</h1>
  <mat-divider class="divider"></mat-divider>
</div>
<mat-dialog-content class="content" align="center">
  <p>
    {{ 'settings.companies.sure-delete-company' | translate }}<b>{{ data.companyName }}</b
    >?
  </p>
</mat-dialog-content>
<mat-divider class="divider"></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] [disabled]="waitingResponse">
    {{ 'settings.companies.button-cancel' | translate }}
  </button>
  <button mat-button (click)="DeleteCompany()">
    {{ 'settings.companies.button-remove' | translate }}
  </button>
</mat-dialog-actions>
