<mat-dialog-content class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
  <p>
    {{ 'registration.signed-as-admin' | translate }} {{ data.companyName }}.
    {{ 'registration.company-admin' | translate }}
  </p>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] routerLink="/Settings/Companies">OK</button>
</mat-dialog-actions>
