import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Company } from 'src/app/core/interfaces/company.interface';
import { CompanyService } from 'src/app/core/services/company.service';
import { MeetingService } from '../../../matchmaking/services/meeting.service';
import { ResultDialogData } from 'src/app/shared/components/result-dialog/result-dialog-data.interface';
import { ResultDialogComponent } from '@ccab/components-lib';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/core/services/theme.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
@Component({
  selector: 'app-schedule-meeting-dialog',
  templateUrl: './schedule-meeting-dialog.component.html',
  styleUrls: ['./schedule-meeting-dialog.component.scss']
})
export class ScheduleMeetingDialogComponent implements OnInit {
  meetingHour = new FormControl('', [Validators.required]);
  meetingDate = new FormControl(null as Date, [Validators.required]);
  currentCompany: Company;
  isLoading = false;

  today = new Date();
  isEditing = false;
  currentTheme: string;

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#555',
      buttonColor: '#fff'
    },
    dial: {
      dialBackgroundColor: '#2f3c4d'
    },
    clockFace: {
      clockFaceBackgroundColor: '#2f3c4d',
      clockHandColor: '#9fbd90',
      clockFaceTimeInactiveColor: '#fff'
    }
  };

  lightTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#ffffff',
      buttonColor: '#00c9af'
    },
    dial: {
      dialBackgroundColor: '#00b596',
      dialInactiveColor: '#ffffff',
      dialEditableBackgroundColor: '#00b596'
    },
    clockFace: {
      clockFaceBackgroundColor: '#00c9af',
      clockHandColor: '#ffffff',
      clockFaceTimeInactiveColor: '#031124',
      clockFaceTimeActiveColor: '#031124',
      clockFaceInnerTimeInactiveColor: '#073f5a'
    }
  };

  guestCompanyHolidayFilter: (date: Date | null) => boolean = () => true;

  constructor(
    private readonly dialogRef: MatDialogRef<ScheduleMeetingDialogComponent>,
    private readonly companyService: CompanyService,
    private readonly meetingService: MeetingService,
    private readonly dialog: MatDialog,
    private readonly themeService: ThemeService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentTheme = this.themeService.getCurrentTheme().name;

    if (data.meetingPreview?.meetingStatus === 'Canceled') {
      data.meetingPreview.date = null;
    }

    if (data.meetingPreview?.meetingStatus !== 'Canceled' && data.meetingPreview?.id) {
      this.isEditing = true;
      this.hourAdjustsUTC();
    } else if (data?.id && data?.lastStatus !== 'Canceled' && data?.meetDate) {
      this.isEditing = true;
      const meetingDate = new Date(this.data.meetDate);

      this.meetingHour.setValue(
        new Date(meetingDate).toLocaleTimeString(navigator.language, {
          hour: '2-digit',
          minute: '2-digit'
        })
      );
      this.meetingDate.setValue(meetingDate);
    } else {
      this.isEditing = false;
      const meetingDate = new Date();
      this.meetingHour.setValue(new Date(meetingDate.getTime() + 60 * 60 * 1000).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }));
      this.meetingDate.setValue(meetingDate);
    }
    this.currentCompany = this.companyService.getCurrentCompany();
  }

  ngOnInit(): void {
    if (this.data?.idCompanyGuest) {
      this.meetingService.getCompanyHolidays(this.data.idCompanyGuest).then((holidays) => {
        this.setupGuestCompanyHolidayFilter(holidays);
      });
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  async save(): Promise<any> {
    const dateGMT = this.hourAdjusts();
    const newMeeting = {
      idCompany: this.currentCompany.id,
      idOrder: this.data.idOrder || this.data.id,
      meetingDate: new Date(dateGMT.toUTCString()).toISOString()
    };

    try {
      this.isLoading = true;

      const response = await this.meetingService.scheduleMeeting(newMeeting);
      this.validateScheduleResponse(response);
    } catch (e) {
      this.isLoading = false;
      const dialogData: ResultDialogData = {
        success: false,
        text: 'matchmaking.dialog.schedule-erro',
        timeout: 3000
      };
      this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
    }
  }

  async edit() {
    const dateGMT = this.hourAdjusts();

    const newMeeting = {
      idCompany: this.currentCompany.id,
      idMeeting: this.data?.meetingPreview?.id || this.data?.id,
      meetingDate: new Date(dateGMT.toUTCString()).toISOString()
    };
    try {
      this.isLoading = true;
      const response = await this.meetingService.editMeeting(newMeeting);
      this.validateScheduleResponse(response);
    } catch (e) {
      this.isLoading = false;
      const dialogData: ResultDialogData = {
        success: false,
        text: 'matchmaking.dialog.schedule-erro',
        timeout: 3000
      };
      this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
    }
  }

  hourAdjusts() {
    const dateGMT = new Date(this.meetingDate.value);
    const hour = this.meetingHour.value.split(':')[0];
    const minute = this.meetingHour.value.split(':')[1];
    dateGMT.setHours(+hour, +minute);
    return dateGMT;
  }

  hourAdjustsUTC() {
    const offset = new Date().getTimezoneOffset();
    const meetingDate = new Date(new Date(this.data.meetingPreview.date).getTime() - offset * 60 * 1000);
    this.meetingHour.setValue(
      new Date(meetingDate).toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit'
      })
    );
    this.meetingDate.setValue(meetingDate);
  }

  validateScheduleResponse(response) {
    if (response.success) {
      const dialogData: ResultDialogData = {
        success: response.success,
        text: response.success ? 'matchmaking.dialog.successfully-scheduled' : 'matchmaking.dialog.schedule-erro',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.dialogRef.close(true);
        });

      this.router.navigateByUrl('/Matchmaking/Meetings');
      this.isLoading = false;
    } else {
      this.isLoading = false;
      const dialogData: ResultDialogData = {
        success: false,
        text: 'matchmaking.dialog.schedule-erro',
        timeout: 3000
      };
      this.dialog.open(ResultDialogComponent, {
        disableClose: true,
        data: dialogData
      });
    }
  }

  setTime(event) {
    this.meetingHour.setValue(event);
  }

  setupGuestCompanyHolidayFilter(holidays: Date[] = []) {
    this.guestCompanyHolidayFilter = (date) => {
      if (date) {
        return !holidays.some(
          (holiday) => holiday.getUTCDate() === date.getUTCDate() && holiday.getUTCMonth() === date.getUTCMonth() && holiday.getUTCFullYear() === date.getUTCFullYear()
        );
      } else {
        return false;
      }
    };
  }
}
