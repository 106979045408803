<section class="content" id="content">
  <h2>{{ 'matchmaking.request-title' | translate }}</h2>

  <div fxLayout="row wrap" fxLayoutGap="25px" fxLayoutGap.lt-lg="0">
    <section class="chat mat-elevation-z8" fxFlex="50" fxFlex.lt-md="100" fxFlex.lt-lg="60">
      <app-chat *ngIf="matchmaking?.id" [idMatchmaking]="matchmaking?.id" [idCompany]="currentCompany?.id"></app-chat>
    </section>

    <div fxLayout="column" fxLayoutGap="20px" [style.background]="color" class="details mat-elevation-z8" fxFlex="45" fxFlex.lt-lg="60" fxFlex.lt-md="100" *ngIf="!loading">
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.solicitation' | translate }}:</b>
        <p>M-{{ matchmaking?.id }}</p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.requester' | translate }}:</b>
        <p>{{ matchmaking?.requester }}</p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'marketplace.dialog.category' | translate }}:</b>
        <p>{{ matchmaking?.categoryName }}</p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'marketplace.dialog.subcategory' | translate }}:</b>
        <p>{{ matchmaking.subCategoryName }}</p>
      </span>
      <span fxLayout="row  row-wrap" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.products-interest' | translate }}:</b>
        <p *ngFor="let product of matchmaking.products">{{ product }};&nbsp;</p>
        <p *ngIf="matchmaking.products.length == 0">{{ '-' }}&nbsp;</p>
      </span>
      <span fxLayout="row row-wrap" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.countries-interest' | translate }}:</b>
        <p *ngFor="let country of matchmaking.countries">{{ country }};{{ ' ' }}</p>
        <p *ngIf="matchmaking.countries.length == 0">{{ '-' }}</p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.request-date' | translate }}:</b>
        <p>{{ this.date }}</p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.number-meetings' | translate }}:</b>
        <p>{{ matchmaking.numberOfMeetings }}</p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.need-translator' | translate }}</b>
        <p>
          {{ (matchmaking.translatorNeeded ? 'matchmaking.dialog.yes' : 'matchmaking.dialog.no') | translate }}
        </p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.best-time' | translate }}:</b>
        <p>
          {{ 'matchmaking.dialog.' + matchmaking.bestTime.toLocaleLowerCase() | translate }}
        </p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.company-profile' | translate }}:</b>
        <p>
          {{ 'matchmaking.dialog.' + matchmaking.companyProfile.toLocaleLowerCase() | translate }}
        </p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.meeting-type' | translate }}:</b>
        <p>
          {{ ('matchmaking.dialog.' + matchmaking.meetingType).toLocaleLowerCase() | translate }}
        </p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.restrictions' | translate }}:</b>
        <p>{{ matchmaking.restrictions || '-' }}</p>
      </span>
      <span fxLayout="row" fxLayout.lt-sm="column"
        ><b class="title">{{ 'matchmaking.dialog.coments' | translate }}:</b>
        <p>{{ matchmaking.comments || '-' }}</p>
      </span>
    </div>
    <div fxLayout="column" fxLayoutGap="20px" class="details mat-elevation-z8" fxFlex="45" fxFlex.lt-lg="60" fxFlex.lt-md="100" *ngIf="loading">
      <app-ellos-spinner [text]="'matchmaking.loading' | translate"></app-ellos-spinner>
    </div>
  </div>
</section>
