import { StoreKey } from './../interfaces/storeKey.interface';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import StoreItem from '../interfaces/storeItem.interface';
import { CookieService } from 'ngx-cookie-service';
import { cookieOptions } from 'src/app/shared/utils/cookie-options';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly storageSub: Subject<StoreItem>;
  private readonly keySub: Subject<string>;

  constructor(private cookieService: CookieService) {
    this.storageSub = new Subject<StoreItem>();
    this.keySub = new Subject<string>();
  }
  set(storeItem: StoreItem): void {
    storeItem.key == StoreKey.apiToken || storeItem.key == StoreKey.Refresh
      ? this.cookieService.set(storeItem.key, storeItem.value, cookieOptions)
      : localStorage.setItem(storeItem.key, storeItem.value);

    this.storageSub.next(storeItem);
  }

  get(key: StoreKey): StoreItem {
    let value: string = localStorage.getItem(key.toString());
    key == StoreKey.apiToken || key == StoreKey.Refresh ? (value = this.cookieService.get(key.toString())) : (value = localStorage.getItem(key.toString()));
    return {
      key: key,
      value: value
    };
  }
}
