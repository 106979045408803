<div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
  <object data="../../../../../assets/icons/Meeting_Ellos.svg" type="image/svg+xml"></object>

  <h1>{{ 'matchmaking.closed-meeting' | translate }}</h1>

  <p>{{ 'matchmaking.thanks-message' | translate }}</p>

  <div fxLayoutAlign="row" fxLayoutAlign="center start" fxLayoutGap="25px">
    <button mat-button routerLink="/Matchmaking/Meetings" [disabled]="isLoading">
      {{ 'matchmaking.button-back-meeting' | translate }}
    </button>
    <button mat-button (click)="scheduleMeetingDialog()" [disabled]="isLoading" *ngIf="element">
      {{ 'matchmaking.button-new-meeting' | translate }}
    </button>

    <button mat-flat-button color="accent" (click)="completeProposal()" [disabled]="isLoading" *ngIf="element">
      {{ 'matchmaking.button-approve-proposal' | translate }}
    </button>
  </div>
</div>
