import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Theme from 'src/app/core/interfaces/theme.interface';

@Directive({
  selector: '[cardBackground]'
})
export class CardBackgroundDirective implements OnInit, OnDestroy {
  @Input() lightBg: string;
  @Input() darkBg: string;
  @Input() lightColor: string;
  @Input() darkColor: string;
  private theme$: Observable<Theme>;
  private destroy$: Subject<any> = new Subject<any>();

  constructor(private readonly el: ElementRef, private readonly themeStore: Store<{ theme: Theme }>, private renderer: Renderer2) {
    this.theme$ = themeStore.pipe(select('theme'));
  }
  ngOnInit() {
    this.themeObserver();
  }

  themeObserver() {
    this.theme$.pipe(takeUntil(this.destroy$)).subscribe((newTheme: Theme) => {
      if (newTheme?.name == 'ellos-light-theme') {
        this.el.nativeElement.style.backgroundColor = this.lightBg;
        this.el.nativeElement.style.color = this.lightColor;
      } else if (newTheme?.name == 'ellos-dark-theme') {
        this.el.nativeElement.style.backgroundColor = this.darkBg;
        this.el.nativeElement.style.color = this.darkColor;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
