import { Store } from '@ngrx/store';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ProductExportation } from '../../../Models/product-exportation.interface';
import { ProductToCertificate } from './../../../reducers/products-certificate.reducer';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import * as fromAddProductToCertificate from '../../../actions/products-certificate.action';
import * as fromProductToCertificateSelector from '../../../reducers/products-certificate.reducer';

@Component({
  selector: 'app-certificate-product-exportation-grid',
  templateUrl: './certificate-product-exportation-grid.component.html',
  styleUrls: ['./certificate-product-exportation-grid.component.scss']
})
export class CertificateProductExportationGridComponent implements OnInit {
  public totalWeight: number = 0;
  public totalNetWeight: number = 0;
  public Currency = ['BRL', 'USD', 'EUR'];
  productToCertificate: ProductToCertificate[] = [];
  datasource = new MatTableDataSource<ProductToCertificate>([]);

  @Input() canEdit: boolean = true;
  @Input() readonlyGrid: boolean = false;
  @Input() isEditProduct: boolean = false;
  @Input() useProductValue: boolean = false;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() editProductEvent = new EventEmitter<number>();
  @Output() iseditProductEvent = new EventEmitter<boolean>();
  @Output() deletedProduct = new EventEmitter<ProductExportation>();

  public displayedColumns: string[] = [];
  constructor(private readonly productCertificateStore: Store<{ productToCertificate: ProductToCertificate[] }>) {}

  ngOnInit(): void {
    this.displayedColumns = ['ncm', 'amount', 'unit', 'grossWeight', 'netWeight', 'marks', 'sif', 'productionDate', 'expirationDate', 'description'];

    if (!this.readonlyGrid) {
      this.displayedColumns.push('actions');
    }
    // add the product value column right before description column
    if (this.useProductValue) {
      this.displayedColumns.splice(9, 0, 'productValue');
    }

    this.productCertificateStore.select(fromProductToCertificateSelector.getProductToCertificate).subscribe((_productToCertificate: ProductToCertificate[]) => {
      this.productToCertificate = _productToCertificate;
      this.datasource = new MatTableDataSource<ProductToCertificate>(_productToCertificate);
      this.datasource.paginator = this.paginator;
      if (this.table) {
        this.table.renderRows();
      }
      this.calculateTotalWeight();
    });
  }

  checkEdit(): boolean {
    if (this.canEdit) {
      return true;
    } else {
      return this.canEdit && !this.readonlyGrid;
    }
  }

  ngDoCheck() {
    this.calculateTotalWeight();
  }

  ngOnChanges(e) {
    this.table?.renderRows();
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }

  public deleteProduct(index) {
    this.deletedProduct.emit(index);
    if (index > -1) {
      this.productCertificateStore.dispatch(fromAddProductToCertificate.DeleteproductToCertificateByIndex({ _indexOfProductToCertificate: index }));
    }
  }

  editProduct(index) {
    this.editProductEvent.emit(index);
  }
  upProduct(index) {
    this.productCertificateStore.dispatch(fromAddProductToCertificate.UpProductToCertificate({ _indexOfProductToCertificate: index }));
    this.table?.renderRows();
  }

  downProduct(index) {
    this.productCertificateStore.dispatch(fromAddProductToCertificate.DownProductToCertificate({ _indexOfProductToCertificate: index }));
    this.table?.renderRows();
  }

  public calculateTotalWeight() {
    this.totalWeight = 0;
    this.totalNetWeight = 0;

    if (this.productToCertificate?.length > 0) {
      this.productToCertificate.forEach((element) => {
        let restTotalWeight = this.totalWeight + element?.grossWeight || 0;
        this.totalWeight = parseFloat(restTotalWeight.toFixed(2));

        let restTotalNetWeight = this.totalNetWeight + element?.netWeight || 0;
        this.totalNetWeight = parseFloat(restTotalNetWeight.toFixed(2));
      });
    }
  }
}
