<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center ">
      <p></p>
      <h1 fxFlexAlign="center">
        {{ 'marketplace.dialog.schedule-details' | translate }}
      </h1>

      <button mat-icon-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between start" class="main-content" *ngIf="meeting && !editing">
    <div fxLayout="row" fxLayoutAlign="start center " fxLayoutGap="35px">
      <div fxLayout="column" fxLayoutAlign="start start" class="content">
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="meeting.lastStatus === 'Confirmed'">
          <p class="title">{{ 'marketplace.negotiation.table.status' | translate }}:</p>
          <p class="meeting-scheduled">
            {{ 'marketplace.dialog.status-meeting.scheduled' | translate }}
          </p>
        </span>
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="meeting?.lastStatus === 'Canceled'">
          <p class="title">{{ 'marketplace.negotiation.table.status' | translate }}:</p>
          <p class="meeting-canceled">
            {{ 'marketplace.dialog.status-meeting.canceled' | translate }}
          </p>
        </span>
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="meeting.lastStatus === 'Pending'">
          <p class="title">{{ 'marketplace.negotiation.table.status' | translate }}:</p>
          <p class="meeting-waiting">
            {{ 'marketplace.dialog.status-meeting.waiting' | translate }}
          </p>
        </span>
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="meeting.lastStatus === 'PendingOrganizer'">
          <p class="title">{{ 'marketplace.negotiation.table.status' | translate }}:</p>
          <p class="meeting-waiting">
            {{ 'marketplace.dialog.status-meeting.pending-organizer' | translate }}
          </p>
        </span>
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="meeting.lastStatus === 'Completed'">
          <p class="title">{{ 'marketplace.negotiation.table.status' | translate }}:</p>
          <p class="meeting-completed">
            {{ 'marketplace.dialog.status-meeting.completed' | translate }}
          </p>
        </span>

        <span fxLayout="row" fxLayoutGap="5px" *ngIf="meeting.lastStatus === 'PendingGuest'">
          <p class="title">{{ 'marketplace.negotiation.table.status' | translate }}:</p>
          <p class="meeting-waiting">
            {{ 'marketplace.dialog.status-meeting.pending-guest' | translate }}
          </p>
        </span>

        <span fxLayout="row" fxLayoutGap="5px" *ngIf="data.productName">
          <p class="title">{{ 'marketplace.dialog.product' | translate }}:</p>
          <p class="product-name">{{ data.productName | truncate: 60 }}</p>
        </span>
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="data?.sellerCompanyName || data?.buyerCompanyName || data?.companyName">
          <p class="title">{{ 'marketplace.dialog.company' | translate }}:</p>
          <p>
            {{ data?.sellerCompanyName || data?.buyerCompanyName || data?.companyName }}
          </p>
        </span>
        <div fxLayout="row" fxLayoutAlign="start center " fxLayoutGap="20px">
          <span fxLayout="row" fxLayoutGap="5px">
            <p class="title">{{ 'marketplace.dialog.date' | translate }}:</p>
            <p>{{ date | ellosDate }}</p>
          </span>
          <span fxLayout="row" fxLayoutGap="5px">
            <p class="title">{{ 'marketplace.dialog.hour' | translate }}:</p>
            <p>{{ time }}</p>
          </span>
        </div>
      </div>
      <div class="right-content content" fxLayout="column">
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="data.productName">
          <p class="title">{{ 'marketplace.dialog.proposal' | translate }}:</p>
          <p>P-{{ data.idOrder || data.id }}</p>
        </span>
        <span fxLayout="row" fxLayoutGap="5px" *ngIf="!data.productName">
          <p class="title">Matchmaking:</p>
          <p *ngIf="!data.productName">M-{{ data.idMatchmaking || data.idOrder || data.id }}</p>
        </span>

        <span fxLayout="row" *ngIf="meeting.lastStatus == 'Confirmed'">
          <a fxLayout="row" fxLayoutAlign="center center" (click)="joinMeeting()">
            <mat-icon>videocam</mat-icon>
            <span class="link">{{ 'matchmaking.dialog.enter-meeting' | translate }}</span>
          </a>
        </span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start " class="meeting-status" fxLayoutGap="50px">
      <div fxLayout="column" fxLayoutAlign="start start " fxFlex="50" class="content" *ngIf="meeting?.lastStatus !== 'Canceled' && meeting?.lastStatus !== 'Completed'">
        <p class="title">
          {{ 'marketplace.dialog.presence-confirm' | translate }}
        </p>

        <div class="button-group" fxLayout="row" *ngIf="!data.buyerConfirmation && !editing">
          <button
            [class]="
              (meeting.idCompanyOrganizer == currentCompany.id && meeting.organizerConfirmed) || (meeting.idCompanyGuest == currentCompany.id && meeting.guestConfirmed)
                ? 'button-meeting button-meeting-choosed'
                : 'button-meeting'
            "
            (click)="attendMeeting(true)"
          >
            {{ 'marketplace.dialog.yes' | translate }}
          </button>
          <button
            [class]="
              (meeting.idCompanyOrganizer == currentCompany.id && !meeting.organizerConfirmed) || (meeting.idCompanyGuest == currentCompany.id && !meeting.guestConfirmed)
                ? 'button-meeting button-meeting-choosed'
                : 'button-meeting'
            "
            (click)="attendMeeting(false)"
          >
            {{ 'marketplace.dialog.no' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-content fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center " class="main-content" *ngIf="!meeting || editing">
    <app-ellos-spinner [text]="'marketplace.details.loading' | translate"></app-ellos-spinner>
  </div>

  <mat-divider class="divider"></mat-divider>
  <div mat-dialog-actions class="dialog-content" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button color="warn" (click)="cancelSchedule()" *ngIf="meeting && meeting?.lastStatus !== 'Completed' && meeting?.lastStatus !== 'Canceled'" [disabled]="editing">
      {{ ('marketplace.dialog.cancel-schedule-button' | translate).toUpperCase() }}
    </button>
    <div *ngIf="modules.includes(modulePermission.EditarReuniaoShowRoom)">
      <button mat-button (click)="onNoClick()" [disabled]="editing">
        {{ ('marketplace.dialog.back-button' | translate).toUpperCase() }}
      </button>
    </div>
    <div *ngIf="modules.includes(modulePermission.EditarReuniaoShowRoom)">
      <button mat-button (click)="scheduleMeetingDialog(data)" [disabled]="editing" *ngIf="meeting && meeting?.lastStatus !== 'Completed' && meeting?.lastStatus !== 'Canceled'">
        {{ ('marketplace.search.edit' | translate).toUpperCase() }}
      </button>
    </div>
  </div>
</div>
