import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ResultDialogComponent } from '@ccab/components-lib';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';
import { CompanyJoin } from '../../models/company-join';
import { Country } from 'src/app/shared/models/country';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidatorCaracterEmail } from './customValidators';
import { FloatLabelType } from '@angular/material/form-field';
import { ChangeTheme } from 'src/app/core/actions/theme.action';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { InviteInformations } from '../../models/invite-informations';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-white-space.validator';
import { RequestAccessCompanyDialogComponent } from 'src/app/modules/settings/components/request-access-company-dialog/request-access-company-dialog.component';

import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { InviteService } from 'src/app/modules/settings/services/invite.service';
import { AssociateService } from '../../../../shared/services/associate.service';

import Theme from 'src/app/core/interfaces/theme.interface';
import { allCountries } from 'src/app/core/models/all-countries';
import NewAssociate from '../../../../core/interfaces/newAssociate.interface';
import { ResultDialogData } from 'src/app/shared/components/result-dialog/result-dialog-data.interface';

@Component({
  selector: 'app-create-account-company',
  templateUrl: './create-account-company.component.html',
  styleUrls: ['./create-account-company.component.scss']
})
export class CreateAccountCompany implements OnInit, OnDestroy {
  Country: Country[] = allCountries.filter((x) => x.alpha2Code == 'BR');
  access: FormGroup = this.fb.group(
    {
      email: ['', Validators.required],
      password1: ['', [Validators.required, Validators.pattern('^(?=.{6,32}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*[●!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~].*')]],
      password2: ['', [Validators.required, Validators.pattern('^(?=.{6,32}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*[●!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~].*')]],
      phonenumber: ['', [Validators.required]],
      recaptcha: []
    },
    {
      validators: [this.matchingPassword, ValidatorCaracterEmail]
    }
  );

  personalInformation = this.fb.group({
    firstname: ['', [Validators.required, noWhitespaceValidator]],
    lastname: ['', [Validators.required, noWhitespaceValidator]],
    jobTitle: ['', [Validators.required, noWhitespaceValidator]],
    nationality: [null as Country, [Validators.required]]
  });

  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('email' as FloatLabelType);
  options = this.fb.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl
  });

  companies: CompanyJoin[] = [];

  selectedCompany: CompanyJoin;
  waitingLogin = false;
  searchCompany = false;
  companyFound = false;
  userRegistered = false;
  loadingCompanies = false;
  isAdmin = false;
  integrated = false;
  finished = false;
  makingLink = false;
  checkEmail = false;
  isInvite = false;
  finaliza = false;
  validator = false;
  loadingInvite = false;
  userEmail: Subject<string> = new Subject<string>();
  checkStep = true;
  name: any;
  confirmedInvite = false;
  @ViewChild('stepper') stepper: MatStepper;
  isRequested: boolean;
  requestInvite: boolean;
  cnpjInvite: any;
  emailInvite: any;
  existingCompanyRequest: boolean;
  isRequestCompany: boolean;
  chooseSendOption = false;
  destroy$: Subject<any> = new Subject<any>();

  faCheckCircle = faCheckCircle;
  inviteCode: string;
  inviteCodeSent: boolean = false;
  inviteInfomations: InviteInformations;
  loggedIn: boolean;

  constructor(
    private fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly associateService: AssociateService,
    private readonly companyService: CompanyService,
    private readonly inviteService: InviteService,
    private readonly themeService: ThemeService,
    private readonly dialog: MatDialog,
    private readonly themeStore: Store<Theme>,
    private activatedRoute: ActivatedRoute
  ) {}

  getFloatLabelValue(): string {
    return this.floatLabelControl.value || 'email';
  }

  teste() {
    return this.floatLabelControl.value;
  }

  async ngOnInit(): Promise<any> {
    this.resetTheme();
    if (this.route.snapshot.url[0].path.toLocaleLowerCase() === 'invite') {
      this.isInvite = true;
      this.validator = true;
      this.checkStep = false;
      this.inviteCode = this.route.snapshot.paramMap.get('inviteCode');
      this.name = 'registration.company-information';
      await this.setInviteInformatitions();
    } else {
      this.name = 'registration.confirm-invite';
    }
  }

  matchingPassword(group: FormGroup) {
    const password1 = group.controls['password1'].value;
    const password2 = group.controls['password2'].value;

    if (password1 === password2) {
      return null;
    }

    return { matching: true };
  }

  errorCountryValue() {
    const countryCode = this.personalInformation.controls.nationality.value?.numericCode;
    if (isNaN(countryCode) && this.personalInformation.controls.nationality?.value) {
      this.personalInformation.controls.nationality.markAsPending();

      return true;
    } else {
      this.personalInformation.controls.nationality.setValue(this.personalInformation.controls.nationality.value);

      return false;
    }
  }

  async onSubmit(checkEmail?: MatCheckbox) {
    let valor = this.getFloatLabelValue();
    this.waitingLogin = true;
    const associate: NewAssociate = {
      firstName: this.personalInformation.controls.firstname.value,
      lastname: this.personalInformation.controls.lastname.value,
      phone: this.access.controls.phonenumber.value,
      countryNumericCode: this.personalInformation.controls.nationality.value.numericCode,
      email: this.access.controls.email.value,
      password: this.access.controls.password1.value,
      inviteCode: this.inviteCode,
      jobTitle: this.personalInformation.controls.jobTitle.value
    };
    try {
      await this.authService.register(associate);
      this.inviteCodeSent = true;

      if (this.isInvite) {
        await this.login();
        await this.confirmInvite();
        await this.setFinished(true);
      } else {
        this.confirmAccountStep();

        if (this.validator) {
          this.isInvite = false;
          this.markAsRegistered(true);
          this.confirmAccountStep();
        }
      }
    } catch (exception) {
      this.snackBar.open(exception, 'OK', {
        duration: 4000
      });
    }

    this.waitingLogin = false;
  }

  async onCountrySelected(event) {
    this.personalInformation.controls.nationality.setValue(event);
    this.personalInformation.updateValueAndValidity();
  }

  async setInviteInformatitions() {
    this.loadingInvite = true;
    try {
      this.inviteInfomations = await this.inviteService.checkInviteCode(this.inviteCode);
      if (!this.inviteInfomations.email || !this.inviteInfomations.idInvite) {
        this.router.navigateByUrl('/Login');
      }
      this.access.controls.email.setValue(this.inviteInfomations.email);
      this.access.controls.email.disable();
    } catch (e) {
      this.router.navigateByUrl('/Login');
    } finally {
      this.loadingInvite = false;
    }
  }

  setEmail(event) {
    this.userEmail.next(event);
  }

  confirmAccountStep() {
    this.userEmail.next(this.access.controls.email.value);
    this.userRegistered = true;
  }

  async login() {
    const login = {
      email: this.access.controls.email.value,
      password: this.access.controls.password1.value
    };
    const response = await this.authService.login(login);
    if (response) {
      localStorage.setItem('firstAccess', 'true');
    }
  }
  async markAsRegistered(completed: boolean) {
    if (completed) {
      await this.login();
      if (this.isInvite) {
        this.stepper.selected.completed = true;
        this.stepper.next();
        setTimeout(async () => {
          await this.confirmInvite();
        }, 1000);
      } else {
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.loggedIn = true;
        this.checkEmail = true;
      }
    }
  }

  async confirmInvite() {
    try {
      const response = await this.inviteService.acceptInvite(this.inviteInfomations.idInvite);
      const dialogData: ResultDialogData = {
        success: response,
        text: response ? 'settings.companies.successfully-confirmed' : 'settings.companies.confirm-invite-error',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.confirmedInvite = response;
          this.stepper.selected.completed = true;
          this.stepper.next();
        });
    } catch (e) {
      const dialogData: ResultDialogData = {
        success: false,
        text: 'settings.companies.confirm-invite-error',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.stepper.selected.completed = true;
          this.stepper.next();
        });
    }
  }

  requestAcessDialog(event) {
    if (this.isRequestCompany && this.existingCompanyRequest && this.cnpjInvite.length === 18) {
      const data = {
        cnpj: this.cnpjInvite,
        email: this.emailInvite,
        newUser: true
      };
      const dialogRef = this.dialog.open(RequestAccessCompanyDialogComponent, {
        width: '830px',
        height: '270px',
        data: data,
        disableClose: true
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
          }
        });
    }
  }
  setAdmin(e) {
    this.isAdmin = e;
  }

  setCompanyFound(e) {
    this.companyFound = e;
  }

  async setFinished(e) {
    if (this.isInvite) {
      if (this.confirmedInvite) {
        await this.router.navigateByUrl('/Home');
      } else {
        await this.router.navigateByUrl('/Settings/MyInvitations/Recieved');
      }
    } else {
      this.finished = e;
      this.finaliza = e;
    }
  }

  toPreferences(event) {
    if (event) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }
  resetTheme(): void {
    const defaultTheme = this.themeService.resetTheme();
    this.themeStore.dispatch(ChangeTheme(defaultTheme));
  }

  checkInvalidEmail() {
    return this.access.errors?.invalidEmail;
  }

  handleValueRecaptcha(value: string) {
    if (value !== null) {
      this.access.controls['recaptcha'].setValue(value);
      this.personalInformation.updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
