import { Injectable } from '@angular/core';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { UserConsensus } from '../interfaces/user-consensus';

@Injectable({
  providedIn: 'root'
})
export class UserConsensusService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getUserConsentInfo(email: string, platform: number, version: number): Promise<UserConsensus> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `UserConsent/consentCookie?email=${email}&platform=${platform}&version=${version}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<UserConsensus>(request);
      return response.data;
    } catch (e) {}
  }

  async createUserConsent(input: UserConsensus): Promise<any> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `UserConsent/consentCookieCreate`,
      body: input
    };

    try {
      let response = await this.ellosApi.post<UserConsensus>(request);
      return response.data;
    } catch (e) {}
  }
}
