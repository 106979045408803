<mat-sidenav-container class="container" color="primary">
  <mat-sidenav class="sidenav" mode="side" opened fixedInViewport="true" *ngIf="loggedIn$ | async">
    <mat-nav-list class="nav-list">
      <!-- Home -->
      <a *ngIf="!companyIsSharedProcess()" mat-button [routerLink]="['/Home']" class="link" routerLinkActive="active-link">
        <i class="material-icons material-icons-outlined"> home </i>
        <span class="aligned-with-icon">Home</span>
      </a>

      <!-- EasyTrade -->
      <a
        *ngIf="modules.includes(modulePermission.AcessarEasyTradeHome)"
        mat-button
        (click)="redirectToUrl()"
        class="link"
        routerLinkActive="active-link"
        [disabled]="disabled"
        [matTooltip]="'settings.can-not-use-tooltip' | translate"
        [matTooltipDisabled]="!disabled"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
      >
        <i class="material-icons"> compare_arrows </i>
        <span class="aligned-with-icon">{{ 'sidebar.export' | translate }}</span>
      </a>
      <!-- EasyTrade -->

      <a
        *ngIf="modules.includes(modulePermission.AcessarShowroomHome) && !isExportcompany()"
        mat-button
        routerLink="/Certificates"
        class="link"
        [matTooltip]="'settings.can-not-use-tooltip' | translate"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
        routerLinkActive="active-link"
        [disabled]="disabled"
      >
        <i class="material-icons"> book </i>
        <span class="aligned-with-icon">{{ 'sidebar.certificates' | translate }} </span>
      </a>

      <!-- showromm and Marketplace -->
      <a
        *ngIf="!isProduction && modules.includes(modulePermission.AcessarShowroomHome) && !companyIsSharedProcess()"
        mat-button
        routerLink="/Marketplace"
        class="link"
        [matTooltip]="'settings.can-not-use-tooltip' | translate"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
        routerLinkActive="active-link"
        [disabled]="disabled"
      >
        <i class="material-icons"> view_quilt </i>
        <span class="aligned-with-icon">{{ 'sidebar.vitrine' | translate }} </span>
      </a>
      <a
        *ngIf="isProduction && modules.includes(modulePermission.AcessarShowroomHome) && !companyIsSharedProcess()"
        mat-button
        routerLink="/Marketplace"
        class="link"
        [matTooltip]="'home.dashboard-card.coming-soon' | translate"
        [matTooltipDisabled]="disabled"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
        routerLinkActive="active-link"
        [disabled]="true"
      >
        <i class="material-icons"> view_quilt </i>
        <span class="aligned-with-icon">{{ 'sidebar.vitrine' | translate }} </span>
      </a>
      <!-- showromm and Marketplace -->

      <!-- Matchmaking -->
      <a
        *ngIf="!isProduction && modules.includes(modulePermission.AcessarMatchmakingHome) && !companyIsSharedProcess()"
        mat-button
        routerLink="/Matchmaking"
        [matTooltip]="'settings.can-not-use-tooltip' | translate"
        [matTooltipDisabled]="!disabled"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
        class="link"
        routerLinkActive="active-link"
        [disabled]="disabled"
      >
        <i class="material-icons"> date_range </i>
        <span class="aligned-with-icon">{{ 'sidebar.business-metting' | translate }}</span>
      </a>
      <a
        *ngIf="isProduction && modules.includes(modulePermission.AcessarMatchmakingHome) && !companyIsSharedProcess()"
        mat-button
        [matTooltip]="'home.dashboard-card.coming-soon' | translate"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
        class="link"
        routerLinkActive="active-link"
        [disabled]="true"
      >
        <i class="material-icons"> date_range </i>
        <span class="aligned-with-icon">{{ 'sidebar.business-metting' | translate }}</span>
      </a>
      <!-- Matchmaking -->

      <!-- chamber-club -->
      <a
        mat-button
        class="link"
        disabled
        [matTooltip]="'home.dashboard-card.coming-soon' | translate"
        *ngIf="modules.includes(modulePermission.AcessarChamberClubHome) && !companyIsSharedProcess()"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
      >
        <i class="material-icons"> star_outline </i>
        <span class="aligned-with-icon">{{ 'sidebar.chamber-club' | translate }} </span>
      </a>

      <!-- content -->
      <a
        mat-button
        class="link"
        disabled
        [matTooltip]="'home.dashboard-card.coming-soon' | translate"
        matTooltipClass="tooltip"
        matTooltipPosition="right"
        *ngIf="modules.includes(modulePermission.AcessarContentHome) && !companyIsSharedProcess()"
      >
        <i class="material-icons"> vertical_split </i>
        <span class="aligned-with-icon">{{ 'sidebar.content' | translate }} </span>
      </a>

      <!-- settings -->
      <a mat-button routerLink="/Settings" class="link" routerLinkActive="active-link" *ngIf="modules.includes(modulePermission.AcessarSettingsHome) && !companyIsSharedProcess()">
        <i class="material-icons"> settings </i>
        <span class="aligned-with-icon">{{ 'sidebar.settings' | translate }} </span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <router-outlet> </router-outlet>
    <div *ngIf="socialFooter" class="container">
      <div class="content-footer-social mat-elevation-z" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="100">
          <div class="footer-infor" fxLayout="row">
            <div class="redes-sociais" fxLayout="column" fxFlex="50">
              <div fxLayout="row">
                <a href="https://www.instagram.com/camaraarabebrasileira/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
                <a href="https://www.facebook.com/CamaraArabeBrasileira/" class="mg-l" target="_blank"><fa-icon [icon]="faFacebookSquare"></fa-icon></a>
                <a href="https://twitter.com/CAMARAARABE" class="mg-l" target="_blank"><fa-icon [icon]="faTwitterSquare"></fa-icon></a>
                <a href="https://www.linkedin.com/company/camaraarabebrasileira/" class="mg-l" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
                <a href="https://www.youtube.com/user/CamaraArabeTV" class="mg-l" target="_blank"><fa-icon [icon]="faYoutube"></fa-icon></a>
              </div>
            </div>
            <div class="content-fied" fxLayout="row" fxFlex="50" fxLayoutAlign="end">
              <p>Câmara de Comércio Árabe Brasileira | {{ year }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
