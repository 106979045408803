import EllosAction from '../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../core/models/actionTypes';

export function profileImageReducer(state: string, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.ProfileImage: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
