export enum RegistrationCertificateOriginEnum {
  Invoice = 1,
  Companies = 2,
  Shipment = 3,
  Product = 4,
  Finish = 5,
  Completed = 6
}

export enum OriginCertificateStatusEnum {
  NotValidate = 0,
  InProcess = 1,
  WaitingPayment = 2,
  Registered = 3
}
