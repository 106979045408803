import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import Language from '@ccab/components-lib/interfaces/language.interface';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {
  @ViewChild('divRecaptcha')
  divRecaptcha!: ElementRef<HTMLDivElement>;
  parentElement: HTMLElement;
  get grecaptcha(): any {
    const w = window as any;
    return w['grecaptcha'];
  }

  widgetId: number;
  language$: Observable<Language>;
  destroy$ = new Subject();
  @Output() valueGrecaptcha = new EventEmitter<string>();

  constructor(private ngZone: NgZone, private langStore: Store<{ language: Language }>) {
    this.language$ = langStore.pipe(select('language'));
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      this.renderizarReCaptcha(lang);
    });
  }

  renderizarReCaptcha(lang: Language) {
    this.destruirReCaptcha();
    this.ngZone.runOutsideAngular(() => {
      if (!this.grecaptcha || !this.divRecaptcha) {
        setTimeout(() => {
          this.renderizarReCaptcha(lang);
        }, 500);
        return;
      }

      const idElemento = this.divRecaptcha.nativeElement.getAttribute('id');
      this.parentElement = this.divRecaptcha.nativeElement.parentElement;

      this.widgetId = this.grecaptcha.render(idElemento, {
        sitekey: environment.reCaptchaSiteKey,
        theme: 'dark',
        hl: lang.code,
        size: 'normal',
        callback: (response: string) => {
          this.valueGrecaptcha.emit(response);
          this.ngZone.run(() => {});
        }
      });
    });
  }

  destruirReCaptcha() {
    if (this.widgetId != null) {
      this.grecaptcha.reset(this.widgetId);
      const element = this.divRecaptcha;

      if (element && element.nativeElement) {
        const parent = element.nativeElement.parentElement;
        parent.removeChild(element.nativeElement);

        const newDiv = document.createElement('div');
        newDiv.setAttribute('id', 'recaptcha');
        parent.appendChild(newDiv);

        this.divRecaptcha = new ElementRef<HTMLDivElement>(newDiv);
      }

      this.widgetId = null;
    }
  }
  ngOnInit(): void {}
}
