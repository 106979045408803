import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'src/app/core/services/company.service';
import { Member } from '../../models/member.interface';

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: ['./delete-user-dialog.component.scss']
})
export class DeleteUserDialogComponent implements OnInit {
  waitingResponse: boolean = false;
  constructor(
    private readonly dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idCompany: number; member: Member },
    private readonly comapnyService: CompanyService
  ) {}

  ngOnInit(): void {}

  async deleteUser() {
    try {
      this.waitingResponse = true;
      let response = await this.comapnyService.deleteCompanyMember({
        idAssociate: this.data.member.idAssociate,
        idCompany: this.data.idCompany
      });
      if (response) {
        this.dialogRef.close(this.data.member);
      }
    } catch (e) {
    } finally {
      this.waitingResponse = false;
    }
  }
}
