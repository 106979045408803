import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CRMDropDownService {
  primaryChapterEN = [
    { id: 1, name: 'LIVE ANIMALS' },
    { id: 2, name: 'MEAT AND EDIBLE MEAT OFFAL' },
    {
      id: 3,
      name: 'FISH AND CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES'
    },
    { id: 4, name: "DAIRY PRODUCE; BIRDS' EGGS; NATURAL HONEY; OTHERS" },
    {
      id: 5,
      name: 'PRODUCTS OF ANIMAL ORIGIN, NOT SPECIFIED OR INCLUDED ELSEWHERE'
    },
    { id: 6, name: 'LIVE TREES AND OTHER PLANTS; OTHERS' },
    { id: 7, name: 'EDIBLE VEGETABLES AND CERTAIN ROOTS AND TUBERS' },
    { id: 8, name: 'EDIBLE FRUIT AND NUTS; PEEL OF CITRUS FRUITS OR MELONS' },
    { id: 9, name: 'COFFEE, TEA, MATÉ AND SPICES' },
    { id: 10, name: 'CEREALS' },
    {
      id: 11,
      name: 'PRODUCTS OF THE MILLING INDUSTRY; MALT; STARCHES; INULIN; WHEAT GLUTEN'
    },
    { id: 12, name: 'OIL SEEDS AND OLEAGINOUS FRUITS; GRAINS, SEEDS, OTHERS' },
    { id: 13, name: 'LAC; GUMS, RESINS AND OTHER VEGETABLE SAPS AND EXTRACTS' },
    {
      id: 14,
      name: 'VEGETABLE PLAITING MATERIALS; VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED'
    },
    { id: 15, name: 'ANIMAL OR VEGETABLE FATS AND OILS; OTHERS' },
    { id: 16, name: 'PREPARATIONS OF MEAT, OF FISH OR OF CRUSTACEANS, OTHERS' },
    { id: 17, name: 'SUGARS AND SUGAR CONFECTIONERY' },
    { id: 18, name: 'COCOA AND COCOA PREPARATIONS' },
    {
      id: 19,
      name: "PREPARATIONS OF CEREALS, FLOUR, STARCH OR MILK; PASTRYCOOKS' PRODUCTS"
    },
    {
      id: 20,
      name: 'PREPARATIONS OF VEGETABLES, FRUIT, NUTS OR OTHER PARTS OF PLANTS'
    },
    { id: 21, name: 'MISCELLANEOUS EDIBLE PREPARATIONS' },
    { id: 22, name: 'BEVERAGES, SPIRITS AND VINEGAR' },
    { id: 23, name: 'RESIDUES AND WASTE FROM THE FOOD INDUSTRIES; OTHERS' },
    { id: 24, name: 'TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES' },
    {
      id: 25,
      name: 'SALT; SULPHUR; EARTHS AND STONE; PLASTERING MATERIALS, LIME AND CEMENT'
    },
    { id: 26, name: 'ORES, SLAG AND ASH' },
    {
      id: 27,
      name: 'MINERAL FUELS, MINERAL OILS, BITUMINOUS SUBSTANCES; MINERAL WAXES'
    },
    {
      id: 28,
      name: 'INORGANIC CHEMICALS; ORGANIC OR INORGANIC COMPOUNDS OF PRECIOUS METALS, OTHERS'
    },
    { id: 29, name: 'ORGANIC CHEMICALS' },
    { id: 30, name: 'PHARMACEUTICAL PRODUCTS' },
    { id: 31, name: 'FERTILISERS' },
    {
      id: 32,
      name: 'TANNING OR DYEING EXTRACTS; TANNINS AND THEIR DERIVATIVES; OTHERS'
    },
    {
      id: 33,
      name: 'ESSENTIAL OILS AND RESINOIDS; PERFUMERY, COSMETIC OR TOILET PREPARATIONS'
    },
    { id: 34, name: 'SOAP, ORGANIC SURFACE-ACTIVE AGENTS, OTHERS' },
    {
      id: 35,
      name: 'ALBUMINOIDAL SUBSTANCES; MODIFIED STARCHES; GLUES; ENZYMES'
    },
    { id: 36, name: 'EXPLOSIVES; PYROTECHNIC PRODUCTS; MATCHES; OTHERS' },
    { id: 37, name: 'PHOTOGRAPHIC OR CINEMATOGRAPHIC GOODS' },
    { id: 38, name: 'MISCELLANEOUS CHEMICAL PRODUCTS' },
    { id: 39, name: 'PLASTICS AND ARTICLES THEREOF' },
    { id: 40, name: 'RUBBER AND ARTICLES THEREOF' },
    { id: 41, name: 'RAW HIDES AND SKINS (OTHER THAN FURSKINS) AND LEATHER' },
    {
      id: 42,
      name: 'ARTICLES OF LEATHER; ARTICLES OF ANIMAL GUT (OTHER THAN SILKWORM GUT), OTHERS'
    },
    { id: 43, name: 'FURSKINS AND ARTIFICIAL FUR; MANUFACTURES THEREOF' },
    { id: 44, name: 'WOOD AND ARTICLES OF WOOD; WOOD CHARCOAL' },
    { id: 45, name: 'CORK AND ARTICLES OF CORK' },
    {
      id: 46,
      name: 'MANUFACTURES OF STRAW, OF ESPARTO OR OF OTHER PLAITING MATERIALS '
    },
    {
      id: 47,
      name: 'PULP OF WOOD OR OF OTHER FIBROUS CELLULOSIC MATERIAL, OTHERS,'
    },
    {
      id: 48,
      name: 'PAPER AND PAPERBOARD; ARTICLES OF PAPER PULP, OF PAPER OR OF PAPERBOARD'
    },
    {
      id: 49,
      name: 'BOOKS, NEWSPAPERS, PICTURES AND OTHER PRODUCTS OF THE PRINTING INDUSTRY; OTHERS'
    },
    { id: 50, name: 'SILK' },
    {
      id: 51,
      name: 'WOOL, FINE OR COARSE ANIMAL HAIR; HORSEHAIR YARN AND WOVEN FABRIC'
    },
    { id: 52, name: 'COTTON' },
    {
      id: 53,
      name: 'OTHER VEGETABLE TEXTILE FIBRES; PAPER YARN AND WOVEN FABRICS OF PAPER YARN'
    },
    { id: 54, name: 'MAN-MADE FILAMENTS' },
    { id: 55, name: 'MAN-MADE STAPLE FIBRES' },
    { id: 56, name: 'WADDING, FELT AND NONWOVENS; OTHERS' },
    { id: 57, name: 'CARPETS AND OTHER TEXTILE FLOOR COVERINGS' },
    {
      id: 58,
      name: 'SPECIAL WOVEN FABRICS; TUFTED TEXTILE FABRICS; LACE; TAPESTRIES; OTHERS'
    },
    {
      id: 59,
      name: 'IMPREGNATED, COATED, COVERED OR LAMINATED TEXTILE FABRICS; OTHERS'
    },
    { id: 60, name: 'LNITTED OR CROCHETED FABRICS' },
    {
      id: 61,
      name: 'ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED'
    },
    {
      id: 62,
      name: 'ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, NOT KNITTED OR CROCHETED'
    },
    { id: 63, name: 'OTHER MADE-UP TEXTILE ARTICLES; SETS; RAGS, OTHERS' },
    { id: 64, name: 'FOOTWEAR, GAITERS AND THE LIKE; PARTS OF SUCH ARTICLES' },
    { id: 65, name: 'HEADGEAR AND PARTS THEREOF' },
    {
      id: 66,
      name: 'UMBRELLAS, SUN UMBRELLAS, WALKING-STICKS, SEAT-STICKS, WHIPS, RIDING-CROPS, OTHERS'
    },
    {
      id: 67,
      name: 'PREPARED FEATHERS AND ARTICLES MADE OF FEATHERS OR OF DOWN; OTHERS'
    },
    {
      id: 68,
      name: 'ARTICLES OF STONE, PLASTER, CEMENT, ASBESTOS, MICA OR SIMILAR MATERIALS'
    },
    { id: 69, name: 'CERAMIC PRODUCTS' },
    { id: 70, name: 'GLASS AND GLASSWARE' },
    {
      id: 71,
      name: 'NATURAL OR CULTURED PEARLS, PRECIOUS OR SEMI-PRECIOUS STONES, OTHERS'
    },
    { id: 72, name: 'IRON AND STEEL' },
    { id: 73, name: 'ARTICLES OF IRON OR STEEL' },
    { id: 74, name: 'COPPER AND ARTICLES THEREOF' },
    { id: 75, name: 'NICKEL AND ARTICLES THEREOF' },
    { id: 76, name: 'ALUMINIUM AND ARTICLES THEREOF' },
    { id: 78, name: 'LEAD AND ARTICLES THEREOF' },
    { id: 79, name: 'ZINC AND ARTICLES THEREOF' },
    { id: 80, name: 'TIN AND ARTICLES THEREOF' },
    { id: 81, name: 'OTHER BASE METALS; CERMETS; ARTICLES THEREOF' },
    {
      id: 82,
      name: 'TOOLS, IMPLEMENTS, CUTLERY, SPOONS AND FORKS, OF BASE METAL; PARTS THEREOF OF BASE METAL'
    },
    { id: 83, name: 'MISCELLANEOUS ARTICLES OF BASE METAL' },
    {
      id: 84,
      name: 'NUCLEAR REACTORS, BOILERS, MACHINERY AND MECHANICAL APPLIANCES; OTHERS'
    },
    {
      id: 85,
      name: 'ELECTRICAL MACHINERY AND EQUIPMENT AND PARTS THEREOF; OTHERS'
    },
    {
      id: 86,
      name: 'RAILWAY OR TRAMWAY LOCOMOTIVES, ROLLING-STOCK AND PARTS THEREOF; OTHERS'
    },
    {
      id: 87,
      name: 'VEHICLES OTHER THAN RAILWAY OR TRAMWAY ROLLING-STOCK, AND PARTS AND ACCESSORIES THEREOF'
    },
    { id: 88, name: 'AIRCRAFT, SPACECRAFT, AND PARTS THEREOF' },
    { id: 89, name: 'SHIPS, BOATS AND FLOATING STRUCTURES' },
    {
      id: 90,
      name: 'OPTICAL, PHOTOGRAPHIC, CINEMATOGRAPHIC INSTRUMENTS; OTHERS'
    },
    { id: 91, name: 'CLOCKS AND WATCHES AND PARTS THEREOF' },
    {
      id: 92,
      name: 'MUSICAL INSTRUMENTS; PARTS AND ACCESSORIES OF SUCH ARTICLES'
    },
    { id: 93, name: 'ARMS AND AMMUNITION; PARTS AND ACCESSORIES THEREOF' },
    {
      id: 94,
      name: 'FURNITURE; BEDDING, MATTRESSES, CUSHIONS AND SIMILAR STUFFED FURNISHINGS; OTHERS'
    },
    {
      id: 95,
      name: 'TOYS, GAMES AND SPORTS REQUISITES; PARTS AND ACCESSORIES THEREOF'
    },
    { id: 96, name: 'MISCELLANEOUS MANUFACTURED ARTICLES' },
    { id: 97, name: "WORKS OF ART, COLLECTORS' PIECES AND ANTIQUES" },
    { id: 99, name: 'Special operations' },
    { id: 10001, name: 'ADVOCACY' },
    { id: 10002, name: 'ADVISORY' },
    { id: 10003, name: 'BANKS' },
    { id: 10004, name: 'CURRENCY EXCHANGE' },
    { id: 10005, name: 'HALAL CERTIFIERS' },
    { id: 10006, name: 'CUSTOMS BROKERS' },
    { id: 10007, name: 'LOGISTICS' },
    { id: 10008, name: 'OTHERS' },
    { id: 10009, name: 'IT AND SOFTWARE (SYSTEMS)' },
    { id: 10010, name: 'TOURISM' },
    { id: 10011, name: 'FERTILE EGGS' },
    { id: 10012, name: 'COFFEE AND DERIVATIVES' },
    { id: 10013, name: 'TEA, MATE AND SPICES' },
    { id: 10014, name: 'RAW SUGAR' },
    { id: 10015, name: 'BAKERY PRODUCTS' }
  ];

  primaryChapterPT = [
    { id: 1, name: 'Animais vivos' },
    { id: 2, name: 'Carnes e miudezas, comestíveis' },
    {
      id: 3,
      name: 'Peixes e crustáceos, moluscos e outros invertebrados aquáticos'
    },
    {
      id: 4,
      name: 'Leite e lacticínios; ovos de aves; mel natural; produtos comestíveis de origem animal, não especificados nem compreendidos noutros Capítulos'
    },
    {
      id: 5,
      name: 'Outros produtos de origem animal, não especificados nem compreendidos noutros Capítulos'
    },
    { id: 6, name: 'Plantas vivas e produtos de floricultura' },
    {
      id: 7,
      name: 'Produtos hortícolas, plantas, raízes e tubérculos, comestíveis.'
    },
    { id: 8, name: 'Frutas; cascas de frutos cítricos e de melões' },
    { id: 9, name: 'Café, chá, mate e especiarias' },
    { id: 10, name: 'Cereais' },
    {
      id: 11,
      name: 'Produtos da indústria de moagem; malte; amidos e féculas; inulina; glúten de trigo'
    },
    {
      id: 12,
      name: 'Sementes e frutos oleaginosos; grãos, sementes e frutos diversos; plantas industriais ou medicinais; palhas e forragens'
    },
    { id: 13, name: 'Gomas, resinas e outros sucos e extratos vegetais' },
    {
      id: 14,
      name: 'Matérias para entrançar e outros produtos de origem vegetal, não especificados nem compreendidos noutros Capítulos'
    },
    {
      id: 15,
      name: 'Gorduras e óleos animais ou vegetais; produtos da sua dissociação; gorduras alimentares elaboradas; ceras de origem animal ou vegetal'
    },
    {
      id: 16,
      name: 'Preparações de carne, de peixes ou de crustáceos, de moluscos ou de outros invertebrados aquáticos'
    },
    { id: 17, name: 'Açúcares e produtos de confeitaria' },
    { id: 18, name: 'Cacau e suas preparações' },
    {
      id: 19,
      name: 'Preparações à base de cereais, farinhas, amidos, féculas ou leite; produtos de pastelaria'
    },
    {
      id: 20,
      name: 'Preparações de produtos hortícolas, de frutas ou de outras partes de plantas'
    },
    { id: 21, name: 'Preparações alimentícias diversas' },
    { id: 22, name: 'Bebidas, líquidos alcoólicos e vinagres' },
    {
      id: 23,
      name: 'Resíduos e desperdícios das indústrias alimentares; alimentos preparados para animais'
    },
    { id: 24, name: 'Tabaco e seus sucedâneos manufaturados' },
    { id: 25, name: 'Sal; enxofre; terras e pedras; gesso, cal e cimento' },
    { id: 26, name: 'Minerios, escórias e cinzas' },
    {
      id: 27,
      name: 'Combustíveis minerais, óleos minerais e produtos da sua destilação; matérias betuminosas; ceras minerais'
    },
    {
      id: 28,
      name: 'Produtos químicos inorgânicos; compostos inorgânicos ou orgânicos de metais preciosos, de elementos radioativos, de metais das terras raras ou de isótopos'
    },
    { id: 29, name: 'Produtos químicos orgânicos' },
    { id: 30, name: 'Produtos farmacêuticos' },
    { id: 31, name: 'Adubos (fertilizantes)' },
    {
      id: 32,
      name: 'Extratos tanantes e tintoriais; taninos e seus derivados; pigmentos e outras matérias corantes; tintas e vernizes; mástiques; tintas de escrever'
    },
    {
      id: 33,
      name: 'Óleos essenciais e resinóides; produtos de perfumaria ou de toucador preparados e preparações cosméticas'
    },
    {
      id: 34,
      name: 'Sabões, agentes orgânicos de superfície, preparações para lavagem, preparações lubrificantes, "ceras" artificiais, ceras preparadas, produtos de conservação e limpeza, velas e artigos semelhantes, massas ou pastas para modelar, ceras para dentistas e Comp'
    },
    {
      id: 35,
      name: 'Matérias albuminóides; produtos à base de amidos ou de féculas modificados; colas; enzimas'
    },
    {
      id: 36,
      name: 'Pólvoras e explosivos; artigos de pirotecnia; fósforos; ligas pirofóricas; matérias inflamáveis'
    },
    { id: 37, name: 'Produtos para fotografia e cinematografia' },
    { id: 38, name: 'Produtos diversos das indústrias químicas' },
    { id: 39, name: 'Plásticos e suas obras' },
    { id: 40, name: 'Borracha e suas obras' },
    { id: 41, name: 'Peles, exceto as peles com pelo, e couros' },
    {
      id: 42,
      name: 'Obras de couro; artigos de correiro ou de seleiro; artigos de viagem, bolsas e artefatos semelhantes; obras de tripa'
    },
    { id: 43, name: 'Peles com pelo e suas obras; peles com pelo artificiais' },
    { id: 44, name: 'Madeira, carvão vegetal e obras de madeira' },
    { id: 45, name: 'Cortiça e suas obras' },
    { id: 46, name: 'Obras de espartaria ou de cestaria' },
    {
      id: 47,
      name: 'Pastas de madeira ou de outras matérias fibrosas celulósicas; papel ou cartão para reciclar (desperdícios e aparas).'
    },
    {
      id: 48,
      name: 'Papel e cartão; obras de pasta de celulose, de papel ou de cartão'
    },
    {
      id: 49,
      name: 'Livros, jornais, gravuras e outros produtos das indústrias gráficas; textos manuscritos ou datilografados, planos e plantas'
    },
    { id: 50, name: 'Seda' },
    { id: 51, name: 'Lã, pelos finos ou grosseiros; fios e tecidos de crina' },
    { id: 52, name: 'Algodão' },
    {
      id: 53,
      name: 'Outras fibras têxteis vegetais; fios de papel e tecidos de fios de papel'
    },
    { id: 54, name: 'Filamentos sintéticos ou artificiais' },
    { id: 55, name: 'Fibras sintéticas ou artificiais, descontínuas' },
    {
      id: 56,
      name: 'Pastas (ouates), feltros e falsos tecidos; fios especiais; cordéis, cordas e cabos; artigos de cordoaria'
    },
    {
      id: 57,
      name: 'Tapetes e outros revestimentos para pisos (pavimentos), de matérias têxteis'
    },
    {
      id: 58,
      name: 'Tecidos especiais; tecidos tufados; rendas; tapeçarias; passamanarias; bordados.'
    },
    {
      id: 59,
      name: 'Tecidos impregnados, revestidos, recobertos ou estratificados; artigos para usos técnicos de matérias têxteis'
    },
    { id: 60, name: 'Tecidos de malha' },
    { id: 61, name: 'Vestuário e seus acessórios, de malha' },
    { id: 62, name: 'Vestuário e seus acessórios, exceto de malha' },
    {
      id: 63,
      name: 'Outros artefatos têxteis confeccionados; sortidos; artefatos de matérias têxteis, calçados, chapéus e artefatos de uso semelhante, usados; trapos'
    },
    { id: 64, name: 'Calçados, polainas e artefatos semelhantes; suas partes' },
    { id: 65, name: 'Chapéus e artefatos de uso semelhante, e suas partes' },
    {
      id: 66,
      name: 'Guarda-chuvas, sombrinhas, guarda-sóis, bengalas, bengalas-assentos, chicotes, pingalins, e suas partes'
    },
    {
      id: 67,
      name: 'Penas e penugem preparadas e suas obras; flores artificiais; obras de cabelo'
    },
    {
      id: 68,
      name: 'Obras de pedra, gesso, cimento, amianto, mica ou de matérias semelhantes'
    },
    { id: 69, name: 'Produtos cerâmicos' },
    { id: 70, name: 'Vidro e suas obras' },
    {
      id: 71,
      name: 'Pérolas naturais ou cultivadas, pedras preciosas ou semipreciosas e semelhantes, metais preciosos, metais folheados ou chapeados de metais preciosos (plaquê), e suas obras; bijuterias; moedas'
    },
    { id: 72, name: 'Ferro fundido, ferro e aço' },
    { id: 73, name: 'Obras de ferro fundido, ferro ou aço' },
    { id: 74, name: 'Cobre e suas obras' },
    { id: 75, name: 'Níquel e suas obras' },
    { id: 76, name: 'Alumínio e suas obras' },
    { id: 78, name: 'Chumbo e suas obras' },
    { id: 79, name: 'Zinco e suas obras' },
    { id: 80, name: 'Estanho e suas obras' },
    {
      id: 81,
      name: 'Outros metais comuns; ceramais (cermets); obras dessas matérias'
    },
    {
      id: 82,
      name: 'Ferramentas, artefatos de cutelaria e talheres, e suas partes, de metais comuns'
    },
    { id: 83, name: 'Obras diversas de metais comuns' },
    {
      id: 84,
      name: 'Reatores nucleares, caldeiras, máquinas, aparelhos e instrumentos mecânicos, e suas partes'
    },
    {
      id: 85,
      name: 'Máquinas, aparelhos e materiais elétricos, e suas partes; aparelhos de gravação ou de reprodução de som, aparelhos de gravação ou de reprodução de imagens e de som em televisão, e suas partes e acessórios'
    },
    {
      id: 86,
      name: 'Veículos e material para vias férreas ou semelhantes, e suas partes; aparelhos mecânicos (incluindo os eletromecânicos) de sinalização para vias de comunicação'
    },
    {
      id: 87,
      name: 'Veículos automóveis, tratores, ciclos e outros veículos terrestres, suas partes e acessórios'
    },
    { id: 88, name: 'Aeronaves e aparelhos espaciais, e suas partes' },
    { id: 89, name: 'Embarcações e estruturas flutuantes' },
    {
      id: 90,
      name: 'Instrumentos e aparelhos de óptica, de fotografia, de cinematografia, de medida, de controle ou de precisão; instrumentos e aparelhos médico-cirúrgicos; suas partes e acessórios'
    },
    { id: 91, name: 'Artigos de relojoaria' },
    { id: 92, name: 'Instrumentos musicais; suas partes e acessórios' },
    { id: 93, name: 'Armas e munições; suas partes e acessórios' },
    {
      id: 94,
      name: 'Móveis; mobiliário médico-cirúrgico, colchões, almofadas e semelhantes; aparelhos de iluminação não especificados nem compreendidos em outros capítulos; anúncios, cartazes ou tabuletas e placas indicadoras luminosos, e artigos semelhantes; Construções Pré'
    },
    {
      id: 95,
      name: 'Brinquedos, jogos, artigos para divertimento ou para esporte; suas partes e acessórios'
    },
    { id: 96, name: 'Obras diversas' },
    { id: 97, name: 'Objetos de arte, de coleção e antiguidades' },
    { id: 99, name: 'Transações especiais' },
    { id: 10001, name: 'Advocacia' },
    { id: 10002, name: 'Assessoria' },
    { id: 10003, name: 'Bancos' },
    { id: 10004, name: 'Câmbios' },
    { id: 10005, name: 'Certificadoras Halal' },
    { id: 10006, name: 'Despachantes aduaneiros' },
    { id: 10007, name: 'Logística' },
    { id: 10008, name: 'Outros' },
    { id: 10009, name: 'TI e software (sistemas)' },
    { id: 10010, name: 'Turismo' },
    { id: 10011, name: 'Ovos férteis' },
    { id: 10012, name: 'Café e derivados' },
    { id: 10013, name: 'Chá, mate e especiarias' },
    { id: 10014, name: 'Açúcar bruto' },
    { id: 10015, name: 'Produtos de confeitaria' }
  ];

  segmentsPT = [
    { id: 400, name: 'Cosméticos' },
    { id: 500, name: 'Defesa' },
    { id: 600, name: 'Máquinas e Equipamentos' },
    { id: 700, name: 'Moda' },
    { id: 900, name: 'Serviços' },
    { id: 1000, name: 'Químicos, Plásticos e Borrachas' },
    { id: 1100, name: 'Saúde' },
    { id: 800, name: 'Outros' }
  ];

  segmentsEN = [
    { id: 400, name: 'Cosmetics' },
    { id: 500, name: 'Defense' },
    { id: 600, name: 'Machinery, electronics and transport' },
    { id: 700, name: 'Fashion: garments, footwear, cosmetics and jewelry' },
    { id: 900, name: 'Services' },
    { id: 1000, name: 'Chemicals, plastics and rubber' },
    { id: 1100, name: 'Hospital medical products' },
    { id: 800, name: 'Others' }
  ];
  constructor() {}

  getprimaryChapter(language: string) {
    switch (language.toUpperCase()) {
      case 'PT':
        return this.primaryChapterPT;
      case 'EN':
        return this.primaryChapterEN;
    }
  }

  getSegments(language: string) {
    switch (language.toUpperCase()) {
      case 'PT':
        return this.segmentsPT;
      case 'EN':
        return this.segmentsEN;
    }
  }
}
