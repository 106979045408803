import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { AssociateService } from '../../services/associate.service';
import { ProfileImageAction } from '../../actions/profile-image.action';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
// import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { ImageCropperComponent } from '@ccab/components-lib';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
  @Input() edit: boolean = false;
  @Input() size: number = 50;
  @Input() saveEvent: Observable<boolean> = new Observable<boolean>();

  url: string | SafeResourceUrl = '../../../../assets/img/profile-placeholder.png';
  isDragOver: boolean = false;
  isLoggedin: boolean = false;
  profileImage$: Observable<string>;
  destroy$ = new Subject();
  updating: boolean;
  currentImage: string | SafeResourceUrl;

  _file: any;
  constructor(
    private associateService: AssociateService,
    private readonly dialog: MatDialog,
    private readonly sanitizer: DomSanitizer,
    private authService: AuthService,
    private readonly profileImageStore: Store<{ profileImage: string }>
  ) {
    this.profileImage$ = this.profileImageStore.pipe(select('profileImage'));
  }

  async ngOnInit() {
    this.authService
      .isLoggedIn()
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isLoggedin = status;
      });

    this.profileImage$.pipe(takeUntil(this.destroy$)).subscribe(async (url: string) => {
      if (!url) {
        try {
          let file;
          if (this.isLoggedin) {
            file = await this.associateService.getProfileImage();
          }
          file === '' || !file ? (this.url = '../../../../assets/img/profile-placeholder.png') : (this.url = 'data:image/png;base64,' + file);
          this.currentImage = this.url;
          this.setImageProfile(this.url);
        } catch (e) {
          this.url = '../../../../assets/img/profile-placeholder.png';
          this.setImageProfile(this.url);
        }
      } else {
        url === '' || !url ? (this.url = '../../../../assets/img/profile-placeholder.png') : (this.url = url);
      }
    });

    this.saveEvent.pipe(takeUntil(this.destroy$)).subscribe((save) => {
      if (save) {
        this.uploadImage();
      } else if (!save && this.currentImage) {
        this.url = this.currentImage;
        this._file = undefined;
      }
    });
  }
  openCropperDialog(file: File) {
    if (this.isValidFile(file)) {
      let dialogRef = this.dialog.open(ImageCropperComponent, {
        data: {
          file: file,
          aspectRatio: 1 / 1,
          roundCropper: true,
          resizeToWidth: 300,
          resizeToHeight: 300,
          cropperMinWidth: 200
        }
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((e) => {
          if (e) {
            this._file = new File([e], 'profile.jpg');
            this.changeImage();
          }
        });
    }
  }

  onDragOverEvent(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.edit) {
      this.isDragOver = true;
    }
  }
  async onFileDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.edit) {
      if (this.isValidFile(event.dataTransfer.files[0])) {
        this._file = event.dataTransfer.files[0];
        // this.changeImage();
        this.openCropperDialog(event.dataTransfer.files[0]);
      }

      this.isDragOver = false;
    }
  }
  changeImage() {
    var reader = new FileReader();
    reader.readAsDataURL(this._file);
    reader.onload = (event) => {
      this.url = this.sanitizer.bypassSecurityTrustUrl(event.target.result.toString());
    };
  }

  async imageAdd(event) {
    if (this.isValidFile(event[0])) {
      this._file = event.item(0);

      this.openCropperDialog(event.item(0));
    }
  }
  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.edit) {
      this.isDragOver = false;
    }
  }

  async uploadImage() {
    if (this._file) {
      try {
        this.updating = true;
        let response = await this.associateService.updateProfileImage(this._file);
        if (response.success) {
          var reader = new FileReader();
          this.setImageProfile(this.url);
          this._file = undefined;
        }
        this.updating = false;
      } catch (e) {
        this.updating = false;
      }
    }
  }

  setImageProfile(url) {
    this.profileImageStore.dispatch(ProfileImageAction(url));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  isValidFile(file) {
    return file.type.split('/')[0] === 'image';
  }
}
