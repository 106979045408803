import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RemoveEmoji } from '../utils/remove-emoji';

@Directive({
  selector: '[appPreventEmoji]'
})
export class PreventEmojiDirective {
  constructor(private readonly _el: ElementRef, private control: NgControl) {}
  @HostListener('input', ['$event.target.value']) // Fired when manually changing the input value
  onInput(value: string) {
    this._el.nativeElement.value = RemoveEmoji(value);
    this.control.control.setValue(this._el.nativeElement.value);
  }
}
