import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { SessionUser } from 'src/app/shared/models/session-user';
import { Meeting } from '../../interfaces/meeting.interface';
import { MeetingService } from '../../services/meeting.service';

@Component({
  selector: 'app-meeting-room',
  templateUrl: './meeting-room.component.html',
  styleUrls: ['./meeting-room.component.scss']
})
export class MeetingRoomComponent implements OnInit {
  private jitsi: any;
  participantId: string;
  roomName: string;
  userName: string = '';
  userPicture: string;
  options = {
    roomName: '',
    parentNode: document.querySelector('#meet'),

    userInfo: {
      displayName: this.userName
    }
  };

  loading: boolean = true;
  meeting$: Observable<Meeting>;
  sessionUser$: Observable<SessionUser>;
  profileImage$: Observable<string>;

  constructor(
    private readonly userService: AuthService,
    private readonly router: Router,
    private readonly meetingService: MeetingService,
    private readonly meetingStore: Store<{ meeting: Meeting }>,
    private readonly profileImageStore: Store<{ profileImage: string }>,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>
  ) {
    this.meeting$ = meetingStore.pipe(select('meeting'));
    this.profileImage$ = this.profileImageStore.pipe(select('profileImage'));

    this.sessionUser$ = this.sessionUserStore.pipe(select('sessionUser'));
  }

  ngOnInit() {
    this.sessionUser$.pipe(take(1)).subscribe((user: SessionUser) => {
      this.userName = user.name;

      this.options.userInfo.displayName = this.userName;
    });
    this.profileImage$.pipe(take(1)).subscribe(async (url: string) => {
      this.userPicture = url;
    });
    this.meeting$.pipe(take(1)).subscribe(async (meeting: Meeting) => {
      this.roomName = meeting.id;
    });

    if (!this.roomName) {
      this.router.navigateByUrl('/Matchmaking/Meetings');
    }

    this.options.roomName = this.roomName;

    const domain = 'meet.jit.si';

    this.options['parentNode'] = document.querySelector('#meet');
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, this.options); // Jitsi running in local docker container
    this.jitsi.executeCommand('avatarUrl', this.userPicture);
    this.jitsi.addEventListener('participantRoleChanged', (event) => {
      if (event.role === 'moderator') {
        this.jitsi.executeCommand('toggleLobby', true);
      }
    });
    this.jitsi.addEventListener('videoConferenceLeft', (e) => {
      document.getElementById('meet').innerHTML = undefined;
      this.router.navigateByUrl('/Matchmaking/Meetings/ClosedMeeting');
    });
    this.jitsi.on('tileViewChanged', (e) => {
      this.loading = false;
    });
  }

  ngAfterViewInit() {}
}
