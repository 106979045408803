export enum ActionTypes {
  ChangeLanguage = 'CHANGE_LANGUAGE',
  ChangeCompany = 'CHANGE_COMPANY',
  ChangeTheme = 'CHANGE_THEME',
  QueryNewProducts = 'QUERY_NEW_PRODUCTS',
  QueryNewCategory = 'QUERY_NEW_CATEGORY',
  QueryNewProposal = 'QUERY_NEW_PROPOSAL',
  Meeting = 'MEETING',
  QueryNewMatchmaking = 'QUERY_NEW_MATCHMAKING',
  MatchmakingId = 'MATCHMAKING_ID',
  ProfileImage = 'PROFILE_IMAGE',
  SessionUser = 'SESSION_USER',
  QueryCompanies = 'QUERY_COMPANIES',
  QueryCompanyMembers = 'QUERY_COMPANY_MEMBERS',
  SessionCompanies = 'SESSION_COMPANIES',
  Timezones = 'TIMEZONES',
  Sectors = 'SECTORS',
  Categories = 'SESSION_CATEGORIES',
  UnitsMeasure = 'UNITS_MEASURE',
  MonitoringQuery = 'MONITORING_QUERY',
  CompanyChangeFailValidation = 'CompanyChangeFailValidation',
  CompanyChangeSuccessValidation = 'CompanyChangeSuccessValidation',
  ChangeReport = 'CHANGE_REPORT_EXPORTATION',
  QueryListInvolvedCompanies = 'QUERY_LIST_INVOLVED_COMPANIES'
}
