import { Action, createReducer, on } from '@ngrx/store';
import * as OriginCertificateAction from '../actions/origin-certificate-query.action';
import { OriginCertificateQuery } from '../interfaces/OriginCertificateQuery.interface';
export const initialState: OriginCertificateQuery = {
  pageIndex: 0,
  pageSize: 10,
  idCompany: undefined,
  finalDate: undefined,
  startDate: undefined,
  certificateNumber: [],
  invoiceNumber: [],
  repeat: false,
  columnName: 'c.CREATE_AT',
  direction: 'DESC'
};

const originCertificate = createReducer(
  initialState,
  on(OriginCertificateAction.date, (state, action) => {
    return {
      ...state,
      startDate: action.startDate,
      finalDate: action.finalDate,
      pageIndex: 0
    };
  }),
  on(OriginCertificateAction.companyOriginCertificate, (state, action) => {
    return { ...initialState, idCompany: action.idCompany, pageIndex: 0 };
  }),
  on(OriginCertificateAction.certificateNumber, (state, action) => {
    return {
      ...state,
      certificateNumber: action.certificateNumber,
      pageIndex: 0
    };
  }),
  on(OriginCertificateAction.invoiceNumber, (state, action) => {
    return { ...state, invoiceNumber: action.invoiceNumber, pageIndex: 0 };
  }),
  on(OriginCertificateAction.statusAction, (state, action) => {
    return { ...state, status: action.status, pageIndex: 0 };
  }),
  on(OriginCertificateAction.repeatQuery, (state, action) => {
    return { ...state, repeat: action.repeat };
  }),
  on(OriginCertificateAction.clearFilter, (state, action) => {
    return { ...initialState, idCompany: state.idCompany };
  }),
  on(OriginCertificateAction.pageIndex, (state, action) => {
    return { ...state, pageIndex: action.pageIndex };
  }),
  on(OriginCertificateAction.pageConfig, (state, action) => {
    return { ...state, pageIndex: action.pageIndex, pageSize: action.pageSize };
  })
);

export function originCertificateQueryReducer(state: OriginCertificateQuery | undefined, action: Action) {
  return originCertificate(state, action);
}
