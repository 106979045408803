import { ErrorState } from './../interfaces/error-state';
import { createReducer, on } from '@ngrx/store';
import { changeError, clearError } from '../actions/error.action';

export const InitialErrorState: ErrorState = {
  error: undefined,
  errorCode: undefined,
  additionalInfo: undefined,
  additionalInfoMore: []
};

export const errorReducer = createReducer<ErrorState>(
  InitialErrorState,
  on(changeError, (errorState, action) => {
    const errcode =
      action?.errorState && action?.errorState?.additionalInfoMore
        ? action?.errorState?.additionalInfoMore.length > 0
          ? errorState.errorCode
            ? errorState.errorCode[0]
            : errorState.errorCode
          : errorState.errorCode
        : errorState.errorCode;
    const x = {
      ...errorState,
      additionalInfo: action?.errorState?.additionalInfo,
      additionalInfoMore: action?.errorState?.additionalInfoMore,
      errorCode: errcode
    };
    return x;
  }),
  on(clearError, () => {
    return {
      ...InitialErrorState
    };
  })
);
