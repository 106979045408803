import { Md5 } from 'ts-md5/dist/md5';
import { Injectable } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { parseJwt } from 'src/app/shared/utils/parse-jwt';
import Login from 'src/app/core/interfaces/login.interface';

import { CrmService } from './crm-service.service';
import { AssociateService } from './associate.service';
import { WindowReferenceService } from './window-reference.service';
import { CompanyService } from 'src/app/core/services/company.service';

import { Sector } from '../models/sector';
import { PrimaryChapter } from '../models/primary-chapter';
import { CompanyDetail } from 'src/app/modules/settings/models/company-detail';
import { ProfileDetails } from 'src/app/modules/settings/models/profile-details';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private window;

  userEmailMD5: string;
  userId: string;
  pageName: string;
  EntityCompany: CompanyDetail;
  language: string;
  setor: Sector;
  primaryChapter: PrimaryChapter;
  CompanyEmailMD5: string;
  user: ProfileDetails;
  CompanyProduct: string;

  constructor(
    private _windowRef: WindowReferenceService,
    private entitycompany: CompanyService,
    private crmservice: CrmService,
    private readonly associateService: AssociateService,
    private cookieService: CookieService
  ) {
    this.window = _windowRef.nativeWindow;
  }

  async setPageName(eventChangePage?: NavigationStart) {
    this.EntityCompany = JSON.parse(localStorage.getItem('EntityCompany'));
    this.CompanyProduct = JSON.parse(localStorage.getItem('ProductCompany'));
    if (this.EntityCompany?.cnpj != null) {
      this.CompanyEmailMD5 = Md5.hashStr(this.EntityCompany.cnpj).toString();
    }
    if (this.EntityCompany?.sectorId != null) {
      try {
        this.setor = await this.crmservice.getSectorById(this.EntityCompany.sectorId, 'English');
      } catch (e) {}
    }
    if (this.EntityCompany?.primaryChapterId != null) {
      this.primaryChapter = await this.crmservice.getPrimaryChapterById(this.EntityCompany.sectorId, this.EntityCompany.primaryChapterId, 'English');
    }
    this.user = JSON.parse(localStorage.getItem('userEntity'));
    if (this.user?.email != null) {
      this.userEmailMD5 = Md5.hashStr(this.user.email).toString();
    }
    let _parseJwt = parseJwt(this.cookieService.get('token'));
    this.userId = _parseJwt?.aud;
    this.pageName = eventChangePage?.url;
    this.pushValues();
  }
  async InitCompany() {
    this.EntityCompany = JSON.parse(localStorage.getItem('EntityCompany'));
    this.CompanyProduct = JSON.parse(localStorage.getItem('ProductCompany'));
    if (this.EntityCompany?.cnpj != null) {
      this.CompanyEmailMD5 = Md5.hashStr(this.EntityCompany.cnpj).toString();
    }
    if (this.EntityCompany?.sectorId != null) {
      try {
        this.setor = await this.crmservice.getSectorById(this.EntityCompany.sectorId, 'English');
      } catch (e) {}
    }
    if (this.EntityCompany.primaryChapterId != null) {
      this.primaryChapter = await this.crmservice.getPrimaryChapterById(this.EntityCompany.sectorId, this.EntityCompany.primaryChapterId, 'English');
    }
    this.user = JSON.parse(localStorage.getItem('userEntity'));
    if (this.user?.email != null) {
      this.userEmailMD5 = Md5.hashStr(this.user.email).toString();
    }
    let _parseJwt = parseJwt(this.cookieService.get('token'));
    this.userId = _parseJwt?.aud;
    this.pushValues();
  }
  async setInfoUser(login: Login) {
    let user = await this.associateService.getProfileDetails();
    localStorage.setItem('userEntity', JSON.stringify(user));
  }

  pushValues() {
    const dataLayers = {
      pageName: this.pageName,
      user: {
        internal: this.userEmailMD5,
        id: this.userId
      },
      company: {
        name: this.EntityCompany?.name,
        internal: this.CompanyEmailMD5 != null ? this.CompanyEmailMD5 : undefined,
        city: this.EntityCompany?.city != null ? this.EntityCompany?.city : undefined,
        state: this.EntityCompany?.state != null ? this.EntityCompany.state : undefined,
        country: this.EntityCompany?.country?.name != null ? this.EntityCompany?.country?.name : undefined,
        sector: this.setor?.name != null ? this.setor?.name : undefined,
        segment: this.EntityCompany?.primaryChapterId != null ? this.primaryChapter?.name : undefined,
        products: this.CompanyProduct,
        producs: [{}]
      }
    };
    this.window.dataLayer?.push(dataLayers);
  }
}
