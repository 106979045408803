import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/core/interfaces/company.interface';
import Language from 'src/app/core/interfaces/language.interface';
import Theme from 'src/app/core/interfaces/theme.interface';
import { CompanyService } from 'src/app/core/services/company.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MatchmackingId } from '../../interfaces/matchmaking-id';
import { MatchmakingDetails } from '../../models/matchmaking-details';
import { MatchmakingService } from '../../services/matchmaking.service';

@Component({
  selector: 'app-matchmaking-details',
  templateUrl: './matchmaking-details.component.html',
  styleUrls: ['./matchmaking-details.component.scss']
})
export class MatchmakingDetailsComponent implements OnInit {
  id: number;
  matchmakingId$: Observable<MatchmackingId>;
  destroy$ = new Subject();
  language$: Observable<Language>;
  matchmaking: MatchmakingDetails;
  company$: Observable<Company>;
  currentCompany: Company;
  date: string;
  firstLoad: boolean = true;
  loading: boolean = false;
  theme$: Observable<Theme>;
  color: string;
  constructor(
    private readonly router: Router,
    private readonly languageStore: Store<{ language: Language }>,
    private readonly languageService: LanguageService,
    private readonly matchmakingService: MatchmakingService,
    private readonly companyStore: Store<{ company: Company }>,
    private readonly themeStore: Store<{ theme: Theme }>,
    private readonly companyService: CompanyService,
    private readonly matchmakingStore: Store<{ matchmakingId: MatchmackingId }>
  ) {
    this.matchmakingId$ = matchmakingStore.pipe(select('matchmakingId'));
    this.language$ = languageStore.pipe(select('language'));
    this.company$ = companyStore.pipe(select('company'));
    this.currentCompany = this.companyService.getCurrentCompany();
    this.theme$ = themeStore.pipe(select('theme'));
  }

  async ngOnInit() {
    this.theme$.pipe(takeUntil(this.destroy$)).subscribe((newTheme: Theme) => {
      if (newTheme) {
        this.color = newTheme.name == 'ellos-dark-theme' ? '#005466' : '#00546611';
      }
    });
    this.matchmakingId$.pipe(takeUntil(this.destroy$)).subscribe(async (matchmakingId: MatchmackingId) => {
      this.id = matchmakingId?.id;
      if (this.id) await this.getDetails();
      else {
        this.backtMeetings();
      }
    });
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      if (!this.firstLoad && this.id) await this.getDetails();
    });

    this.company$.pipe(takeUntil(this.destroy$)).subscribe(async (comp: Company) => {
      if (!this.firstLoad) this.backtMeetings();
    });
    this.firstLoad = false;
  }

  async getDetails() {
    try {
      this.loading = true;
      this.matchmaking = undefined;
      this.matchmaking = await this.matchmakingService.matchmackingDetails({
        idMatchmaking: this.id,
        LanguageCode: this.languageService.getCurrentLanguage()
      });
      this.adjustDate();
      this.loading = false;
    } catch (e) {
      this.backtMeetings();
    }
  }

  adjustDate() {
    let offset = new Date().getTimezoneOffset();
    let dataLocal = new Date(new Date(this.matchmaking.creationDate).getTime() - offset * 60 * 1000);
    this.matchmaking.creationDate = new Date(new Date(this.matchmaking.creationDate).getTime() - offset * 60 * 1000);
    this.date = new Date(dataLocal).toLocaleDateString();
  }

  backtMeetings() {
    this.router.navigateByUrl('/Matchmaking/Meetings');
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
