import { Injectable, OnDestroy } from '@angular/core';
import { MatStepperIntl } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Subscription } from 'rxjs';

@Injectable()
export class EllosMatStepperIntl extends MatStepperIntl implements OnDestroy {
  private languageChange$: Subscription;

  constructor(private readonly translateService: TranslateService) {
    super();

    this.languageChange$ = this.translateService.onLangChange.subscribe(() => {
      this.changeOptionalLabel();
    });

    this.changeOptionalLabel();
  }

  private async changeOptionalLabel() {
    this.optionalLabel = await lastValueFrom(this.translateService.get('marketplace.dialog.optional'));
  }

  ngOnDestroy(): void {
    this.languageChange$.unsubscribe();
  }
}
