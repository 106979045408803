import { FormGroup } from '@angular/forms';
export function checkExpirationDate(formGroup: FormGroup) {
  const manufactoringDate = formGroup.get('manufactoringDate')?.value ?? 0;
  const expirationDate = formGroup.get('expirationDate')?.value ?? 0;

  if (manufactoringDate && expirationDate) {
    if (manufactoringDate > expirationDate) {
      return { checkExpirationDate: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
}
