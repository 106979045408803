import { CompanyExportationNotification } from 'src/app/modules/certificates/interfaces/exportation-notification.interface';
import { createAction, props } from '@ngrx/store';

export const enum notificationTypeAction {
  ADD_NOTIFICATION = '[NOTIFICATION] ADD NOTIFICATION',
  REMOVE_BY_ID_NOTIFICATION = '[NOTIFICATION] REMOVE BY ID NOTIFICATION'
}

export const AddNotification = createAction(notificationTypeAction.ADD_NOTIFICATION, props<{ _companyExportationNotification: CompanyExportationNotification[] }>());

export const RemoveById = createAction(notificationTypeAction.REMOVE_BY_ID_NOTIFICATION, props<{ id: number }>());
