import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-company-invitation',
  templateUrl: './company-invitation.component.html',
  styleUrls: ['./company-invitation.component.scss']
})
export class CompanyInvitationComponent implements OnInit {
  companyName: string;

  constructor(private readonly route: ActivatedRoute, private readonly router: Router) {
    this.companyName = route.snapshot.paramMap.get('companyName');
  }

  ngOnInit(): void {}
}
