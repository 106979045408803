import { Observable, throwError } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Component({
  templateUrl: './s3-files.component.html',
  styleUrls: ['./s3-files.component.scss']
})
export class S3FilesComponent implements OnInit {
  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  public fileToDownload: string;
  public messageToUser: string;

  async ngOnInit(): Promise<void> {
    this.fileToDownload = this.route.snapshot.params.file;

    await this.downloadFile(this.fileToDownload);
  }

  downloadFile(filename: string = null): void {
    window.location.href = `https://ccab-ellos-frontend-v2-prod.s3.amazonaws.com/news/${filename}`;

    //   const route = `https://ccab-ellos-frontend-v2-prod.s3.amazonaws.com/news/${filename}`;
    //   const header = new HttpHeaders({
    //     'Access-Control-Allow-Origin': '*',
    //   });

    //   this.http.get(route, { headers: header, responseType: 'blob' as 'json' }).subscribe(
    //     (response: any) => {
    //       const dataType = response.type;
    //       let binaryData = [];
    //       binaryData.push(response);
    //       let downloadLink = document.createElement('a');
    //       downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    //       if (filename) {
    //         downloadLink.setAttribute('download', filename);
    //       }
    //       document.body.appendChild(downloadLink);
    //       downloadLink.click();
    //       document.body.removeChild(downloadLink);
    //     }
    //   );
    // }
  }
}
