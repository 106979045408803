import { Ncm } from 'src/app/shared/models/ncm.interface';
import { moveElementOfArray } from 'src/app/shared/utils/move-element-of-array';
import { UnitMeasure } from '../../marketplace/interfaces/unitMeasure.interface';
import * as ProductToExportationAction from '../actions/products-exportation.action';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export interface ProductToExportation {
  id?: number;
  ncm: Ncm;
  unitMeasure: UnitMeasure;
  unitMeasureWeight: UnitMeasure;
  certificateId?: number;
  quantity: number;
  description: string;
  expirationDate: string;
  grossWeight: number;
  idUnitMeasure?: number;
  idUnitMeasureWeight?: number;
  manufactoringDate: string;
  marksAndNumbers?: string;
  netWeight: number;
  sif?: string;
  productValue?: number;
  currency: number;
  order?: number;
  valid: boolean;
}

export interface ProductToExportationState {
  productToExportation: ProductToExportation[];
}

export const initialState: ProductToExportationState = { productToExportation: [] };

const _productToExportation = createReducer(
  initialState,
  on(ProductToExportationAction.AddProductToExportation, (state, { _productToExportation }) => {
    return {
      ...state,
      productToExportation: [...state.productToExportation, _productToExportation].sort((a) => a?.order)
    };
  }),
  on(ProductToExportationAction.AddAllProductToExportation, (state, { _productToExportation }) => {
    return {
      ...state,
      productToExportation: _productToExportation
    };
  }),
  on(ProductToExportationAction.DeleteproductToExportationByIndex, (state, { _indexOfProductToExportation }) => ({
    ...state,
    productToExportation: orderToExportationReducer([...state.productToExportation], _indexOfProductToExportation)
  })),
  on(ProductToExportationAction.UpProductToExportation, (state, { _indexOfProductToExportation }) => ({
    ...state,
    productToExportation: moveElementOfArray([...state.productToExportation], _indexOfProductToExportation, _indexOfProductToExportation - 1).sort((a) => a?.order)
  })),
  on(ProductToExportationAction.DownProductToExportation, (state, { _indexOfProductToExportation }) => ({
    ...state,
    productToExportation: moveElementOfArray([...state.productToExportation], _indexOfProductToExportation, _indexOfProductToExportation + 1).sort((a) => a?.order)
  })),
  on(ProductToExportationAction.CleanProductsToExportation, (state) => ({
    ...state,
    productToExportation: []
  })),
  on(ProductToExportationAction.ValidateSifProductsToExportation, (state) => ({
    ...state,
    productToExportation: validateSif([...state.productToExportation])
  }))
);

export function productToExportationReducer(state = initialState, action: Action) {
  return _productToExportation(state, action);
}

export function validateSif(productToExportation: ProductToExportation[]): ProductToExportation[] {
  productToExportation.forEach((element) => {
    element.sif = element.sif != null ? element.sif : '';
  });
  return productToExportation;
}

export function orderToExportationReducer(productToExportation: ProductToExportation[], index): ProductToExportation[] {
  productToExportation.splice(index, 1);
  for (let i = 0; i < productToExportation.length; i++) {
    const order = i + 1;
    const updatedProduct = { ...productToExportation[i], order: order };
    productToExportation[i] = updatedProduct;
  }
  return productToExportation;
}

const getProductToExportationState = createFeatureSelector<ProductToExportationState>('productToExportation');

export const getProductToExportation = createSelector(getProductToExportationState, (state: ProductToExportationState) => state.productToExportation);
