import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngrx/store';
import Language from 'src/app/core/interfaces/language.interface';

import { GlobalizeCalendar } from '../../../../globalize-calendar';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends GlobalizeCalendar {
  constructor(dateAdapter: DateAdapter<Date>, langStore: Store<{ language: Language }>) {
    super(dateAdapter, langStore);
  }

  ngOnInit(): void {}
}
