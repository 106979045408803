<ng-template #empty>
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
    <p>{{ 'settings.companies.event.no-subscriptions' | translate }}</p>
  </div>
</ng-template>
<table mat-table [dataSource]="events" class="w100" *ngIf="events.data.length > 0; else empty">
  <ng-container matColumnDef="event">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'settings.companies.event.event' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.eventName }}</td>
  </ng-container>
  <ng-container matColumnDef="method">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'settings.companies.event.method' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element.method }}</td>
  </ng-container>
  <ng-container matColumnDef="url">
    <th mat-header-cell *matHeaderCellDef>URL</th>
    <td mat-cell *matCellDef="let element" [title]="element?.apiUrl">
      {{ element.apiUrl | truncate: 30 }}
    </td>
  </ng-container>
  <ng-container matColumnDef="header">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'settings.companies.event.auth-header' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" [title]="element?.apiAuthHeaderName">
      {{ element?.apiAuthHeaderName || '-' | truncate: 30 }}
    </td>
  </ng-container>
  <ng-container matColumnDef="headerValue">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'settings.companies.event.auth-header-value' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ getHeaderValue(element) | truncate: 30 }}
      <button *ngIf="element?.apiAuthHeaderValue" mat-icon-button (click)="element.displayHeaderValue = !element.displayHeaderValue">
        <mat-icon>{{ element.displayHeaderValue ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'settings.companies.event.actions' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="edit">
      <button mat-icon-button (click)="editEvent(element)">
        <mat-icon> edit </mat-icon>
      </button>
      <button mat-icon-button (click)="deleteEvent(element)">
        <mat-icon> delete </mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator class="w100" *ngIf="events.data.length > 0" [length]="count" [pageSize]="pageSize" (page)="getEvents()"> </mat-paginator>
