import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { take } from 'rxjs/operators';
import { CompanyService } from 'src/app/core/services/company.service';
import { InviteDetail } from '../../models/invite-detail.interface';
import { SocialMedia } from '../../models/social-media';
import { InviteService } from '../../services/invite.service';
import { InviteActionDialogComponent } from '../invite-action-dialog/invite-action-dialog.component';
@Component({
  selector: 'app-company-invitation-detail',
  templateUrl: './company-invitation-detail.component.html',
  styleUrls: ['./company-invitation-detail.component.scss']
})
export class CompanyInvitationDetailComponent implements OnInit {
  idInvitation: number;
  role: String;
  companyLogo: string;
  inviteDetail: InviteDetail;
  socialMedia: SocialMedia[];
  loading: boolean = false;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faLinkedin = faLinkedin;
  faTwitter = faTwitter;
  constructor(
    private readonly inviteService: InviteService,
    private readonly companyService: CompanyService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {
    this.idInvitation = +route.snapshot.paramMap.get('idInvitation');
    this.role = route.snapshot.paramMap.get('role');

    if (!this.queryDataValidation()) {
      this.redirectToInvites();
    }
  }
  queryDataValidation() {
    return isNaN(this.idInvitation) || this.idInvitation > 0;
  }

  async ngOnInit() {
    try {
      this.loading = true;
      await this.getDetail();
      await this.getSocialMedia();
      await this.getLogo();
      this.loading = false;
    } catch (e) {
      this.redirectToInvites();
    }
  }
  async getLogo() {
    try {
      this.companyLogo = await this.companyService.companyLogo(this.inviteDetail.companyId);
      this.renderLogo();
    } catch (e) {
      this.redirectToInvites();
    }
  }
  async getSocialMedia() {
    try {
      this.socialMedia = await this.companyService.getSocialMedia(this.inviteDetail.companyId);
    } catch (e) {
      this.redirectToInvites();
    }
  }
  async getDetail() {
    try {
      this.inviteDetail = await this.inviteService.getInviteDetail(this.idInvitation);
    } catch (e) {
      this.redirectToInvites();
    }
  }
  redirectToInvites() {
    this.router.navigateByUrl('/Settings/MyInvitations');
  }

  getIcon(media) {
    switch (media) {
      case 'facebook':
        return this.faFacebook;
      case 'instagram':
        return this.faInstagram;
      case 'linkedin':
        return this.faLinkedin;
      case 'twitter':
        return this.faTwitter;
      default:
        break;
    }
  }
  renderLogo() {
    if (this.companyLogo === '' || !this.companyLogo || this.companyLogo == null) {
      this.companyLogo = '../../../../assets/img/empty-company-logo.png';
    } else {
      this.companyLogo = 'data:image/png;base64,' + this.companyLogo;
    }
  }

  openAction(action: string) {
    this.inviteDetail['role'] = this.role;
    let dialog$ = this.dialog.open(InviteActionDialogComponent, {
      data: {
        action: action,
        invite: { ...this.inviteDetail }
      }
    });

    dialog$
      .afterClosed()
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.router.navigateByUrl('/Settings/MyInvitations/Recieved');
        }
      });
  }
}
