import { Injectable } from '@angular/core';
import { Category } from '../models/category';
import { SubCategory } from '../models/subCategory';
import { EllosApiService } from '../../../core/http/ellosApi.service';
import { EllosApi } from '../../../core/interfaces/ellosApi.interface';
import EllosRequest from '../../../core/interfaces/ellosRequest.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getCategory(languageCode: string): Promise<Category[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Category/category?languageCode=${languageCode}`,
      body: null
    };

    let response = await this.ellosApi.get<Category[]>(request);
    return response.data;
  }

  async getSubCategory(categoryId: number, languageCode: string): Promise<SubCategory[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Category/subcategory?languageCode=${languageCode}&IdCategory=${categoryId}`,
      body: null
    };

    let response = await this.ellosApi.get<SubCategory[]>(request);
    return response.data;
  }

  async getServiceSubCategory(languageCode: string): Promise<SubCategory[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Category/service-subcategory?languageCode=${languageCode}`,
      body: null
    };

    const response = await this.ellosApi.get<SubCategory[]>(request);
    return response.data;
  }

  async getAllCategories(langCode: string): Promise<Category[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Category?languageCode=${langCode}`,
      body: null
    };
    let response = await this.ellosApi.get<Category[]>(request);
    return response.data;
  }
}
