import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssociateService } from 'src/app/shared/services/associate.service';

@Component({
  selector: 'app-company-list-dialog',
  templateUrl: './company-list-dialog.component.html',
  styleUrls: ['./company-list-dialog.component.scss']
})
export class CompanyListDialogComponent implements OnInit {
  companies: any;
  constructor(
    private readonly dialogRef: MatDialogRef<CompanyListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private associateService: AssociateService
  ) {}

  ngOnInit(): void {
    this.companies = this.data;
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
