<div class="fixed-field" fxLayout="column">
  <div fxLayoutGap="20px" fxLayout="row wrap" fxLayoutAlign="start">
    <mat-form-field class="search-input" fxFlex="40" fxFlex.lt-sm="100" fxFlex.lt-md="50" appearance="outline">
      <mat-label>{{ 'matchmaking.search-label' | translate }}</mat-label>
      <input matInput name="searchInput" autocomplete="off" #searchInput />
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="outline" (click)="picker.open()" fxFlex="30" fxFlex.lt-sm="100" fxFlex.lt-md="60">
      <mat-label>{{ 'marketplace.negotiation.filter-by-date' | translate }}</mat-label>
      <mat-date-range-input [formGroup]="rangeDate" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="" />
        <input matEndDate formControlName="end" placeholder="" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker (opened)="resetCalendar()" (closed)="filterDate()"> </mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-sm="100" fxFlex.lt-md="60">
      <mat-select [(value)]="selectedFilter" (selectionChange)="sendNewMatchmakingQuery()">
        <mat-option value="all">{{ 'matchmaking.all' | translate }}</mat-option>
        <mat-option value="matchmaking">Matchmaking</mat-option>
        <mat-option value="negotiation">{{ 'matchmaking.negotiations' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
