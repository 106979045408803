<div
  mat-button
  class="button-like"
  [matMenuTriggerFor]="menuCompany"
  (menuOpened)="menuClosed = false"
  (menuClosed)="menuClosed = true"
  *ngIf="companies?.length > 0 && companiesLoaded"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
>
  <app-avatar-image style="display: flex" [url]="selectedCompany?.logo" class="logo" size="50" matPrefix> </app-avatar-image>

  <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px" class="company-name">
    <span>{{ 'settings.working-as' | translate }}</span>
    <span>{{ selectedCompany?.name | truncate : 20 }}</span>
  </div>
  <mat-icon mat-line fxFlexAlign="center">arrow_drop_down</mat-icon>
</div>
<div fxLayout="row" class="not-found-company" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="companies?.length == 0 && companiesLoaded">
  <div
    mat-card-avatar
    class="not-found-image"
    matTooltipClass="tooltip"
    [matTooltip]="'settings.company-in-analisys-tooltip' | translate"
    matBadge="!"
    matBadgePosition="below after"
    matBadgeColor="accent"
    matPrefix
  ></div>
  <span>{{ 'settings.no-active-company' | translate }}</span>
</div>
<mat-menu #menuCompany="matMenu">
  <div class="menu-selector" fxLayout="row" fxLayoutAlign="center start" fxLayout="column" fxLayoutAlign="start start">
    <div id="companyList" style="overflow: auto" (scroll)="onScrollDown()">
      <ng-container *ngFor="let company of companies">
        <div *ngIf="validateCompany(company)" mat-menu-item fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="menu-item" (click)="dispatchCompany(company)">
          <app-avatar-image class="menu-logo" [url]="getLogo(company?.logo)" size="50" matPrefix matPrefix> </app-avatar-image>
          <div fxLayout="column" [title]="company?.name" fxLayoutAlign="center start" fxLayoutGap="2px" class="company-select">
            <span
              ><b>{{ company?.name | truncate : 20 }}</b></span
            >
            <span>{{ company?.cnpj || company.country }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <span fxLayout="row" class="loader" fxLayoutAlign="center start" *ngIf="loadingMore">
      <mat-spinner align="center" color="accent" diameter="30"></mat-spinner>
    </span>
  </div>
</mat-menu>
