import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import Language from 'src/app/core/interfaces/language.interface';
import { EllosEventSubmit } from '../../models/ellos-event-submit.interface';
import { SubscriptionResponse } from '../../models/subscription-response.interface';
import { EventService } from '../../services/event.service';
import { DeleteEventDialogComponent } from '../delete-event-dialog/delete-event-dialog.component';
import { EditEventDialogComponent } from '../edit-event-dialog/edit-event-dialog.component';

@Component({
  selector: 'app-events-grid',
  templateUrl: './events-grid.component.html',
  styleUrls: ['./events-grid.component.scss']
})
export class EventsGridComponent implements OnInit {
  events = new MatTableDataSource<SubscriptionResponse>();
  displayedColumns: string[] = ['event', 'url', 'method', 'header', 'headerValue', 'actions'];
  lastId = 1;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() idCompany: number;
  count: number = 0;
  destroy$ = new Subject<any>();

  language: string;
  language$: Observable<Language>;
  pageSize = 10;
  constructor(private readonly dialog: MatDialog, private readonly langStore: Store<{ language: Language }>, private readonly eventService: EventService) {
    this.language$ = langStore.pipe(select('language'));
  }

  ngOnInit(): void {
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      this.language = lang?.code;
      this.getEvents();
    });
  }

  async getEvents() {
    try {
      let request = {
        idCompany: this.idCompany,
        pageSize: this.pageSize,
        languageCode: this.language,
        pageIndex: this.paginator?.pageIndex || 0
      };

      let response = await this.eventService.getEventsSubscription(request);
      this.count = response.count;
      this.events.data = response.items;
    } catch (e) {
      this.events.data = [];
    }
  }

  deleteEvent(event: SubscriptionResponse) {
    let dialogRef = this.dialog.open(DeleteEventDialogComponent, {
      data: event
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.getEvents();
        }
      });
  }
  getHeaderValue(event: SubscriptionResponse) {
    let hasHeaderValue = !!event.apiAuthHeaderValue;
    if (!hasHeaderValue) return '-';

    return event.displayHeaderValue ? event.apiAuthHeaderValue : '******';
  }
  editEvent(event: SubscriptionResponse) {
    let edit: EllosEventSubmit = {
      id: event.id,
      idEvent: event.idEvent,
      apiUrl: event.apiUrl,
      method: event.method,
      apiAuthHeaderName: event.apiAuthHeaderName,
      apiAuthHeaderValue: event.apiAuthHeaderValue
    };
    let dialogRef = this.dialog.open(EditEventDialogComponent, {
      data: {
        event: edit,
        idCompany: this.idCompany,
        edit: true
      },
      width: '685px'
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          let index = this.events.data.findIndex((e) => e.idEvent === event.idEvent);
          if (index >= 0) {
            this.events.data[index] = { ...res };
            this.table.renderRows();
          }
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
