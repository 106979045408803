export interface RegisterCompanyContato extends RegisterCompany {
  contactEmail: string;
  companyPhone: string;
}

export interface RegisterCompany {
  countryNumericCode: number;
  companyName: string;
  cnpj?: string;
  state?: string;
  idSector?: number;
  isForwardingAgent: boolean;
  isExporter: boolean;
  isImporter: boolean;
  companyType?: CompanyType;
  addressDetails?: RegisterCompanyAddress;
  exporterId?: number;
  timezoneId?: Number;
  fantasyName?: string;
  vatNumber?: number;
}

export interface RequestCompanyInvite {
  cnpj?: any;
  email?: string;
  inviteLink?: string;
}

export interface RegisterCompanyAddress {
  city?: string;
  street?: string;
  zipCode?: string;
  poBox?: string;
  complement?: string;
  district?: string;
  number?: string;
  state?: string;
  stateRegistry?: string;
}

export enum CompanyType {
  ELLOS,
  EXTERNAL
}
