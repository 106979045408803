<h2>{{ 'matchmaking.past-meetings' | translate }}</h2>

<Section
  fxLayout="row wrap"
  fxLayoutAlign="start start"
  fxLayoutGap="18px"
  infinite-scroll
  [infiniteScrollDisabled]="matchmakings.length + meetings.length < 20"
  [infiniteScrollDistance]="scrollDistance"
  (scrolled)="onScrollDown()"
  *ngIf="!isLoading"
>
  <app-matchmaking-card class="card" *ngFor="let meet of matchmakings" [matchmaking]="meet" isMatchmaking="true"></app-matchmaking-card>
  <app-matchmaking-card class="card" *ngFor="let meet of meetings" [matchmaking]="meet"></app-matchmaking-card>
</Section>
<app-result-not-found
  [titleMSG]="'meeting-not-found' | translate"
  [subtitleMSG]="'sorry-meeting-not-found' | translate"
  *ngIf="matchmakings.length + meetings.length === 0 && !isLoading"
></app-result-not-found>

<app-ellos-spinner [text]="'marketplace.details.loading' | translate" *ngIf="isLoading || loadingMore"></app-ellos-spinner>
