import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-not-auth-layout',
  templateUrl: './not-auth-layout-social-media.component.html',
  styleUrls: ['./not-auth-layout-social-media.component.scss']
})
export class NotAuthLayoutSocialMediaComponent implements OnInit {
  destroy$: Subject<any> = new Subject();
  isCertificate = true;

  faGlobeAfrica = faGlobeAfrica;
  faQuestionCircle = faQuestionCircle;
  faInstagram = faInstagram;
  faFacebookSquare = faFacebookSquare;
  faTwitterSquare = faTwitterSquare;
  faYoutube = faYoutube;
  faLinkedin = faLinkedin;

  year = new Date().getFullYear();

  constructor(private readonly router: Router) {}

  ngOnInit() {}
}
