<h1 class="title">
  {{ 'easy-trade.origin-certificate.table.certificate' | translate }}
  {{ certificateData?.id }} -
  {{ 'easy-trade.monitoring.blockchain.details' | translate }}
</h1>

<mat-card>
  <mat-tab-group #tab mat-align-tabs="start" [selectedIndex]="tabIndex" color="accent">
    <!-- INVOICE -->
    <mat-tab [label]="'easy-trade.monitoring.blockchain.stepper.invoice' | translate">
      <div fxLayout="row" class="transportation-area" fxLayoutAlign="start" fxLayoutGap="90px">
        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.invoice_information.incoterm' | translate"
            [label]="getIncotermEnum(certificateData?.incotermType)"
          ></app-title-and-label>

          <app-title-and-label [title]="'marketplace.dialog.currency' | translate" [label]="getCurrencyEnum(certificateData?.currency) | translate"></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label [title]="'easy-trade.new-trade.stepper.invoice_information.cost' | translate" [label]="certificateData?.cost | currency : ' '"></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.invoice_information.freight' | translate"
            [label]="certificateData?.freightCost | currency : ' '"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.invoice_information.insurance' | translate"
            [label]="certificateData?.insurance | currency : ' '"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.invoice_information.landinf_fee' | translate"
            [label]="certificateData?.landingFee | currency : ' '"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.invoice_information.others' | translate"
            [label]="certificateData?.others | currency : ' '"
          ></app-title-and-label>
        </div>
      </div>

      <div fxLayout="row" class="transportation-area" fxLayoutAlign="start" fxLayoutGap="90px">
        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.invoice_information.invoice-number' | translate"
            [label]="'IN-' + certificateData?.invoiceNumber"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.origin' | translate" [label]="originCountry?.name"></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label [title]="'easy-trade.new-trade.stepper.details-requirements.destination' | translate" [label]="destinationCountry?.name"></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.monitoring.blockchain.stepper.departure-date' | translate"
            [label]="certificateData?.shipmentInformation?.shippingDate | ellosDate"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.invoice_information.emission-date' | translate"
            [label]="certificateData?.emissionDate | ellosDate"
          ></app-title-and-label>
        </div>
      </div>

      <div fxLayout="row" class="transportation-area" fxLayoutAlign="start" fxLayoutGap="90px">
        <app-title-and-label [title]="'easy-trade.monitoring.blockchain.stepper.comments' | translate" [label]="certificateData?.observations"></app-title-and-label>
      </div>
    </mat-tab>

    <!-- COMPANIES -->
    <mat-tab [label]="'easy-trade.monitoring.blockchain.stepper.companies-involved' | translate">
      <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="60px" class="transportation-area">
        <div *ngIf="certificateData?.companiesEnvolved?.exporter">
          <h2>
            {{ 'easy-trade.monitoring.blockchain.stepper.exporter-information' | translate }}
          </h2>
          <app-companies-involved [company]="certificateData?.companiesEnvolved?.exporter"></app-companies-involved>
        </div>

        <div *ngIf="certificateData?.companiesEnvolved?.importer">
          <h2>
            {{ 'easy-trade.monitoring.blockchain.stepper.importer-information' | translate }}
          </h2>
          <app-companies-involved [company]="certificateData?.companiesEnvolved?.importer"></app-companies-involved>
        </div>

        <div *ngIf="certificateData?.companiesEnvolved?.buyer">
          <h2>
            {{ 'easy-trade.monitoring.blockchain.stepper.buyer-information' | translate }}
          </h2>
          <app-companies-involved [company]="certificateData?.companiesEnvolved?.buyer"></app-companies-involved>
        </div>

        <div *ngIf="certificateData?.companiesEnvolved?.consignee">
          <h2>
            {{ 'easy-trade.monitoring.blockchain.stepper.consignee-information' | translate }}
          </h2>
          <app-companies-involved [company]="certificateData?.companiesEnvolved?.consignee"></app-companies-involved>
        </div>
      </div>
    </mat-tab>

    <!-- TRANSPORTATION -->
    <mat-tab [label]="'easy-trade.monitoring.blockchain.stepper.transportation' | translate">
      <div fxLayout="row" class="transportation-area" fxLayoutAlign="start" fxLayoutGap="90px">
        <div *ngIf="destinationCountry?.alpha3Code == 'EGY'" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label [title]="'easy-trade.new-trade.stepper.shipment-information.acidNumber' | translate" [label]="certificateData?.acidNumber"></app-title-and-label>
        </div>
        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.shipment-information.awbNumber' | translate"
            [label]="certificateData?.shipmentInformation?.blawbNumber"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.shipment-information.imo_number' | translate"
            [label]="certificateData?.shipmentInformation?.imoNumber"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.shipment-information.shipping-date' | translate"
            [label]="certificateData?.shipmentInformation?.shippingDate | ellosDate"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.details-requirements.destination-country' | translate"
            [label]="destinationCountry?.name"
          ></app-title-and-label>
        </div>

        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px">
          <app-title-and-label
            [title]="'easy-trade.new-trade.stepper.shipment-information.vessel-name' | translate"
            [label]="certificateData?.shipmentInformation?.vesselName"
          ></app-title-and-label>
        </div>
      </div>

      <div fxLayout="row" class="transportation-area" fxLayoutAlign="center center">
        <app-transshipment-flow fxFill [defaultTranshipments]="certificateStopPoints" [shipmentMethod]="getShipmentMethod()" [readonlyField]="true"></app-transshipment-flow>
      </div>
    </mat-tab>

    <!-- PRODUCTS -->
    <mat-tab [label]="'easy-trade.monitoring.blockchain.stepper.products' | translate">
      <div fxLayout="row" class="transportation-area" fxLayoutAlign="center center">
        <app-certificate-product-exportation-grid *ngIf="productsForGrid.length > 0" fxFill [useProductValue]="true" [readonlyGrid]="true">
        </app-certificate-product-exportation-grid>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-divider></mat-divider>

  <mat-card-actions align="end">
    <span>
      <button mat-button routerLink="/Certificates">
        {{ 'easy-trade.monitoring.blockchain.stepper.back-button' | translate }}
      </button>
    </span>
  </mat-card-actions>
</mat-card>
