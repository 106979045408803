import { QueryCategory } from '../interfaces/queryCategory.interface';
import EllosAction from '../../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../../core/models/actionTypes';

export function queryCategoryReducer(state: QueryCategory, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.QueryNewCategory: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
