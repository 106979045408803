import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import EllosResponse from '../interfaces/ellosResponse.interface';
import { environment } from '../../../environments/environment';
import EllosRequest from '../interfaces/ellosRequest.interface';
import { EllosApi } from '../interfaces/ellosApi.interface';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EllosApiService {
  constructor(private readonly http: HttpClient, private _translateService: TranslateService) {}

  private getEllosApi(ellosApi: EllosApi): string {
    switch (ellosApi) {
      case EllosApi.Associate:
        return environment.associateApi;
      case EllosApi.Showcase:
        return environment.showcaseApi;
      case EllosApi.EasyTrade:
        return environment.easyTradeApi;
      case EllosApi.Meeting:
        return environment.meetingApi;
      case EllosApi.Ncm:
        return environment.ncmApi;
      case EllosApi.Company:
        return environment.companyApi;
      case EllosApi.Exportation:
        return environment.exportationApi;
      case EllosApi.EasyTradeCertifier:
        return environment.easyTradeCertifierApi;
      case EllosApi.OriginCertificate:
        return environment.originCertificate;
      case EllosApi.EasyTradeCustoms:
        return environment.easytradeCustomsApi;
      case EllosApi.Profile:
        return environment.profileApi;
      default:
        throw new Error('Invalid API');
    }
  }

  private getUrl(request: EllosRequest): string {
    return `${this.getEllosApi(request.ellosApi)}/${request.resource}`;
  }

  async get<TData>(request: EllosRequest): Promise<EllosResponse<TData>> {
    const url = this.getUrl(request);
    return await lastValueFrom(this.http.get<EllosResponse<TData>>(url));
  }

  async patch<TData>(request: EllosRequest): Promise<EllosResponse<TData>> {
    const url = this.getUrl(request);
    return await lastValueFrom(this.http.patch<EllosResponse<TData>>(url, request.body));
  }

  async post<TData>(request: EllosRequest): Promise<EllosResponse<TData>> {
    const url = this.getUrl(request);
    return await lastValueFrom(this.http.post<EllosResponse<TData>>(url, request.body));
  }

  async put<TData>(request: EllosRequest): Promise<EllosResponse<TData>> {
    const url = this.getUrl(request);
    return await lastValueFrom(this.http.put<EllosResponse<TData>>(url, request.body));
  }

  async delete<TData>(request: EllosRequest): Promise<EllosResponse<TData>> {
    const url = this.getUrl(request);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: request.body
    };
    return await lastValueFrom(this.http.delete<EllosResponse<TData>>(url, options));
  }

  public getErrorMessage<TData>(response: EllosResponse<TData>): string {
    let errorMsg = '';
    response.errors.forEach((er) => {
      errorMsg = `${errorMsg}${er} `;
    });
    return errorMsg;
  }

  public checkStatus(response) {
    if (response.status >= 500 && response.status <= 511) {
      return this._translateService.instant('error.standard-error-message');
    } else {
      this.getErrorMessage(response.error);
    }
  }
}
