import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';

@Component({
  selector: 'app-settings-navbar',
  templateUrl: './settings-navbar.component.html',
  styleUrls: ['./settings-navbar.component.scss']
})
export class SettingsNavbarComponent implements OnInit {
  public modules = [];
  public modulePermission = PermissionsEnum;

  constructor(private readonly permissions: PermissionsService) {}

  ngOnInit(): void {
    this.modules = this.permissions.getPermissionStorage();
  }
}
