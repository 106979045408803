import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Language from 'src/app/core/interfaces/language.interface';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EllosEventSubmit } from '../../models/ellos-event-submit.interface';
import { EllosEvent } from '../../models/ellos-event.interface';
import { EditEvent } from '../../models/event-edit.interface';
import { EventType } from '../../models/event-type.interface';
import { SubscriptionResponse } from '../../models/subscription-response.interface';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-event-subscribe',
  templateUrl: './event-subscribe.component.html',
  styleUrls: ['./event-subscribe.component.scss']
})
export class EventSubscribeComponent implements OnInit {
  @Output() newEvent = new EventEmitter<EllosEvent>();
  @Output() newEventEdit = new EventEmitter<SubscriptionResponse>();
  @Output() editEvent = new EventEmitter<EllosEventSubmit>();
  @Output() validForm = new EventEmitter<boolean>();
  @Output() loadingEvents = new EventEmitter<boolean>();
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
  @Input() idCompany: number;
  @Input() edit: boolean = false;
  @Input() deafultEvent: SubscriptionResponse;
  @ViewChild('form') form: NgForm;
  eventsType: EventType[] = [];
  destroy$ = new Subject<any>();
  language: string;
  language$: Observable<Language>;
  showPassword: boolean;
  subscribeForm: FormGroup = this.fb.group({});
  submiting: boolean;
  constructor(
    private readonly fb: FormBuilder,
    private readonly eventService: EventService,
    private readonly notificationService: NotificationService,
    private readonly langStore: Store<{ language: Language }>
  ) {
    this.language$ = langStore.pipe(select('language'));
  }

  ngOnInit(): void {
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      this.language = lang.code;
      this.getEvents();
    });

    this.subscribeForm = this.fb.group({
      id: [this.deafultEvent?.id || ''],
      event: [this.deafultEvent?.idEvent || '', [Validators.required]],
      method: ['POST'],
      apiUrl: [this.deafultEvent?.apiUrl || '', [Validators.required]],
      authHeader: [this.deafultEvent?.apiAuthHeaderName || ''],
      authHeaderValue: [this.deafultEvent?.apiAuthHeaderValue || '']
    });
    this.subscribeForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.editEvent.emit(event);
      this.validForm.emit(this.subscribeForm.valid);
    });
    this.editEvent.emit(this.subscribeForm.value);
    this.validForm.emit(this.subscribeForm.valid);
  }

  async getEvents() {
    if (this.idCompany && this.language) {
      try {
        this.loadingEvents.emit(true);
        const request = {
          idCompany: this.idCompany,
          languageCode: this.language
        };
        this.eventsType = await this.eventService.getEventsType(request);
      } catch {
        this.eventsType = [];
      } finally {
        this.loadingEvents.emit(false);
      }
    }
  }
  getNewEvent() {
    let eventName = this.eventsType.find((e) => e.id == this.subscribeForm.controls.event.value);

    let event: EllosEvent = {
      id: this.subscribeForm.controls.id.value,
      idEvent: this.subscribeForm.controls.event.value,
      idCompany: this.idCompany,
      url: this.subscribeForm.controls.apiUrl.value,
      method: this.subscribeForm.controls.method.value,
      name: eventName.description,
      authHeader: this.subscribeForm.controls.authHeader?.value,
      authHeaderValue: this.subscribeForm.controls.authHeaderValue?.value
    };

    return event;
  }
  getEditEvent() {
    let eventName = this.eventsType.find((e) => e.id == this.subscribeForm.controls.event.value);

    let event: SubscriptionResponse = {
      id: this.subscribeForm.controls.id.value,
      idEvent: this.subscribeForm.controls.event.value,
      apiUrl: this.subscribeForm.controls.apiUrl.value,
      method: this.subscribeForm.controls.method.value,
      eventName: eventName.description,
      apiAuthHeaderName: this.subscribeForm.controls.authHeader?.value,
      apiAuthHeaderValue: this.subscribeForm.controls.authHeaderValue?.value
    };

    return event;
  }
  async onSubmit() {
    if (this.subscribeForm.valid && !this.edit) {
      try {
        this.submiting = true;
        this.validForm.emit(this.subscribeForm.valid);
        let event = this.getNewEvent();
        let response = await this.eventService.eventSubscription(event);
        if (response) {
          this.newEvent.emit(event);
          this.notificationService.notifyDialog({
            success: true,
            timeout: 3000,
            text: 'settings.companies.event.success-subscribed'
          });
          this.resetForm();
        }
      } catch {
        this.notificationService.notifyDialog({
          success: false,
          timeout: 3000,
          text: 'settings.companies.event.error-subscribed'
        });
      } finally {
        this.submiting = false;
      }
    } else if (this.subscribeForm.valid && this.edit) {
      let eventSubmit = this.getNewEvent();
      let edit: EditEvent = {
        url: eventSubmit.url,
        authHeader: eventSubmit.authHeader,
        authHeaderValue: eventSubmit.authHeaderValue,
        idCompany: this.idCompany,
        idEvent: eventSubmit.idEvent,
        idSubscription: eventSubmit.id,
        method: eventSubmit.method
      };
      try {
        this.submiting = true;

        let response = await this.eventService.updateEventSubscription(edit);
        if (response) {
          this.validForm.emit(this.subscribeForm.valid);
          let event = this.getEditEvent();
          this.newEventEdit.emit(event);
          this.notificationService.notifyDialog({
            success: true,
            timeout: 3000,
            text: 'settings.companies.event.success-updated',
            closeAll: true
          });
          this.resetForm();
        }
      } catch (e) {
        this.notificationService.notifyDialog({
          success: false,
          timeout: 3000,
          text: 'settings.companies.event.error-unsubscribed'
        });
        this.submiting = false;
      }
    }
  }
  toggleInput() {
    this.showPassword = !this.showPassword;
  }
  resetForm() {
    this.subscribeForm = this.fb.group({
      event: ['', [Validators.required]],
      method: ['', [Validators.required]],
      apiUrl: ['', [Validators.required]],
      authHeader: [''],
      authHeaderValue: ['']
    });
    this.form.resetForm();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
