<div fxLayout="row" fxLayoutAlign="center start" *ngIf="!finished">
  <div fxLayout="column">
    <p class="mat-h1 title">{{ 'registration.title' | translate }}</p>
  </div>
</div>

<br />

<!-- stepper -->
<div fxLayout="row" class="register" fxLayoutAlign="center start">
  <div fxLayout="column" fxFlex="90">
    <div class="register-loader" *ngIf="loadingInvite">
      <app-ellos-spinner [text]="'registration.wait' | translate"></app-ellos-spinner>
    </div>
    <mat-horizontal-stepper linear #stepper labelPosition="bottom" *ngIf="!loadingInvite">
      <mat-step [stepControl]="access" [editable]="!userRegistered" #stepper>
        <ng-template matStepLabel>{{ 'registration.label-user' | translate }}</ng-template>

        <form [formGroup]="access">
          <div fxLayout="row" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'registration.email' | translate }}</mat-label>
              <input
                matInput
                autocomplete="off"
                formControlName="email"
                #inputEmail
                [disabled]="isInvite"
                (ngModelChange)="setEmail($event)"
                placeholder="example@example.com"
                required
              />
              <mat-hint *ngIf="checkInvalidEmail()">
                {{ 'error.email-invalid' | translate }}
              </mat-hint>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'registration.password' | translate }}</mat-label>
              <input
                matInput
                autocomplete="off"
                type="password"
                formControlName="password1"
                placeholder="{{ 'registration.password' | translate }}"
                required
                #password="matInput"
              />
            </mat-form-field>
          </div>
          <mat-error *ngIf="access.controls.password1.hasError('pattern')"> {{ 'registration.password-hint' | translate }}</mat-error>

          <div fxLayout="column" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill type="password" appearance="outline">
              <mat-label>{{ 'registration.confirm-password' | translate }}</mat-label>
              <input
                matInput
                autocomplete="off"
                type="password"
                formControlName="password2"
                placeholder="{{ 'registration.confirm-password' | translate }}"
                required
                #confirmPassword="matInput"
              />
            </mat-form-field>
          </div>
          <mat-error *ngIf="confirmPassword.value !== password.value && confirmPassword.value !== ''"> {{ 'registration.password-match' | translate }}</mat-error>
          <div fxLayout="column" fxLayoutAlign="center start" class="input-phone">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'registration.phone' | translate }}</mat-label>
              <ngx-mat-intl-tel-input class="input-phone" [onlyCountries]="['br']" [enableSearch]="true" formControlName="phonenumber" required> </ngx-mat-intl-tel-input>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="center start">
            <button [class.btnDisable]="!access.valid" class="p-1" [disabled]="!access.valid" fxFlexFill mat-raised-button color="accent" matStepperNext>
              {{ 'registration.next-button' | translate }}
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step label="{{ 'registration.personal-information' | translate }}" [editable]="!userRegistered">
        <form [formGroup]="personalInformation" *ngIf="!waitingLogin">
          <div fxLayout="row" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'registration.firstname' | translate }}</mat-label>
              <input matInput autocomplete="off" formControlName="firstname" required />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'registration.lastname' | translate }}</mat-label>
              <input matInput autocomplete="off" formControlName="lastname" required />
            </mat-form-field>
          </div>

          <!-- Colocamos aqui -->
          <div fxLayout="row" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'registration.jobTitle' | translate }}</mat-label>
              <input matInput autocomplete="off" formControlName="jobTitle" />
            </mat-form-field>
          </div>

          <mat-error *ngIf="errorCountryValue()">
            {{ 'registration.country-error' | translate }}
          </mat-error>
          <div fxLayout="row" fxLayoutAlign="start start" class="w100">
            <mat-select-country
              class="w100"
              fxFlex="100"
              appearance="outline"
              [countries]="Country"
              [placeHolder]="'registration.nationality' | translate"
              [label]="'registration.nationality' | translate"
              formControlName="nationality"
              (onCountrySelected)="onCountrySelected($event)"
              ngDefaultControl
            >
            </mat-select-country>
          </div>

          <app-recaptcha (valueGrecaptcha)="handleValueRecaptcha($event)" formControlName="recaptcha"></app-recaptcha>
          <br />

          <div fxLayout="row" fxLayoutAlign="center start">
            <button
              [class.btnDisable]="!personalInformation.valid || errorCountryValue()"
              class="p-1"
              [disabled]="!personalInformation.valid || errorCountryValue() || !access.get('recaptcha').value"
              fxFlexFill
              mat-raised-button
              color="accent"
              matStepperNext
              (click)="registerUserisNewForwardingAgent()"
            >
              {{ 'registration.next-button' | translate }}
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step *ngIf="!isNewForwardingAgent" label="{{ 'registration.confirm-account' | translate }}" [completed]="false">
        <div *ngIf="!waitingLogin && !inviteCodeSent">
          <div fxLayout="row" fxLayoutAlign="start" class="mt-5">
            <p class="mat-h4 mb-4">
              <mat-label>{{ 'registration.choose-platform' | translate }}</mat-label>
            </p>
          </div>
          <div fxLayout="column" fxLayoutAlign="start">
            <mat-radio-group [formControl]="floatLabelControl">
              <mat-radio-button value="email" [checked]="true" class="mat-h4 mb-5">Email</mat-radio-button> <br />
            </mat-radio-group>
          </div>

          <div fxLayout="row">
            <p class="mat-h4 mt-5">
              <label>{{ 'registration.send-information' | translate }}</label>
            </p>
          </div>

          <div fxLayout="row">
            <p class="mat-h4 mb-5">
              <label>{{ getFloatLabelValue() === 'email' ? access.controls.email.value : access.controls.phonenumber.value }}</label>
            </p>
          </div>

          <div fxLayout="row" fxLayoutAlign="center start">
            <button fxFlexFill mat-raised-button color="accent" class="p-1" (click)="onSubmit()">
              {{ 'registration.Generate-code-button' | translate }}
            </button>
          </div>
        </div>
        <div class="register-loader" *ngIf="waitingLogin">
          <app-ellos-spinner [text]="'registration.register-loader' | translate"></app-ellos-spinner>
        </div>

        <div *ngIf="!inviteCode && inviteCodeSent">
          <app-confirm-account
            [redirectLogin]="false"
            (Resendcounter)="onSubmit()"
            [email]="access.controls.email.value"
            [tel]="access.controls.phonenumber.value"
            [floatLabelControl]="floatLabelControl.value"
            (registered)="markAsRegistered($event)"
          ></app-confirm-account>
        </div>
      </mat-step>

      <mat-step *ngIf="isInvite && isNewForwardingAgent" [completed]="false" [editable]="false">
        <ng-template matStepLabel>{{ 'registration.company-information' | translate }}</ng-template>
        <app-register-company
          [isInvite]="isInvite"
          [validator]="validator"
          [isNewForwardingAgent]="isNewForwardingAgent"
          [emailDefault]="inviteInfomations.email"
          [cnpjDefault]="cnpjInviteParam"
          [confirmedInvite]="confirmedInvite"
          (cnpjInvite)="cnpjInvite = $event"
          (emailInvite)="emailInvite = $event"
          (isRequestCompany)="isRequestCompany = $event"
          (isRequested)="requestAcessDialog($event)"
          (existingCompanyRequest)="existingCompanyRequest = $event"
          (createUser)="registerUserisNewForwardingAgent()"
          (isAdmin)="setAdmin($event)"
          (companyFound)="setCompanyFound($event)"
          (finished)="toPreferences($event)"
          (selectedCompany)="selectedCompany = $event"
        ></app-register-company>
      </mat-step>

      <mat-step *ngIf="!isInvite" [completed]="false" [editable]="false">
        <ng-template matStepLabel>{{ 'registration.company-information' | translate }}</ng-template>
        <app-register-company
          [isInvite]="isInvite"
          [validator]="validator"
          [confirmedInvite]="confirmedInvite"
          (cnpjInvite)="cnpjInvite = $event"
          (emailInvite)="emailInvite = $event"
          (isRequestCompany)="isRequestCompany = $event"
          (isRequested)="requestAcessDialog($event)"
          (existingCompanyRequest)="existingCompanyRequest = $event"
          *ngIf="loggedIn"
          (isAdmin)="setAdmin($event)"
          (companyFound)="setCompanyFound($event)"
          (finished)="toPreferences($event)"
          (selectedCompany)="selectedCompany = $event"
        ></app-register-company>
      </mat-step>

      <mat-step *ngIf="!inviteCode || isNewForwardingAgent" label="{{ 'registration.preferences' | translate }}">
        <app-user-preferences-form *ngIf="!finaliza" (savedPreferences)="setFinished($event)"></app-user-preferences-form>
      </mat-step>
    </mat-horizontal-stepper>

    <!-- message -->

    <br /><br /><br /><br />

    <div fxLayout="row" fxLayoutAlign="center start" *ngIf="finished">
      <div fxLayout="column">
        <p class="mat-h1 title">
          <span>
            <button mat-icon-button>
              <fa-icon class="icone" [icon]="faCheckCircle"></fa-icon>
            </button>
          </span>

          {{ 'registration.thank-signup' | translate }}!
        </p>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px" *ngIf="finished">
      <p class="message" *ngIf="isAdmin && companyFound">
        {{ 'registration.signed-as-admin' | translate }}
        {{ selectedCompany.companyName }} {{ selectedCompany.cnpj }}. <br />
        {{ 'registration.company-admin' | translate }}
      </p>
      <p class="message" *ngIf="!isAdmin && companyFound">
        {{ 'registration.signed-as-member' | translate }}
        {{ selectedCompany.companyName }} {{ selectedCompany.cnpj }}. <br />
        {{ 'registration.administrator-will-contact' | translate }}
      </p>

      <br />

      <p class="message" *ngIf="isAdmin && !companyFound">
        {{ 'registration.signed-as-member' | translate }}
        {{ selectedCompany.companyName }} {{ selectedCompany.cnpj }}. <br />
        {{ 'registration.company-not-found-regitration' | translate }}
      </p>

      <p class="message2">{{ 'registration.enjoy-easy' | translate }}</p>

      <button *ngIf="finished" routerLink="/Home" class="login-button p-1" mat-raised-button color="accent">Login</button>
    </div>
  </div>
</div>
