import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-requester-detail',
  templateUrl: './requester-detail.component.html',
  styleUrls: ['./requester-detail.component.scss']
})
export class RequesterDetailComponent implements OnInit {
  idCompany: number;

  companyName: string;

  idAssociate: number;
  constructor(private readonly companyService: CompanyService, private readonly route: ActivatedRoute, private readonly router: Router) {
    this.idCompany = +route.snapshot.paramMap.get('idCompany');
    this.idAssociate = +route.snapshot.paramMap.get('idAssociate');
    this.companyName = route.snapshot.paramMap.get('companyName');

    if (this.queryDataValidation()) {
      this.redirectToCompanies();
    }
  }

  async ngOnInit() {}
  queryDataValidation() {
    return isNaN(this.idCompany) || this.idCompany < 1 || isNaN(this.idAssociate) || this.idAssociate < 1;
  }
  redirectToCompanies() {
    this.router.navigateByUrl('/Settings/Companies');
  }
}
