import { createAction, props } from '@ngrx/store';
import { AddressToExportation } from '../reducers/exportation-delivery-address.reducer';
import { ExportationDeliveryAddress } from 'src/app/modules/payment/interfaces/exportationDeliveryAddress.interface';

export const enum exportationDeliveryAddress {
  ADD_ADDRESS = '[ADDRESS_OF_EXPORTATION] ADD ADDRESS',
  ADD_ALL_ADDRESS = '[ADDRESS_OF_EXPORTATION] ADD ALL ADDRESS',
  REMOVE_ADDRESS_BY_ID = '[ADDRESS_OF_EXPORTATION] REMOVE ADDRESS BY ID',
  CLEAN_ADDRESS = '[ADDRESS_OF_EXPORTATION]  CLEAN ADDRESSS',
  SET_ADDRESS_BY_ID = '[ADDRESS_OF_EXPORTATION]  SET ADDRESSS'
}

export const AddAllAddressToExportation = createAction(exportationDeliveryAddress.ADD_ALL_ADDRESS, props<{ _addressToExportation: ExportationDeliveryAddress[] }>());

export const AddAddressToExportation = createAction(exportationDeliveryAddress.ADD_ADDRESS, props<{ _addressToExportation: ExportationDeliveryAddress }>());

export const DeleteAddressToExportationById = createAction(exportationDeliveryAddress.REMOVE_ADDRESS_BY_ID, props<{ _idAddress: number }>());

export const CleanAddresssToExportation = createAction(exportationDeliveryAddress.CLEAN_ADDRESS);

export const SetAddresssByIdToExportation = createAction(exportationDeliveryAddress.SET_ADDRESS_BY_ID, props<{ _addresssToExportation: ExportationDeliveryAddress }>());
