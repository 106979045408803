<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ 'settings.contact-and-help.ccab' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="content">
    <mat-list>
      <ng-container *ngFor="let contact of contacts">
        <mat-divider></mat-divider>
        <mat-list-item>
          <mat-icon mat-list-icon>pin_drop</mat-icon>
          <div mat-line class="item">
            <b>{{ contact.address.location | translate }}</b>
          </div>
          <p mat-line class="item">
            {{ contact.address.address | translate }}
          </p>
        </mat-list-item>

        <mat-list-item>
          <mat-icon mat-list-icon>settings_phone</mat-icon>

          <div *ngFor="let phone of contact.phones" mat-line class="item">
            <span> {{ 'settings.contact-and-help.phone' | translate }}: </span>
            <a [href]="'tel:' + phone">{{ phone }}</a>
          </div>
        </mat-list-item>
      </ng-container>
      <mat-divider></mat-divider>
      <mat-list-item>
        <p mat-line class="text-upper">
          <b>{{ 'settings.contact-and-help.press-office' | translate }}</b>
        </p>
        <p mat-line>Daniel Medeiros</p>
        <p mat-line>A4&Holofote Comunicação</p>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
