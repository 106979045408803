import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/core/interfaces/company.interface';
import { CompanyService } from 'src/app/core/services/company.service';
import { ScheduleMeetingDialogComponent } from 'src/app/modules/marketplace/components/schedule-meeting-dialog/schedule-meeting-dialog.component';
import { OrderMeeting } from 'src/app/modules/marketplace/models/order-meeting';
import { NegotiationService } from 'src/app/modules/marketplace/services/negotiation.service';
import { ResultDialogData } from 'src/app/shared/components/result-dialog/result-dialog-data.interface';
import { ResultDialogComponent } from '@ccab/components-lib';
import { Meeting } from '../../interfaces/meeting.interface';
@Component({
  selector: 'app-closed-meeting',
  templateUrl: './closed-meeting.component.html',
  styleUrls: ['./closed-meeting.component.scss']
})
export class ClosedMeetingComponent implements OnInit {
  unsb$: Subject<any> = new Subject();
  company: Company;
  isLoading = false;
  element: OrderMeeting;
  meeting$: Observable<Meeting>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly negotiationService: NegotiationService,
    private readonly companyStore: Store<{ company: Company }>,
    private readonly companyService: CompanyService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly meetingStore: Store<{ meeting: Meeting }>
  ) {
    this.meeting$ = meetingStore.pipe(select('meeting'));
  }

  ngOnInit() {
    this.company = this.companyService.getCurrentCompany();

    this.companyStore
      .pipe(select('company'))
      .pipe(takeUntil(this.unsb$))
      .subscribe(async (comp: Company) => {
        if (comp.id !== this.company.id) this.router.navigateByUrl('/Matchmaking/Meetings');
      });

    this.meeting$.pipe(take(1)).subscribe(async (meeting: Meeting) => {
      this.element = meeting.order;
    });
  }

  scheduleMeetingDialog(): void {
    const dialogRef = this.dialog.open(ScheduleMeetingDialogComponent, {
      width: '800px',
      disableClose: true,
      data: this.element
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsb$))
      .subscribe((result) => {});
  }
  async completeProposal() {
    try {
      this.isLoading = true;
      let successMsg: string = this.translateService.instant('matchmaking.approved-proposal');

      let response = await this.negotiationService.completeOrder({
        idCompany: this.company.id,
        idOrder: this.element.idOrder || this.element.id
      });
      const dialogData: ResultDialogData = {
        success: !!response,
        text: !!response ? successMsg : 'matchmaking.erro-proposal',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigateByUrl('/Matchmaking/Meetings');
        });
    } catch (error) {
      this.isLoading = false;
      let erroMsg: string = this.translateService.instant('matchmaking.erro-proposal');
      const dialogData: ResultDialogData = {
        success: false,
        text: 'matchmaking.erro-proposal',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {});
    }
  }
  ngOnDestroy() {
    this.unsb$.next(null);
  }
}
