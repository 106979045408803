import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalizeCalendar } from 'src/app/globalize-calendar';
import Language from 'src/app/core/interfaces/language.interface';

@Component({
  selector: 'app-anonymous-layout',
  templateUrl: './anonymous-layout.component.html',
  styleUrls: ['./anonymous-layout.component.scss']
})
export class AnonymousLayoutComponent extends GlobalizeCalendar implements OnInit, OnDestroy {
  destroy$: Subject<any> = new Subject();
  isLogginPage = false;
  language$: Observable<Language>;
  isPt: boolean;

  constructor(
    private readonly router: Router,
    private readonly languageStore: Store<{ language: Language }>,

    dateAdapter: DateAdapter<Date>,
    langStore: Store<{ language: Language }>
  ) {
    super(dateAdapter, langStore);

    this.language$ = languageStore.pipe(select('language'));
  }

  ngOnInit(): void {
    this.isLogginPage = this.router.url === '/Login';
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        this.isLogginPage = event.url === '/Login';
      });
    this.language$.pipe(takeUntil(this.destroy$)).subscribe((lang: Language) => {
      if (lang) {
        this.isPt = lang.code.toLowerCase() === 'pt';
      }
    });
  }
}
