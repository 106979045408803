import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/core/services/language.service';
import { Category } from 'src/app/modules/marketplace/models/category';
import { SubCategory } from 'src/app/modules/marketplace/models/subCategory';
import { CategoryService } from 'src/app/modules/marketplace/services/category.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { Country } from 'src/app/shared/models/country';
import { Observable } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/core/services/company.service';
import { Company } from 'src/app/core/interfaces/company.interface';
import { MatchmakingService } from '../../services/matchmaking.service';
import { ResultDialogComponent } from '@ccab/components-lib';
import { ResultDialogData } from 'src/app/shared/components/result-dialog/result-dialog-data.interface';
@Component({
  selector: 'app-schedule-matchmaking-dialog',
  templateUrl: './schedule-matchmaking-dialog.component.html',
  styleUrls: ['./schedule-matchmaking-dialog.component.scss']
})
export class ScheduleMatchmakingDialogComponent implements OnInit {
  teste;
  category: Category[] = [];
  selectedSubCategory = [];
  selectedCategory = [];
  subcategories: SubCategory[] = [];
  selectedLangCode: string;

  visible = true;
  selectable = true;
  removable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  loading: boolean = false;

  translateToggle = [
    { name: 'matchmaking.dialog.yes', value: true },
    { name: 'matchmaking.dialog.no', value: false }
  ];
  meetTypeToggle = [
    { name: 'matchmaking.dialog.online', value: 'Online' },
    { name: 'matchmaking.dialog.presential', value: 'Presential' }
  ];
  meetTimeToggle = [
    { name: 'matchmaking.dialog.morning', value: 'Morning' },
    { name: 'matchmaking.dialog.evening', value: 'Evening' },
    { name: 'matchmaking.dialog.night', value: 'Night' }
  ];

  newMatchmaking = this.fb.group({
    category: ['', [Validators.required]],
    idSubCategory: ['', [Validators.required]],
    companyProfile: ['', [Validators.required]],
    products: this.fb.array([], Validators.required),
    numberOfMeetings: ['', [Validators.required]],
    restrictions: [''],
    comments: ['']
  });
  acceptTerms: boolean = false;
  interestProducts = this.newMatchmaking.get('products') as FormArray;
  interestCountries = new FormArray([], Validators.required);
  interestCountriesId = new FormControl([], Validators.required);

  accept = 'acceptTerms';

  bestTime: string;
  meetingType: string;
  translatorNeeded: boolean = null;

  @ViewChild('countriesInput') countriesInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  countries: Country[] = [];
  filteredCountries: Observable<Country[]>;

  countriesCtrl = new FormControl();

  currentCompany: Company;
  @ViewChild('countriesInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  constructor(
    private readonly dialogRef: MatDialogRef<ScheduleMatchmakingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly categoryService: CategoryService,
    private readonly languageService: LanguageService,
    private readonly fb: FormBuilder,
    private readonly countryService: CountryService,
    private readonly companyService: CompanyService,
    private readonly dialog: MatDialog,
    private readonly matchmakingService: MatchmakingService
  ) {
    this.currentCompany = this.companyService.getCurrentCompany();
  }

  async ngOnInit(): Promise<any> {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.loading = true;
    this.selectedLangCode = this.languageService.getCurrentLanguage();
    this.category = await this.categoryService.getCategory(this.selectedLangCode);
    this.countries = await this.countryService.getCountries();

    this.filteredCountries = this.countriesCtrl.valueChanges.pipe(
      startWith(null),
      map((country: string | null) => (country ? this._filter(country) : this.countries.slice()))
    );
    this.loading = false;
  }
  scrollEvent = (event: any): void => {
    if (this.autoComplete.panelOpen)
      // this.autoComplete.closePanel();
      this.autoComplete.updatePosition();
  };
  onNoClick() {
    this.dialogRef.close();
  }

  subcategoryChange(subcategoryId) {
    this.selectedSubCategory = this.subcategories.filter((c) => (c.id = subcategoryId));
  }

  async getSubcategories(categoryId: number): Promise<any> {
    this.selectedCategory = this.category.filter((c) => c.id === categoryId);
    this.newMatchmaking.controls.idSubCategory.setValue(undefined);
    this.selectedSubCategory = [];
    this.subcategories = await this.categoryService.getSubCategory(categoryId, this.selectedLangCode);
  }

  add(event: MatChipInputEvent, chipList: string): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      switch (chipList) {
        case 'chipListProducts':
          this.interestProducts.push(new FormControl(value.trim()));
          break;
      }
    }
    if (input) {
      input.value = '';
    }
  }

  remove(interest: string, chipList: string): void {
    let index;
    switch (chipList) {
      case 'chipListProducts':
        index = this.interestProducts.value.indexOf(interest);
        if (index >= 0) {
          this.interestProducts.removeAt(index);
        }
        break;
      case 'chipListCountries':
        index = this.interestCountries.value.indexOf(interest);
        if (index >= 0) {
          this.interestCountries.removeAt(index);
          this.interestCountriesId.value.splice(index, 1);
        }
        break;
    }
  }

  async onSubmit() {
    this.loading = true;
    try {
      let response = await this.matchmakingService.requestMatchmaking({
        idSubCategory: +this.newMatchmaking.value.idSubCategory,
        companyProfile: this.newMatchmaking.value.companyProfile,
        products: this.newMatchmaking.value.products as string[],
        countries: this.interestCountriesId.value,
        numberOfMeetings: +this.newMatchmaking.value.numberOfMeetings,
        translatorNeeded: this.translatorNeeded,
        meetingType: this.meetingType,
        restrictions: this.newMatchmaking.value.restrictions,
        comments: this.newMatchmaking.value.comments,
        idCompany: this.currentCompany.id,
        bestTime: this.bestTime
      });

      this.validateScheduleResponse(response);
    } catch (e) {
      let response = { success: false };
      this.validateScheduleResponse(response);
    }
  }

  selected(event: MatAutocompleteSelectedEvent, chipList: string): void {
    if ((event.option.viewValue || '').trim()) {
      let index;
      switch (chipList) {
        case 'chipListProducts':
          index = this.interestCountries.value.findIndex((product) => {
            product == event.option.viewValue.trim();
          });
          if (index < 0) {
            this.interestProducts.push(new FormControl(event.option.viewValue.trim()));
          }
          break;
        case 'chipListCountries':
          index = this.interestCountries.value.findIndex((country) => country == event.option.viewValue.trim());

          if (index < 0) {
            this.interestCountries.push(new FormControl(event.option.viewValue.trim()));
            this.interestCountriesId.value.push(event.option.value);
          }
          break;
      }
    }
    this.countriesInput.nativeElement.value = '';
    this.countriesCtrl.setValue(null);
  }

  private _filter(value) {
    if (isNaN(value)) {
      let filterValue = value.toLowerCase();

      return this.countries.filter((country) => country.name.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  validateScheduleResponse(response) {
    if (response.success) {
      const dialogData: ResultDialogData = {
        success: response.success,
        text: response.success ? 'matchmaking.dialog.successfully-request' : 'matchmaking.dialog.request-erro',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        disableClose: true,
        data: dialogData
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.dialogRef.close(response.data);
        });

      this.loading = false;
    } else {
      this.loading = false;
      const dialogData: ResultDialogData = {
        success: false,
        text: 'matchmaking.dialog.request-erro',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        disableClose: true,
        data: dialogData
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.dialogRef.close(response.data);
        });
    }
  }
}
