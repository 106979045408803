import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  questions = [
    { ask: 'what-is-easytrade-ask', answer: 'what-is-easytrade-answer' },
    {
      ask: 'what-are-easytrade-benefits-ask',
      answer: 'what-are-easytrade-benefits-answer'
    },
    {
      ask: 'foreign-company-export-ask',
      answer: 'foreign-company-export-answer'
    },
    { ask: 'different-products-ask', answer: 'different-products-answer' },
    { ask: 'deadline-process-ask', answer: 'deadline-process-answer' },
    {
      ask: 'notifier-status-change-ask',
      answer: 'notifier-status-change-answer'
    },
    {
      ask: 'process-saved-indefinitely-ask',
      answer: 'process-saved-indefinitely-answer'
    },
    { ask: 'document-sent-ask', answer: 'document-sent-answer' },
    {
      ask: 'company-pending-payment-ask',
      answer: 'company-pending-payment-answer'
    },
    {
      ask: 'triangular-operation-ask',
      answer: 'triangular-operation-answer',
      observation: 'triangular-operation-observation'
    },
    {
      ask: 'triangular-operation-two-invoice-ask',
      answer: 'triangular-operation-two-invoice-answer'
    },
    {
      ask: 'origin-certificate-emission-ask',
      answer: 'origin-certificate-emission-answer'
    },
    {
      ask: 'different-modes-ask',
      answer: 'different-modes-answer',
      observation: 'different-modes-observation'
    },
    {
      ask: 'different-countries-destination-ask',
      answer: 'different-countries-destination-answer'
    },
    { ask: 'how-payment-is-done-ask', answer: 'how-payment-is-done-answer' },
    { ask: 'include-new-document-ask', answer: 'include-new-document-answer' },
    { ask: 'request-changes-ask', answer: 'request-changes-answer' },
    {
      ask: 'food-and-beverage-industry-ask',
      answer: 'food-and-beverage-industry-answer'
    },
    {
      ask: 'only-qatar-exportation-ask',
      answer: 'only-qatar-exportation-answer'
    },
    {
      ask: 'dispatcher-multi-countries-ask',
      answer: 'dispatcher-multi-countries-answer'
    },
    {
      ask: 'need-certifier-document-ask',
      answer: 'need-certifier-document-answer'
    },
    {
      ask: 'systems-cosularization-ask',
      answer: 'systems-cosularization-answer'
    },
    { ask: 'document-ellos-team-ask', answer: 'document-ellos-team-answer' },
    {
      ask: 'registered-on-platform-ask',
      answer: 'registered-on-platform-answer'
    },
    { ask: 'pay-to-access-ask', answer: 'pay-to-access-answer' }
  ];
  constructor() {}

  ngOnInit(): void {}
}
