import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from '../../../../core/services/company.service';

@Component({
  selector: 'app-leave-company-dialog',
  templateUrl: './leave-company-dialog.component.html',
  styleUrls: ['./leave-company-dialog.component.scss']
})
export class LeaveCompanyDialogComponent implements OnInit {
  waitingResponse: boolean = false;

  constructor(
    private readonly dialogRef: MatDialogRef<LeaveCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { idCompany: number; companyName: string },
    private readonly companyService: CompanyService
  ) {}

  ngOnInit(): void {}

  async leaveCompany() {
    try {
      this.waitingResponse = true;
      await this.companyService.leaveCompany(this.data.idCompany);
      this.dialogRef.close(true);
    } catch (e) {
    } finally {
      this.waitingResponse = false;
    }
  }
}
