import { ComponentsLibModule } from '@ccab/components-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificatesComponent } from './pages/certificates/certificates.component';

import { CertificatesRoutingModule } from './certificates-routing.module';
import { MatchmakingRoutingModule } from '../matchmaking/matchmaking-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { certificateNavbarComponent } from './components/certificates-navbar/certificate-navbar.component';

import { CertificateOriginStatusLabelComponent } from './components/origin-certificate-status-label/origin-certificate-status-label.component';
import { OriginCertificateComponent } from './pages/certificates-origin/origin-certificate.component';
import { ModalConsularApprovalComponent } from './modals/modal-consular-approval/modal-consular-approval.component';
import { ModalNotificationTemplateComponent } from './modals/modal-notification-template/modal-notification-template.component';
import { ModalImportOriginCertificateComponent } from './modals/modal-import-origin-certificate/modal-import-origin-certificate.component';
import { SearchOriginCertificateComponent } from './components/search-origin-certificate/search-origin-certificate.component';
import { NewOriginCertificateComponent } from './pages/new-origin-certificate/new-origin-certificate.component';
import { InvoiceFormComponent } from './components/invoice-form/invoice-form.component';
import { CompanyManagementComponent } from './components/company-management/company-management.component';
import { TableOriginCertificateComponent } from './components/origin-certificate-table/origin-certificate-table.component';
import { OriginCertificateDetailComponent } from './pages/origin-certificate-detail/origin-certificate-detail.component';
import { CompaniesInvolvedComponent } from './components/companies-involved/companies-involved.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { CertificateProductExportationGridComponent } from './components/certificate-exportation-products/certificate-product-exportation-grid/certificate-product-exportation-grid.component';
import { CertificateExportationProductFormComponent } from './components/certificate-exportation-products/certificate-exportation-product-form/certificate-exportation-product-form.component';
import { CertificateExportationProductsComponent } from './components/certificate-exportation-products/certificate-exportation-products.component';
import { ModalCertificateEdit } from './modals/modal-certificate-edit/modal-certificate-edit.component';

@NgModule({
  declarations: [
    CertificatesComponent,
    SearchOriginCertificateComponent,
    CompaniesInvolvedComponent,
    OriginCertificateDetailComponent,
    OriginCertificateComponent,
    CertificateProductExportationGridComponent,
    CertificateExportationProductFormComponent,
    CertificateExportationProductsComponent,
    CompanyManagementComponent,
    InvoiceFormComponent,
    NewOriginCertificateComponent,
    ModalImportOriginCertificateComponent,
    ModalNotificationTemplateComponent,
    certificateNavbarComponent,
    ModalConsularApprovalComponent,
    TableOriginCertificateComponent,
    OriginCertificateComponent,
    CertificateOriginStatusLabelComponent,
    ModalCertificateEdit
  ],
  imports: [CommonModule, CertificatesRoutingModule, MatchmakingRoutingModule, SharedModule, ReactiveFormsModule, FormsModule, TourMatMenuModule, ComponentsLibModule]
})
export class CertificatesModule {}
