import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[capslockOn]',
  outputs: ['capslockOn']
})
export class CapsLockOnDirective {
  @Output() capslockOn = new EventEmitter<boolean>();
  constructor() {}

  @HostListener('window:click', ['$event']) onClick(event) {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn.emit(true);
    } else {
      this.capslockOn.emit(false);
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn.emit(true);
    } else {
      this.capslockOn.emit(false);
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn.emit(true);
    } else {
      this.capslockOn.emit(false);
    }
  }
}
