import * as fromAddNotification from './../../core/actions/notifications.action';
import * as fromCompanyExportationNotificationSelector from './../../core/reducers/notifications.reducer';

import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TourService } from 'ngx-ui-tour-md-menu';
import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ChangeTheme } from '../../core/actions/theme.action';
import { SessionUser } from 'src/app/shared/models/session-user';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';

import Theme from 'src/app/core/interfaces/theme.interface';
import { Company } from 'src/app/core/interfaces/company.interface';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';

import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { EasyTradeService } from 'src/app/modules/easy-trade/services/easy-trade.service';
import { CompanyExportationNotification } from 'src/app/modules/certificates/interfaces/exportation-notification.interface';
import { MatDrawer } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('drawer', { read: ElementRef }) containerElement: ElementRef;
  @ViewChild('drawer') drawer: MatDrawer;

  user: SessionUser;
  companies: Company[];
  company: CompanyPreview;
  public subscription: any;
  selectedCompany: Company;
  currentThemeName: string;
  faGlobeAfrica = faGlobeAfrica;
  destroy$: Subject<any> = new Subject<any>();

  theme$: Observable<Theme>;
  loggedIn$: Observable<boolean>;
  company$: Observable<CompanyPreview>;
  sessionUser$: Observable<SessionUser>;

  public modules = [];
  public modulePermission = PermissionsEnum;
  hidden = false;
  showFiller = false;
  public notifications: CompanyExportationNotification[] = [];
  public notificationsCount = 0;
  public scrollDistance = 1;
  public loadingMore = false;
  canLoadMore: boolean = true;
  public notificationValidation = false;
  @Input() showTimeZone = true;
  @Input() showCompanySelector = true;
  @Input() ShowTranslationDropdown = false;
  @Input() opened = false;
  companyExportationNotification: CompanyExportationNotification[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly themeService: ThemeService,
    private readonly companyState: Store<{ company: CompanyPreview }>,
    private readonly themeStore: Store<{ theme: Theme }>,
    public tourService: TourService,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>,
    public permissionsService: PermissionsService,
    public readonly easyTradeService: EasyTradeService,
    private companyExportationNotificationStore: Store<{
      companyExportationNotification: CompanyExportationNotification[];
    }>,
    private elementRef: ElementRef,
    private readonly dialog: MatDialog
  ) {
    this.company$ = this.companyState.pipe(select('company'));
    this.sessionUser$ = this.sessionUserStore.pipe(select('sessionUser'));
    this.theme$ = themeStore.pipe(select('theme'));
    this.containerElement = this.elementRef;
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(targetElement: any) {
    if (
      (this.containerElement && this.containerElement.nativeElement.contains(targetElement)) ||
      this.toggleButton.nativeElement == targetElement ||
      this.toggleButton.nativeElement.contains(targetElement)
    ) {
    } else {
      this.drawer.close();
    }
  }

  async ngOnInit() {
    this.companyExportationNotificationStore
      .select(fromCompanyExportationNotificationSelector.getCompanyExportationNotification)
      .subscribe((_companyExportationNotification: CompanyExportationNotification[]) => {
        this.companyExportationNotification = _companyExportationNotification.filter((notification) => notification.status.idStatus == 1);
        this.notificationsCount = this.companyExportationNotification.length;
      });
    this.resetTheme();
    this.theme$.pipe(takeUntil(this.destroy$)).subscribe((newTheme: Theme) => {
      if (newTheme) {
        this.currentThemeName = newTheme.displayName;
      }
    });

    this.sessionUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      if (company) {
        this.company = company;
      }
    });

    this.subscription = this.permissionsService.getPermissionschangeEmitter().subscribe((item) => {
      this.modules = item;
    });
    await this.listNotification();
    this.notificationsCount = this.companyExportationNotification.length;
    this.notificationValidation = this.notificationsCount > 0;
    this.opened = false;
  }

  async ngOnChanges() {
    await this.listNotification();
  }

  private resetTheme(): void {
    const defaultTheme = this.themeService.resetTheme();

    this.currentThemeName = defaultTheme.displayName;
    this.themeStore.dispatch(ChangeTheme(defaultTheme));
  }

  changeTheme(): void {
    const currentTheme: Theme = this.themeService.changeTheme();
    this.themeStore.dispatch(ChangeTheme(currentTheme));
    this.currentThemeName = currentTheme.displayName;
  }

  logout(): void {
    this.dialog.closeAll();
    this.authService.logout();
    this.router.navigateByUrl('/Login');
  }

  payment(): void {
    this.router.navigateByUrl('/Payment');
  }

  validateTimeZone(): boolean {
    if (this.company?.timezone) {
      return true;
    } else {
      return false;
    }
  }

  async onScrollDown() {
    this.loadingMore = true;
    if (this.companyExportationNotification.length == 0) {
      this.loadingMore = false;
      return;
    }
    this.loadingMore = false;
  }

  validateHiddenTimeZone(): boolean {
    return !this.modules.includes(this.modulePermission.AcessarPerfilHome);
  }

  navigateSettings() {
    this.router.navigateByUrl('/Settings/MyProfile');
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.subscription.unsubscribe();
  }
  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  async listNotification() {
    try {
      const result = await this.easyTradeService.listCompanyExportationNotifications(this.company.id);
      this.companyExportationNotificationStore.dispatch(fromAddNotification.AddNotification({ _companyExportationNotification: result }));
    } catch (error) {
      error;
    }
  }
  onCompanyChange(event: CompanyExportationNotification[]) {
    this.companyExportationNotificationStore.dispatch(fromAddNotification.AddNotification({ _companyExportationNotification: event }));
    this.notificationValidation = this.notificationsCount > 0;
    this.opened = false;
  }

  async clearAllNotifications() {
    let promises: Promise<any>[] = [];
    promises.push(this.easyTradeService.clearAllNotifications(this.company.id));
    const results = await Promise.allSettled(promises);
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        this.companyExportationNotificationStore.dispatch(fromAddNotification.AddNotification({ _companyExportationNotification: [] }));
      } else {
      }
    });
  }

  async clearNotificationById(id: number) {
    let promises: Promise<any>[] = [];
    promises.push(this.easyTradeService.clearNotificationById(id));
    const results = await Promise.allSettled(promises);
    results.forEach((result, index) => {
      if (result.status !== 'fulfilled') {
        console.error(result, 'error');
      } else {
        this.companyExportationNotificationStore.dispatch(fromAddNotification.RemoveById({ id: id }));
      }
    });
  }
}
