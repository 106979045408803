<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center ">
      <p></p>
      <h1 fxFlexAlign="center">
        {{ 'marketplace.dialog.schedule-meeting' | translate }}
      </h1>
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center " class="main-content" *ngIf="!isLoading">
    <mat-form-field appearance="outline" class="proposal-input" *ngIf="data.productName">
      <mat-label>
        {{ 'marketplace.dialog.proposal-demand' | translate }}
      </mat-label>
      <input matInput [value]="'P-' + (data.idOrder || data.id) + ' - ' + data.productName" disabled />
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" class="proposal-input input-group">
      <mat-form-field color="accent" appearance="outline" fxFlexAlign="start">
        <mat-label> {{ 'marketplace.dialog.date' | translate }} </mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="meetingDatePicker"
          [formControl]="meetingDate"
          [min]="today"
          [matDatepickerFilter]="guestCompanyHolidayFilter"
          (click)="meetingDatePicker.open()"
          (focus)="meetingDatePicker.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="meetingDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #meetingDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlexAlign="start">
        <mat-label> {{ 'marketplace.dialog.hour' | translate }} </mat-label>
        <input
          matInput
          readonly
          [ngxTimepicker]="meetingHourTimePicker"
          [formControl]="meetingHour"
          [format]="24"
          [value]="this.meetingHour.value == null || !this.meetingHour.value ? '12:00' : this.meetingHour.value"
        />
        <button mat-icon-button matSuffix (click)="meetingHourTimePicker.open()">
          <mat-icon>schedule</mat-icon>
        </button>
        <ngx-material-timepicker [theme]="currentTheme == 'ellos-dark-theme' ? darkTheme : lightTheme" (timeSet)="setTime($event)" #meetingHourTimePicker></ngx-material-timepicker>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-content fxLayout="row" fxLayoutAlign="center center " class="main-content" *ngIf="isLoading">
    <app-ellos-spinner [text]="'marketplace.details.loading' | translate"></app-ellos-spinner>
  </div>
  <mat-divider class="divider"></mat-divider>
  <div mat-dialog-actions class="dialog-content" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="onNoClick()">
      {{ 'marketplace.dialog.cancel-button' | translate | uppercase }}
    </button>
    <button mat-button (click)="save()" [disabled]="!meetingHour.valid || !meetingDate.valid || data?.id <= 0" *ngIf="!isEditing">
      {{ 'marketplace.dialog.confirm-button' | translate | uppercase }}
    </button>

    <button mat-button (click)="edit()" [disabled]="!meetingHour.valid || !meetingDate.valid || data?.id <= 0" *ngIf="isEditing">
      {{ 'marketplace.dialog.confirm-button' | translate | uppercase }}
    </button>
  </div>
</div>
