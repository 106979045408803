import { Injectable } from '@angular/core';
import Theme from '../interfaces/theme.interface';
import { OverlayContainer } from '@angular/cdk/overlay';
import { StoreKey } from '../interfaces/storeKey.interface';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly _themes: Array<Theme>;
  private _currentTheme: Theme;

  constructor(private readonly storageService: StorageService, private readonly overlayContainer: OverlayContainer) {
    this._themes = [
      {
        name: 'ellos-light-theme',
        displayName: 'toolbar.light-theme'
      },
      {
        name: 'ellos-dark-theme',
        displayName: 'toolbar.dark-theme'
      }
    ];
  }

  /**
   * Gets dark theme
   * @returns dark theme
   */
  getDarkTheme(): Theme {
    return this._themes[1];
  }
  /**
   * Gets light theme
   * @returns light theme
   */
  getLightTheme(): Theme {
    return this._themes[0];
  }
  /**
   * Gets current theme
   * @returns current theme
   */
  getCurrentTheme(): Theme {
    return this._currentTheme;
  }

  /**
   * Changes current theme
   * @returns current Theme
   */
  changeTheme(): Theme {
    const prevThemeName = this._currentTheme.name;
    if (this._currentTheme.name === this._themes[0].name) {
      this._currentTheme = this._themes[1];
    } else {
      this._currentTheme = this._themes[0];
    }

    this.overlayContainer.getContainerElement().classList.remove(prevThemeName);

    this.overlayContainer.getContainerElement().classList.add(this._currentTheme.name);

    this.storageService.set({
      key: StoreKey.themeName,
      value: this._currentTheme.name
    });

    return this._currentTheme;
  }
  resetTheme(): Theme {
    const selectedTheme = localStorage.getItem('theme-name');
    const prevThemeName = selectedTheme !== null ? selectedTheme : undefined;
    this._currentTheme = prevThemeName && this._themes.find((t) => t.name === prevThemeName) ? this._themes.find((t) => t.name === prevThemeName) : this._themes[1];

    this.setOverlay(JSON.stringify(prevThemeName), this._currentTheme.name);
    return this._currentTheme;
  }

  private setOverlay(prevTheme: string, currentTheme: string) {
    this.overlayContainer.getContainerElement().classList.remove(prevTheme);

    this.overlayContainer.getContainerElement().classList.add(currentTheme);
  }
}
