import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompanyPreview } from '../../../..//core/interfaces/company-preview.interface';
import { CompanyService } from '../../../../core/services/company.service';
// import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ImageCropperComponent } from '@ccab/components-lib';

@Component({
  selector: 'app-company-cover',
  templateUrl: './company-cover.component.html',
  styleUrls: ['./company-cover.component.scss']
})
export class CompanyCoverComponent implements OnInit {
  @Input() edit: boolean = false;
  @Input() size: number = 50;
  @Input() companyId: number;
  @Input() url: string | SafeResourceUrl;
  @Input() saveEvent: Observable<boolean> = new Observable<any>();

  isDragOver: boolean = false;
  updating: boolean;
  destroy$ = new Subject();
  company$: Observable<CompanyPreview>;
  sessionCompanies$: Observable<CompanyPreview[]>;
  companies: CompanyPreview[];
  company: CompanyPreview;
  _file: any;
  constructor(
    private readonly companyService: CompanyService,
    private readonly companyStore: Store<CompanyPreview>,
    private readonly sessionCompaniesStore: Store<CompanyPreview[]>,
    private readonly dialog: MatDialog,
    private readonly sanitizer: DomSanitizer,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>,
    private readonly companyState: Store<{ company: CompanyPreview }>
  ) {
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
    this.company$ = this.companyState.pipe(select('company'));
  }

  async ngOnInit() {
    if (!this.url) {
      this.url = '../../../../../assets/img/store-cover.jpg';
    }

    this.sessionCompanies$.pipe(takeUntil(this.destroy$)).subscribe(async (companies) => {
      if (companies) {
        this.companies = [...companies];
      }
    });

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      if (company) {
        this.company = { ...company };
      }
    });

    this.saveEvent.pipe(takeUntil(this.destroy$)).subscribe((save) => {
      if (save) {
        this.uploadImage();
      }
    });
  }

  onDragOverEvent(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.edit) {
      this.isDragOver = true;
    }
  }
  async onFileDrop(event) {
    event.stopPropagation();
    event.preventDefault();

    this.openCropperDialog(event.dataTransfer.files[0]);
    this.isDragOver = false;
  }
  async imageAdd(event) {
    this.openCropperDialog(event.item(0));
  }
  openCropperDialog(file: File) {
    if (this.isValidFile(file)) {
      let dialogRef = this.dialog.open(ImageCropperComponent, {
        data: {
          file: file,
          aspectRatio: 7 / 1.6,
          resizeToWidth: 920,
          resizeToHeight: 283,
          cropperMinWidth: 200
        }
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((e) => {
          if (e) {
            this._file = new File([e], 'company_cover.jpg');
            this.changeImage();
          }
        });
    }
  }

  changeImage() {
    var reader = new FileReader();
    reader.readAsDataURL(this._file);
    reader.onload = (event) => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result.toString());
    };
  }

  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.edit) {
      this.isDragOver = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async uploadImage() {
    if (this._file) {
      try {
        this.updating = true;
        let response = await this.companyService.updateCompanyCover(this._file, this.companyId);
        if (response.success) {
          var reader = new FileReader();
          reader.readAsDataURL(this._file);
          reader.onload = (event) => {
            this.changeImage();
          };
        }
        this.updating = false;
      } catch (e) {
        this.updating = false;
      }
    }
  }

  isValidFile(file) {
    return file.type.split('/')[0] === 'image';
  }
}
