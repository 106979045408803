import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectCard } from 'src/app/shared/models/select-card.interface';
import { UserPreferencesEnum } from '../../enums/user-preferences.enum';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserPreferencesComponent),
      multi: true
    }
  ]
})
export class UserPreferencesComponent implements OnInit, ControlValueAccessor {
  options: SelectCard<UserPreferencesEnum>[] = [
    {
      matIconCode: 'import_contacts',
      description: 'settings.profile.preferences.costumized-studies',
      value: UserPreferencesEnum.costumizedService
    },
    {
      matIconCode: 'menu_book',
      description: 'settings.profile.preferences.anba-content',
      value: UserPreferencesEnum.anbaContent
    },
    {
      matIconCode: 'groups',
      description: 'settings.profile.preferences.consultancy',
      value: UserPreferencesEnum.consultancy
    },
    {
      matIconCode: 'g_translate',
      description: 'settings.profile.preferences.translation',
      value: UserPreferencesEnum.tranlation
    },
    {
      matIconCode: 'analytics',
      description: 'settings.profile.preferences.competitive-analysis',
      value: UserPreferencesEnum.competitiveAnalysis
    },
    {
      matIconCode: 'show_chart',
      description: 'settings.profile.preferences.ra-analysis',
      value: UserPreferencesEnum.raAnalysis
    },
    {
      matIconCode: 'view_quilt',
      description: 'settings.profile.preferences.layout-service',
      value: UserPreferencesEnum.layoutService
    },
    {
      matIconCode: 'closed_caption',
      description: 'settings.profile.preferences.subtitling',
      value: UserPreferencesEnum.subtitling
    },
    {
      matIconCode: 'directions_boat',
      description: 'settings.profile.preferences.transport-and-ship',
      value: UserPreferencesEnum.transportAndShipping
    },
    {
      matIconCode: 'web',
      description: 'settings.profile.preferences.brand-page',
      value: UserPreferencesEnum.brandPage
    }
  ];

  private _values: any[];
  public get values(): any[] {
    return this._values;
  }
  public set values(v: any[]) {
    this._values = v;
    this.propagateChange(this._values);
  }
  propagateChange = (_) => {};
  constructor() {}

  ngOnInit(): void {}

  writeValue(values: any[]): void {
    if (!values || values == null) this._values = [];
    else this._values = values;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // not implemented but required by interface
  }
  setDisabledState?(isDisabled: boolean): void {
    // not implemented but required by interface
  }
}
