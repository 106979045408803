<h1>{{ companyDetail?.name }}</h1>

<mat-card>
  <mat-tab-group color="accent" class="tab" (selectedIndexChange)="loadContent($event)">
    <mat-tab [label]="'settings.companies.company-information' | translate">
      <div fxLayout="column" class="main-content" fxLayoutAlign="start start" fxLayoutGap="20px">
        <div fxLayout="row" class="company" fxLayout.lt-md="column" fxLayout.lt-md="center center" fxLayoutAlign="start center" fxLayoutGap="40px">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex.lt-md="100" fxLayoutGap="20px">
            <ngx-avatars size="117" [src]="companyLogo"></ngx-avatars>
          </div>
          <app-company-cover class="company-logo margemCapa" [edit]="false" fxFlex="grow" [url]="companyCover" [companyId]="companyId"> </app-company-cover>
        </div>
        <div id="divTeste" fxLayout="row wrap" fxLayoutAlign="start start" fxFill class="divPrincipal">
          <label class="titulo">{{ 'registration.company-information' | translate }}</label>
          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.company-name' | translate }}</span>
              <p>{{ companyDetail?.name }}</p>
            </div>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.country' | translate }}</span>
              <p><span [ngClass]="classePaisBandeira"></span>{{ companyDetail?.country.name }}</p>
            </div>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.timezone' | translate }}</span>
              <p>
                <span *ngIf="companyDetail?.timezone">
                  GMT&nbsp;
                  <span *ngIf="companyDetail?.timezone?.offset > 0">+</span> </span
                >{{ companyDetail?.timezone?.offset }}&nbsp;{{ companyDetail?.timezone?.name.replace('_', ' ') || '-' }}
              </p>
            </div>
            <div fxFlex="25" fxFlex.lt-sm="100" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.date-affiliation' | translate }}</span>
              <p>{{ (companyDetail?.associationDate | ellosDate) || '-' }}</p>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.segment' | translate }}</span>
              <p>{{ sector?.name || '-' }}</p>
            </div>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.primary-chapter' | translate }}</span>
              <p>{{ primaryChapter?.name || '-' }}</p>
            </div>
            <div fxFlex="25" fxFlex.lt-sm="100" *ngIf="checkTypeCompany()">
              <span>{{ 'settings.companies.type-trade' | translate }}</span>
              <p *ngIf="companyDetail?.profiles.length > 0; else withoutProfile">
                <span *ngFor="let profile of companyDetail?.profiles; let i = index">
                  {{ 'settings.companies.' + profile.toLowerCase() | translate }}<span *ngIf="i + 1 !== companyDetail?.profiles.length">, </span>
                </span>
              </p>

              <ng-template #withoutProfile>
                <p>-</p>
              </ng-template>
            </div>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.state-registration' | translate }}</span>
              <p>{{ companyDetail?.stateRegistry || '-' }}</p>
            </div>
          </div>

          <label class="titulo">{{ 'registration.company-address' | translate }}</label>

          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.zip-code' | translate }}</span>
              <p>{{ companyDetail?.zipCode || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.street' | translate }}</span>
              <p>{{ companyDetail?.street || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.number' | translate }}</span>
              <p>{{ companyDetail?.number || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.city' | translate }}</span>
              <p>{{ companyDetail?.city || '-' }}</p>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.district' | translate }}</span>
              <p>{{ companyDetail?.district || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.state' | translate }}</span>
              <p>{{ companyDetail?.state || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.complement' | translate }}</span>
              <p>{{ companyDetail?.number || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.po-box' | translate }}</span>
              <p>{{ companyDetail?.poBox | dashIfNull }}</p>
            </div>
          </div>

          <label class="titulo"
            >{{ 'registration.cnpj-data' | translate }}
            <span>
              <div class="tooltip">
                <span class="informationClass">i</span>
                <span class="tooltiptext">Esses dados foram automaticamente recebidos pelo número do CNPJ</span>
              </div>
            </span>
          </label>

          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>CNPJ</span>
              <p>{{ companyDetail?.cnpj || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.name' | translate }}</span>
              <p>{{ companyDetail?.name || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.fantasy-name' | translate }}</span>
              <p>{{ companyDetail?.fantasyName || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.country' | translate }}</span>
              <p>{{ companyDetail?.country?.name || '-' }}</p>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.zip-code' | translate }}</span>
              <p>{{ companyDetail?.originalAddressDetails?.zipCode || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.street' | translate }}</span>
              <p>{{ companyDetail?.originalAddressDetails?.street || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.number' | translate }}</span>
              <p>{{ companyDetail?.originalAddressDetails?.number || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.city' | translate }}</span>
              <p>{{ companyDetail?.originalAddressDetails?.city || '-' }}</p>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.district' | translate }}</span>
              <p>{{ companyDetail?.originalAddressDetails?.district || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.state' | translate }}</span>
              <p>{{ companyDetail?.originalAddressDetails?.state || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>{{ 'settings.companies.complement' | translate }}</span>
              <p>{{ companyDetail?.originalAddressDetails?.number || '-' }}</p>
            </div>
          </div>

          <label class="titulo">{{ 'registration.social-media' | translate }}</label>

          <div fxLayout="row wrap" fxLayoutAlign="start start" fxFill>
            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>Site</span>
              <p>{{ companyDetail?.originalAddressDetails?.website || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>Instagram</span>
              <p class="fontIcone"><fa-icon [icon]="faInstagram"></fa-icon>{{ companyDetail?.originalAddressDetails?.instagram || ' - ' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>Facebook</span>
              <p class="fontIcone"><fa-icon [icon]="faFacebookSquare"></fa-icon>{{ companyDetail?.originalAddressDetails?.facebook || '-' }}</p>
            </div>

            <div fxFlex="25" fxFlex.lt-sm="100">
              <span>linkedin</span>
              <p class="fontIcone"><fa-icon [icon]="faLinkedin"></fa-icon>{{ companyDetail?.originalAddressDetails?.linkedin || '-' }}</p>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="'settings.companies.address' | translate">
      <div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="25px">
        <div fxLayout="row wrap" class="row" fxLayoutAlign="start start" fxLayoutGap="100px">
          <div>
            <span>{{ 'settings.companies.zip-code' | translate }}</span>
            <p>{{ companyDetail?.zipCode || '-' }}</p>
          </div>
          <div>
            <span>{{ 'settings.companies.po-box' | translate }}</span>
            <p>{{ companyDetail?.poBox || '-' }}</p>
          </div>

          <div>
            <span>{{ 'settings.companies.address' | translate }}</span>
            <p>{{ companyDetail?.street || '-' }}</p>
          </div>
          <div>
            <span>{{ 'settings.companies.number' | translate }}</span>
            <p>{{ companyDetail?.number || '-' }}</p>
          </div>
        </div>
        <div fxLayout="row wrap" class="row" fxLayoutAlign="start  start" fxLayoutGap="100px">
          <div>
            <span>{{ 'settings.companies.city' | translate }}</span>
            <p>{{ companyDetail?.city || '-' }}</p>
          </div>
          <div>
            <span>{{ 'settings.companies.district' | translate }}</span>
            <p>{{ companyDetail?.district || '-' }}</p>
          </div>
          <div>
            <span>{{ 'settings.companies.state' | translate }}</span>
            <p>{{ companyDetail?.state || '-' }}</p>
          </div>
          <div>
            <span>{{ 'settings.companies.complement' | translate }}</span>
            <p>{{ companyDetail?.complement || '-' }}</p>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="'settings.companies.contact' | translate">
      <div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="25px">
        <div fxLayout="column" class="row container-contact" fxLayoutAlign="start start" *ngIf="contacts?.length > 0; else noContacts">
          <div fxLayout="row row" class="row" fxLayoutAlign="start start">
            <div fxFlex="20">
              <span>{{ 'settings.companies.name' | translate }}</span>
            </div>
            <div fxFlex="20">
              <span>{{ 'settings.companies.role' | translate }}</span>
            </div>
            <div fxFlex="20"><span>Email</span></div>
            <div fxFlex="20">
              <span>{{ 'settings.companies.phone' | translate }}</span>
            </div>
          </div>

          <ng-container *ngFor="let contact of contacts">
            <div fxLayout="row" class="row" fxLayoutAlign="start start">
              <div fxFlex="20">
                <p>{{ contact?.name || '-' }}</p>
              </div>
              <div fxFlex="20">
                <p>{{ contact?.role || '-' }}</p>
              </div>
              <div fxFlex="20">
                <p>{{ contact?.email }}</p>
              </div>
              <div fxFlex="20">
                <p>{{ contact?.phone || '-' }}</p>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-template #noContacts>
          <p>{{ 'settings.companies.no-contacts' | translate }}</p>
        </ng-template>

        <div>
          <div *ngIf="socialMedia?.length > 0; else noSocialMedias">
            <div fxLayout="row" fxLayoutAlign="start  start">
              <span class="social-media">{{ 'settings.companies.social-media' | translate }}</span>
              <span>Link </span>
            </div>
            <div fxLayout="column" fxLayoutAlign="start  start" *ngFor="let media of socialMedia">
              <div fxLayout="row" fxLayoutAlign="start  start">
                <p class="social-media">
                  <fa-icon class="media-icon" [icon]="getIcon(media?.socialMedia.toLowerCase())"> </fa-icon>
                </p>
                <p>{{ media.mediaUrl }}</p>
              </div>
            </div>
          </div>
          <ng-template #noSocialMedias>
            <p>{{ 'settings.companies.no-social-medias' | translate }}</p>
          </ng-template>
          <div fxLayout="row wrap" class="row" fxLayoutAlign="start  start" fxLayoutGap="100px"></div>
        </div>

        <div>
          <span>Website</span>
          <p>{{ companyDetail?.website || '-' }}</p>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="'settings.companies.users' | translate">
      <app-company-members [companyId]="companyId" [companyName]="companyDetail?.name" [userRole]="companyDetail?.roleName"></app-company-members>
    </mat-tab>

    <mat-tab [label]="'settings.companies.events' | translate" *ngIf="companyDetail?.roleName.toUpperCase() === 'ADMIN'">
      <section class="event">
        <app-events-grid [idCompany]="companyDetail.id" class="w100" #eventGrid></app-events-grid>
      </section>
    </mat-tab>
    <mat-tab [label]="'settings.companies.integration' | translate" *ngIf="companyDetail?.roleName.toUpperCase() === 'ADMIN'">
      <div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="25px">
        <div class="col-12">
          <mat-form-field class="input-address" appearance="outline" fxFlex.lt-sm="300" [style.width.%]="100">
            <mat-label>{{ 'settings.companies.tradelens' | translate }}</mat-label>
            <input matInput autocomplete="off" [value]="companyDetail?.tradelensId" disabled />
          </mat-form-field>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <mat-divider></mat-divider>
  <mat-card-actions align="end">
    <button mat-button routerLink="/Settings/Companies">
      {{ ('settings.companies.button-back' | translate).toUpperCase() }}
    </button>
    <button
      mat-button
      *ngIf="companyDetail?.status.toUpperCase() !== 'INACTIVE' && companyDetail?.roleName.toUpperCase() === 'ADMIN'"
      [routerLink]="'/Settings/Companies/Edit/' + companyDetail.id"
    >
      {{ ('settings.companies.button-edit' | translate).toUpperCase() }}
    </button>
  </mat-card-actions>
</mat-card>
