import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CompanyService } from 'src/app/core/services/company.service';
import { RequestAccessErrorDialogComponent } from '../request-access-error-dialog/request-access-error-dialog.component';
import { RequestAccessInviteDialogComponent } from '../request-access-invite-dialog/request-access-invite-dialog.component';

@Component({
  selector: 'app-request-access-company-dialog',
  templateUrl: './request-access-company-dialog.component.html',
  styleUrls: ['./request-access-company-dialog.component.scss']
})
export class RequestAccessCompanyDialogComponent implements OnInit {
  makingLinkExistingCompany: boolean = false;
  emailInvite: string;
  cnpjInvite: string;
  newUser: boolean = false;
  inviteResult: boolean = false;
  @Output() requestInvite = new EventEmitter<boolean>();
  @Output() isAdmin = new EventEmitter<boolean>();
  @Output() finished = new EventEmitter<any>();

  constructor(
    private readonly router: Router,
    private readonly companyService: CompanyService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<RequestAccessCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.newUser = data.newUser ? data.newUser : false;
  }

  ngOnInit(): void {}

  async requestCompany() {
    try {
      this.inviteResult = true;
      const data = {
        email: this.data.email,
        cnpj: this.data.cnpj
      };
      let response = await this.companyService.requestInvite(data);
      if (response) {
        this.inviteResult = false;
        this.inviteRequested();
      } else {
        this.inviteResult = false;
        this.inviteRequestedFail();
      }
    } catch (e) {
      this.inviteResult = false;
      this.makingLinkExistingCompany = false;
      if (e?.error?.errors?.length > 0 && e?.error?.errors[0] == 'ELL-0006') {
        await setTimeout(async () => {
          await this.router.navigateByUrl('/Settings/Companies');
        }, 1000);
      }
    }
  }

  async routerLink() {
    if (!this.data.newUser) {
      await this.router.navigateByUrl('/Settings/Companies');
    }
  }

  async inviteRequested() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(RequestAccessInviteDialogComponent, {
      width: '830px',
      height: '270px',
      data: this.data,
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
        }
      });
  }
  async inviteRequestedFail() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(RequestAccessErrorDialogComponent, {
      width: '830px',
      height: '270px',
      data: this.data,
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
        }
      });
  }
}
