import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ellos-spinner',
  templateUrl: './ellos-spinner.component.html',
  styleUrls: ['./ellos-spinner.component.scss']
})
export class EllosSpinnerComponent implements OnInit {
  constructor() {}

  @Input() text: string;
  ngOnInit(): void {}
}
