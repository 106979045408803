import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CertificatesComponent } from './pages/certificates/certificates.component';
import { OriginCertificateComponent } from './pages/certificates-origin/origin-certificate.component';
import { NewOriginCertificateComponent } from './pages/new-origin-certificate/new-origin-certificate.component';
import { OriginCertificateDetailComponent } from './pages/origin-certificate-detail/origin-certificate-detail.component';

const routes: Routes = [
  {
    path: '',
    component: CertificatesComponent,
    children: [
      {
        path: '',
        redirectTo: 'Certificateorigin',
        pathMatch: 'full'
      },
      {
        path: 'Certificateorigin',
        component: OriginCertificateComponent
      },
      {
        path: 'Certificateorigin/NewCertificateOrigin',
        component: NewOriginCertificateComponent
      },
      {
        path: 'Certificateorigin/EditOriginCertificate/:id',
        component: NewOriginCertificateComponent
      },
      {
        path: 'Certificateorigin/DetailOriginCertificate/:id',
        component: OriginCertificateDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CertificatesRoutingModule {}
