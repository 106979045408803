import { Md5 } from 'ts-md5/dist/md5';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';
// Angular
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { BrowserModule } from '@angular/platform-browser';
import { MatStepperIntl } from '@angular/material/stepper';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Libraries
import { AvatarModule } from 'ngx-avatars';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { AuthModule } from './modules/auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';
import { themeReducer } from './core/reducers/theme.reducer';
import { companyReducer } from './core/reducers/company.reducer';
import { languageReducer } from './core/reducers/language.reducer';
import { reportReducer } from './core/reducers/report.reducer';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { timezonesReducer } from './shared/reducers/timezone.reducer';
import { sessionUserReducer } from './shared/reducers/session-user.reducer';
import { profileImageReducer } from './shared/reducers/profile-image.reducer';
import { meetingReducer } from './modules/matchmaking/reducers/meeting.reducer';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { DevEnvironmentGuard } from './modules/auth/dev-environmet-guard.service';
import { DragAndDropDirective } from './shared/directive/drag-and-drop.directive';
import { sessionCompaniesReducer } from './core/reducers/session-companies.reducer';
import { queryProductReducer } from './modules/marketplace/reducers/product.reducer';
import { queryCategoryReducer } from './modules/marketplace/reducers/category.reducer';
import { EllosMatStepperIntl } from './shared/services/ellos-mat-stepper-intl.service';
import { queryProposalReducer } from './modules/marketplace/reducers/proposal.reducer';
import { unitsMeasureReducer } from './modules/marketplace/reducers/units-measure.reducer';
import { queryCompaniesReducer } from './modules/settings/reducers/query-companies.reducer';
import { matchmakingIdReducer } from './modules/matchmaking/reducers/matchmaking-id.reducer';
import { queryMatchmakingReducer } from './modules/matchmaking/reducers/matchmaking.reducer';
import { categoriesReducer } from './modules/marketplace/reducers/session-categories.reducer';
import { monitoringQueryReducer } from './modules/easy-trade/reducers/monitoring-query.reducer';
import { AnonymousLayoutComponent } from './layout/anonymous-layout/anonymous-layout.component';
import { queryCompanyMembersReducer } from './modules/settings/reducers/query-company-members.reducer';
import { originCertificateQueryReducer } from './modules/easy-trade/reducers/origin-certificate-query.reducer';
// Project
import { SharedModule } from './shared/shared.module';

import { ModalMessageConfirmTemplateComponent } from './modules/easy-trade/modals/modal-message-confirm-template/modal-message-confirm-template.component';
import { S3FilesComponent } from './shared/components/s3-files/s3-files.component';
import { NotAuthLayoutComponent } from './layout/not-auth-layout/not-auth-layout.component';
import { NotAuthLayoutSocialMediaComponent } from './layout/not-auth-layout-social-media/not-auth-layout-social-media.component';

import { EffectsModule } from '@ngrx/effects';
import { errorReducer } from './core/reducers/error.reducer';
import { CompanyEffects } from './core/effects/company.effects';
import { queryListInvolvedCompaniesReducer } from './modules/easy-trade/reducers/query-list-involved-companies.reducer';
import { ModalNotificationTemplateComponent } from './modules/easy-trade/modals/modal-notification-template/modal-notification-template.component';

import { MatPaginatorTranslator } from './modules/paginator/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CertificatesModule } from './modules/certificates/certificates.module';
import { ComponentsLibModule } from '@ccab/components-lib';
import { productToExportationReducer } from './modules/easy-trade/reducers/products-exportation.reducer';
import { companyExportationNotificationReducer } from './core/reducers/notifications.reducer';
import { productToCertificateReducer } from './modules/certificates/reducers/products-certificate.reducer';
import { addressToExportationReducer } from './core/reducers/exportation-delivery-address.reducer';

export function HttpLoaderFactory(httpClient: HttpClient) {
  if (window.location.href.includes('Login')) {
    return new TranslateHttpLoader(httpClient, '../assets/i18n/public/', '.json');
  }
  return new TranslateHttpLoader(httpClient, '../assets/i18n/private/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = 'pt';
        translate.setDefaultLang('pt');
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    AuthLayoutComponent,
    AnonymousLayoutComponent,
    DragAndDropDirective,
    ModalMessageConfirmTemplateComponent,
    ModalNotificationTemplateComponent,
    S3FilesComponent,
    NotAuthLayoutComponent,
    NotAuthLayoutSocialMediaComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AvatarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    ComponentsLibModule,
    CertificatesModule,
    AngularMultiSelectModule,
    AuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      language: languageReducer,
      report: reportReducer,
      company: companyReducer,
      addressToExportation: addressToExportationReducer,
      theme: themeReducer,
      queryProduct: queryProductReducer,
      queryCategory: queryCategoryReducer,
      queryProposal: queryProposalReducer,
      meeting: meetingReducer,
      queryMatchmaking: queryMatchmakingReducer,
      matchmakingId: matchmakingIdReducer,
      profileImage: profileImageReducer,
      sessionUser: sessionUserReducer,
      queryCompanies: queryCompaniesReducer,
      queryCompanyMembers: queryCompanyMembersReducer,
      sessionCompanies: sessionCompaniesReducer,
      timezones: timezonesReducer,
      categories: categoriesReducer,
      unitsMeasure: unitsMeasureReducer,
      monitoringQuery: monitoringQueryReducer,
      queryListInvolvedCompanies: queryListInvolvedCompaniesReducer,
      originCertificateQuery: originCertificateQueryReducer,
      productToExportation: productToExportationReducer,
      productToCertificate: productToCertificateReducer,
      companyExportationNotification: companyExportationNotificationReducer,
      errorState: errorReducer
    }),
    EffectsModule.forRoot([CompanyEffects]),
    TourMatMenuModule.forRoot(),
    FontAwesomeModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production
    }),
    NgxChartsModule
  ],
  exports: [TranslateModule, FontAwesomeModule],
  providers: [
    DatePipe,
    Md5,
    DevEnvironmentGuard,
    { provide: MatStepperIntl, useClass: EllosMatStepperIntl },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorTranslator
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
