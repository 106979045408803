import { Component, Input, OnInit } from '@angular/core';
import { OriginCertificateStatusEnum } from '../../enums/origin.enum';

@Component({
  selector: 'app-origin-certificate-status-label',
  templateUrl: './origin-certificate-status-label.component.html',
  styleUrls: ['./origin-certificate-status-label.component.scss']
})
export class CertificateOriginStatusLabelComponent implements OnInit {
  @Input() status: number;
  ocStatus = OriginCertificateStatusEnum;

  constructor() {}

  ngOnInit(): void {}
}
