import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export class DateValidator {
  static dateVaidator(timeStr: string): ValidatorFn {
    return (AC: AbstractControl) => {
      if (
        AC &&
        AC.value &&
        !moment(AC.value, 'MM/YYYY', true).isValid() &&
        !moment(AC.value, 'M/YYYY', true).isValid() &&
        !moment(AC.value, 'MM/DD/YYYY', true).isValid() &&
        !moment(AC.value, 'M/D/YYYY', true).isValid() &&
        !moment(AC.value, 'MM/D/YYYY', true).isValid() &&
        !moment(AC.value, 'M/DD/YYYY', true).isValid() &&
        timeStr === 'en'
      ) {
        return { dateVaidator: true };
      }
      if (
        AC &&
        AC.value &&
        !moment(AC.value, 'MM/YYYY', true).isValid() &&
        !moment(AC.value, 'M/YYYY', true).isValid() &&
        !moment(AC.value, 'D/M/YYYY', true).isValid() &&
        !moment(AC.value, 'DD/M/YYYY', true).isValid() &&
        !moment(AC.value, 'D/MM/YYYY', true).isValid() &&
        timeStr === 'pt'
      ) {
        return { dateVaidator: true };
      }
      return null;
    };
  }
  static minDate(timeStr: string, lang: string): ValidatorFn {
    return (AC: AbstractControl) => {
      if (AC && AC.value && timeStr) {
        if (moment(AC?.value, lang === 'pt' ? 'DD/MM/YYYY' : 'MM/DD/YYYY').isBefore(timeStr)) {
          return { minDate: true };
        }
      }
      return null;
    };
  }
  static maxDate(timeStr: string, lang: string): ValidatorFn {
    return (AC: AbstractControl) => {
      if (AC && AC.value && timeStr) {
        if (moment(AC?.value, lang === 'pt' ? 'DD/MM/YYYY' : 'MM/DD/YYYY').isAfter(timeStr)) {
          return { maxDate: true };
        }
      }
      return null;
    };
  }
}
