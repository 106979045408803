import EllosAction from '../interfaces/ellosAction.interface';
import Report from '../interfaces/report.interface';
import { ActionTypes } from '../models/actionTypes';

export function reportReducer(state: Report, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.ChangeReport: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
