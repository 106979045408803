<div fxFlex="row wrap" class="content" fxLayoutAlign="start start " fxLayoutGap="20px">
  <mat-form-field class="search-input" color="accent" fxFlex="50" fxFlex.lt-sm="100" fxFlex.lt-md="60" appearance="outline">
    <mat-label>{{ 'settings.companies.search-member-label' | translate }}</mat-label>
    <input matInput name="searchInput" autocomplete="off" #searchInput />
    <button mat-icon-button matSuffix>
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'settings.companies.user-type' | translate }}</mat-label>
    <mat-select [formControl]="userType" (ngModelChange)="sendNewCompanyMembersQuery()">
      <mat-option value="">{{ 'settings.companies.all' | translate }}</mat-option>
      <mat-option value="Admin">{{ 'settings.companies.admin' | translate }}</mat-option>
      <mat-option value="Member">{{ 'settings.companies.member' | translate }}</mat-option>
      <mat-option value="FowardAgent">{{ 'settings.companies.forwarding-agent' | translate }}</mat-option>
      <mat-option value="Certifier">{{ 'settings.companies.certifier' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" (click)="campaignOnePicker.open()">
    <mat-label>{{ 'settings.companies.signup-date' | translate }}</mat-label>
    <mat-date-range-input [formGroup]="rangeDate" [rangePicker]="campaignOnePicker">
      <input matStartDate formControlName="start" placeholder="" />>
      <input matEndDate formControlName="end" placeholder="" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #campaignOnePicker (opened)="resetCalendar()" (closed)="filterDate()"></mat-date-range-picker>
  </mat-form-field>
</div>
