import { Injectable } from '@angular/core';
import printJS, { PrintTypes } from 'print-js';
import { PDFDocument } from 'pdf-lib';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  constructor() {}

  async print(url: any) {
    try {
      if (typeof url === 'string') {
        printJS({
          printable: url,
          type: 'pdf',
          showModal: false
        });
      } else {
        await this.__print4(url);
      }
    } catch (e) {
    } finally {
      return;
    }
  }

  getUrlExtension(url: string): PrintTypes {
    let extension = url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
    return extension == 'pdf' ? 'pdf' : 'image';
  }

  async __print4(urlsToPrint) {
    const mergedPdf = await PDFDocument.create();
    for (const pdfCopyDoc of urlsToPrint) {
      const pdfBytes = await fetch(pdfCopyDoc).then((res) => res.arrayBuffer());
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }
    const mergedPdfFile = await mergedPdf.save();
    this.downloadFile(mergedPdfFile);
  }

  downloadFile(data) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    printJS({
      printable: url,
      type: 'pdf'
    });
  }
}
