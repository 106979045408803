import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { StoreKey } from '../interfaces/storeKey.interface';
import { LanguageResponse } from '../models/language-response.interface';
import { EllosApiService } from '../http/ellosApi.service';
import { EllosApi } from '../interfaces/ellosApi.interface';
import EllosRequest from '../interfaces/ellosRequest.interface';
import { Cacheable } from '../../shared/decorators/cacheable.decorator';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private readonly ellosApi: EllosApiService, private readonly storageService: StorageService) {}

  getCurrentLanguage(): string {
    return this.storageService.get(StoreKey.language).value;
  }

  async getLanguages(): Promise<LanguageResponse[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      body: null,
      resource: `Language`
    };

    const response = await this.ellosApi.get<LanguageResponse[]>(request);
    if (response.success && response.data !== null) {
      return response.data;
    }

    return [];
  }
}
