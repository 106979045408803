import { Injectable } from '@angular/core';
import { EllosApiService } from '../../../core/http/ellosApi.service';
import EllosRequest from '../../../core/interfaces/ellosRequest.interface';
import { EllosApi } from '../../../core/interfaces/ellosApi.interface';
import { OrderBuy } from '../models/order-buy';
import { Order } from '../models/order';
import { BuyOrderPreview } from '../models/buy-order-preview';
import { SellOrderPreview } from '../models/sell-order-preview';
import { NewOrder } from '../models/new-order';
import { QueryUpdateOrder } from '../interfaces/queryUpdateOrder.interface';
import { QueryProposal } from '../interfaces/queryProposal.interface';
import { stringify } from 'query-string';
import { BehaviorSubject } from 'rxjs';
import { OrderDetail } from '../interfaces/order-detail.interface';
@Injectable({
  providedIn: 'root'
})
export class NegotiationService {
  proposals$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>(null);
  constructor(private readonly ellosApi: EllosApiService) {}

  async buyProduct(order: OrderBuy): Promise<any> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: 'Order/buy',
      body: order
    };

    try {
      let response = await this.ellosApi.post<any>(request);
      return response;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async getOrderBuy(queryProposal: QueryProposal): Promise<BuyOrderPreview> {
    let queryString = stringify(queryProposal);
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/buy?${queryString}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<BuyOrderPreview>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async getOrderSell(queryProposal: QueryProposal): Promise<SellOrderPreview> {
    let queryString = stringify(queryProposal);
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/sell?${queryString}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<SellOrderPreview>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
  async approveOrder(queryUpdate: QueryUpdateOrder): Promise<NewOrder> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/approve`,
      body: queryUpdate
    };
    try {
      let response = await this.ellosApi.patch<NewOrder>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
  async rejectOrder(queryUpdate: QueryUpdateOrder): Promise<NewOrder> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/reject`,
      body: queryUpdate
    };
    try {
      let response = await this.ellosApi.patch<NewOrder>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async completeOrder(queryUpdate: QueryUpdateOrder): Promise<NewOrder> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/approve`,
      body: queryUpdate
    };
    try {
      let response = await this.ellosApi.patch<NewOrder>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async latestsOrders(idCompany: number): Promise<number> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/latest/${idCompany}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<number>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async getOrderDetail(detailRequest: { IdCompany: number; IdOrder: number; LanguageCode: string }): Promise<OrderDetail> {
    let query = stringify(detailRequest);
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/details?${query}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<OrderDetail>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
}
