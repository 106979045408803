<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title class="mat-dialog-title">
    <div fxLayout="row" fxLayoutAlign="center">
      <h1 fxFlexAlign="center">
        {{ 'settings.dialog.delete-account' | translate }}
      </h1>
    </div>

    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxFlex="grow" fxLayoutAlign="center center">
    <div class="message">
      <p>{{ 'settings.dialog.erasable-account-message' | translate }}</p>
      <p class="confirm-message">
        {{ 'settings.dialog.sure-delete' | translate }}
      </p>
    </div>

    <div *ngIf="!saving" fxLayout="row" class="button-group" fxLayoutAlign="center end" fxLayoutGap="50px">
      <button mat-stroked-button class="button" (click)="onNoClick()">
        {{ 'settings.dialog.keep-account' | translate }}
      </button>
      <button mat-stroked-button class="button" color="warn" (click)="DeleteCompanyDialog()">
        {{ 'settings.dialog.delete-account-forever' | translate }}
      </button>
    </div>
    <div *ngIf="saving">
      <mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner>
    </div>
  </div>
</div>
