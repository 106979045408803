<mat-dialog-content class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
  <div class="container">
    <div class="flex-container" fxLayout="row" fxLayoutAlign="center center">
      <p>{{ 'registration.existing-company-request-invite' | translate }}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end" fxLayoutAlign="end center">
  <button id="mat-raised-button-accept" mat-button (click)="requestCompany()" [disabled]="inviteResult">
    <div *ngIf="!inviteResult">{{ 'registration.yes' | translate }}</div>
    <mat-spinner *ngIf="inviteResult" diameter="35"></mat-spinner>
  </button>

  <button mat-button [mat-dialog-close] (click)="routerLink()" [disabled]="inviteResult">
    <div *ngIf="!inviteResult">{{ 'registration.no' | translate }}</div>
  </button>
</mat-dialog-actions>
