import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CurrencyPipe' })
export class CurrencyPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'REAL':
        return 'currency.real';
      case 'DOLLAR':
        return 'currency.dollar';
      case 'EURO':
        return 'currency.euro';
      default:
        return '-';
    }
  }
}
