import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCurrencyEnum } from '../../enums/product-currency.enum';
import { CertificateData } from '../../Models/certificate-data.interface';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { OriginCertificateService } from '../../services/origin-certificate.service';
import { CurrencyEnum, IncotermEnum, IncotermNumberEnum } from '../../enums/new-trade.enum';
import { CertificateProductRequest } from '../../Models/Template/CertificateProductRequest.interface';
import { ResponseCertificateOrigem } from 'src/app/modules/easy-trade/pages/new-trade-by-co/new-trade-by-co.component';

@Component({
  selector: 'app-modal-modal-certificate-edit',
  templateUrl: './modal-certificate-edit.component.html',
  styleUrls: ['./modal-certificate-edit.component.scss']
})
export class ModalCertificateEdit implements OnInit {
  currentCompany: CompanyPreview;
  destroy$ = new Subject<any>();
  certificateData!: CertificateData;
  company$: Observable<CompanyPreview>;
  sessionCompany$: Observable<CompanyPreview>;
  awbNumberControl = new FormControl(null, [Validators.required]);
  shippingDateControl = new FormControl(null, [Validators.required]);

  constructor(
    public readonly dialogRef: MatDialogRef<ModalCertificateEdit>,
    @Inject(MAT_DIALOG_DATA)
    public data: ResponseCertificateOrigem,
    private readonly oringinCertificateService: OriginCertificateService,
    private readonly __formBuilder: FormBuilder,
    private readonly companySessionState: Store<{ company: CompanyPreview }>
  ) {
    this.sessionCompany$ = this.companySessionState.pipe(select('company'));
  }

  ngOnInit(): void {
    this.sessionCompany$.subscribe(async (company) => {
      this.currentCompany = company;
      console.log(this.currentCompany);
    });

    if (this.data.shipmentInformation?.blawbNumber) {
      this.awbNumberControl.setValue(this.data.shipmentInformation.blawbNumber);
    }
    if (this.data.shipmentInformation?.shippingDate) {
      this.shippingDateControl.setValue(this.data.shipmentInformation.shippingDate);
    }
  }

  async updateCertificate() {
    try {
      this.loadData();
      let idOriginCertificate = await this.oringinCertificateService.updateCertificate(this.certificateData);
      this.dialogRef.close(true);
    } catch (error) {
      this.dialogRef.close(false);
      console.error(error);
    }
  }

  getIncotermEnum(p) {
    return IncotermNumberEnum[p];
  }

  loadData() {
    const exporter: CompanyPreview = { id: this.data.companiesEnvolved.exporter?.id };
    this.data.companiesEnvolved.exporter = exporter;

    this.certificateData = {
      id: this.data.id,
      incotermType: this.mapIncotermNumberToIncotermEnum(this.data.incotermType),
      currency: this.mapNumberToCurrencyEnum(this.data.currency),
      cost: this.data.cost,
      freightCost: this.data.freightCost,
      insurance: this.data.insurance,
      landingFee: this.data?.landingFee,
      others: this.data?.others,
      invoiceNumber: this.data.invoiceNumber,
      emissionDate: this.data.emissionDate,
      originCountry: this.data.originCountry,
      destinationCountry: this.data.destinationCountry,
      certificateLanguage: this.data.certificateLanguage,
      certificateEmail: this.data.certificateEmail,
      observations: this.data.observations,
      hasCertificateBrazilianOrigin: this.validateCertificateBrazilianOrigin(),
      companiesEnvolved: this.data.companiesEnvolved,
      shipmentInformation: this.getShipmentInformation(),
      products: this.getProducts()
    };
  }

  validateCertificateBrazilianOrigin() {
    return this.currentCompany.cnpj != null && this.currentCompany?.cnpj.trim().length > 1;
  }

  getShipmentInformation() {
    this.data.shipmentInformation.blawbNumber = this.awbNumberControl.value;
    this.data.shipmentInformation.shippingDate = this.shippingDateControl.value;
    return this.data.shipmentInformation;
  }

  getProducts(): CertificateProductRequest[] {
    let products: CertificateProductRequest[] = [];
    for (const product of this.data.products) {
      // Mapeie cada propriedade do 'Product' para um 'CertificateProductRequest'
      const certificateProduct: CertificateProductRequest = {
        id: product.id, // mantenha o mesmo id, ou omita se não necessário
        ncm: product.ncm,
        idUnitMeasure: product.idUnitMeasure,
        idUnitMeasureWeight: product.idUnitMeasureWeight,
        quantity: product.quantity,
        grossWeight: product.grossWeight,
        netWeight: product.netWeight,
        productValue: product.productValue,
        marks: product.marks,
        sif: product.sif ? Number(product.sif) : undefined, // convertendo string para number, se aplicável
        productionDate: product.productionDate ?? '', // tratamento para null usando coalescência nula
        expirationDate: product.expirationDate ?? '', // tratamento para null usando coalescência nula
        description: product.description,
        currency: this.mapNumberToCurrencyProductEnum(product.currency) // usando a função de mapeamento criada
      };
      products.push(certificateProduct);
    }
    return products;
  }

  mapIncotermNumberToIncotermEnum(value: number): IncotermEnum | undefined {
    const mapping: { [key: number]: IncotermEnum } = {
      [IncotermNumberEnum.CIF]: IncotermEnum.CIF,
      [IncotermNumberEnum.CIP]: IncotermEnum.CIP,
      [IncotermNumberEnum.CFR]: IncotermEnum.CFR,
      [IncotermNumberEnum.CPT]: IncotermEnum.CPT,
      [IncotermNumberEnum.DDP]: IncotermEnum.DDP,
      // Uncomment and complete these if needed
      // [IncotermNumberEnum.DDU]: IncotermEnum.DDU,
      // [IncotermNumberEnum.DEQ]: IncotermEnum.DEQ,
      // [IncotermNumberEnum.DES]: IncotermEnum.DES,
      [IncotermNumberEnum.FOB]: IncotermEnum.FOB,
      [IncotermNumberEnum.EXW]: IncotermEnum.EXW,
      [IncotermNumberEnum.FCA]: IncotermEnum.FCA,
      [IncotermNumberEnum.FAS]: IncotermEnum.FAS,
      [IncotermNumberEnum.DAP]: IncotermEnum.DAP,
      [IncotermNumberEnum.DPU]: IncotermEnum.DPU
    };

    return mapping[value];
  }

  mapNumberToCurrencyEnum(value: number): CurrencyEnum | undefined {
    const mapping: { [key: number]: CurrencyEnum } = {
      [CurrencyEnum.REAL]: CurrencyEnum.REAL,
      [CurrencyEnum.DOLLAR]: CurrencyEnum.DOLLAR,
      [CurrencyEnum.EURO]: CurrencyEnum.EURO
    };

    return mapping[value];
  }

  mapNumberToCurrencyProductEnum(value: number): ProductCurrencyEnum | undefined {
    const mapping: { [key: number]: ProductCurrencyEnum } = {
      [ProductCurrencyEnum.REAL]: ProductCurrencyEnum.REAL,
      [ProductCurrencyEnum.DOLLAR]: ProductCurrencyEnum.DOLLAR,
      [ProductCurrencyEnum.EURO]: ProductCurrencyEnum.EURO
    };
    return mapping[value];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
