<div mat-dialog-title align="center">
  <h1>
    {{ (data.edit ? 'settings.companies.event.edit' : 'settings.companies.event.new-event') | translate }}
  </h1>
  <mat-divider class="divider"></mat-divider>
</div>
<mat-dialog-content class="content">
  <app-event-subscribe
    #form
    (validForm)="validForm = $event"
    (newEvent)="newEventclose($event)"
    (newEventEdit)="editEvent($event)"
    [idCompany]="data.idCompany"
    [deafultEvent]="data.event"
    [edit]="data.edit"
  ></app-event-subscribe>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] [disabled]="form.submiting">
    {{ 'settings.companies.button-cancel' | translate }}
  </button>
  <button mat-button (click)="form.onSubmit()" type="submit" [disabled]="!validForm || form.submiting">
    {{ 'settings.companies.button-confirm' | translate }}
  </button>
</mat-dialog-actions>
