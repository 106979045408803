import { Injectable } from '@angular/core';
import EllosRequest from '../../core/interfaces/ellosRequest.interface';
import { EllosApi } from '../../core/interfaces/ellosApi.interface';
import { EllosApiService } from '../../core/http/ellosApi.service';
import { Timezone } from '../models/timezone';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getTimeZone(countryName: string = ''): Promise<Timezone[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Timezone?countryName=${countryName}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<Timezone[]>(request);

      return response.data;
    } catch (e) {
      throw Error;
    }
  }
}
