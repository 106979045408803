import { ActionTypes } from '../../../core/models/actionTypes';
import { QueryCompanyMember } from '../models/query-company-member';
import EllosAction from '../../../core/interfaces/ellosAction.interface';

export function queryCompanyMembersReducer(state: QueryCompanyMember, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.QueryCompanyMembers: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
