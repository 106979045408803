import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorTranslator extends MatPaginatorIntl {
  itemsPerPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;
  firstPageLabel: string;
  lastPageLabel: string;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    this.getTranslations();
    return page * pageSize + 1 + ' - ' + (page * pageSize + pageSize) + ' ' + this.translateService.instant('of') + ' ' + length;
  };

  public getTranslations() {
    this.translateService.onLangChange.subscribe(() => {
      this.itemsPerPageLabel = this.translateService.instant('mat-table.itemsPerPageLabel');
      this.nextPageLabel = this.translateService.instant('mat-table.nextPageLabel');
      this.previousPageLabel = this.translateService.instant('mat-table.previousPageLabel');
      this.firstPageLabel = this.translateService.instant('mat-table.firstPageLabel');
      this.lastPageLabel = this.translateService.instant('mat-table.lastPageLabel');
      this.changes.next();
    });
  }
}
