import { Injectable } from '@angular/core';

import { EllosApiService } from '../../core/http/ellosApi.service';
import EllosRequest from '../../core/interfaces/ellosRequest.interface';
import { EllosApi } from '../../core/interfaces/ellosApi.interface';
import { Preference } from '../models/preference';
import { BehaviorSubject } from 'rxjs';
import { ProfileDetails } from '../../modules/settings/models/profile-details';
import { ProfileEdit } from 'src/app/modules/settings/models/profile-edit';
import { stringify } from 'query-string';
import { UserPreferencesEnum } from 'src/app/modules/settings/enums/user-preferences.enum';
@Injectable({
  providedIn: 'root'
})
export class AssociateService {
  currentImage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(private readonly ellosApi: EllosApiService) {}

  async getPreferences(): Promise<Preference[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Preferences`,
      body: null
    };
    let response;
    response = await this.ellosApi.get<Preference[]>(request);

    return response.data == null ? [] : response.data;
  }
  async savePreferences(preferencesAndInterests: { preferencesCode?: UserPreferencesEnum[]; interests?: string[] }) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Preferences`,
      body: preferencesAndInterests
    };
    let response;
    try {
      response = await this.ellosApi.post<boolean>(request);
      return response.success;
    } catch (e) {
      return false;
    }
  }

  async updateProfileImage(image) {
    let formData: FormData = new FormData();
    formData.append('picture', image);

    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Profile/picture`,
      body: formData
    };
    let response;
    response = await this.ellosApi.put<any>(request);
    return response;
  }

  async getProfileImage(): Promise<string> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Profile/picture`,
      body: null
    };
    let response;
    try {
      response = await this.ellosApi.get<string>(request);
      return response.data == null ? '' : response.data.file;
    } catch (e) {
      return null;
    }
  }
  async getProfileImageById(idAssociate: number): Promise<string> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Profile/picture/${idAssociate}`,
      body: null
    };
    let response;
    try {
      response = await this.ellosApi.get<string>(request);
      return response.data == null ? '' : response.data.file;
    } catch (e) {
      return null;
    }
  }

  async getProfileDetails(): Promise<ProfileDetails> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Profile/details`,
      body: null
    };
    let response;
    try {
      response = await this.ellosApi.get<ProfileDetails>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getAssociateDetails(query: { IdCompany: number; IdAssociate: number }): Promise<ProfileDetails> {
    let queryString = stringify(query);
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Associate/details?${queryString}`,
      body: null
    };
    let response;
    try {
      response = await this.ellosApi.get<ProfileDetails>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getProfilePercent(): Promise<number> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Profile/percent`,
      body: null
    };
    let response;
    try {
      response = await this.ellosApi.get<number>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async updateProfile(profileEdit: ProfileEdit) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Profile`,
      body: profileEdit
    };
    let response;
    response = await this.ellosApi.put<boolean>(request);
    return response.success;
  }

  async existsUser(email: string) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Associate,
      resource: `Associate/exists?email=${email}`,
      body: null
    };
    let response;
    try {
      response = await this.ellosApi.get<boolean>(request);
      return response.data;
    } catch (e) {
      return false;
    }
  }

  async deleteUser(email: string) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Associate/delete?email=${email}`
    };

    let response = await this.ellosApi.delete<any>(request);
    return response;
  }
}
