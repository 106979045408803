<div
  class="content border mat-elevation-z8"
  fxLayout="column"
  fxLayoutAlign="center center"
  (dragover)="onDragOverEvent($event)"
  (dragleave)="isDragOver = false"
  (mouseleave)="isDragOver = false"
>
  <img class="cover-image" [src]="url" />
  <label
    fxLayout="column"
    fxLayoutAlign="center center"
    for="cover"
    (dragover)="onDragOverEvent($event)"
    (dragleave)="isDragOver = false"
    (drop)="onFileDrop($event)"
    (mouseleave)="isDragOver = false"
    *ngIf="edit && !updating"
    [ngClass]="isDragOver && edit ? 'input-cover dropzone' : 'input-cover'"
  >
  </label>
  <label fxLayout="column" fxLayoutAlign="center center" *ngIf="updating" class="updating-cover">
    <mat-spinner diameter="30"> </mat-spinner>
  </label>
  <input (click)="$event.target.value = null" type="file" class="input-file" id="cover" *ngIf="edit" accept="image/*" (change)="imageAdd($event.target.files)" />
</div>
