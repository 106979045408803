<div [ngClass]="{ selected: check.checked }" class="card">
  <label class="card__label label" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="9px">
    <mat-checkbox #check="matCheckbox" [checked]="checked" (change)="emitValue($event.checked)" class="label__checkbox checkbox"></mat-checkbox>
    <mat-icon class="label__icon">{{ option?.matIconCode }}</mat-icon>
    <p
      [ngClass]="{
        'label__description-lower': (option?.description | translate).length > 30
      }"
      class="label__description"
    >
      {{ option?.description | translate | truncate: 45 }}
    </p>
  </label>
</div>
