import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ellos-option-toggle',
  templateUrl: './ellos-option-toggle.component.html',
  styleUrls: ['./ellos-option-toggle.component.scss']
})
export class EllosOptionToggleComponent implements OnInit {
  @Input() display: { name: string; value: any }[] = [];
  @Input() title: string;
  @Input() default: number;
  @Output() value = new EventEmitter();
  selected: string;
  constructor() {}

  ngOnInit(): void {
    this.selected = this.display[this.default]?.value;
    this.changeOption(this.selected);
  }

  changeOption(option: string) {
    this.selected = option;
    this.value.emit(this.selected);
  }
}
