import { Component, Input, OnInit } from '@angular/core';
import { QueryCompanyMembersAction } from '../../actions/query-company-member.action';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { QueryCompanyMember } from '../../models/query-company-member';
import { Observable, Subject } from 'rxjs';
import { Member } from '../../models/member.interface';
import { CompanyService } from '../../../../core/services/company.service';
import { MatDialog } from '@angular/material/dialog';
import { InviteUserDialogComponent } from '../../components/invite-user-dialog/invite-user-dialog.component';
import { SessionUser } from '../../../../shared/models/session-user';
import { EditMemberRoleDialogComponent } from '../edit-member-role-dialog/edit-member-role-dialog.component';
import { DeleteUserDialogComponent } from '../delete-user-dialog/delete-user-dialog.component';
import { LeaveCompanyDialogComponent } from '../leave-company-dialog/leave-company-dialog.component';
import { Router } from '@angular/router';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { SessionCompanies } from 'src/app/core/actions/session-companies.action';
import { faBuilding, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-company-members',
  templateUrl: './company-members.component.html',
  styleUrls: ['./company-members.component.scss']
})
export class CompanyMembersComponent implements OnInit {
  @Input() companyId;
  @Input() userRole: string;
  @Input() companyName: string;
  faBuilding = faBuilding;
  faSignOutAlt = faSignOutAlt;
  destroy$ = new Subject<any>();
  queryCompanyMembers$: Observable<QueryCompanyMember>;
  private sessionCompanies$: Observable<CompanyPreview[]>;

  members: Member[] = [];
  companiesSession: CompanyPreview[] = [];
  displayedColumns: string[] = ['name', 'email', 'signupDate', 'userType', 'status', 'actions'];

  count: number;
  user: SessionUser;
  loading: boolean = false;
  sessionUser$: Observable<SessionUser>;
  queryCompanyMembers: QueryCompanyMember;

  constructor(
    private readonly companyService: CompanyService,
    private readonly dialog: MatDialog,
    private readonly queryCompanyMembersState: Store<QueryCompanyMember>,
    private readonly queryCompanyMembersStore: Store<{
      queryCompanyMembers: QueryCompanyMember;
    }>,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>,

    private readonly router: Router
  ) {
    this.queryCompanyMembers$ = this.queryCompanyMembersStore.pipe(select('queryCompanyMembers'));
    this.sessionUser$ = this.sessionUserStore.pipe(select('sessionUser'));
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
  }

  ngOnInit(): void {
    this.queryCompanyMembers$.pipe(takeUntil(this.destroy$)).subscribe(async (query) => {
      if (query) {
        this.queryCompanyMembers = { ...query };
        await this.getCompanyMembers();
      }
    });
    this.sessionCompanies$.subscribe((companies) => {
      this.companiesSession = companies ? [...companies] : [];
    });

    this.sessionUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }
  pageChange(event) {
    let query: QueryCompanyMember = {
      idCompany: this.companyId,
      role: this.queryCompanyMembers.role,
      nameOrEmail: this.queryCompanyMembers.nameOrEmail,
      pageIndex: event.pageIndex,
      pageSize: this.queryCompanyMembers.pageSize
    };

    this.queryCompanyMembersState.dispatch(QueryCompanyMembersAction(query));
  }

  async getCompanyMembers(): Promise<Member[]> {
    if (this.queryCompanyMembers) {
      this.loading = true;
      try {
        let response = await this.companyService.getCompanyMembers(this.queryCompanyMembers);
        this.count = response.memberCount;
        this.members = response.members;
        this.loading = false;
      } catch (e) {
        this.count = 0;
        this.members = [];
        this.loading = false;
      }
    }

    return;
  }
  inviteUser(): void {
    const dialogRef = this.dialog.open(InviteUserDialogComponent, {
      data: this.companyId,
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  editMemberRoleDialog(member: Member) {
    const dialogRef = this.dialog.open(EditMemberRoleDialogComponent, {
      data: { idCompany: this.companyId, member },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: Member) => {
        if (result) {
          member = { ...result };
          if (member.email === this.user.email) {
            this.userRole = result.roleName;
          }
        }
      });
  }

  async changeMemberStatus(member: Member) {
    if (this.queryCompanyMembers) {
      this.loading = true;
      try {
        let payload = {
          idCompany: this.companyId,
          status: member.status == 1 ? 0 : 1,
          idAssociate: member.idAssociate,
          idForwardingCompany: null
        };
        let response = await this.companyService.changeCompanyMembersStatus(payload);
        if (response) {
          member.status = member.status == 1 ? 0 : 1;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    }

    return;
  }

  deleteCompanyMemberDialog(member: Member) {
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      data: { idCompany: this.companyId, member },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: Member) => {
        if (result) {
          let newMembersList = [...this.members];
          let index = newMembersList.findIndex((m) => m.idAssociate === result.idAssociate);
          if (index >= 0) {
            newMembersList.splice(index, 1);
            this.members = [...newMembersList];
            this.count--;
          }
        }
      });
  }

  leaveCompanyDialog() {
    const dialogRef = this.dialog.open(LeaveCompanyDialogComponent, {
      data: { idCompany: this.companyId, companyName: this.companyName },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: boolean) => {
        if (result) {
          this.removeCompanyFromState();
          this.router.navigateByUrl('/Settings/Companies');
        }
      });
  }

  removeCompanyFromState() {
    let index = this.companiesSession.findIndex((c) => c.id === this.companyId);
    if (index >= 0) {
      this.companiesSession.splice(index, 1);
      this.sessionCompaniesState.dispatch(SessionCompanies(this.companiesSession));
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
