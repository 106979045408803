import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { CompanyService } from 'src/app/core/services/company.service';
import { Invite } from '../../models/invite';
import { InviteService } from '../../services/invite.service';

@Component({
  selector: 'app-invite-action-dialog',
  templateUrl: './invite-action-dialog.component.html',
  styleUrls: ['./invite-action-dialog.component.scss']
})
export class InviteActionDialogComponent implements OnInit {
  sessionCompanies$: Observable<CompanyPreview[]>;
  destroy$ = new Subject<any>();
  companies: CompanyPreview[];

  loading: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<InviteActionDialogComponent>,
    private readonly inviteService: InviteService,
    private readonly companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA)
    public data: { action: 'accept' | 'decline'; invite: Invite },
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>
  ) {
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
  }

  ngOnInit() {
    this.sessionCompanies$.pipe(takeUntil(this.destroy$)).subscribe(async (companies) => {
      this.companies = companies ? [...companies] : undefined;
    });
  }

  async executeAction() {
    try {
      switch (this.data.action) {
        case 'accept':
          try {
            this.loading = true;
            const accepted = await this.acceptInvite(this.data.invite.id);
            if (accepted) {
              await this.companyService.getCompanyPreviewById(this.data.invite?.idCompany);
            }
          } catch (e) {
            console.error(e);
          } finally {
            this.loading = false;
            break;
          }
        case 'decline':
          try {
            this.loading = true;
            await this.declineInvite(this.data.invite.id);
          } catch (e) {
            console.error(e);
          } finally {
            this.loading = false;
            break;
          }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.dialogRef.close(true);
    }
  }

  async declineInvite(idInvite: number) {
    let response = await this.inviteService.declineInvite(idInvite);
    if (response) {
      this.dialogRef.close(true);
    }
  }

  async acceptInvite(idInvite: number): Promise<boolean> {
    try {
      let response = await this.inviteService.acceptInvite(idInvite);
      if (response) {
        if (!this.companies || this.companies.length === 0) {
          await this.companyService.getCompanies({ pageSize: 1, pageIndex: 0 });
        }
      }
      return Promise.resolve(response);
    } catch (error) {
      console.error(error);
      return Promise.reject(false);
    }
  }
}
