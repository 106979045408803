import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import LoginResult from '../models/loginResult';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionUser } from '../../shared/models/session-user';
import { cookieOptions } from 'src/app/shared/utils/cookie-options';
import { SessionUserAction } from '../../shared/actions/session-user-action';

import { EllosApi } from '../interfaces/ellosApi.interface';
import Login, { Refresh } from '../interfaces/login.interface';
import EllosRequest from '../interfaces/ellosRequest.interface';
import NewAssociate from '../interfaces/newAssociate.interface';
import ResetPassword from '../interfaces/resetPassword.interface';
import ConfirmAccount from '../interfaces/confirmAccount.interface';
import ForgotPassword from '../interfaces/forgotPassword.interface';
import { CompanyPreview } from '../interfaces/company-preview.interface';

import { ChangeCompany } from '../actions/company.action';
import { SessionCompanies } from '../actions/session-companies.action';
import { ProfileImageAction } from 'src/app/shared/actions/profile-image.action';

import { CookieService } from 'ngx-cookie-service';
import { CompanyService } from './company.service';
import { EllosApiService } from './../http/ellosApi.service';
import { DataLayerService } from './../../shared/services/data-layer.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private sessionUser$: BehaviorSubject<SessionUser> = new BehaviorSubject<SessionUser>(undefined);
  private loggedOut$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(
    private router: Router,
    private readonly ellosApi: EllosApiService,
    private readonly CompanyApi: CompanyService,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>,
    private readonly profileImageStore: Store<{ profileImage: string }>,
    private readonly sessionCompaniesStore: Store<CompanyPreview[]>,
    private readonly companyStore: Store<CompanyPreview>,
    private dataLayerService: DataLayerService,
    private cookieService: CookieService
  ) {}

  isLoggedIn(): Observable<boolean> {
    this.setSessionUser();
    this.cookieService.get('token') ? this.loggedIn$.next(true) : this.loggedIn$.next(false);
    return this.loggedIn$.asObservable();
  }

  isLoggedOut(): Observable<boolean> {
    this.setSessionUser();
    this.cookieService.get('token') ? this.loggedOut$.next(false) : this.loggedOut$.next(true);
    return this.loggedOut$.asObservable();
  }

  loggedOut(): boolean {
    this.isLoggedOut();
    return this.loggedOut$.value;
  }
  async RefreshToken(token: string) {
    try {
      const Refresh: Refresh = {
        refresh: token
      };
      const request: EllosRequest = {
        ellosApi: EllosApi.Associate,
        body: Refresh,
        resource: 'Associate/refresh'
      };
      const responses = await this.ellosApi.post<LoginResult>(request);
      this.cookieService.set('token', responses.data.token, cookieOptions);
      this.cookieService.set('RefreshToken', responses.data.refreshToken, cookieOptions);
      this.setSessionUser(responses.data);
      this.setProfileImage(responses.data.profilePicture);
    } catch (exception) {
      this.cookieService.delete('token');
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async login(login: Login): Promise<boolean> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Associate,
        body: login,
        resource: 'Associate/login'
      };

      const response = await this.ellosApi.post<LoginResult>(request);
      await this.cleanAllLocalStorage();
      await this.cleanAllCookies();
      this.loggedIn$.next(true);
      this.cookieService.set('token', response.data.token, cookieOptions);
      this.cookieService.set('RefreshToken', response.data.refreshToken, cookieOptions);
      this.setSessionUser(response.data);
      this.setProfileImage(response.data.profilePicture);
      if (response.success) {
        this.dataLayerService.setInfoUser(login);
      }
      return response.success;
    } catch (exception) {
      this.cookieService.delete('token');
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
  setEntity(userLogin?: LoginResult) {}
  setSessionUser(userLogin?: LoginResult) {
    if (userLogin) {
      const userStorage: SessionUser = {
        email: userLogin.email,
        name: userLogin.name
      };

      localStorage.setItem('user', JSON.stringify(userStorage));
      this.sessionUserStore.dispatch(SessionUserAction(userStorage));
      this.sessionUser$.next(userStorage);
    }
    if (!this.sessionUser$.value) {
      const user = userLogin || JSON.parse(localStorage.getItem('user'));

      if (user) {
        try {
          const userSession: SessionUser = {
            name: user.name,
            email: user.email
          };
          this.sessionUser$.next(userSession);

          this.sessionUserStore.dispatch(SessionUserAction(userSession));
        } catch (e) {
          this.logout();
        }
      }
    }
  }

  setProfileImage(url) {
    url === '' || !url || url == null ? (url = '../../../../assets/img/profile-placeholder.png') : (url = 'data:image/png;base64,' + url);

    this.profileImageStore.dispatch(ProfileImageAction(url));
  }
  async verifyCompany(cnpj: string) {
    try {
      const response = await this.CompanyApi.getEllosCompanyByCnpj(cnpj);
      return response;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async register(associate: NewAssociate): Promise<string> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Associate,
        body: associate,
        resource: 'Associate/create'
      };

      const response = await this.ellosApi.post<string>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async confirmAccount(confirmAccount: ConfirmAccount): Promise<boolean> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Associate,
        body: confirmAccount,
        resource: 'Associate/confirm'
      };

      const response = await this.ellosApi.patch<boolean>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
  async forgotPassword(forgotPassword: ForgotPassword): Promise<boolean> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Associate,
        body: forgotPassword,
        resource: 'Associate/forgot-password'
      };

      const response = await this.ellosApi.post<boolean>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async resetPassword(resetPassword: ResetPassword): Promise<boolean> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Associate,
        body: resetPassword,
        resource: 'Associate/confirm-forgot-password'
      };

      const response = await this.ellosApi.patch<boolean>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async logout() {
    await this.cleanAllLocalStorage();
    await this.cleanAllCookies();
    this.sessionUser$.next(undefined);
    this.loggedIn$.next(false);
    this.loggedOut$.next(true);
    this.profileImageStore.dispatch(ProfileImageAction(undefined));
    this.sessionCompaniesStore.dispatch(SessionCompanies(undefined));
    this.companyStore.dispatch(ChangeCompany(undefined));
  }

  async cleanAllLocalStorage() {
    localStorage.removeItem('ProductCompany');
    localStorage.removeItem('haveExportation');
    localStorage.removeItem('EntityCompany');
    localStorage.removeItem('lastAction');
    localStorage.removeItem('language');
    localStorage.removeItem('modules');
    localStorage.removeItem('current-company');
    localStorage.removeItem('user');
    localStorage.removeItem('getPrivateI18n');
    localStorage.removeItem('userEntity');
    localStorage.removeItem('_grecaptcha');
  }

  async cleanAllCookies() {
    if (this.cookieService.check('token')) {
      await this.cookieService.delete('token');
    }
    if (this.cookieService.check('RefreshToken')) {
      await this.cookieService.delete('RefreshToken');
    }
    if (this.cookieService.check('_ga')) {
      await this.cookieService.delete('_ga');
    }
    if (this.cookieService.check('_ga_DSRWMH66VR')) {
      await this.cookieService.delete('_ga_DSRWMH66VR');
    }
  }
}
