<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title class="mat-dialog-title">
    <div fxLayout="row" fxLayoutAlign="space-between center ">
      <p></p>
      <h1 fxFlexAlign="center">
        {{ 'settings.dialog.unable-to-delete' | translate }}
      </h1>
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-divider></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxFlex="grow" fxLayoutAlign="start start ">
    <div class="message">
      <p>{{ 'settings.dialog.unable-to-delete-message' | translate }}</p>
      <div *ngFor="let company of companies">
        {{ company.name }}
      </div>
    </div>

    <div fxLayout="row" class="button-group" fxLayoutAlign="center end" fxLayoutGap="50px">
      <button mat-stroked-button class="button" (click)="onNoClick()">
        {{ 'settings.dialog.ok' | translate }}
      </button>
    </div>
  </div>
</div>
