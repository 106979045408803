import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import Language from 'src/app/core/interfaces/language.interface';
import { LanguageService } from 'src/app/core/services/language.service';

@Pipe({
  name: 'ellosDate',
  pure: false
})
export class EllosDatePipe implements PipeTransform {
  language$: Observable<Language>;
  dateOb: Observable<any> = new Observable();
  constructor(private readonly languageService: LanguageService) {}

  transform(value: Date | string, ...args: unknown[]) {
    if (value !== null) return this.getTime(value);
    return '';
  }
  getTime(time) {
    if (moment(time).isValid()) {
      if (this.languageService.getCurrentLanguage() == 'en') {
        return moment(time).format('MM/DD/YYYY');
      } else {
        return moment(time).format('DD/MM/YYYY');
      }
    } else {
      return time;
    }
  }
}
