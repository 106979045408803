import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { LanguageService } from 'src/app/core/services/language.service';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-white-space.validator';

import { IncotermEnum } from '../../enums/new-trade.enum';
import { InvoiceFormTypeEnum } from 'src/app/core/enums/invoice-form-type.enum';

import { InvoiceForm } from '../../Models/invoice-form.interface';
import { LanguageResponse } from 'src/app/core/models/language-response.interface';
import Language from 'src/app/core/interfaces/language.interface';
import { StoreKey } from 'src/app/core/interfaces/storeKey.interface';
import { allCountries } from 'src/app/core/models/all-countries';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { Country } from 'src/app/shared/models/country';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit {
  @Input() defaultValue: InvoiceForm;
  @Input() isReviewStep: boolean;
  @Input() type!: InvoiceFormTypeEnum;
  @Input() readonlyFields: boolean = false;
  @Input() disabledFieldsByDue: boolean = false;
  Country: CompanyPreview = JSON.parse(localStorage.getItem(StoreKey.company));
  language$: Observable<Language>;
  invoiceFormTypeEnum: typeof InvoiceFormTypeEnum = InvoiceFormTypeEnum;
  public invoiceForm: FormGroup;
  destroy$ = new Subject<any>();
  languages: LanguageResponse[] = [];
  @Output() formValue = new EventEmitter();
  incoterms = Object.keys(IncotermEnum)
    .map((o) => IncotermEnum[o as any])
    .map((o) => o as IncotermEnum);

  predefinedCountries: Country[] = allCountries.filter((country) => country.id !== 107);

  constructor(private readonly fb: FormBuilder, private readonly languageService: LanguageService) {}

  ngOnChanges(e) {
    this.setDefaultValues();
  }

  async ngOnInit() {
    if (!this.readonlyFields) {
      this.languages = await this.languageService.getLanguages();
    }
    this.setDefaultValues();
  }

  setDefaultValues() {
    this.invoiceForm = this.fb.group({
      invoiceNumber: [
        {
          value: this.defaultValue?.invoiceNumber || '',
          disabled: this.readonlyFields
        },
        [Validators.required, noWhitespaceValidator]
      ],
      emissionDate: [
        {
          value: this.defaultValue?.emissionDate || '',
          disabled: this.readonlyFields
        },
        [Validators.required]
      ],
      originCountry: [
        {
          value: this.defaultValue?.originCountry || this.predefinedCountries.find((c) => c.name == this.Country.country),
          disabled: this.readonlyFields
        },
        [Validators.required]
      ],
      destinationCountry: [
        {
          value: this.defaultValue?.destinationCountry || this.predefinedCountries.find((c) => c.name == this.Country.country),
          disabled: this.readonlyFields
        },
        [Validators.required]
      ],
      certificateLanguage: [
        {
          value: this.defaultValue?.certificateLanguage || this.languages?.find((l) => l.name == 'English'),
          disabled: this.readonlyFields
        },
        [Validators.required]
      ],
      certificateEmail: [
        {
          value: this.defaultValue?.certificateEmail || '',
          disabled: this.readonlyFields
        },
        [Validators.required, Validators.email]
      ],
      incoterm: [
        {
          value: this.defaultValue?.incoterm || '',
          disabled: this.readonlyFields
        },
        [Validators.required]
      ],
      cost: [{ value: this.defaultValue?.cost || '', disabled: this.readonlyFields }],
      freight: [
        {
          value: this.defaultValue?.freight || '',
          disabled: this.readonlyFields
        },
        []
      ],
      insurance: [
        {
          value: this.defaultValue?.insurance || '',
          disabled: this.readonlyFields
        },
        []
      ],
      landinf_fee: [
        {
          value: this.defaultValue?.landinf_fee || '',
          disabled: this.readonlyFields
        },
        []
      ],
      others: [
        {
          value: this.defaultValue?.others || '',
          disabled: this.readonlyFields
        },
        []
      ],
      observations: [
        {
          value: this.defaultValue?.observations || '',
          disabled: this.readonlyFields
        }
      ],
      hasCertificateBrazilianOrigin: [
        {
          value: this.defaultValue?.hasCertificateBrazilianOrigin || false,
          disabled: this.readonlyFields
        },
        [Validators.required]
      ]
    });

    this.invoiceForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((a) => {
      a.isValid = this.invoiceForm.valid;
      this.formValue.emit(a);
    });
    this.invoiceForm.enable();
  }

  updateValidators() {
    this.invoiceForm.get('cost').updateValueAndValidity();
    this.invoiceForm.get('freight').updateValueAndValidity();
    this.invoiceForm.get('insurance').updateValueAndValidity();
    this.invoiceForm.get('landinf_fee').updateValueAndValidity();
    this.invoiceForm.get('others').updateValueAndValidity();
  }

  onCountrySelected(evt) {}

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onKeyAction(event) {
    var value = this.invoiceForm.controls.observations.value;
    var length = value.replace('\n', ' ')?.length;
    var foo = value.split('\n');
    var lines = parseInt(foo.length + value.length / 123);

    if ((lines >= 50 || length >= 3000) && event.keyCode != 46 && event.keyCode != 8) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
