import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Login from 'src/app/core/interfaces/login.interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CognitoError } from './../../../../core/interfaces/cognitoError.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  waitingLogin: boolean;
  urlCallback: string;
  capslockOn: boolean;
  extras: any;
  constructor(private fb: FormBuilder, private readonly authService: AuthService, private readonly router: Router, private readonly notifyService: NotificationService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      recaptcha: [null, Validators.required]
    });
    this.extras = this.router.getCurrentNavigation().extras;
  }

  ngOnInit(): void {
    if (this.extras && this.extras.state && (!this.extras.state.url || this.extras.state.url.length > 1)) {
      this.urlCallback = this.extras.state.from;
      this.notifyExpiredSession();
    }
  }
  async onSubmit() {
    this.waitingLogin = true;
    const login: Login = {
      password: this.loginForm.controls['password'].value,
      email: this.loginForm.value.email.toString().toLowerCase()
    };
    try {
      const loginResult = await this.authService.login(login);
      if (loginResult) {
        const currentCompany = JSON.parse(localStorage.getItem('current-company'));
        if (currentCompany?.profiles.includes('SHP')) {
          this.router.navigate(['/EasyTrade/Monitoring/Exportations']);
        } else {
          this.router.navigateByUrl('/Home');
        }
      }
    } catch (exception) {
      try {
        if ((<string>exception).includes(CognitoError.userNotConfirmed)) {
          this.router.navigate(['/Confirm-account', login.email]);
        }
      } catch (e) {
        console.error(e);
      }

      this.notifyError(exception?.trim());
    } finally {
      this.waitingLogin = false;
    }
  }
  ngAfterViewInit() {
    if (this.extras && this.extras.state && (!this.extras.state.url || this.extras.state.url.length > 1)) {
      this.urlCallback = this.extras.state.from;
      this.notifyExpiredSession();
    }
  }
  notifyError(msg) {
    const message = `error.code.${msg}`;

    this.notifyService.errorSnackBar({
      buttonText: 'X',
      duration: 4000,
      text: message,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  notifyExpiredSession() {
    setTimeout(() => {
      this.notifyService.errorSnackBar({
        buttonText: 'X',
        duration: 5000,
        text: 'error.expired-session',
        horizontalPosition: 'end',
        verticalPosition: 'top'
      });
    }, 500);
  }

  capsLock(e) {
    this.capslockOn = e;
  }

  handleValueRecaptcha(value: string) {
    if (value !== null) {
      this.loginForm.controls['recaptcha'].setValue(value);
    }
  }
}
