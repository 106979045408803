<table #table mat-table [dataSource]="datasource" fxFill *ngIf="datasource.data.length">
  <!-- ncm Column -->
  <ng-container matColumnDef="ncm">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.ncm' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.ncm?.ncmCode }}
    </td>
  </ng-container>

  <!-- amount Column -->
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.amount' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.quantity }}
    </td>
  </ng-container>

  <!-- unit Column -->
  <ng-container matColumnDef="unit">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.unit' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.unitMeasure?.code }}
    </td>
  </ng-container>

  <!-- grossWeight Column -->
  <ng-container matColumnDef="grossWeight">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.gross-weight' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element?.grossWeight }} {{ element?.unitMeasureWeight?.code || 'KG' }}</td>
  </ng-container>

  <!-- netWeight Column -->
  <ng-container matColumnDef="netWeight">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.net-weight' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">{{ element?.netWeight }} {{ element?.unitMeasureWeight?.code || 'KG' }}</td>
  </ng-container>

  <!-- marks Column -->
  <ng-container matColumnDef="marks">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.marks-and-numbers' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.marksAndNumbers }}
    </td>
  </ng-container>

  <!-- sif Column -->
  <ng-container matColumnDef="sif">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.sif' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.sif }}
    </td>
  </ng-container>

  <!-- productionDate Column -->
  <ng-container matColumnDef="productionDate">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.production' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.manufactoringDate | ellosDate }}
    </td>
  </ng-container>

  <!-- expirationDate Column -->
  <ng-container matColumnDef="expirationDate">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.shelf-life' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.expirationDate | ellosDate }}
    </td>
  </ng-container>

  <!-- product value Column -->
  <ng-container matColumnDef="productValue" *ngIf="useProductValue">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.value' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.productValue | currency : Currency[element.currency - 1] }}
    </td>
  </ng-container>

  <!-- description Column -->
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.description' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" style="padding: 0.5rem 0; width: 30%">
      {{ element.description | truncate : 50 }}
    </td>
  </ng-container>

  <!-- actions Column -->
  <ng-container matColumnDef="actions" *ngIf="!readonlyGrid">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'easy-trade.new-trade.stepper.product-information.table.action' | translate }}
    </th>
    <td mat-cell *matCellDef="let element; index as i">
      <button mat-icon-button (click)="editProduct(i)" *ngIf="checkEdit()" [disabled]="isEditProduct">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="deleteProduct(i)" *ngIf="!readonlyGrid">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button (click)="upProduct(i)" [disabled]="i == 0">
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <button mat-icon-button (click)="downProduct(i)" [disabled]="productToCertificate.length - 1 == i">
        <mat-icon>arrow_downward</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div class="container-total-grid" fxLayout="row" *ngIf="datasource.data.length">
  <div fxFlex="50">
    <div class="total-weight" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
        <p>
          {{ 'easy-trade.new-trade.stepper.product-information.table.total-gross-weight' | translate }}
        </p>
        <span>{{ totalWeight }} {{ datasource[0]?.unitMeasureWeight?.code || 'KG' }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
        <p>
          {{ 'easy-trade.new-trade.stepper.product-information.table.total-net-weight' | translate }}
        </p>
        <span>
          {{ totalNetWeight }}
          {{ datasource[0]?.unitMeasureWeight?.code || 'KG' }}</span
        >
      </div>
    </div>
  </div>
  <div fxFlex="auto">
    <mat-paginator #paginator [length]="datasource.data.length" [pageSizeOptions]="[10, 20, 50, 100]"> </mat-paginator>
  </div>
</div>
