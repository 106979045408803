<mat-card>
  <mat-card-header class="card-header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="!loading">
    <ngx-avatars class="profile-image" size="120" [src]="companyLogo"></ngx-avatars>
    <p class="title">
      {{ 'settings.companies.company-invitation-message' | translate }}
    </p>

    <div fxLayout="row" class="button-group" fxLayoutAlign="center start" fxLayoutGap="20px">
      <button mat-raised-button color="accent" class="button accept" (click)="openAction('accept')">
        {{ 'settings.companies.button-accept' | translate }}
      </button>
      <button mat-stroked-button color="warn" class="button" (click)="openAction('decline')">
        {{ 'settings.companies.button-decline' | translate }}
      </button>
    </div>
  </mat-card-header>

  <mat-card-content *ngIf="!loading">
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.corporate-name' | translate }}</p>
      <p>{{ inviteDetail?.companyName }}</p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.fantasy-name' | translate }}</p>
      <p>{{ inviteDetail?.fantasyName }}</p>
    </div>

    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.country' | translate }}</p>
      <p>{{ inviteDetail?.countryName }}</p>
    </div>

    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.segment' | translate }}</p>
      <p>
        {{ (inviteDetail?.sectorId ? 'registration.' + inviteDetail?.sectorId : '-') | translate }}
      </p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>CNPJ/{{ 'registration.ministry-of-commerce' | translate }}</p>
      <p>{{ inviteDetail?.cnpj }}</p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.address' | translate }}</p>
      <p>{{ inviteDetail?.address }}</p>
    </div>

    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.city' | translate }}</p>
      <p>{{ inviteDetail?.city }} - {{ inviteDetail?.state }}</p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.phone' | translate }}</p>
      <p>{{ inviteDetail?.phone }}</p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>Site</p>
      <p>
        <a [href]="'https://' + inviteDetail.website" target="_blank">{{ inviteDetail?.website }}</a>
      </p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.social-media' | translate }}</p>
      <p fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center" fxLayoutGap="15px">
        <span *ngFor="let media of socialMedia">
          <fa-icon [icon]="getIcon(media?.socialMedia.toLowerCase())"> </fa-icon>
          <span
            ><a [href]="'https://' + media.mediaUrl" target="_blank"> {{ media.mediaUrl }}</a></span
          >
        </span>
      </p>
    </div>
  </mat-card-content>
  <app-ellos-spinner [text]="'marketplace.details.loading' | translate" *ngIf="loading"></app-ellos-spinner>

  <div fxLayoutAlign="end end">
    <button mat-stroked-button (click)="redirectToInvites()">
      {{ 'settings.companies.button-back' | translate }}
    </button>
  </div>
</mat-card>
