export interface Ncm {
  id: number;
  ncmCode: string;
  description: string;
  validations?: NcmValidation[];
  isDue?: boolean;
  pendingToAdd?: boolean;
  pendingCertificate?: boolean;
}

export interface NcmValidation {
  ncmCode: string;
  mandatoryDate: EnumMandatoryNcm;
  mandatorySif: EnumMandatoryNcm;
}

export enum EnumMandatoryNcm {
  Mandatory = 1,
  Optional = 2
}
