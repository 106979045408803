<h1>CCAB</h1>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" class="content" fxLayoutGap="17px">
  <div fxFlex="65" class="profile-card">
    <app-profile-card [edit]="edit" [saveEvent]="saveEvent" [saving]="saving" (valid)="valid = $event" (newUser)="setUser($event)"></app-profile-card>
  </div>

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="55px">
    <div fxLayout="column" fxLayoutAlign="start start" fxFill fxLayoutGap="25px" *ngIf="!edit">
      <button *ngIf="modules.includes(modulePermission.EditarInformacoes)" mat-stroked-button class="button" (click)="edit = !edit">
        {{ 'settings.profile.edit-information' | translate }}
      </button>
      <button *ngIf="modules.includes(modulePermission.DeletarConta)" mat-stroked-button class="button" color="warn" (click)="deleteAccount()">
        {{ 'settings.profile.delete-account' | translate }}
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" fxFill fxLayoutGap="25px" *ngIf="edit">
      <button mat-stroked-button class="button" [disabled]="!valid" (click)="save()">
        {{ 'settings.profile.button-save' | translate }}
      </button>
      <button mat-stroked-button class="button" color="warn" (click)="cancel()">
        {{ 'settings.profile.button-cancel' | translate }}
      </button>
    </div>
  </div>
</div>
