import { CompanyManagementSettingsComponent } from './components/company-management-settings/company-management-settings.component';
import { SearchCompaniesManagerComponent } from './components/search-companies-manager/search-companies-manager.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SettingsNavbarComponent } from './components/settings-navbar/settings-navbar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ComponentsLibModule } from '@ccab/components-lib';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { CredencialsCardComponent } from './components/credencials-card/credencials-card.component';
import { DeleteAccountDialogComponent } from './components/delete-account-dialog/delete-account-dialog.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { SearchCompaniesComponent } from './components/search-companies/search-companies.component';
import { CompanyDetailComponent } from './pages/company-detail/company-detail.component';
import { RequesterProfileComponent } from './components/requester-profile/requester-profile.component';
import { RequesterDetailComponent } from './pages/requester-detail/requester-detail.component';
import { InviteUserDialogComponent } from './components/invite-user-dialog/invite-user-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyEditComponent } from './pages/company-edit/company-edit.component';
import { RequestCompanyComponent } from './pages/request-company/request-company.component';
import { NewCompanyComponent } from './pages/new-company/new-company.component';
import { CompanyNotFoundDialogComponent } from './components/company-not-found-dialog/company-not-found-dialog.component';
import { RequestAccessDialogComponent } from './components/request-access-dialog/request-access-dialog.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { RequestAccessAdminDialogComponent } from './components/request-access-admin-dialog/request-access-admin-dialog.component';
import { RequestAccessNotFoundDialogComponent } from './components/request-access-not-found-dialog/request-access-not-found-dialog.component';
import { RequestAccessErrorDialogComponent } from './components/request-access-error-dialog/request-access-error-dialog.component';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { SearchCompanyMemberComponent } from './components/search-company-member/search-company-member.component';
import { CompanyMembersComponent } from './components/company-members/company-members.component';
import { EditMemberRoleDialogComponent } from './components/edit-member-role-dialog/edit-member-role-dialog.component';
import { DeleteUserDialogComponent } from './components/delete-user-dialog/delete-user-dialog.component';
import { LeaveCompanyDialogComponent } from './components/leave-company-dialog/leave-company-dialog.component';
import { CompanyInvitationComponent } from './pages/company-invitation/company-invitation.component';
import { CompanyInvitationDetailComponent } from './components/company-invitation-detail/company-invitation-detail.component';
import { InvitationsComponent } from './pages/invitations/invitations.component';
import { RecievedInvitationsComponent } from './components/recieved-invitations/recieved-invitations.component';
import { SentInvitationsComponent } from './components/sent-invitations/sent-invitations.component';
import { CRMDropDownService } from './services/crm-drop-down.service';
import { InviteService } from './services/invite.service';
import { InviteActionDialogComponent } from './components/invite-action-dialog/invite-action-dialog.component';
import { CompanyCoverComponent } from './components/company-cover/company-cover.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { EventSubscribeComponent } from './components/event-subscribe/event-subscribe.component';
import { EventService } from './services/event.service';
import { EventsGridComponent } from './components/events-grid/events-grid.component';
import { DeleteEventDialogComponent } from './components/delete-event-dialog/delete-event-dialog.component';
import { EditEventDialogComponent } from './components/edit-event-dialog/edit-event-dialog.component';
import { ContactAndHelpComponent } from './pages/contact-and-help/contact-and-help.component';
import { FaqComponent } from './components/faq/faq.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { UserPreferencesComponent } from './components/user-preferences/user-preferences.component';
import { UserInterestsComponent } from './components/user-interests/user-interests.component';
import { UserPreferencesFormComponent } from './pages/user-preferences-form/user-preferences-form.component';
import { RequestAccessInviteDialogComponent } from './components/request-access-invite-dialog/request-access-invite-dialog.component';
import { RequestAccessCompanyDialogComponent } from './components/request-access-company-dialog/request-access-company-dialog.component';
import { DeleteCompanyDialogComponent } from './components/delete-company-dialog/delete-company-dialog.component';
import { CompanyListDialogComponent } from './components/company-list-dialog/company-list-dialog.component';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    MyProfileComponent,
    SettingsComponent,
    SettingsNavbarComponent,
    ProfileCardComponent,
    CredencialsCardComponent,
    DeleteAccountDialogComponent,
    CompaniesComponent,
    SearchCompaniesComponent,
    SearchCompaniesManagerComponent,
    CompanyDetailComponent,
    RequesterProfileComponent,
    RequesterDetailComponent,
    InviteUserDialogComponent,
    CompanyEditComponent,
    RequestCompanyComponent,
    NewCompanyComponent,
    CompanyNotFoundDialogComponent,
    RequestAccessDialogComponent,
    TermsAndConditionsComponent,
    RequestAccessAdminDialogComponent,
    RequestAccessCompanyDialogComponent,
    RequestAccessNotFoundDialogComponent,
    RequestAccessInviteDialogComponent,
    RequestAccessErrorDialogComponent,
    CompanyLogoComponent,
    CompanyManagementSettingsComponent,
    SearchCompanyMemberComponent,
    CompanyMembersComponent,
    CompanyListDialogComponent,
    EditMemberRoleDialogComponent,
    DeleteUserDialogComponent,
    LeaveCompanyDialogComponent,
    CompanyInvitationComponent,
    CompanyInvitationDetailComponent,
    InvitationsComponent,
    RecievedInvitationsComponent,
    SentInvitationsComponent,
    InviteActionDialogComponent,
    CompanyCoverComponent,
    EventSubscribeComponent,
    EventsGridComponent,
    DeleteEventDialogComponent,
    EditEventDialogComponent,
    ContactAndHelpComponent,
    FaqComponent,
    ContactCardComponent,
    UserPreferencesComponent,
    UserInterestsComponent,
    UserPreferencesFormComponent,
    DeleteCompanyDialogComponent
  ],
  imports: [CommonModule, SettingsRoutingModule, FormsModule, TourMatMenuModule, MatSortModule, SharedModule, ComponentsLibModule, ReactiveFormsModule],
  exports: [UserPreferencesComponent, UserInterestsComponent, UserPreferencesFormComponent],
  providers: [CRMDropDownService, InviteService, EventService]
})
export class SettingsModule {}
