import { Injectable } from '@angular/core';
import { stringify } from 'query-string';
import EllosResponse from 'src/app/core/interfaces/ellosResponse.interface';
import { EllosApiService } from '../../../core/http/ellosApi.service';
import { EllosApi } from '../../../core/interfaces/ellosApi.interface';
import EllosRequest from '../../../core/interfaces/ellosRequest.interface';
import { EllosEventResponse } from '../models/ellos-event-response.interface';
import { EllosEvent } from '../models/ellos-event.interface';
import { EventType } from '../models/event-type.interface';
import { EditEvent } from '../models/event-edit.interface';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getEventsType(queryEvent: { idCompany: number; languageCode: string }): Promise<EventType[]> {
    const query = stringify(queryEvent);
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Events?${query}`,
      body: null
    };

    let response = await this.ellosApi.get<EventType[]>(request);
    return response.data;
  }

  async getEventsSubscription(queryEvents: { idCompany: number; languageCode: string; pageIndex: number; pageSize: number }): Promise<EllosEventResponse> {
    const query = stringify(queryEvents);
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `EventSubscription?${query}`,
      body: null
    };

    let response = await this.ellosApi.get<EllosEventResponse>(request);
    return response.data;
  }
  async eventSubscription(event: EllosEvent) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `EventSubscription`,
      body: event
    };

    let response = await this.ellosApi.post<boolean>(request);
    return response.success;
  }
  async updateEventSubscription(eventSubmit: EditEvent) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `EventSubscription`,
      body: eventSubmit
    };

    let response = await this.ellosApi.put<EllosResponse<boolean>>(request);
    return response.success;
  }
  async removeSubscription(idSubscription: number) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `EventSubscription/${idSubscription}`,
      body: null
    };

    let response = await this.ellosApi.delete<boolean>(request);
    return response.success;
  }
}
