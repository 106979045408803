import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

import { FormDeactivate } from './interfaces/form-deactivate.interface';

@Injectable({ providedIn: 'root' })
export class FormDeactivateGuard implements CanDeactivate<FormDeactivate> {
  loggedOut: boolean;
  constructor(private readonly authService: AuthService) {
    authService.isLoggedOut().subscribe();
  }
  async canDeactivate(component: FormDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot): Promise<boolean> {
    return this.authService.loggedOut() || component.leaveComponent();
  }
}
