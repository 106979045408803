import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[OnlyNumber]'
})
export class OnlyNumberDirective {
  regexStr = '^[0-9]*$';
  @Input() setControlValue: boolean;
  constructor(private el: ElementRef, private control: NgControl) {}

  @Input() OnlyNumber: boolean;
  @Input() allowedKey: string[] = [];
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (this.OnlyNumber) {
      if (
        [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey) ||
        // Allow: Ctrl+C
        numbers.findIndex((n) => n === parseInt(e.key)) > -1 ||
        // allow numbers
        this.allowedKey.findIndex((key) => key === e.key) > -1 ||
        // allow parameter character
        (e.keyCode == 67 && e.ctrlKey) ||
        // Allow: Ctrl+V
        (e.keyCode == 86 && e.ctrlKey) ||
        // Allow: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      let ch = String.fromCharCode(e.keyCode);
      let regEx = new RegExp(this.regexStr);
      if (regEx.test(ch)) return;
      else e.preventDefault();
    }
  }
  @HostListener('input', ['$event.target.value']) // Fired when manually changing the input value
  onInput(value: string) {
    if (this.OnlyNumber) {
      let reg2 = '[^\\d';
      for (let index = 0; index < this.allowedKey.length; index++) {
        reg2 = reg2 + `\\${this.allowedKey[index]}`;
      }

      reg2 = reg2 + ']';

      this.el.nativeElement.value = value.replace(new RegExp(reg2, 'g'), '');
      if (this.setControlValue) this.control.control.setValue(this.el.nativeElement.value);
    }
  }
}
