import { environment } from 'src/environments/environment';
export const getEnviroment = (): string => {
  switch (environment.develop || environment.homolog) {
    case environment.develop:
      return 'develop';
    case environment.homolog:
      return 'homolog';
    default:
      return 'develop';
  }
};
