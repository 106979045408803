import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociateService } from 'src/app/shared/services/associate.service';
import { ProfileDetails } from '../../models/profile-details';

@Component({
  selector: 'app-requester-profile',
  templateUrl: './requester-profile.component.html',
  styleUrls: ['./requester-profile.component.scss']
})
export class RequesterProfileComponent implements OnInit {
  idCompany: number;
  idAssociate: number;
  associate: ProfileDetails;
  photo: string;
  constructor(private readonly associateService: AssociateService, private readonly route: ActivatedRoute, private readonly router: Router) {
    this.idCompany = +route.snapshot.paramMap.get('idCompany');
    this.idAssociate = +route.snapshot.paramMap.get('idAssociate');

    if (this.queryDataValidation()) {
      this.redirectToCompanies();
    }
  }
  queryDataValidation() {
    return isNaN(this.idCompany) || this.idCompany < 1 || isNaN(this.idAssociate) || this.idAssociate < 1;
  }

  async ngOnInit() {
    try {
      this.associate = await this.associateService.getAssociateDetails({
        IdCompany: this.idCompany,
        IdAssociate: this.idAssociate
      });
    } catch (e) {
      this.redirectToCompanies();
    }
  }

  getPhoto() {
    if (this.associate?.picture === '' || !this.associate?.picture || this.associate?.picture == null) {
      return '../../../../../assets/img/profile-placeholder.png';
    } else {
      return 'data:image/png;base64,' + this.associate?.picture;
    }
  }
  redirectToCompanies() {
    this.router.navigateByUrl('/Settings/Companies');
  }
}
