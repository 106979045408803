import { CompanyManagementSettingsComponent } from './components/company-management-settings/company-management-settings.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecievedInvitationsComponent } from './components/recieved-invitations/recieved-invitations.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { CompanyDetailComponent } from './pages/company-detail/company-detail.component';
import { CompanyEditComponent } from './pages/company-edit/company-edit.component';
import { CompanyInvitationComponent } from './pages/company-invitation/company-invitation.component';
import { ContactAndHelpComponent } from './pages/contact-and-help/contact-and-help.component';
import { InvitationsComponent } from './pages/invitations/invitations.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { NewCompanyComponent } from './pages/new-company/new-company.component';
import { RequestCompanyComponent } from './pages/request-company/request-company.component';
import { RequesterDetailComponent } from './pages/requester-detail/requester-detail.component';
import { SettingsComponent } from './pages/settings/settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,

    children: [
      {
        path: '',
        redirectTo: 'MyProfile',
        pathMatch: 'full'
      },
      {
        path: 'MyProfile',
        data: {
          breadcrumb: 'settings.my-profile'
        },
        component: MyProfileComponent,
        children: []
      },
      {
        path: 'Companies',
        data: {
          breadcrumb: 'settings.companies'
        },
        component: CompaniesComponent,
        children: []
      },
      {
        path: 'CompanyManagement',
        data: {
          breadcrumb: 'settings.company-settings-management'
        },
        component: CompanyManagementSettingsComponent,
        children: []
      },
      {
        path: 'Companies/Detail/:id',
        data: {
          breadcrumb: 'settings.companies'
        },
        component: CompanyDetailComponent,
        children: []
      },
      {
        path: 'Companies/User/:idCompany/:idAssociate/:companyName',
        data: {
          breadcrumb: 'settings.companies'
        },
        component: RequesterDetailComponent,
        children: []
      },

      {
        path: 'Companies/Edit/:id',
        data: {
          breadcrumb: 'settings.companies'
        },
        component: CompanyEditComponent,
        children: []
      },
      {
        path: 'Companies/Request',
        data: {
          breadcrumb: 'settings.companies'
        },
        component: RequestCompanyComponent,
        children: []
      },
      {
        path: 'Companies/NewCompany',
        data: {
          breadcrumb: 'settings.companies'
        },
        component: NewCompanyComponent,
        children: []
      },
      {
        path: 'MyInvitations',
        component: InvitationsComponent,
        data: {
          breadcrumb: 'settings.my-invitations'
        },
        children: [
          {
            path: '',
            redirectTo: 'Recieved',
            pathMatch: 'full'
          },
          {
            path: 'Recieved',
            component: RecievedInvitationsComponent
          }
        ]
      },
      {
        path: 'MyInvitations/Detail/:idInvitation/:companyName/:role',
        data: {
          breadcrumb: 'settings.companies'
        },
        component: CompanyInvitationComponent,
        children: []
      },
      {
        path: 'ContactAndHelp',
        component: ContactAndHelpComponent,
        data: {
          breadcrumb: 'settings.contact-and-help'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}
