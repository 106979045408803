<div class="content" (dragover)="onDragOverEvent($event)" (dragleave)="isDragOver = false" (mouseleave)="isDragOver = false">
  <!-- <ngx-avatars class="profile-image" [size]="size" [src]="url"></ngx-avatars> -->
  <app-avatar-image style="display: flex" [size]="size" [url]="url"></app-avatar-image>
  <label
    fxLayout="column"
    fxLayoutAlign="center center"
    for="image"
    (dragover)="onDragOverEvent($event)"
    (dragleave)="isDragOver = false"
    (drop)="onFileDrop($event)"
    (mouseleave)="isDragOver = false"
    *ngIf="edit && !updating"
    [ngClass]="isDragOver && edit ? 'input-cover dropzone' : 'input-cover'"
  >
  </label>
  <label fxLayout="column" fxLayoutAlign="center center" *ngIf="updating" class="updating-cover">
    <mat-spinner diameter="30"> </mat-spinner>
  </label>
  <input type="file" class="input-file" (click)="$event.target.value = null" id="image" *ngIf="edit" accept="image/*" (change)="imageAdd($event.target.files)" />
</div>
