<div mat-dialog-title align="center">
  <h1>{{ 'settings.companies.edit-user-role-title' | translate }}</h1>
  <mat-divider class="divider"></mat-divider>
</div>
<mat-dialog-content class="content" align="center">
  <p>
    {{ 'settings.companies.choose-role-text' | translate }}<b>{{ data.member.name }}</b>
  </p>
  <mat-form-field class="form-data" appearance="outline">
    <mat-label>{{ 'settings.companies.role' | translate }}</mat-label>
    <mat-select (ngModelChange)="searchForward()" [formControl]="memberEdit" [disabled]="waitingResponse">
      <mat-option value="Admin">
        {{ 'settings.companies.admin' | translate }}
      </mat-option>
      <mat-option value="Member">
        {{ 'settings.companies.member' | translate }}
      </mat-option>
      <mat-option value="ForwardingAgent">
        {{ 'settings.companies.forwarding-agent' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="forwarding-area" *ngIf="memberEdit.value === 'ForwardingAgent'" align="center">
    <mat-spinner diameter="40" color="accent" *ngIf="waitingResponse"></mat-spinner>
    <mat-form-field appearance="outline" class="form-data" *ngIf="memberEdit.value === 'ForwardingAgent' && companies?.length > 0">
      <mat-label>{{ 'settings.companies.forwarding-company' | translate }}</mat-label>
      <mat-select #companyName [formControl]="forwardingCompany" [disabled]="waitingResponse" required>
        <mat-option></mat-option>

        <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <p *ngIf="memberEdit.value === 'ForwardingAgent' && companies?.length === 0" align="center">
      {{ 'settings.companies.not-forwarding-company' | translate }}
    </p>
  </div>
</mat-dialog-content>
<mat-divider class="divider"></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] [disabled]="waitingResponse">
    {{ 'settings.companies.button-cancel' | translate }}
  </button>
  <button
    mat-button
    (click)="updateMemberRole()"
    [disabled]="!forwardingCompany?.valid || !memberEdit?.valid || waitingResponse || (memberEdit.value === 'ForwardingAgent' && companies?.length === 0)"
  >
    {{ 'settings.companies.button-save' | translate }}
  </button>
</mat-dialog-actions>
