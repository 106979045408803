<div mat-dialog-title align="center">
  <h1>{{ 'settings.companies.delete-member' | translate }}</h1>
  <mat-divider class="divider"></mat-divider>
</div>
<mat-dialog-content class="content" align="center" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="45px">
  <p>{{ 'settings.companies.sure-delete-user' | translate }}?</p>
  <div fxLayout="column" fxLayoutAlign="center start">
    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
      <b>{{ 'settings.companies.name' | translate }}: </b>
      <span>{{ data.member.name }}</span>
    </p>
    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
      <b>Email: </b> <span>{{ data.member.email }}</span>
    </p>
    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
      <b>{{ 'settings.companies.role' | translate }}: </b>
      <span>{{ 'settings.companies.' + data.member.roleName.toLowerCase() | translate }}</span>
    </p>
  </div>
</mat-dialog-content>
<mat-divider class="divider"></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] [disabled]="waitingResponse">
    {{ 'settings.companies.button-cancel' | translate }}
  </button>
  <button mat-button (click)="deleteUser()" [disabled]="waitingResponse">
    {{ 'settings.companies.button-confirm' | translate }}
  </button>
</mat-dialog-actions>
