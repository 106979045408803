import { QueryListInvolvedCompanies } from './../Models/query-list-involved-companies';
import * as QueryListInvolvedCompaniesAction from '../actions/query-list-involved-companies.action';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: QueryListInvolvedCompanies = {
  PageIndex: 0,
  PageSize: 10,
  profileCode: undefined
};

const queryListInvolvedCompanies = createReducer(
  initialState,

  on(QueryListInvolvedCompaniesAction.pageIndex, (state, action) => {
    return {
      ...state,
      PageIndex: action.PageIndex
    };
  }),
  on(QueryListInvolvedCompaniesAction.pageConfig, (state, action) => {
    return { ...state, PageIndex: action.PageIndex, PageSize: action.PageSize };
  })
);

export function queryListInvolvedCompaniesReducer(state: QueryListInvolvedCompanies | undefined, action: Action) {
  return queryListInvolvedCompanies(state, action);
}
