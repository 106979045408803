import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-user-interests',
  templateUrl: './user-interests.component.html',
  styleUrls: ['./user-interests.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserInterestsComponent),
      multi: true
    }
  ]
})
export class UserInterestsComponent implements OnInit, ControlValueAccessor {
  separatorKeysCodes: number[] = [ENTER, COMMA];

  private _values: string[] = [];

  public get values(): string[] {
    return this._values;
  }
  public set values(v: string[]) {
    this.propagateChange(this._values);
  }
  propagateChange = (_) => {};

  constructor() {}

  ngOnInit(): void {}
  removeInterest(interest) {
    let index = this.values.indexOf(interest);
    if (index >= 0) {
      this.values.splice(index, 1);
    }
  }
  addInterest(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this._values.push(value.trim());
      this.values = this._values;
    }

    if (input) {
      input.value = '';
    }
  }
  writeValue(values: string[]): void {
    if (!values || values == null) {
      this.values = [];
    } else this.values = values;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    // not implemented but required by interface
  }
  setDisabledState?(isDisabled: boolean): void {
    // not implemented but required by interface
  }
}
