import { Injectable } from '@angular/core';
import { stringify } from 'query-string';
import { EllosApiService } from '../../../core/http/ellosApi.service';
import { EllosApi } from '../../../core/interfaces/ellosApi.interface';
import EllosRequest from '../../../core/interfaces/ellosRequest.interface';
import { QueryMatchmaking } from '../interfaces/queryMatchmaking.interface';
import { Meet } from '../models/meet';
import { MeetingDetails } from '../models/meeting-details';
import { SearchHolidays } from '../models/search-holidays';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async scheduleMeeting(newMeeting: { idCompany: number; idOrder: number; meetingDate: string }): Promise<any> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: 'OrderMeeting',
      body: newMeeting
    };

    try {
      const response = await this.ellosApi.post<any>(request);
      return response;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async meetingDetails(idMeeting: number): Promise<MeetingDetails> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Meeting/detail/${idMeeting}`,
      body: null
    };

    const response = await this.ellosApi.get<MeetingDetails>(request);
    return response.data;
  }

  async attendMeeting(attend: { idCompany: number; idMeeting: number; willAttend: boolean }): Promise<MeetingDetails> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Meeting/attendance`,
      body: attend
    };
    try {
      const response = await this.ellosApi.patch<MeetingDetails>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async cancelSchedule(idMeeting): Promise<MeetingDetails> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Meeting/${idMeeting}`,
      body: null
    };
    try {
      const response = await this.ellosApi.delete<MeetingDetails>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async editMeeting(newMeeting: { idMeeting: number; meetingDate: string }): Promise<any> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Meeting`,
      body: newMeeting
    };
    try {
      const response = await this.ellosApi.put<any>(request);
      return response;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async getMeetings(queryMatchmaking: QueryMatchmaking): Promise<Meet[]> {
    const queryString = stringify(queryMatchmaking);

    const request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `OrderMeeting?${queryString}`,
      body: null
    };

    try {
      const response = await this.ellosApi.get<Meet[]>(request);
      return response.success ? response.data : [];
    } catch (exception) {
      return [];
    }
  }

  async getCompanyHolidays(idCompany: any): Promise<Date[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Holiday/Company/${idCompany}`,
      body: null
    };

    try {
      const response = await this.ellosApi.get<SearchHolidays>(request);
      return response.success ? response.data?.holidayDates : [];
    } catch (exception) {
      return [];
    }
  }
}
