<mat-form-field appearance="outline" class="fx-fill" color="accent">
  <mat-label>{{ 'shared.ncm-autocomplete.label' | translate }}</mat-label>
  <input
    type="text"
    [placeholder]="'shared.ncm-autocomplete.placeholder' | translate"
    matInput
    [readonly]="disabled"
    [formControl]="inputSearch"
    [matAutocomplete]="auto"
    autocomplete="off"
    #searchInput
  />

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (opened)="checkNcms()" (optionSelected)="setNcm($event.option.value)">
    <mat-option *ngFor="let ncm of ncms" [value]="ncm"> {{ ncm.ncmCode }} - {{ ncm.description }} </mat-option>
  </mat-autocomplete>
</mat-form-field>
