import { QueryListInvolvedCompanies } from './../../modules/easy-trade/Models/query-list-involved-companies';
import { MatPaginator } from '@angular/material/paginator';
import { ListInvolvedCompaniesPreview } from './../models/list-involved-companies-preview';

import { EllosApiService } from './../http/ellosApi.service';
import { Injectable, isDevMode } from '@angular/core';
import { Company } from '../interfaces/company.interface';
import { AssociateCompanyPreview } from '../models/associate-company-previews';
import EllosRequest from '../interfaces/ellosRequest.interface';
import { EllosApi } from '../interfaces/ellosApi.interface';
import { StorageService } from './storage.service';
import { StoreKey } from '../interfaces/storeKey.interface';
import StoreItem from '../interfaces/storeItem.interface';
import { CompanyJoin } from 'src/app/modules/auth/models/company-join';
import { RegisterCompany, RequestCompanyInvite } from '../../shared/models/register-company';
import { QueryCompanies } from 'src/app/modules/settings/models/query-companies';
import { stringify } from 'query-string';
import { CompanyPreview } from '../interfaces/company-preview.interface';
import { CompanyDetail } from '../../modules/settings/models/company-detail';
import { select, Store } from '@ngrx/store';
import { SessionCompanies } from '../actions/session-companies.action';
import { ChangeCompany } from '../actions/company.action';
import { SocialMedia } from 'src/app/modules/settings/models/social-media';
import { HttpParams } from '@angular/common/http';
import { Contact } from 'src/app/modules/settings/models/contact';
import { QueryCompanyMember } from 'src/app/modules/settings/models/query-company-member';
import { MemberResponse } from 'src/app/modules/settings/models/member-response.interface';
import { from, Observable, of } from 'rxjs';
import { CompanyInvolved } from 'src/app/modules/easy-trade/Models/company-involved.interface';
import { CompanyImporter } from '../interfaces/company-importer.interface';
import { CompanyImporterEnum } from '../enums/company-importer.enum';
import { PromiseType } from 'protractor/built/plugins';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private sessionCompanies$: Observable<CompanyPreview[]>;
  private sessionCompany$: Observable<Company>;
  private companiesSession: CompanyPreview[] = [];
  private companySession: Company;
  constructor(
    private readonly ellosApi: EllosApiService,
    private readonly storageService: StorageService,
    private readonly companyStore: Store<CompanyPreview>,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>,
    private readonly companySessionState: Store<{ company: Company }>,
    private readonly sessionCompaniesStore: Store<CompanyPreview[]>
  ) {
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
    this.sessionCompany$ = this.companySessionState.pipe(select('company'));
    this.sessionCompany$.subscribe((company) => {
      this.companySession = company ? { ...company } : undefined;
    });
    this.sessionCompanies$.subscribe((companies) => {
      this.companiesSession = companies ? [...companies] : [];
    });
  }

  setCurrentCompany(company: Company): void {
    this.storageService.set({
      key: StoreKey.company,
      value: company.id.toString()
    });
  }

  getCurrentCompany(): Company {
    const store: StoreItem = this.storageService.get(StoreKey.company);
    if (store.value !== null) {
      const company: Company = JSON.parse(store.value);
      company.associationDate = new Date(company?.associationDate);
      return company;
    }
    return undefined;
  }

  get sessionCompany(): CompanyPreview {
    const store: StoreItem = this.storageService.get(StoreKey.company);
    if (store.value !== null) {
      const company: CompanyPreview = JSON.parse(store.value);
      return company;
    }
    return undefined;
  }
  async getCompanies(query: { pageIndex: number; pageSize: number }): Promise<CompanyPreview[]> {
    const queryString = stringify(query);
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/preview?${queryString}`
    };

    try {
      const response = await this.ellosApi.get<CompanyPreview[]>(request);
      this.dispatchCompanies(response.data);
      return response.data;
    } catch (e) {
      this.dispatchCompanies([]);
      return [];
    }
  }

  async getCompanyPreviewById(idCompany: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/previewById/${idCompany}`
    };

    try {
      const response = await this.ellosApi.get<CompanyPreview>(request);
      if (response != null) {
        this.companiesSession.push(response.data);
        this.sessionCompaniesStore.dispatch(SessionCompanies(this.companiesSession));
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getCrmCompaniesByToken(): Promise<CompanyJoin[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Crm/companies`
    };

    const response = await this.ellosApi.get<CompanyJoin[]>(request);
    return response.success && response.data !== null ? response.data : [];
  }

  async getExportationCompanyDetail(id: number, importerType: CompanyImporterEnum): Promise<CompanyInvolved> {
    const query = stringify({ id: id, companyType: importerType });
    const request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      body: null,
      resource: `Company/exportationData?${query}`
    };

    const response = await this.ellosApi.get<CompanyInvolved>(request);
    return response.data;
  }
  async getAssociateCompanies(query: QueryCompanies): Promise<AssociateCompanyPreview> {
    const queryString = stringify(query);
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company?${queryString}`
    };

    const response = await this.ellosApi.get<AssociateCompanyPreview>(request);
    return response.data;
  }

  async getListInvolvedCompanies(query: QueryListInvolvedCompanies): Promise<ListInvolvedCompaniesPreview> {
    const currentCompany = JSON.parse(localStorage.getItem('current-company'));
    query = {
      ...query,
      ProfileCode: 'Importer',
      exporterId: currentCompany?.id
    };
    const queryString = stringify(query);
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/ListInvolvedCompanies?${queryString}`
    };

    const response = await this.ellosApi.get<ListInvolvedCompaniesPreview>(request);
    return response.data;
  }

  async getListInvolvedCompaniesByName(query: QueryListInvolvedCompanies, nameCompany: string): Promise<ListInvolvedCompaniesPreview> {
    const currentCompany = JSON.parse(localStorage.getItem('current-company'));
    query = {
      ...query,
      ProfileCode: 'Importer',
      exporterId: currentCompany?.id
    };

    const queryString = stringify(query);
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/ListInvolvedCompanies?${queryString}&CompanyName=${nameCompany}`
    };

    const response = await this.ellosApi.get<ListInvolvedCompaniesPreview>(request);
    return response.data;
  }

  async updateInvolvedCompanie(companyUpdate: any) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Company/importer`,
      body: companyUpdate
    };
    const response = await this.ellosApi.put<any>(request);
    return response;
  }

  async favoriteCompanie(companyUpdate: any, favorite: boolean) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Company/favorite?CompanyId=${companyUpdate}&Favorite=${favorite}`
    };
    const response = await this.ellosApi.put<any>(request);
    return response;
  }

  async getListAll(query: QueryListInvolvedCompanies): Promise<ListInvolvedCompaniesPreview> {
    query = {
      ...query
    };
    const queryString = stringify(query);
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/list-all?${queryString}&Profile=Importer`
    };

    const response = await this.ellosApi.get<ListInvolvedCompaniesPreview>(request);
    return response.data;
  }
  async getCompany(idCompany: number): Promise<Company> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/store/${idCompany}`
    };

    const response = await this.ellosApi.get<Company>(request);

    return response.data;
  }

  async getEllosCompanyByCnpj(cnpj: string): Promise<Company> {
    let cnpjValid = cnpj.replace(/[./-]/g, '');
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/${cnpjValid}`
    };

    const response = await this.ellosApi.get<Company>(request);
    return response.data;
  }

  async getCompanyByCnpjSearch(cnpj: string): Promise<Company> {
    let cnpjValid = cnpj.replace(/[./-]/g, '');
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/search/${cnpjValid}`
    };
    const response = await this.ellosApi.get<Company>(request);
    return response.data;
  }
  async updateCompany(companyUpdate: CompanyDetail) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Company`,
      body: companyUpdate
    };
    const response = await this.ellosApi.put<any>(request);
    return response;
  }

  async getProductsTag(idCompany: number): Promise<string[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `CompanyTags/${idCompany}`
    };

    const response = await this.ellosApi.get<string[]>(request);
    return response.data;
  }
  async updateProductsTag(idCompany: number, Tags: string[]) {
    let params = new HttpParams();
    Tags.forEach((t) => {
      params = params.append('Tags', t);
    });
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `CompanyTags?IdCompany=${idCompany}&${params}`
    };

    const response = await this.ellosApi.post<boolean>(request);
    return response.success;
  }
  async companyDetail(idCompany: number): Promise<CompanyDetail> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/detail?idCompany=${idCompany}`
    };

    const response = await this.ellosApi.get<CompanyDetail>(request);

    return response.success ? response.data : undefined;
  }
  async EntityCompany(idCompany: number): Promise<CompanyDetail> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Company/detail?idCompany=${idCompany}`
    };

    let response = await this.ellosApi.get<CompanyDetail>(request);
    await this.Getproduct(response.data.id);
    localStorage.setItem('EntityCompany', JSON.stringify(response.data));
    return response.success ? response.data : undefined;
  }
  async Getproduct(idCompany: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `CompanyTags/${idCompany}`
    };

    const response = await this.ellosApi.get<string[]>(request);
    localStorage.setItem('ProductCompany', JSON.stringify(response.data));
  }
  async companyLogo(idCompany: number): Promise<string> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Logo?idCompany=${idCompany}`
    };
    try {
      const response = await this.ellosApi.get<string>(request);
      return response.data;
    } catch (e) {
      return undefined;
    }
  }
  async companyCover(idCompany: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Cover/${idCompany}`
    };
    try {
      const response = await this.ellosApi.get<string>(request);
      return response.data;
    } catch (e) {
      return undefined;
    }
  }

  async getSocialMedia(idCompany: number): Promise<SocialMedia[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `SocialMedia/${idCompany}`
    };

    const response = await this.ellosApi.get<SocialMedia[]>(request);
    return response.data == null || !response.data ? [] : response.data;
  }
  async removeSocialMedia(mediaId: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `SocialMedia/${mediaId}`
    };

    const response = await this.ellosApi.delete<boolean>(request);
    return response.success;
  }
  async addSocialMedia(socialMedia: { socialMedia: string; mediaUrl: string; idCompany: number }): Promise<number> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: socialMedia,
      resource: `SocialMedia`
    };

    const response = await this.ellosApi.post<number>(request);
    return response.data;
  }
  async linkUserToCompany(membership: { idCompanyCrm: string; isForwardingAgent: boolean }): Promise<boolean> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: membership,
      resource: `Membership`
    };

    const response = await this.ellosApi.post<boolean>(request);
    return response.success;
  }

  async linkUserToCompanyCnpj(membership: { idCompanyCrm: string; isForwardingAgent: boolean; cnpj: string }): Promise<boolean> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: membership,
      resource: `Membership/cnpj`
    };

    const response = await this.ellosApi.post<boolean>(request);
    return response.success;
  }

  async registerCompany(company: RegisterCompany): Promise<number> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: company,
      resource: `Company`
    };

    const response = await this.ellosApi.post<number>(request);
    return response.data;
  }

  async requestInvite(data: RequestCompanyInvite): Promise<number> {
    if (isDevMode()) {
      data.inviteLink = 'https://dev.ellos.org.br/Settings/Companies/Detail/';
    } else {
      data.inviteLink = 'https://ellos.org.br/Settings/Companies/Detail/';
    }

    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: data,
      resource: `Company/requestInvite`
    };
    const response = await this.ellosApi.post<number>(request);
    return response.data;
  }

  async searchForwarding(email: string): Promise<CompanyPreview[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Associate/forwarder/${email}`
    };

    const response = await this.ellosApi.get<CompanyPreview[]>(request);
    return response.data == null || !response.data ? [] : response.data;
  }
  async searchForCompanyByCNPJCrm(cnpj: string): Promise<CompanyJoin[]> {
    const cnpjFormat = cnpj.replace('/', '*');
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Crm/company/cnpj/${cnpjFormat}`
    };

    const newReturn = await this.ellosApi.get<any>(request);
    if (newReturn.data.length > 0) {
      const response: CompanyJoin = {
        id: newReturn.data[0].accountid,
        companyName: newReturn.data[0].name,
        countryName: newReturn.data[0].new_pais,
        cnpj: newReturn.data[0].trs_cnpj
      };
      return [response];
    } else {
      return [];
    }
  }
  async updateCompanyLogo(image, idCompany: number) {
    const formData: FormData = new FormData();
    formData.append('logo', image);

    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Logo?idCompany=${idCompany}`,
      body: formData
    };
    const response = await this.ellosApi.put<any>(request);
    return response;
  }

  async updateCompanyCover(image, idCompany: number) {
    const formData: FormData = new FormData();
    formData.append('cover', image);

    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Cover?idCompany=${idCompany}`,
      body: formData
    };
    const response = await this.ellosApi.put<any>(request);
    return response;
  }

  async updateCompanyFlagCrm(email: string) {
    const data = { email: email };
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Crm/companyFlag/${email}`,
      body: data
    };
    const response = this.ellosApi.put<any>(request);
    return response;
  }
  async createExternalCompany(company: {
    name: string;
    city?: string;
    state?: string;
    street?: string;
    complement?: string;
    poBox?: string;
    district?: string;
    countryAlpha3: string;
  }) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: company,
      resource: `ExternalCompany`
    };

    const response = await this.ellosApi.post<any>(request);
    return response.success;
  }

  async getCompanyContacts(companyId: number): Promise<Contact[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Contact/${companyId}`
    };

    const response = await this.ellosApi.get<Contact[]>(request);
    return response.data == null || !response.data ? [] : response.data;
  }
  async addCompanyContact(contact: { email: string; phone: string; idCompany: number }): Promise<number> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: contact,
      resource: `Contact`
    };

    const response = await this.ellosApi.post<number>(request);
    return response.data;
  }
  async getCompanyMembers(queryCompanyMembers: QueryCompanyMember): Promise<MemberResponse> {
    const queryString = stringify(queryCompanyMembers);
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Membership?${queryString}`
    };
    const response = await this.ellosApi.get<MemberResponse>(request);
    return response.data;
  }
  async updateMemberRole(member: { idCompany: number; newRole: string; idAssociate: number; idForwardingCompany: number }) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Membership`,
      body: member
    };
    const response = await this.ellosApi.put<any>(request);
    return response;
  }
  async changeCompanyMembersStatus(member: { idCompany: number; status: number; idAssociate: number; idForwardingCompany: number }) {
    const queryString = stringify(member);
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Membership/changeMemberStatus?${queryString}`
    };
    const response = await this.ellosApi.post<boolean>(request);
    return response;
  }
  async deleteCompanyMember(member: { idAssociate: number; idCompany: number }) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: member,
      resource: `Membership/remove`
    };

    const response = await this.ellosApi.delete<boolean>(request);
    return response.success;
  }

  async leaveCompany(idCompany: number) {
    const company = {
      idCompany
    };
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: company,
      resource: `Membership/exit`
    };

    const response = await this.ellosApi.delete<boolean>(request);
    return response.success;
  }
  async DeleteCompany(idCompany: number, role: string) {
    const deleteCompany = {
      role,
      idCompany
    };
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: deleteCompany,
      resource: `Company/remove`
    };

    const response = await this.ellosApi.delete<boolean>(request);
    return response.success;
  }

  async deleteCompanyImpoter(idCompany: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: { idCompany: idCompany },
      resource: `Company/importer`
    };

    const response = await this.ellosApi.delete<boolean>(request);
    return response;
  }

  async removeContact(contactId: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `Contact/${contactId}`
    };

    const response = await this.ellosApi.delete<boolean>(request);
    return response.success;
  }

  async editContact(contact: any) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: contact,
      resource: `Contact`
    };
    let response = await this.ellosApi.put<boolean>(request);
    return response.success;
  }

  async deleteCompanyProfile(profile: { idCompany: number; profileCode: string }) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: profile,
      resource: `CompanyProfile`
    };

    const response = await this.ellosApi.delete<boolean>(request);
    return response.success;
  }
  async setCompanyProfile(profile: { idCompany: number; profileCode: string }) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: profile,
      resource: `CompanyProfile`
    };

    const response = await this.ellosApi.post<boolean>(request);
    return response.success;
  }

  public validateCompany(id: number) {
    if (id) {
      const request: EllosRequest = {
        ellosApi: EllosApi.Company,
        body: null,
        resource: `company/validate/${id}`
      };

      return from(this.ellosApi.get<string>(request));
    }
    return of(null);
  }

  async validateCompanyAsync(id: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      body: null,
      resource: `company/validate/${id}`
    };

    try {
      const response = await this.ellosApi.get<string>(request);
      return response.data;
    } catch (e) {
      return undefined;
    }
  }

  async getAllImporters(queryImporters: { PageIndex: number; PageSize: number; CompanyName?: string }): Promise<CompanyImporter[]> {
    const query = stringify({ ...queryImporters, profile: 'Importer' });
    const request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Company/list-all?${query}`,
      body: null
    };
    const response = await this.ellosApi.get<CompanyImporter[]>(request);

    return response.data;
  }
  private dispatchCompanies(companies: CompanyPreview[]) {
    this.companiesSession = this.companiesSession.concat([...companies]);
    this.sessionCompaniesStore.dispatch(SessionCompanies(this.companiesSession));
    this.verifyStorageCompany(this.companiesSession);
  }
  private verifyStorageCompany(companies: CompanyPreview[]) {
    const storage = this.storageService.get(StoreKey.company);
    if (storage.value !== 'undefined' && storage.value != null) {
      const storageCompany = storage.value ? JSON.parse(storage.value) : undefined;

      if (storageCompany && !this.companySession) {
        const index = companies.findIndex((c) => c.id === storageCompany.id);

        if (index >= 0) {
          this.changeCompany(storageCompany);
        } else {
          this.changeCompany(companies[0]);
        }
      }
    } else {
      this.changeCompany(companies[0]);
    }
  }
  private changeCompany(company: CompanyPreview): void {
    if (company) {
      const comp = { ...company };
      comp.logo = undefined;
      this.companyStore.dispatch(ChangeCompany(comp));
    }
  }
}
