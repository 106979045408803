import store from 'store';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

const MINUTES_UNITL_AUTO_LOGOUT = 30;
const CHECK_INTERVALL = 1000;
const STORE_KEY = 'lastAction';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  constructor(private auth: AuthService, private readonly dialog: MatDialog, private router: Router, private ngZone: NgZone, private readonly authService: AuthService) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  get lastAction() {
    return parseInt(store.get(STORE_KEY), 10);
  }
  set lastAction(value) {
    store.set(STORE_KEY, value);
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mousemove', () => this.reset());
      document.body.addEventListener('input', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    });
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    this.ngZone.run(() => {
      if (isTimeout && this.auth.isLoggedIn) {
        console.log(`Sie wurden automatisch nach ${MINUTES_UNITL_AUTO_LOGOUT} Minuten Inaktivität ausgeloggt.`);
        this.forceExpiration();
      }
    });
  }
  async forceExpiration() {
    await this.closeSessionAndRedirectToLogin();
  }

  async closeSessionAndRedirectToLogin() {
    this.dialog.closeAll();
    this.authService.logout();
    this.router.navigateByUrl('/Login', { state: { from: this.router.url } });
  }
}
