import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import Language from 'src/app/core/interfaces/language.interface';
import { EllosEventSubmit } from '../../models/ellos-event-submit.interface';
import { EllosEvent } from '../../models/ellos-event.interface';
import { EventType } from '../../models/event-type.interface';
import { SubscriptionResponse } from '../../models/subscription-response.interface';

@Component({
  selector: 'app-edit-event-dialog',
  templateUrl: './edit-event-dialog.component.html',
  styleUrls: ['./edit-event-dialog.component.scss']
})
export class EditEventDialogComponent implements OnInit {
  language: string;
  language$: Observable<Language>;

  destroy$ = new Subject<any>();
  eventsType: EventType[] = [];
  validForm: boolean;
  event: EllosEventSubmit;
  newEvent: EllosEvent;
  constructor(
    private readonly dialogRef: MatDialogRef<EditEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      event: SubscriptionResponse;
      idCompany: number;
      edit: boolean;
    }
  ) {}

  ngOnInit(): void {}

  setEvent(event) {
    this.event = event;
  }
  newEventclose(event) {
    this.dialogRef.close(event);
  }
  editEvent(e) {
    this.dialogRef.close(e);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
