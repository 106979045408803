import { Country } from 'src/app/shared/models/country';
/* tslint:disable */
export const allCountries: Country[] = [
  {
    id: 1,
    name: 'Brazil',
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    numericCode: 76
  },
  {
    id: 2,
    name: 'Afghanistan',
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    numericCode: 4
  },
  {
    id: 3,
    name: 'Åland Islands',
    alpha2Code: 'AX',
    alpha3Code: 'ALA',
    numericCode: 248
  },
  {
    id: 4,
    name: 'Albania',
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    numericCode: 8
  },
  {
    id: 5,
    name: 'Algeria',
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    numericCode: 12
  },
  {
    id: 6,
    name: 'American Samoa',
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    numericCode: 16
  },
  {
    id: 7,
    name: 'Andorra',
    alpha2Code: 'AD',
    alpha3Code: 'AND',
    numericCode: 20
  },
  {
    id: 8,
    name: 'Angola',
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    numericCode: 24
  },
  {
    id: 9,
    name: 'Anguilla',
    alpha2Code: 'AI',
    alpha3Code: 'AIA',
    numericCode: 660
  },
  {
    id: 10,
    name: 'Antarctica',
    alpha2Code: 'AQ',
    alpha3Code: 'ATA',
    numericCode: 10
  },
  {
    id: 11,
    name: 'Antigua and Barbuda',
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    numericCode: 28
  },
  {
    id: 12,
    name: 'Argentina',
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    numericCode: 32
  },
  {
    id: 13,
    name: 'Armenia',
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    numericCode: 51
  },
  {
    id: 14,
    name: 'Aruba',
    alpha2Code: 'AW',
    alpha3Code: 'ABW',
    numericCode: 533
  },
  {
    id: 15,
    name: 'Australia',
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    numericCode: 36
  },
  {
    id: 16,
    name: 'Austria',
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    numericCode: 40
  },
  {
    id: 17,
    name: 'Azerbaijan',
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    numericCode: 31
  },
  {
    id: 18,
    name: 'Bahamas',
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    numericCode: 44
  },
  {
    id: 19,
    name: 'Bahrain',
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    numericCode: 48
  },
  {
    id: 20,
    name: 'Bangladesh',
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    numericCode: 50
  },
  {
    id: 21,
    name: 'Barbados',
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    numericCode: 52
  },
  {
    id: 22,
    name: 'Belarus',
    alpha2Code: 'BY',
    alpha3Code: 'BLR',
    numericCode: 112
  },
  {
    id: 23,
    name: 'Belgium',
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    numericCode: 56
  },
  {
    id: 24,
    name: 'Belize',
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    numericCode: 84
  },
  {
    id: 25,
    name: 'Benin',
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    numericCode: 204
  },
  {
    id: 26,
    name: 'Bermuda',
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    numericCode: 60
  },
  {
    id: 27,
    name: 'Bhutan',
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    numericCode: 64
  },
  {
    id: 28,
    name: 'Bolivia (Plurinational State of)',
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    numericCode: 68
  },
  {
    id: 29,
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2Code: 'BQ',
    alpha3Code: 'BES',
    numericCode: 535
  },
  {
    id: 30,
    name: 'Bosnia and Herzegovina',
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    numericCode: 70
  },
  {
    id: 31,
    name: 'Botswana',
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    numericCode: 72
  },
  {
    id: 32,
    name: 'Bouvet Island',
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    numericCode: 74
  },
  {
    id: 33,
    name: 'British Indian Ocean Territory',
    alpha2Code: 'IO',
    alpha3Code: 'IOT',
    numericCode: 86
  },
  {
    id: 34,
    name: 'Brunei Darussalam',
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    numericCode: 96
  },
  {
    id: 35,
    name: 'Bulgaria',
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    numericCode: 100
  },
  {
    id: 36,
    name: 'Burkina Faso',
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    numericCode: 854
  },
  {
    id: 37,
    name: 'Burundi',
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    numericCode: 108
  },
  {
    id: 38,
    name: 'Cabo Verde',
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    numericCode: 132
  },
  {
    id: 39,
    name: 'Cambodia',
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    numericCode: 116
  },
  {
    id: 40,
    name: 'Cameroon',
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    numericCode: 120
  },
  {
    id: 41,
    name: 'Canada',
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    numericCode: 124
  },
  {
    id: 42,
    name: 'Cayman Islands',
    alpha2Code: 'KY',
    alpha3Code: 'CYM',
    numericCode: 136
  },
  {
    id: 43,
    name: 'Central African Republic',
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    numericCode: 140
  },
  {
    id: 44,
    name: 'Chad',
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    numericCode: 148
  },
  {
    id: 45,
    name: 'Chile',
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    numericCode: 152
  },
  {
    id: 46,
    name: 'China',
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    numericCode: 156
  },
  {
    id: 47,
    name: 'Christmas Island',
    alpha2Code: 'CX',
    alpha3Code: 'CXR',
    numericCode: 162
  },
  {
    id: 48,
    name: 'Cocos (Keeling) Islands',
    alpha2Code: 'CC',
    alpha3Code: 'CCK',
    numericCode: 166
  },
  {
    id: 49,
    name: 'Colombia',
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    numericCode: 170
  },
  {
    id: 50,
    name: 'Comores',
    alpha2Code: 'KM',
    alpha3Code: 'COM',
    numericCode: 174
  },
  {
    id: 51,
    name: 'Congo',
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    numericCode: 178
  },
  {
    id: 52,
    name: 'Congo, Democratic Republic of the',
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    numericCode: 180
  },
  {
    id: 53,
    name: 'Cook Islands',
    alpha2Code: 'CK',
    alpha3Code: 'COK',
    numericCode: 184
  },
  {
    id: 54,
    name: 'Costa Rica',
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    numericCode: 188
  },
  {
    id: 55,
    // eslint-disable-next-line
    name: "Côte d'Ivoire", // eslint-disable-line
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    numericCode: 384
  },
  {
    id: 56,
    name: 'Croatia',
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    numericCode: 191
  },
  {
    id: 57,
    name: 'Cuba',
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    numericCode: 192
  },
  {
    id: 58,
    name: 'Curaçao',
    alpha2Code: 'CW',
    alpha3Code: 'CUW',
    numericCode: 531
  },
  {
    id: 59,
    name: 'Cyprus',
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    numericCode: 196
  },
  {
    id: 60,
    name: 'Czechia',
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    numericCode: 203
  },
  {
    id: 61,
    name: 'Denmark',
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    numericCode: 208
  },
  {
    id: 62,
    name: 'Djibouti',
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    numericCode: 262
  },
  {
    id: 63,
    name: 'Dominica',
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    numericCode: 212
  },
  {
    id: 64,
    name: 'Dominican Republic',
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    numericCode: 214
  },
  {
    id: 65,
    name: 'Ecuador',
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    numericCode: 218
  },
  {
    id: 66,
    name: 'Egypt',
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    numericCode: 818
  },
  {
    id: 67,
    name: 'El Salvador',
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    numericCode: 222
  },
  {
    id: 68,
    name: 'Equatorial Guinea',
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    numericCode: 226
  },
  {
    id: 69,
    name: 'Eritrea',
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    numericCode: 232
  },
  {
    id: 70,
    name: 'Estonia',
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    numericCode: 233
  },
  {
    id: 71,
    name: 'Eswatini',
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    numericCode: 748
  },
  {
    id: 72,
    name: 'Ethiopia',
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    numericCode: 231
  },
  {
    id: 73,
    name: 'Falkland Islands (Malvinas)',
    alpha2Code: 'FK',
    alpha3Code: 'FLK',
    numericCode: 238
  },
  {
    id: 74,
    name: 'Faroe Islands',
    alpha2Code: 'FO',
    alpha3Code: 'FRO',
    numericCode: 234
  },
  {
    id: 75,
    name: 'Fiji',
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    numericCode: 242
  },
  {
    id: 76,
    name: 'Finland',
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    numericCode: 246
  },
  {
    id: 77,
    name: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    numericCode: 250
  },
  {
    id: 78,
    name: 'French Guiana',
    alpha2Code: 'GF',
    alpha3Code: 'GUF',
    numericCode: 254
  },
  {
    id: 79,
    name: 'French Polynesia',
    alpha2Code: 'PF',
    alpha3Code: 'PYF',
    numericCode: 258
  },
  {
    id: 80,
    name: 'French Southern Territories',
    alpha2Code: 'TF',
    alpha3Code: 'ATF',
    numericCode: 260
  },
  {
    id: 81,
    name: 'Gabon',
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    numericCode: 266
  },
  {
    id: 82,
    name: 'Gambia',
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    numericCode: 270
  },
  {
    id: 83,
    name: 'Georgia',
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    numericCode: 268
  },
  {
    id: 84,
    name: 'Germany',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    numericCode: 276
  },
  {
    id: 85,
    name: 'Ghana',
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    numericCode: 288
  },
  {
    id: 86,
    name: 'Gibraltar',
    alpha2Code: 'GI',
    alpha3Code: 'GIB',
    numericCode: 292
  },
  {
    id: 87,
    name: 'Greece',
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    numericCode: 300
  },
  {
    id: 88,
    name: 'Greenland',
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    numericCode: 304
  },
  {
    id: 89,
    name: 'Grenada',
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    numericCode: 308
  },
  {
    id: 90,
    name: 'Guadeloupe',
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    numericCode: 312
  },
  {
    id: 91,
    name: 'Guam',
    alpha2Code: 'GU',
    alpha3Code: 'GUM',
    numericCode: 316
  },
  {
    id: 92,
    name: 'Guatemala',
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    numericCode: 320
  },
  {
    id: 93,
    name: 'Guernsey',
    alpha2Code: 'GG',
    alpha3Code: 'GGY',
    numericCode: 831
  },
  {
    id: 94,
    name: 'Guinea',
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    numericCode: 324
  },
  {
    id: 95,
    name: 'Guinea-Bissau',
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    numericCode: 624
  },
  {
    id: 96,
    name: 'Guyana',
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    numericCode: 328
  },
  {
    id: 97,
    name: 'Haiti',
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    numericCode: 332
  },
  {
    id: 98,
    name: 'Heard Island and McDonald Islands',
    alpha2Code: 'HM',
    alpha3Code: 'HMD',
    numericCode: 334
  },
  {
    id: 99,
    name: 'Holy See',
    alpha2Code: 'VA',
    alpha3Code: 'VAT',
    numericCode: 336
  },
  {
    id: 100,
    name: 'Honduras',
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    numericCode: 340
  },
  {
    id: 101,
    name: 'Hong Kong',
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    numericCode: 344
  },
  {
    id: 102,
    name: 'Hungary',
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    numericCode: 348
  },
  {
    id: 103,
    name: 'Iceland',
    alpha2Code: 'IS',
    alpha3Code: 'ISL',
    numericCode: 352
  },
  {
    id: 104,
    name: 'India',
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    numericCode: 356
  },
  {
    id: 105,
    name: 'Indonesia',
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    numericCode: 360
  },
  {
    id: 106,
    name: 'Iran (Islamic Republic of)',
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    numericCode: 364
  },
  {
    id: 107,
    name: 'Iraq',
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    numericCode: 368
  },
  {
    id: 108,
    name: 'Ireland',
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    numericCode: 372
  },
  {
    id: 109,
    name: 'Isle of Man',
    alpha2Code: 'IM',
    alpha3Code: 'IMN',
    numericCode: 833
  },
  {
    id: 110,
    name: 'Island of South',
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    numericCode: 376
  },
  {
    id: 111,
    name: 'Italy',
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    numericCode: 380
  },
  {
    id: 112,
    name: 'Jamaica',
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    numericCode: 388
  },
  {
    id: 113,
    name: 'Japan',
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    numericCode: 392
  },
  {
    id: 114,
    name: 'Jersey',
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    numericCode: 832
  },
  {
    id: 115,
    name: 'Jordan',
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    numericCode: 400
  },
  {
    id: 116,
    name: 'Kazakhstan',
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    numericCode: 398
  },
  {
    id: 117,
    name: 'Kenya',
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    numericCode: 404
  },
  {
    id: 118,
    name: 'Kiribati',
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    numericCode: 296
  },
  {
    id: 119,
    name: "Korea (Democratic People's Republic of)",
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    numericCode: 408
  },
  {
    id: 120,
    name: 'Korea, Republic of',
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    numericCode: 410
  },
  {
    id: 121,
    name: 'Kuwait',
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    numericCode: 414
  },
  {
    id: 122,
    name: 'Kyrgyzstan',
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    numericCode: 417
  },
  {
    id: 123,
    name: "Lao People's Democratic Republic",
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    numericCode: 418
  },
  {
    id: 124,
    name: 'Latvia',
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    numericCode: 428
  },
  {
    id: 125,
    name: 'Lebanon',
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    numericCode: 422
  },
  {
    id: 126,
    name: 'Lesotho',
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    numericCode: 426
  },
  {
    id: 127,
    name: 'Liberia',
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    numericCode: 430
  },
  {
    id: 128,
    name: 'Libya',
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    numericCode: 434
  },
  {
    id: 129,
    name: 'Liechtenstein',
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    numericCode: 438
  },
  {
    id: 130,
    name: 'Lithuania',
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    numericCode: 440
  },
  {
    id: 131,
    name: 'Luxembourg',
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    numericCode: 442
  },
  {
    id: 132,
    name: 'Macao',
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    numericCode: 446
  },
  {
    id: 133,
    name: 'Madagascar',
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    numericCode: 450
  },
  {
    id: 134,
    name: 'Malawi',
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    numericCode: 454
  },
  {
    id: 135,
    name: 'Malaysia',
    alpha2Code: 'MY',
    alpha3Code: 'MYS',
    numericCode: 458
  },
  {
    id: 136,
    name: 'Maldives',
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    numericCode: 462
  },
  {
    id: 137,
    name: 'Mali',
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    numericCode: 466
  },
  {
    id: 138,
    name: 'Malta',
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    numericCode: 470
  },
  {
    id: 139,
    name: 'Marshall Islands',
    alpha2Code: 'MH',
    alpha3Code: 'MHL',
    numericCode: 584
  },
  {
    id: 140,
    name: 'Martinique',
    alpha2Code: 'MQ',
    alpha3Code: 'MTQ',
    numericCode: 474
  },
  {
    id: 141,
    name: 'Mauritania',
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    numericCode: 478
  },
  {
    id: 142,
    name: 'Mauritius',
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    numericCode: 480
  },
  {
    id: 143,
    name: 'Mayotte',
    alpha2Code: 'YT',
    alpha3Code: 'MYT',
    numericCode: 175
  },
  {
    id: 144,
    name: 'Mexico',
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    numericCode: 484
  },
  {
    id: 145,
    name: 'Micronesia (Federated States of)',
    alpha2Code: 'FM',
    alpha3Code: 'FSM',
    numericCode: 583
  },
  {
    id: 146,
    name: 'Moldova, Republic of',
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    numericCode: 498
  },
  {
    id: 147,
    name: 'Monaco',
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    numericCode: 492
  },
  {
    id: 148,
    name: 'Mongolia',
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    numericCode: 496
  },
  {
    id: 149,
    name: 'Montenegro',
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    numericCode: 499
  },
  {
    id: 150,
    name: 'Montserrat',
    alpha2Code: 'MS',
    alpha3Code: 'MSR',
    numericCode: 500
  },
  {
    id: 151,
    name: 'Morocco',
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    numericCode: 504
  },
  {
    id: 152,
    name: 'Mozambique',
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    numericCode: 508
  },
  {
    id: 153,
    name: 'Myanmar',
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    numericCode: 104
  },
  {
    id: 154,
    name: 'Namibia',
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    numericCode: 516
  },
  {
    id: 155,
    name: 'Nauru',
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    numericCode: 520
  },
  {
    id: 156,
    name: 'Nepal',
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    numericCode: 524
  },
  {
    id: 157,
    name: 'Netherlands',
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    numericCode: 528
  },
  {
    id: 158,
    name: 'New Caledonia',
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    numericCode: 540
  },
  {
    id: 159,
    name: 'New Zealand',
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    numericCode: 554
  },
  {
    id: 160,
    name: 'Nicaragua',
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    numericCode: 558
  },
  {
    id: 161,
    name: 'Niger',
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    numericCode: 562
  },
  {
    id: 162,
    name: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    numericCode: 566
  },
  {
    id: 163,
    name: 'Niue',
    alpha2Code: 'NU',
    alpha3Code: 'NIU',
    numericCode: 570
  },
  {
    id: 164,
    name: 'Norfolk Island',
    alpha2Code: 'NF',
    alpha3Code: 'NFK',
    numericCode: 574
  },
  {
    id: 165,
    name: 'North Macedonia',
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    numericCode: 807
  },
  {
    id: 166,
    name: 'Northern Mariana Islands',
    alpha2Code: 'MP',
    alpha3Code: 'MNP',
    numericCode: 580
  },
  {
    id: 167,
    name: 'Norway',
    alpha2Code: 'NO',
    alpha3Code: 'NOR',
    numericCode: 578
  },
  {
    id: 168,
    name: 'Oman',
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    numericCode: 512
  },
  {
    id: 169,
    name: 'Pakistan',
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    numericCode: 586
  },
  {
    id: 170,
    name: 'Palau',
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    numericCode: 585
  },
  {
    id: 171,
    name: 'Palestine, State of',
    alpha2Code: 'PS',
    alpha3Code: 'PSE',
    numericCode: 275
  },
  {
    id: 172,
    name: 'Panama',
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    numericCode: 591
  },
  {
    id: 173,
    name: 'Papua New Guinea',
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    numericCode: 598
  },
  {
    id: 174,
    name: 'Paraguay',
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    numericCode: 600
  },
  {
    id: 175,
    name: 'Peru',
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    numericCode: 604
  },
  {
    id: 176,
    name: 'Philippines',
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    numericCode: 608
  },
  {
    id: 177,
    name: 'Pitcairn',
    alpha2Code: 'PN',
    alpha3Code: 'PCN',
    numericCode: 612
  },
  {
    id: 178,
    name: 'Poland',
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    numericCode: 616
  },
  {
    id: 179,
    name: 'Portugal',
    alpha2Code: 'PT',
    alpha3Code: 'PRT',
    numericCode: 620
  },
  {
    id: 180,
    name: 'Puerto Rico',
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    numericCode: 630
  },
  {
    id: 181,
    name: 'Qatar',
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    numericCode: 634
  },
  {
    id: 182,
    name: 'Réunion',
    alpha2Code: 'RE',
    alpha3Code: 'REU',
    numericCode: 638
  },
  {
    id: 183,
    name: 'Romania',
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    numericCode: 642
  },
  {
    id: 184,
    name: 'Russian Federation',
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    numericCode: 643
  },
  {
    id: 185,
    name: 'Rwanda',
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    numericCode: 646
  },
  {
    id: 186,
    name: 'Saint Barthélemy',
    alpha2Code: 'BL',
    alpha3Code: 'BLM',
    numericCode: 652
  },
  {
    id: 187,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2Code: 'SH',
    alpha3Code: 'SHN',
    numericCode: 654
  },
  {
    id: 188,
    name: 'Saint Kitts and Nevis',
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    numericCode: 659
  },
  {
    id: 189,
    name: 'Saint Lucia',
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    numericCode: 662
  },
  {
    id: 190,
    name: 'Saint Martin (French part)',
    alpha2Code: 'MF',
    alpha3Code: 'MAF',
    numericCode: 663
  },
  {
    id: 191,
    name: 'Saint Pierre and Miquelon',
    alpha2Code: 'PM',
    alpha3Code: 'SPM',
    numericCode: 666
  },
  {
    id: 192,
    name: 'Saint Vincent and the Grenadines',
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    numericCode: 670
  },
  {
    id: 193,
    name: 'Samoa',
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    numericCode: 882
  },
  {
    id: 194,
    name: 'San Marino',
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    numericCode: 674
  },
  {
    id: 195,
    name: 'Sao Tome and Principe',
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    numericCode: 678
  },
  {
    id: 196,
    name: 'Saudi Arabia',
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    numericCode: 682
  },
  {
    id: 197,
    name: 'Senegal',
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    numericCode: 686
  },
  {
    id: 198,
    name: 'Serbia',
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    numericCode: 688
  },
  {
    id: 199,
    name: 'Seychelles',
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    numericCode: 690
  },
  {
    id: 200,
    name: 'Sierra Leone',
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    numericCode: 694
  },
  {
    id: 201,
    name: 'Singapore',
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    numericCode: 702
  },
  {
    id: 202,
    name: 'Sint Maarten (Dutch part)',
    alpha2Code: 'SX',
    alpha3Code: 'SXM',
    numericCode: 534
  },
  {
    id: 203,
    name: 'Slovakia',
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    numericCode: 703
  },
  {
    id: 204,
    name: 'Slovenia',
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    numericCode: 705
  },
  {
    id: 205,
    name: 'Solomon Islands',
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    numericCode: 90
  },
  {
    id: 206,
    name: 'Somalia',
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    numericCode: 706
  },
  {
    id: 207,
    name: 'South Africa',
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    numericCode: 710
  },
  {
    id: 208,
    name: 'South Georgia and the South Sandwich Islands',
    alpha2Code: 'GS',
    alpha3Code: 'SGS',
    numericCode: 239
  },
  {
    id: 209,
    name: 'South Sudan',
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    numericCode: 728
  },
  {
    id: 210,
    name: 'Spain',
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    numericCode: 724
  },
  {
    id: 211,
    name: 'Sri Lanka',
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    numericCode: 144
  },
  {
    id: 212,
    name: 'Sudan',
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    numericCode: 729
  },
  {
    id: 213,
    name: 'Suriname',
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    numericCode: 740
  },
  {
    id: 214,
    name: 'Svalbard and Jan Mayen',
    alpha2Code: 'SJ',
    alpha3Code: 'SJM',
    numericCode: 744
  },
  {
    id: 215,
    name: 'Sweden',
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    numericCode: 752
  },
  {
    id: 216,
    name: 'Switzerland',
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    numericCode: 756
  },
  {
    id: 217,
    name: 'Syrian Arab Republic',
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    numericCode: 760
  },
  {
    id: 218,
    name: 'Taiwan, Province of China',
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    numericCode: 158
  },
  {
    id: 219,
    name: 'Tajikistan',
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    numericCode: 762
  },
  {
    id: 220,
    name: 'Tanzania, United Republic of',
    alpha2Code: 'TZ',
    alpha3Code: 'TZA',
    numericCode: 834
  },
  {
    id: 221,
    name: 'Thailand',
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    numericCode: 764
  },
  {
    id: 222,
    name: 'Timor-Leste',
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    numericCode: 626
  },
  {
    id: 223,
    name: 'Togo',
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    numericCode: 768
  },
  {
    id: 224,
    name: 'Tokelau',
    alpha2Code: 'TK',
    alpha3Code: 'TKL',
    numericCode: 772
  },
  {
    id: 225,
    name: 'Tonga',
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    numericCode: 776
  },
  {
    id: 226,
    name: 'Trinidad and Tobago',
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    numericCode: 780
  },
  {
    id: 227,
    name: 'Tunisia',
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    numericCode: 788
  },
  {
    id: 228,
    name: 'Turkey',
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    numericCode: 792
  },
  {
    id: 229,
    name: 'Turkmenistan',
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    numericCode: 795
  },
  {
    id: 230,
    name: 'Turks and Caicos Islands',
    alpha2Code: 'TC',
    alpha3Code: 'TCA',
    numericCode: 796
  },
  {
    id: 231,
    name: 'Tuvalu',
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    numericCode: 798
  },
  {
    id: 232,
    name: 'Uganda',
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    numericCode: 800
  },
  {
    id: 233,
    name: 'Ukraine',
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    numericCode: 804
  },
  {
    id: 234,
    name: 'United Arab Emirates',
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    numericCode: 784
  },
  {
    id: 235,
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    numericCode: 826
  },
  {
    id: 236,
    name: 'United States of America',
    alpha2Code: 'US',
    alpha3Code: 'USA',
    numericCode: 840
  },
  {
    id: 237,
    name: 'United States Minor Outlying Islands',
    alpha2Code: 'UM',
    alpha3Code: 'UMI',
    numericCode: 581
  },
  {
    id: 238,
    name: 'Uruguay',
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    numericCode: 858
  },
  {
    id: 239,
    name: 'Uzbekistan',
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    numericCode: 860
  },
  {
    id: 240,
    name: 'Vanuatu',
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    numericCode: 548
  },
  {
    id: 241,
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    numericCode: 862
  },
  {
    id: 242,
    name: 'Viet Nam',
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    numericCode: 704
  },
  {
    id: 243,
    name: 'Virgin Islands (British)',
    alpha2Code: 'VG',
    alpha3Code: 'VGB',
    numericCode: 92
  },
  {
    id: 244,
    name: 'Virgin Islands (U.S.)',
    alpha2Code: 'VI',
    alpha3Code: 'VIR',
    numericCode: 850
  },
  {
    id: 245,
    name: 'Wallis and Futuna',
    alpha2Code: 'WF',
    alpha3Code: 'WLF',
    numericCode: 876
  },
  {
    id: 246,
    name: 'Western Sahara',
    alpha2Code: 'EH',
    alpha3Code: 'ESH',
    numericCode: 732
  },
  {
    id: 247,
    name: 'Yemen',
    alpha2Code: 'YE',
    alpha3Code: 'YEM',
    numericCode: 887
  },
  {
    id: 248,
    name: 'Zambia',
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    numericCode: 894
  },
  {
    id: 249,
    name: 'Zimbabwe',
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    numericCode: 716
  }
];
