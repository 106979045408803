import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { PermissionsService } from 'src/app/core/services/permissions.service';

import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { NotificationService } from '../../../../shared/services/notification.service';
import { NotificationResponse } from '../../../../shared/models/notification-response.interface';

import Language from '../../../../core/interfaces/language.interface';
import { OriginCertificateQuery } from '../../interfaces/OriginCertificateQuery.interface';

import { ModalNotificationTemplateComponent } from '../../modals/modal-notification-template/modal-notification-template.component';

import { repeatQuery } from '../../actions/origin-certificate-query.action';
import { OriginCertificateService } from '../../services/origin-certificate.service';
import { OriginCertificatePreviewResponse } from '../../Models/origin-certificate-preview-response.interface';
import { ModalImportOriginCertificateComponent } from '../../modals/modal-import-origin-certificate/modal-import-origin-certificate.component';
import { allCountries } from 'src/app/core/models/all-countries';
import { ResultDialogData } from 'src/app/shared/components/result-dialog/result-dialog-data.interface';
// import { ResultDialogComponent } from '@ccab/components-lib';
import { OriginCertificateStatusEnum } from '../../enums/origin.enum';
import { ResultDialogComponent } from '@ccab/components-lib';

@Component({
  selector: 'app-origin-certificate',
  templateUrl: './origin-certificate.component.html',
  styleUrls: ['./origin-certificate.component.scss']
})
export class OriginCertificateComponent implements OnInit {
  _query: any;
  language$: Observable<Language>;
  languageCode: string;
  destroy$ = new Subject<any>();
  updateAfterReload?: boolean = null;
  currentQuery: OriginCertificateQuery;
  dataSource: OriginCertificatePreviewResponse;
  notificationResponse: NotificationResponse;
  originCertificateQuery$: Observable<OriginCertificateQuery>;
  dialogRef: MatDialogRef<ModalImportOriginCertificateComponent>;
  dialogRefNotification: MatDialogRef<ModalNotificationTemplateComponent>;
  allSelected: boolean = false;
  someSelected: boolean = false;
  public modules = [];
  public modulePermission = PermissionsEnum;

  constructor(
    private readonly originCertificateQueryStore: Store<{
      originCertificateQuery: OriginCertificateQuery;
    }>,
    private readonly originCerticateService: OriginCertificateService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly langStore: Store<{ language: Language }>,
    public permissionsService: PermissionsService,
    private route: ActivatedRoute,
    public readonly matDialog: MatDialog,
    private router: Router
  ) {
    this.originCertificateQuery$ = originCertificateQueryStore.pipe(select('originCertificateQuery'));
    this.language$ = langStore.pipe(select('language'));
  }
  getIcon(media) {
    switch (media) {
      case 'upload':
        return faGoogleDrive;

      default:
        break;
    }
  }
  ngOnInit(): void {
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      if (lang) {
        this.languageCode = lang.code;
      }
    });

    this.updateAfterReload = true;
    this.originCertificateQuery$.pipe(takeUntil(this.destroy$)).subscribe(async (query) => {
      if (query) {
        this.route.queryParams.subscribe((params) => {
          if (params?.updateReload) {
            this.updateAfterReload = true;
          } else {
            this.updateAfterReload = false;
          }
        });
        this.currentQuery = query;
        this._query = this.currentQuery;
        await this.getData();
      }
    });
    this.modules = this.permissionsService.getPermissionStorage();
  }
  async openNotificationModal() {
    this.notificationResponse = await this.notificationService.getNotificationVariables();
    if (this.notificationResponse !== undefined && this.notificationResponse.modalActive === true) {
      if (this.languageCode !== undefined && this.languageCode === 'en') {
        this.notificationResponse.modalTextEnglish = this.notificationResponse.modalTextEnglish.replace('  ', '\n\n').replace('   ', '\n\n');
        this.dialogRefNotification = this.matDialog.open(ModalNotificationTemplateComponent, {
          data: {
            message1: `${this.notificationResponse.modalTextEnglish}`,
            title: 'Attention'
          }
        });
      } else {
        this.notificationResponse.modalText = this.notificationResponse.modalText.replace('  ', '\n\n').replace('   ', '\n\n');
        this.dialogRefNotification = this.matDialog.open(ModalNotificationTemplateComponent, {
          data: {
            message1: `${this.notificationResponse.modalText}`,
            title: 'Atenção'
          }
        });
      }
      this.dialogRefNotification.afterClosed().subscribe(async () => {
        this.updateAfterReload = true;
        await this.getData();
      });
    }
  }
  openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '370px';
    dialogConfig.width = '800px';
    this.dialogRef = this.matDialog.open(ModalImportOriginCertificateComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(async () => {
      this.updateAfterReload = true;
      await this.getData();
    });
  }
  async getData() {
    if (this._query) {
      try {
        this.router.navigate([], {
          queryParams: {
            updateReload: null
          },
          queryParamsHandling: 'merge'
        });
        this.dataSource = await this.originCerticateService.getOriginCertificateList(this._query, this.updateAfterReload);

        this.dataSource.results.forEach((item) => {
          item.checkbox = false;
          item.country = allCountries.find((country) => country.name.toLowerCase() === item.destinationCountry.toLowerCase());
        });
        this.updateAfterReload = false;
        return;
      } catch (e) {
        console.error(e);
      }
    }
  }
  async filtercertificateNum(certificateNum: string[]) {
    const query = { ...this._query, pageSize: this.dataSource.total };
    let data = await this.originCerticateService.getOriginCertificateList(query, this.updateAfterReload);

    certificateNum.length != 0
      ? (this.dataSource.results = data.results.filter((x) => {
          for (let num of certificateNum) {
            if (x.certificate == num) return true;
          }
        }))
      : await this.getData();

    this.dataSource.results.forEach((item) => {
      item.checkbox = false;
      item.country = allCountries.find((country) => country.name.toLowerCase() === item.destinationCountry.toLowerCase());
    });
  }

  async filterInvoiceNumber(InvoiceNumber: string[]) {
    const query = { ...this._query, pageSize: this.dataSource.total };
    let data = await this.originCerticateService.getOriginCertificateList(query, this.updateAfterReload);

    InvoiceNumber.length != 0
      ? (this.dataSource.results = data.results.filter((x) => {
          for (let num of InvoiceNumber) {
            if (x.invoice == num) return true;
          }
        }))
      : await this.getData();

    this.dataSource.results.forEach((item) => {
      item.checkbox = false;
      item.country = allCountries.find((country) => country.name.toLowerCase() === item.destinationCountry.toLowerCase());
    });
  }
  async downloadCertificates() {
    if (this.dataSource) {
      try {
        const selectedCertificates = this.dataSource.results.filter((item) => item.checkbox);
        const selectedCertificatesIds = selectedCertificates.map((item) => item.id);
        const response: any = await this.originCerticateService.downloadCertificates(selectedCertificatesIds);
        const dialogData: ResultDialogData = {
          success: true,
          text: selectedCertificates.length == 1 ? 'easy-trade.origin.confirm.single-file' : 'easy-trade.origin.confirm.multiple-files',
          timeout: 3000
        };
        const dialogRefReult = this.dialog.open(ResultDialogComponent, {
          data: dialogData,
          disableClose: true
        });

        for (const item of response) {
          const res = await fetch(item.fileContents.fileContents); // pdf url on s3 bucket
          const data = await res.blob();
          const blob = new Blob([data], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `origin_certificate_${item.certId}.pdf`);
          link.click();
          URL.revokeObjectURL(blobUrl);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  async reloadTable(data: boolean) {
    this.originCertificateQueryStore.dispatch(repeatQuery({ repeat: true }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  showSelectedDownloadButton() {
    this.someSelected = this.dataSource?.results?.some((item) => item.checkbox);
    const allSelected = this.dataSource?.results?.every((item) => item.checkbox);
    return this.someSelected && !allSelected;
  }

  showAllDownloadButton() {
    this.allSelected = this.dataSource?.results?.every((item) => item.checkbox);
    return this.allSelected;
  }
}
