<mat-form-field class="form-field" appearance="outline">
  <mat-label>{{ 'settings.companies.timezone' | translate }}</mat-label>
  <mat-select [formControl]="selectedTimezone" (valueChange)="setTimezone($event)" (closed)="resetInput()">
    <mat-form-field class="filter" (click)="cancelEvent($event)" appearance="outline">
      <mat-label>{{ 'toolbar.filter' | translate }}</mat-label>
      <input class="input-filter" matInput #input [formControl]="inputSearch" autocomplete="off" [required]="required" />
    </mat-form-field>
    <mat-option class="button-menu" *ngFor="let timezone of filteredTimezones | async" [value]="timezone">
      <span class="menu-item">GMT&nbsp;{{ timezone.offset }}&nbsp;{{ timezone.name.replace('_', ' ') }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
