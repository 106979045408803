<h2>{{ 'settings.profile.preferences.plan-use-ellos' | translate }}</h2>
<div [formGroup]="preferencesForm" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="35px">
  <div *ngIf="!saving" fxLayout="row" fxLayoutAlign="center center">
    <app-user-preferences formControlName="preferencesCode"></app-user-preferences>
  </div>
  <div fxLayout="column" *ngIf="!saving" fxLayoutAlign="center center" class="interests" fxLayoutGap="25px">
    <app-user-interests formControlName="interests" class="interests__list"></app-user-interests>
    <button mat-flat-button color="accent" fxFlex="auto" type="submit" (click)="submit()" *ngIf="!saving" class="interests__button">
      {{ 'settings.profile.preferences.save-preferences' | translate }}
    </button>
    <button mat-stroked-button color="primary" fxFlex="auto" type="submit" *ngIf="!saving" (click)="skip()" class="interests__button">
      {{ 'settings.profile.preferences.skip-preferences' | translate }}
    </button>
  </div>
  <app-ellos-spinner *ngIf="saving" [text]="'settings.profile.preferences.saving-preferences' | translate"></app-ellos-spinner>
</div>
