import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngrx/store';
import Language from 'src/app/core/interfaces/language.interface';
import { GlobalizeCalendar } from 'src/app/globalize-calendar';

@Component({
  selector: 'app-matchmaking',
  templateUrl: './matchmaking.component.html',
  styleUrls: ['./matchmaking.component.scss']
})
export class MatchmakingComponent extends GlobalizeCalendar {
  constructor(dateAdapter: DateAdapter<Date>, langStore: Store<{ language: Language }>) {
    super(dateAdapter, langStore);
  }

  ngOnInit(): void {}
}
