import { Injectable } from '@angular/core';
import { stringify } from 'query-string';
import { EllosApiService } from '../../../core/http/ellosApi.service';
import { EllosApi } from '../../../core/interfaces/ellosApi.interface';
import EllosRequest from '../../../core/interfaces/ellosRequest.interface';
import { QueryMatchmaking } from '../interfaces/queryMatchmaking.interface';
import { Meet } from '../models/meet';
import { MatchmakingDetails } from '../models/matchmaking-details';

@Injectable({
  providedIn: 'root'
})
export class MatchmakingService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async requestMatchmaking(newMatchmaking: {
    idSubCategory: number;
    companyProfile: string;
    products: string[];
    countries: number[];
    numberOfMeetings: number;
    translatorNeeded: boolean;
    meetingType: string;
    restrictions: string;
    comments: string;
    idCompany: number;
    bestTime: string;
  }): Promise<any> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: 'Matchmaking',
      body: newMatchmaking
    };

    try {
      let response = await this.ellosApi.post<any>(request);
      return response;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async getMatchmakings(queryMatchmaking: QueryMatchmaking): Promise<Meet[]> {
    let queryString = stringify(queryMatchmaking);

    let request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Matchmaking?${queryString}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<Meet[]>(request);
      return response.success ? response.data : [];
    } catch (exception) {
      return [];
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async getMatchmakingMeetings(queryMatchmaking: QueryMatchmaking): Promise<Meet[]> {
    let queryString = stringify(queryMatchmaking);

    let request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `MatchmakingMeeting?${queryString}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<Meet[]>(request);
      return response.success ? response.data : [];
    } catch (exception) {
      return [];

      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async matchmackingDetails(detail: { idMatchmaking: number; LanguageCode: string }): Promise<MatchmakingDetails> {
    let queryString = stringify(detail);

    let request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Matchmaking/detail?${queryString}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<MatchmakingDetails>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
}
