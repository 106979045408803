import { Action, createAction, props } from '@ngrx/store';
import { ActionTypes } from 'src/app/core/models/actionTypes';
import { CompanyProfileEnum } from 'src/app/shared/utils/company.profile.enum';
import { FilterStatusEnum } from '../enums/filter-status.enum';
import { MonitoringQuery } from '../interfaces/monitoring-query.interface';

export const MonitoringQueryAction = (monitoringQuery: MonitoringQuery) => {
  return <Action>{
    type: ActionTypes.MonitoringQuery,
    payload: monitoringQuery
  };
};

export const invoiceNumber = createAction('[Monitoring] Invoice Number', props<{ InvoiceId?: string[] }>());
export const originCertificateNumber = createAction('[Monitoring] Origin Certificate Number', props<{ OriginCertificateNumber?: string[] }>());
export const date = createAction('[Monitoring] Filter by Date', props<{ EndDate: string; InitDate: string }>());
export const pageIndex = createAction('[Monitoring] Page Index', props<{ PageIndex?: number }>());
export const pageConfig = createAction('[Monitoring] Page Config', props<{ PageIndex?: number; PageSize?: number }>());
export const statusAction = createAction('[Monitoring] Filter by Status', props<{ Status?: FilterStatusEnum[] }>());
export const Company = createAction('[Monitoring] Filter by Company Selected', props<{ IdCompany: number; Profile?: CompanyProfileEnum }>());
export const companyProfile = createAction('[Monitoring] Filter by Company Profile', props<{ Profile: CompanyProfileEnum }>());
export const clearFilter = createAction('[Monitoring] Clear Filter');
export const repeatQuery = createAction('[Monitoring] Repeate Query');
export const destinationTerminal = createAction('[Monitoring] Filter by DestinationTerminal', props<{ DestinationTerminal?: string[] }>());
