import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ResultDialogData } from '../components/result-dialog/result-dialog-data.interface';
// import { ResultDialogComponent } from '../components/result-dialog/result-dialog.component';
import EllosRequest from '../../core/interfaces/ellosRequest.interface';
import { EllosApi } from '../../core/interfaces/ellosApi.interface';
import { NotificationResponse } from '../models/notification-response.interface';
import { EllosApiService } from '../../core/http/ellosApi.service';
import { ResultDialogComponent } from '@ccab/components-lib';

export interface NotificationSnack {
  text: string;
  buttonText: string;
  duration?: number;
  horizontalPosition?: any | 'center' | 'end' | 'left' | 'start' | 'right';
  verticalPosition?: any | 'bottom' | 'top';
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationResponse: NotificationResponse;

  constructor(
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly ellosApi: EllosApiService
  ) {}

  public async getNotificationVariables() {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `Notification`
      };

      const response = await this.ellosApi.get<NotificationResponse>(request);
      this.notificationResponse = response.data;
      return this.notificationResponse;
    } catch (exception) {
      throw this.ellosApi.checkStatus(exception);
    }
  }

  notifyDialog(data: ResultDialogData) {
    const dialogRefReult = this.dialog.open(ResultDialogComponent, {
      data,
      disableClose: true
    });
    dialogRefReult
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        if (data.closeAll) {
          this.dialog.closeAll();
        }
      });
  }
  successSnackBar(config: NotificationSnack) {
    const successMsg: string = this.translateService.instant(config.text);
    const buttonTxt: string = this.translateService.instant(config.buttonText);
    this.snackbar.open(successMsg, buttonTxt, {
      duration: config.duration,
      horizontalPosition: config.horizontalPosition,
      verticalPosition: config.verticalPosition,
      panelClass: 'success-snackbar'
    });
  }
  errorSnackBar(config: NotificationSnack) {
    const successMsg: string = this.translateService.instant(config.text);
    const buttonTxt: string = this.translateService.instant(config.buttonText);
    this.snackbar.open(successMsg, buttonTxt, {
      duration: config.duration,
      horizontalPosition: config.horizontalPosition,
      verticalPosition: config.verticalPosition,
      panelClass: 'danger-snackbar'
    });
  }
}
