import { Store } from '@ngrx/store';
import * as deepEqual from 'deep-equal';
import { stringify } from 'query-string';
import { Injectable } from '@angular/core';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import { repeatQuery } from '../actions/origin-certificate-query.action';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { OriginCertificateQuery } from '../interfaces/OriginCertificateQuery.interface';
import { OriginCertificatePreviewResponse } from '../Models/origin-certificate-preview-response.interface';

import { CertificateData } from '../Models/certificate-data.interface';
import { OriginCertificateFile } from '../Models/origin-certificate-file';
import { OriginCertificatePreview } from '../Models/origin-certificate-preview';

@Injectable({
  providedIn: 'root'
})
export class OriginCertificateService {
  public tempCertifier: string;
  private lastQuery: OriginCertificateQuery;
  private originCertificatePreview: OriginCertificatePreviewResponse;

  constructor(
    private readonly __ellosApi: EllosApiService,
    private readonly originCertificateQueryStore: Store<{
      originCertificateQuery: OriginCertificateQuery;
    }>
  ) {}

  async getOriginCertificateList(query: OriginCertificateQuery, updateReload?: boolean): Promise<OriginCertificatePreviewResponse> {
    if (!this.lastQuery || !deepEqual(this.lastQuery, query) || query.repeat || updateReload) {
      let queryString = stringify(query);
      this.lastQuery = { ...query, repeat: false };
      try {
        let request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate?${queryString}`,
          body: null
        };

        let response = await this.__ellosApi.get<OriginCertificatePreviewResponse>(request);
        this.originCertificatePreview = response.data;
        if (query.repeat) this.originCertificateQueryStore.dispatch(repeatQuery({ repeat: false }));

        return response.data;
      } catch (exception) {
        this.originCertificatePreview = {
          total: 0,
          results: [],
          pageSize: 10,
          pageIndex: 0
        };
        return this.originCertificatePreview;
      }
    } else {
      return this.originCertificatePreview;
    }
  }
  async getOriginCertificateByNumberAndCertifier(numberCertificate: number, isTypeOtherInCertificateOrigem: boolean): Promise<CertificateData> {
    if (numberCertificate != null && numberCertificate > 0) {
      this.tempCertifier = isTypeOtherInCertificateOrigem ? 'other' : 'ccab';

      try {
        const request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate/numberCertificateWithId/${numberCertificate}?certifier=${this.tempCertifier}`,
          body: null
        };

        const response = await this.__ellosApi.get<CertificateData>(request);
        return response.data;
      } catch (exception) {
        console.log(exception);
        return null;
      }
    } else {
      console.log('Origin certificate number is required');
      return null;
    }
  }
  async getOriginCertificate(id: number): Promise<CertificateData> {
    if (id != null && id > 0) {
      try {
        let request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate/${id}`,
          body: null
        };

        let response = await this.__ellosApi.get<CertificateData>(request);
        return response.data;
      } catch (exception) {
        console.log(exception);
        return null;
      }
    } else {
      console.log('Origin certificate id is required');
      return null;
    }
  }

  async getOriginCertificateByNumber(numberCertificate: number, certifier: string): Promise<any> {
    if (numberCertificate != null && numberCertificate > 0) {
      if (certifier === 'Other') {
        this.tempCertifier = 'other';
      } else {
        this.tempCertifier = 'ccab';
      }

      try {
        const request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate/numberCertificateWithId/${numberCertificate}`,
          body: null
        };

        const response = await this.__ellosApi.get<CertificateData>(request);
        return response.data;
      } catch (exception) {
        console.log(exception);
        return exception;
      }
    } else {
      console.log('Origin certificate number is required');
      return null;
    }
  }

  async getAvailableOriginCertificates(companyId: number, countryCode: string, ncm: string): Promise<OriginCertificatePreview[]> {
    if (companyId != null && companyId > 0 && countryCode != null && ncm != null) {
      try {
        let request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate/GetCertificatesRegistered/${companyId}?countryCode=${countryCode}&ncm=${ncm}`,
          body: null
        };

        let response = await this.__ellosApi.get<OriginCertificatePreview[]>(request);
        return response.data;
      } catch (exception) {
        console.log(exception);
        return null;
      }
    } else {
      console.log('CompanyId is required');
      return null;
    }
  }

  async createCertificate(certificateData: CertificateData) {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `OriginCertificate`,
        body: certificateData
      };

      let response = await this.__ellosApi.post(request);
      return response.data;
    } catch (exception) {}
  }

  async importOrigincertificate(numberId: number, dados: any) {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `OriginCertificate/importer/${numberId}`,
        body: dados
      };

      let response = await this.__ellosApi.post(request);
      return response.data;
    } catch (exception) {
      throw exception;
    }
  }

  async ImportFileCOByArrayLinesAsync(numberId: number, originCertificatesLines: string[]) {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `OriginCertificate/importFileByLines/${numberId}`,
        body: originCertificatesLines
      };

      let response = await this.__ellosApi.post(request);
      return response;
    } catch (exception) {
      throw exception;
    }
  }

  async updateCertificate(certificateData: CertificateData) {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `OriginCertificate`,
        body: certificateData
      };

      let response = await this.__ellosApi.put(request);
      return response.data;
    } catch (exception) {}
  }

  async deleteCertificate(id: number): Promise<boolean> {
    if (id != null && id > 0) {
      try {
        let request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate/${id}`,
          body: null
        };

        let response = await this.__ellosApi.delete(request);
        return response.success;
      } catch (exception) {
        console.log(exception);
        return null;
      }
    } else {
      console.log('Origin certificate id is required');
      return null;
    }
  }

  async duplicateCertificate(id: number): Promise<boolean> {
    if (id != null && id > 0) {
      try {
        let request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate/${id}/Duplicate`,
          body: null
        };

        let response = await this.__ellosApi.post(request);
        return response.success;
      } catch (exception) {
        console.log(exception);
        return null;
      }
    } else {
      console.log('Origin certificate id is required');
      return null;
    }
  }

  async downloadPDF(id: number, isPreview: boolean = false): Promise<OriginCertificateFile> {
    if (id != null && id > 0) {
      try {
        let request: EllosRequest = {
          ellosApi: EllosApi.EasyTrade,
          resource: `OriginCertificate/${id}/PDF?isPreview=${isPreview}`,
          body: null
        };

        let response: any = await this.__ellosApi.get<OriginCertificateFile>(request);
        window.open(response.data);
        return response.data;
      } catch (exception) {
        console.log(exception);
        return null;
      }
    } else {
      console.log('Origin certificate id is required');
      return null;
    }
  }
  async downloadCertificates(ids: number[]): Promise<OriginCertificateFile[]> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `OriginCertificate/GetAll/PDF?listIds=${ids}&isPreview=false`,
        body: null
      };
      let response = await this.__ellosApi.get<OriginCertificateFile[]>(request);
      return response.data;
    } catch (exception) {
      console.log(exception);
      return null;
    }
  }
}
