export enum RegistrationCertificateOriginEnum {
  Invoice = 1,
  Companies = 2,
  Shipment = 3,
  Product = 4,
  Finish = 5,
  Completed = 6
}

export enum OriginCertificateStatusEnum {
  NotValidate = 0,
  InProcess = 1,
  WaitingPayment = 2,
  Registered = 3
}

export enum ShipmentTypeOriginEnum {
  Plane = 1,
  Ship = 2
}

export enum StepperEnum {
  Invoice = 0, // Invoice
  Companies = 1, // Companies
  Shipment = 2, // Shipment
  Product = 3, // Product
  Finish = 4 // Finish
}

export enum StepperEnumBlockChain {
  detailsa_and_requirements = 0,
  Invoice = 1, // Invoice
  Companies = 2, // Companies
  Shipment = 3, // Shipment
  Product = 4, // Product
  Finish = 5 // Finish
}
