import { createAction, props } from '@ngrx/store';
import { ProductToCertificate } from '../reducers/products-certificate.reducer';

export const enum productsToCertificateTypeAction {
  ADD_PRODUCT = '[PRODUCT_OF_CERTIFICATE] ADD PRODUCT',
  ADD_ALL_PRODUCT = '[PRODUCT_OF_CERTIFICATE] ADD ALL PRODUCT',
  REMOVE_PRODUCT_BY_INDEX = '[PRODUCT_OF_CERTIFICATE] REMOVE PRODUCT BY INDEX',
  UP_PRODUCT_BY_INDEX = '[PRODUCT_OF_CERTIFICATE] UP PRODUCT BY INDEX',
  DOWN_PRODUCT_BY_INDEX = '[PRODUCT_OF_CERTIFICATE] DOWN PRODUCT BY INDEX',
  CLEAN_PRODUCTS = '[PRODUCT_OF_CERTIFICATE]  CLEAN PRODUCTS',
  SET_PRODUCTS = '[PRODUCT_OF_CERTIFICATE]  SET PRODUCTS',
  VALIDATE_SIF_PRODUCTS = '[PRODUCT_OF_CERTIFICATE]  VALIDATE SIF'
}

export const AddAllProductToCertificate = createAction(productsToCertificateTypeAction.ADD_ALL_PRODUCT, props<{ _productToCertificate: ProductToCertificate[] }>());

export const AddProductToCertificate = createAction(productsToCertificateTypeAction.ADD_PRODUCT, props<{ _productToCertificate: ProductToCertificate }>());

export const DeleteproductToCertificateByIndex = createAction(productsToCertificateTypeAction.REMOVE_PRODUCT_BY_INDEX, props<{ _indexOfProductToCertificate: number }>());

export const UpProductToCertificate = createAction(productsToCertificateTypeAction.UP_PRODUCT_BY_INDEX, props<{ _indexOfProductToCertificate: number }>());

export const DownProductToCertificate = createAction(productsToCertificateTypeAction.DOWN_PRODUCT_BY_INDEX, props<{ _indexOfProductToCertificate: number }>());

export const CleanProductsToCertificate = createAction(productsToCertificateTypeAction.CLEAN_PRODUCTS);

export const SetProductsToCertificate = createAction(productsToCertificateTypeAction.SET_PRODUCTS, props<{ _productsToCertificate: ProductToCertificate[] }>());

export const ValidateSifProductsToCertificate = createAction(productsToCertificateTypeAction.VALIDATE_SIF_PRODUCTS);
