import { QueryProduct } from '../interfaces/queryProduct.interface';
import EllosAction from '../../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../../core/models/actionTypes';

export function queryProductReducer(state: QueryProduct, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.QueryNewProducts: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
