<mat-card
  appElevate
  [class]="themeName === 'ellos-dark-theme' ? 'card mat-elevation-z8' : 'card card-light mat-elevation-z8'"
  color="primary"
  (click)="matchmaking.lastStatus == 'Pending' && isMatchmaking && !matchmaking.idMatchmaking ? details(matchmaking.id) : confirmMeetingDialog()"
>
  <mat-card-content class="content" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div [class]="matchmaking.lastStatus + ' status'"></div>

    <div *ngIf="!matchmaking?.meetDate">
      <mat-icon class="icon">schedule</mat-icon>
    </div>

    <div class="date" *ngIf="matchmaking?.meetDate" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px">
      <p class="day">
        {{ day }}
      </p>
      <p class="month">{{ month }}</p>

      <mat-divider class="divider"></mat-divider>

      <p class="hour">{{ hour + 'h' }}</p>
    </div>
    <div *ngIf="!matchmaking?.meetDate && isMatchmaking">
      <div [class]="matchmaking.lastStatus + ' matchmaking-title'">
        <span>Matchmaking: </span>
        M-{{ matchmaking.idMatchmaking || matchmaking.id }}
      </div>
      <div class="matchmaking-content">
        <p class="category">
          {{ matchmaking.categoryName | truncate: 11 }} /
          {{ matchmaking.subcategoryName | truncate: 11 }}
        </p>
        <p>{{ 'matchmaking.waiting-appointment' | translate }}</p>
      </div>
    </div>
    <div *ngIf="matchmaking?.meetDate && isMatchmaking">
      <div class="matchmaking-content">
        <div [class]="matchmaking.lastStatus + ' matchmaking-title'">
          <span>Matchmaking: </span>
          M-{{ matchmaking.idMatchmaking || matchmaking.id }}
        </div>
        {{ matchmaking.categoryName | truncate: 11 }}{{ matchmaking.subcategoryName | truncate: 11 }}
        <span>{{ 'matchmaking.scheduled-with' | translate }}</span>
        <p>{{ matchmaking?.companyName | truncate: 35 }}</p>
      </div>
    </div>

    <div *ngIf="!isMatchmaking && (matchmaking.lastStatus == 'Pending' || matchmaking.lastStatus == 'PendingOrganizer' || matchmaking.lastStatus == 'PendingGuest')">
      <div>
        <b>{{ hour + 'h - ' }} {{ matchmaking?.companyName | truncate: 35 }}</b>
        <p>
          P-{{ matchmaking.idOrder }} -
          {{ matchmaking?.productName | truncate: 20 }}
        </p>
      </div>
    </div>

    <div *ngIf="!isMatchmaking && (matchmaking.lastStatus == 'Confirmed' || matchmaking.lastStatus == 'Completed' || matchmaking.lastStatus == 'Canceled')">
      <div>
        <b>{{ matchmaking?.companyName | truncate: 35 }}</b>
        <p>
          P-{{ matchmaking.idOrder }} -
          {{ matchmaking?.productName | truncate: 20 }}
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
