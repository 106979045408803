import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  contacts = [
    {
      phones: ['+55 (11) 3145-3200', '+55 (11) 3145-3240'],
      address: {
        location: 'settings.contact-and-help.address.sao-paulo.location',
        address: 'settings.contact-and-help.address.sao-paulo.address'
      }
    },
    {
      phones: ['+55 (47) 3075-0601', '+55 (47) 3075-0248'],
      address: {
        location: 'settings.contact-and-help.address.itajai.location',
        address: 'settings.contact-and-help.address.itajai.address'
      }
    },
    {
      phones: ['971 4 429 5885'],
      address: {
        location: 'settings.contact-and-help.address.dubai.location',
        address: 'settings.contact-and-help.address.dubai.address'
      }
    }
  ];
  constructor() {}

  ngOnInit(): void {}
}
