<div mat-dialog-title>
  <div fxLayout="row" fxLayoutAlign="center center ">
    <h1>{{ 'settings.companies.invite-user' | translate }}</h1>
  </div>
  <mat-divider></mat-divider>
</div>

<div mat-dialog-content class="content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <form fxLayout="column" class="form" fxLayout="center center" *ngIf="!loading">
    <div class="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field appearance="outline" class="input-email">
        <mat-label>Email</mat-label>

        <input matInput type="email" name="email" autocomplete="off" [disabled]="loading" [formControl]="email" (blur)="Email()" #inputEmail placeholder="Email" required />
        <app-dot-loader matSuffix *ngIf="searching"></app-dot-loader>
      </mat-form-field>

      <mat-form-field appearance="outline" class="select-role">
        <mat-label>{{ 'settings.companies.role' | translate }}</mat-label>
        <mat-select [formControl]="role" required [disabled]="loading" (ngModelChange)="searchForward()">
          <mat-option></mat-option>

          <mat-option value="ForwardingAgent">{{ 'settings.companies.forwarding-agent' | translate }} </mat-option>
          <mat-option value="Admin">{{ 'settings.companies.admin' | translate }}</mat-option>
          <mat-option value="Member">{{ 'settings.companies.member' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-name" *ngIf="!searching && role?.value === 'ForwardingAgent' && companies?.length > 0">
        <mat-label>{{ 'settings.companies.forwarding-company' | translate }}</mat-label>
        <mat-select [disabled]="loading" [formControl]="forwardingCompanyId" required>
          <mat-option></mat-option>

          <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-email" *ngIf="companies?.length == 0 && role?.value === 'ForwardingAgent'">
        <mat-label>CNPJ</mat-label>
        <input
          matInput
          type="text"
          mask="00.000.000/0000-00"
          autocomplete="off"
          [disabled]="loading"
          [formControl]="cnpj"
          [dropSpecialCharacters]="true"
          (input)="verificationCNPJ($event.target.value)"
          placeholder="CNPJ"
          required
        />
      </mat-form-field>

      <p [ngClass]="{ 'mat-error': !isValidCNPJ || companyNotFound }" *ngIf="errCNPJ && role?.value === 'ForwardingAgent'">{{ errCNPJ | translate }}</p>
    </div>
  </form>
</div>

<mat-divider></mat-divider>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="30px">
  <button mat-button mat-dialog-close>
    {{ 'settings.companies.cancel-button' | translate }}
  </button>
  <button mat-button [disabled]="disable()" (click)="submit()">
    {{ 'settings.companies.send-invitation-button' | translate }}
  </button>
</div>
