<div class="content">
  <section class="header">
    <section class="search" fxLayoutGap="20px" fxLayout="row wrap" fxLayoutAlign="space-between start">
      <app-search *ngIf="modules.includes(modulePermission.PesquisarReunioesMatchmaking)" class="searchMeeting" fxFlex="70" fxFlex.lt-sm="100" fxFlex.lt-md="100"></app-search>

      <div *ngIf="modules.includes(modulePermission.RequisicaoMatchmaking)" class="requestMeeting" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start center">
        <button mat-flat-button color="accent" class="button-schedule" (click)="scheduleMatchmakingDialog()">
          {{ ('matchmaking.button-request' | translate).toUpperCase() }}
        </button>
        <a mat-button class="what-matchmaking" color="accent" #tooltip="matTooltip" matTooltip="{{ 'matchmaking.matchmaking-info' | translate }}">
          {{ 'matchmaking.what-matchmaking' | translate }}
        </a>
      </div>
    </section>

    <section fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div [class]="themeName === 'ellos-dark-theme' ? 'button-group' : 'button-group-light'" fxLayout="row">
        <button class="button-search black-background" mat-flat-button routerLink="/Matchmaking/Meetings/Nexts" routerLinkActive="white-background">
          {{ ('matchmaking.pending-meetings' | translate).toUpperCase() }}
        </button>
        <button class="button-search black-background" mat-flat-button routerLink="/Matchmaking/Meetings/Pasts" routerLinkActive="white-background">
          {{ ('matchmaking.concluded-meetings' | translate).toUpperCase() }}
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="box box-waiting"></div>
        <p class="description">
          {{ 'matchmaking.schedule-to-confirm' | translate }}
        </p>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="box box-confirmed"></div>
        <p class="description">
          {{ 'matchmaking.scheduled-meeting' | translate }}
        </p>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div class="box box-concluded"></div>
        <p class="description">
          {{ 'matchmaking.concluded-completed' | translate }}
        </p>
      </div>
    </section>
  </section>

  <div *ngIf="modules.includes(modulePermission.VisualizarReunioes)" class="main">
    <router-outlet></router-outlet>
  </div>
</div>
