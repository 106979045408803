<mat-card class="main-card">
  <mat-card-header class="card-header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <app-profile-image class="profile-image" [saveEvent]="saveEvent" size="100" [edit]="edit"></app-profile-image>
    <mat-card-title class="title">{{ 'settings.profile.about-me' | translate }}</mat-card-title>
    <mat-progress-bar class="progress" mode="determinate" *ngIf="profilePercent" [value]="profilePercent" color="accent"> </mat-progress-bar>
    <p class="profile-percent" *ngIf="profilePercent">
      {{ 'settings.profile.profile' | translate }} {{ profilePercent }}%
      {{ 'settings.profile.complete' | translate }}
    </p>
  </mat-card-header>

  <mat-card-content *ngIf="!loading && !saving">
    <form [formGroup]="userEdit">
      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.firstname' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.firstName }}</p>

        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.firstname' | translate }}</mat-label>
          <input matInput formControlName="firstName" appearance="outline" autocomplete="off" />
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.lastname' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.lastName }}</p>

        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.lastname' | translate }}</mat-label>
          <input matInput formControlName="lastName" appearance="outline" autocomplete="off" />
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.jobTitle' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.jobTitle || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.jobTitle' | translate }}</mat-label>
          <input matInput formControlName="jobTitle" appearance="outline" autocomplete="off" />
        </mat-form-field>
      </div>
      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.birthdate' | translate }}:</p>
        <p *ngIf="!edit">{{ (user?.birthDate | ellosDate) || '-' }}</p>

        <mat-form-field appearance="outline" class="input full-width" *ngIf="edit">
          <mat-label>{{ 'registration.birthdate' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="birthDate" [max]="today" readonly (click)="picker.open()" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div> -->

      <mat-divider></mat-divider>

      <div *ngIf="!edit" fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.e-mail' | translate }}:</p>
        <p>{{ user?.email }}</p>
      </div>

      <div *ngIf="edit" fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.e-mail' | translate }}:</p>
        <mat-form-field appearance="outline" class="input full-width">
          <mat-label>{{ 'settings.profile.e-mail' | translate }}:</mat-label>
          <input matInput value="{{ user?.email }}" disabled="true" />
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.phone-number' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.phone || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.phone' | translate }}</mat-label>
          <ngx-mat-intl-tel-input class="input-phone" formControlName="phone" enableSearch="true" required> </ngx-mat-intl-tel-input>
        </mat-form-field>
      </div>
      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.street' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.street || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.street' | translate }}</mat-label>
          <input matInput appearance="outline" autocomplete="off" formControlName="street" />
        </mat-form-field>
      </div> -->
      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.complement' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.complement || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'settings.profile.complement' | translate }}</mat-label>

          <input matInput appearance="outline" autocomplete="off" formControlName="complement" />
        </mat-form-field>
      </div> -->
      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.city' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.city || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'settings.profile.city' | translate }}</mat-label>

          <input matInput appearance="outline" autocomplete="off" formControlName="city" />
        </mat-form-field>
      </div> -->
      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.district' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.district || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'settings.profile.district' | translate }}</mat-label>

          <input matInput appearance="outline" autocomplete="off" formControlName="district" />
        </mat-form-field>
      </div> -->
      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.state' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.state || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'settings.profile.state' | translate }}</mat-label>
          <input matInput appearance="outline" autocomplete="off" formControlName="state" maxlength="2" (input)="onSearchSize($event.target.value)" />
          <span *ngIf="user?.state && user?.state.length > 2 && !errorState" class="error">{{ 'error.max-caracter' | translate }}</span>
        </mat-form-field>
      </div> -->

      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.nationality' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.country.name || '-' }}</p>
        <div fxLayout="column" fxLayoutAlign="start start" class="country-area input full-width">
          <mat-error *ngIf="errorCountryValue() && edit">
            {{ 'registration.country-error' | translate }}
          </mat-error>
          <mat-select-country
            class="input-country"
            formControlName="country"
            appearance="outline"
            [placeHolder]="'registration.nationality' | translate"
            [label]="'registration.nationality' | translate"
            *ngIf="edit"
          >
          </mat-select-country>
        </div>
      </div> -->

      <!-- <mat-divider></mat-divider> -->
      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.zip-code' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.zipCode }}</p>

        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.zip-code' | translate }}</mat-label>
          <input matInput appearance="outline" autocomplete="off" formControlName="zipCode" />
        </mat-form-field>
      </div> -->
      <!-- <mat-divider></mat-divider> -->

      <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.po-box' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.poBox }}</p>

        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.po-box' | translate }}</mat-label>
          <input matInput appearance="outline" autocomplete="off" formControlName="poBox" />
        </mat-form-field>
      </div> -->

      <!-- <mat-divider></mat-divider> -->
    </form>
  </mat-card-content>
</mat-card>
