export enum UserPreferencesEnum {
  costumizedService = 'COS',
  anbaContent = 'ANB',
  consultancy = 'CON',
  tranlation = 'TRL',
  competitiveAnalysis = 'CPA',
  raAnalysis = 'RAA',
  layoutService = 'GLS',
  subtitling = 'SUB',
  transportAndShipping = 'ITB',
  brandPage = 'BRP'
}
