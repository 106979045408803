<form [formGroup]="invoiceForm">
  <!-- Line first -->
  <div class="w100" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px">
    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="20">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.invoice-number' | translate }}</mat-label>
      <input matInput autocomplete="off" required formControlName="invoiceNumber" trimInput maxlength="20" />
    </mat-form-field>

    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="30" (click)="emissionDate.open()">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.emission-date' | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="emissionDate"
        required
        [readonly]="disabledFieldsByDue"
        formControlName="emissionDate"
        [placeholder]="'easy-trade.new-trade.stepper.invoice_information.emission-date-placeholder' | translate"
      />
      <mat-datepicker-toggle matSuffix [for]="emissionDate"></mat-datepicker-toggle>
      <mat-datepicker #emissionDate [disabled]="disabledFieldsByDue"></mat-datepicker>
    </mat-form-field>
    <mat-select-country
      class="custom-country"
      color="accent"
      appearance="outline"
      fxFlex="30"
      (onCountrySelected)="onCountrySelected($event)"
      [countries]="predefinedCountries"
      [required]="true"
      appearance="outline"
      [placeHolder]="'easy-trade.new-trade.stepper.invoice_information.origin-country' | translate"
      [label]="'easy-trade.new-trade.stepper.invoice_information.origin-country' | translate"
      [formControl]="invoiceForm.controls['originCountry']"
    ></mat-select-country>

    <mat-select-country
      class="custom-country"
      color="accent"
      appearance="outline"
      fxFlex="30"
      (onCountrySelected)="onCountrySelected($event)"
      [countries]="predefinedCountries"
      [required]="true"
      appearance="outline"
      [placeHolder]="'easy-trade.new-trade.stepper.invoice_information.destiny-country' | translate"
      [label]="'easy-trade.new-trade.stepper.invoice_information.destiny-country' | translate"
      [formControl]="invoiceForm.controls['destinationCountry']"
    ></mat-select-country>

    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="20">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.certificate-language' | translate }}</mat-label>

      <mat-select [formControl]="invoiceForm.controls['certificateLanguage']" required>
        <mat-option [value]="defaultValue?.certificateLanguage" *ngIf="defaultValue && !readonlyFields">
          {{ defaultValue?.certificateLanguage?.name | dashIfNull }}
        </mat-option>
        <mat-option [value]="language" *ngFor="let language of languages">
          {{ language?.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Second line -->
  <div class="w100" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="1 1 auto">
      <mat-label #inputInconter>{{ 'easy-trade.new-trade.stepper.invoice_information.incoterm' | translate }}</mat-label>
      <mat-select formControlName="incoterm" required *ngIf="!isReviewStep">
        <mat-option *ngIf="defaultValue && !readonlyFields" [value]="defaultValue?.incoterm">
          {{ defaultValue?.incoterm?.toUpperCase() }}
        </mat-option>

        <mat-option *ngFor="let incoterm of incoterms" [value]="incoterm">
          {{ incoterm.toUpperCase() }}
        </mat-option>
      </mat-select>

      <input matInput autocomplete="off" *ngIf="isReviewStep" formControlName="incoterm" />
    </mat-form-field>

    <div id="cost-color" *ngIf="this.type != invoiceFormTypeEnum.NEW_CERTIFICATE" class="custom-item">
      <mat-form-field color="accent" appearance="outline" fxFlex="1 1 auto">
        <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.cost' | translate }} </mat-label>
        <input matInput formControlName="cost" currencyMask required [options]="{ prefix: 'USD ', thousands: '.', decimal: ',' }" placeholder="USD 0,00" />
      </mat-form-field>
    </div>

    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="1 1 auto">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.freight' | translate }} </mat-label>
      <input matInput formControlName="freight" currencyMask [options]="{ prefix: 'USD ', thousands: '.', decimal: ',' }" placeholder="USD 0,00" />
    </mat-form-field>
    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="1 1 auto">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.insurance' | translate }} </mat-label>
      <input matInput formControlName="insurance" currencyMask [options]="{ prefix: 'USD ', thousands: '.', decimal: ',' }" placeholder="USD 0,00" />
    </mat-form-field>

    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="1 1 auto">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.landinf_fee' | translate }} </mat-label>
      <input matInput formControlName="landinf_fee" currencyMask [options]="{ prefix: 'USD ', thousands: '.', decimal: ',' }" placeholder="USD 0,00" />
    </mat-form-field>

    <mat-form-field class="custom-item" color="accent" appearance="outline" fxFlex="1 1 auto">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.others' | translate }} </mat-label>
      <input matInput formControlName="others" currencyMask [options]="{ prefix: 'USD ', thousands: '.', decimal: ',' }" placeholder="USD 0,00" />
    </mat-form-field>
  </div>

  <!-- Third line -->
  <div fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start">
    <mat-form-field fxFlex="auto" color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.certificate-email' | translate }}</mat-label>
      <input matInput autocomplete="off" required formControlName="certificateEmail" type="email" />
    </mat-form-field>
  </div>
  <!-- Fourth line -->
  <div fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start">
    <mat-form-field fxFill color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.invoice_information.observations' | translate }}</mat-label>

      <textarea
        matInput
        cdkTextareaAutosize
        trimInput
        cdkAutosizeMaxRows="50"
        cdkAutosizeMinRows="5"
        maxlength="2870"
        (keyup)="onKeyAction($event)"
        (keydown)="onKeyAction($event)"
        formControlName="observations"
      ></textarea>
    </mat-form-field>
  </div>

  <div>
    <mat-checkbox
      class="example-margin"
      formControlName="hasCertificateBrazilianOrigin"
      required
      *ngIf="invoiceForm?.value?.originCountry?.alpha3Code == 'BRA' && !readonlyFields"
      >{{ 'easy-trade.new-trade.stepper.invoice_information.has-certificate-brazilian-origin' | translate }}</mat-checkbox
    >
  </div>
</form>
