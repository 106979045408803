import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Country } from 'src/app/shared/models/country';
import { SessionUser } from 'src/app/shared/models/session-user';
import { AssociateService } from 'src/app/shared/services/associate.service';
import { ProfileDetails } from '../../models/profile-details';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {
  @Input() edit: boolean = false;
  @Input() saving: boolean = false;
  user: ProfileDetails;
  profilePercent: number;
  profileImage$: Observable<string>;
  destroy$ = new Subject();

  userEdit = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    jobTitle: ['', [Validators.required]]
    //country: [null as Country, [Validators.required]],
    //birthDate: [''],
    //street: [''],
    //complement: [''],
    //city: [''],
    //state: [''],
    //district: [''],
    //poBox: [''],
    //zipCode: [''],
    //name: [''],
    //alpha2Code: [''],
    //numericCode: ['']
  });
  today = new Date();
  @Output() newUser = new EventEmitter<ProfileDetails>(false);
  @Output() valid = new EventEmitter<boolean>(false);
  @Input() saveEvent: Observable<boolean> = new Observable<boolean>();

  sessionUser$: Observable<SessionUser>;
  firstLoad: boolean = true;
  errorState: boolean = false;

  loading: boolean = false;
  constructor(
    private readonly associateService: AssociateService,
    private readonly router: Router,
    private readonly profileImageStore: Store<{ profileImage: string }>,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>,
    private readonly fb: FormBuilder
  ) {
    this.profileImage$ = this.profileImageStore.pipe(select('profileImage'));
    this.sessionUser$ = this.sessionUserStore.pipe(select('sessionUser'));
  }
  async ngOnInit() {
    try {
      this.loading = true;
      this.profileImage$.pipe(takeUntil(this.destroy$)).subscribe(async (url: string) => {
        if (url) {
          this.profilePercent = await this.associateService.getProfilePercent();
        }
      });
      this.sessionUser$.pipe(takeUntil(this.destroy$)).subscribe(async (_user) => {
        if (!this.firstLoad) {
          this.profilePercent = await this.associateService.getProfilePercent();
          this.user = await this.associateService.getProfileDetails();
        }
      });
      this.user = await this.associateService.getProfileDetails();

      this.userEdit.controls.firstName.setValue(this.user?.firstName);
      this.userEdit.controls.lastName.setValue(this.user?.lastName);
      this.userEdit.controls.phone.setValue(this.user?.phone);
      this.userEdit.controls.jobTitle.setValue(this.user?.jobTitle);
      this.userEdit.controls.email.setValue(this.user?.email);

      this.valid.emit(this.userEdit.valid);
    } catch (e) {
      this.router.navigateByUrl('/Home');
    } finally {
      this.loading = false;
    }

    this.userEdit.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.newUser.emit(user as ProfileDetails);
      this.valid.emit(this.userEdit.valid);
    });
    this.firstLoad = false;

    this.saveEvent.pipe(takeUntil(this.destroy$)).subscribe((_save) => {});
  }

  // errorCountryValue() {
  //   let countryCode = +this.userEdit.controls.country?.value.numericCode;
  //   if (!this.userEdit.controls.alpha2Code && !this.userEdit.controls.country?.value.name) {
  //     return true;
  //   } else {
  //     this.userEdit.controls.country.setValue(this.userEdit.controls.country.value);

  //     return false;
  //   }
  // }
  onSearchSize(searchValue: string): void {
    if (searchValue.length <= 2) {
      this.errorState = true;
    } else {
      this.errorState = false;
    }
  }
  // onCountrySelected(event) {
  //   this.userEdit.controls.country.setValue(event);
  // }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
