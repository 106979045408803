import { QueryListInvolvedCompanies } from '../Models/query-list-involved-companies';
import { Action, createAction, props } from '@ngrx/store';
import { ActionTypes } from '../../../core/models/actionTypes';

export const QueryListInvolvedCompaniesAction = (queryListInvolvedCompanies: QueryListInvolvedCompanies) => {
  return <Action>{
    type: ActionTypes.QueryListInvolvedCompanies,
    payload: queryListInvolvedCompanies
  };
};
export const pageIndex = createAction('[CompanyList] Page Index', props<{ PageIndex?: number }>());
export const pageConfig = createAction('[CompanyList] Page Config', props<{ PageIndex?: number; PageSize?: number }>());
