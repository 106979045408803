export enum PermissionsEnum {
  // HOME
  Home = 1,
  AlterarIdiomaHome = 2,
  AlterarEmpresaHome = 3,
  AcessarPerfilHome = 4,
  AlterarTemaHome = 5,
  VisualizarNotificacoesHome = 6,
  AcessarChatHome = 7,
  AcessarEasyTradeHome = 8,
  AcessarShowroomHome = 9,
  AcessarMatchmakingHome = 10,
  AcessarChamberClubHome = 11,
  AcessarContentHome = 12,
  AcessarB2BEventHome = 13,
  AcessarSettingsHome = 14,

  // EASYTRADE
  Easytrade = 15,
  NovaExportacaoEasytrade = 16,
  TradeChecklistEasytrade = 17,
  PesquisarCertificadosEasytrade = 18,
  VisualizarDetalhesCertificadoEasytrade = 19,
  EditarCertificadoEasytrade = 20,
  EnviarCertificadosEasytrade = 21,
  DeletarCertificadosEasytrade = 22,
  ImportarCertificadosEasytrade = 23,
  AutenticarCertificadosEasytrade = 24,
  DefinirCustoAutenticacaoEasytrade = 25,
  VisualizarProcessoBlockchainEasytrade = 26,
  ImprimirProcessoBlockchainEasytrade = 27,
  VisualizarTemplateEasytrade = 28,
  UsarTemplateEasytrade = 29,
  DeletarTemplateEasytrade = 30,

  // SHOWROOM
  ShowRoom = 31,
  PesquisarProdutosShowRoom = 32,
  VisualizarDetalhesProdutoShowRoom = 33,
  CompartilharProdutoShowRoom = 34,
  PesquisarServicosShowRoom = 35,
  VisualizarDetalhesServicoShowRoom = 36,
  CompartilharServicoShowRoom = 37,
  CostumizarMinhaLojaShowRoom = 38,
  NovoprodutoShowRoom = 39,
  EditarProdutoShowRoom = 40,
  NovoServicoShowRoom = 41,
  EditarServicoShowRoom = 42,
  EnviarPropostaShowRoom = 43,
  AdicionarFavoritosShowRoom = 44,
  VisualizarFavoritosShowRoom = 45,
  PesquisarNegociacoesShowRoom = 46,
  VisualizarNegociacaoShowRoom = 47,
  ClassificarNegociacaoShowRoom = 48,
  EditarReuniaoShowRoom = 49,
  CancelarNegociacaoShowRoom = 50,

  // MATCHMAKING
  Matchmaking = 51,
  PesquisarReunioesMatchmaking = 52,
  VisualizarReunioes = 53,
  RequisicaoMatchmaking = 54,
  PesquisarRequisicoesMatchmaking = 55,
  VisualizarDetalhesRequisicoesMatchmaking = 56,
  VisualizarCalendarioMatchmaking = 57,

  // CHAMBERCLUB
  ChamberClub = 58,
  DisponibilizarBeneficiosChamberClub = 59,
  PesquisaBeneficiosChamberClub = 60,
  VisualizarDetalheBeneficioChamberClub = 61,

  // CONTENT
  Content = 62,
  VisualizarNoticias = 63,
  PesquisarNoticias = 64,
  VisualizarEstudos = 65,
  PesquisarEstudos = 66,
  VisualizarInformacoesPaises = 67,
  VisualizarVideos = 68,
  PesquisarVideos = 69,
  VisualizarEventos = 70,
  InscricaoEventos = 71,

  // B2B EVENT
  B2BEvent = 72,
  VisualizarEvento = 73,
  PermiteInscricao = 74,
  RecebeConviteLoga = 75,
  RecebeConviteNaoLoga = 76,

  // SETTINGS
  Settings = 77,
  AceiteContrato = 78,
  RealizarPagamentoContrato = 79,
  EditarEmpresa = 80,
  DeixarEmpresa = 81,
  DeletarEmpresa = 82,
  BaixarCredencial = 83,
  EditarInformacoes = 84,
  DeletarConta = 85,
  SolicitarNovaEmpresa = 86,
  VisualizarEmpresas = 87,
  AdicionarNovoUsuarioEmpresa = 88,
  VisualizarConvites = 89,
  EnviarConvites = 90,
  AceitarRejeitarConvites = 91,
  VisualizarDetallhesConvite = 92,
  AcessarIntegracaoAPIs = 93,
  VisualizarInformacoesSeguranca = 94,
  EditarInformacoesSeguranca = 95,
  EntrarContatoCamara = 96,
  VisualizarDuvidasFrequentes = 97,
  SemEmpresaSolicitarNovaEmpresa = 98
}
