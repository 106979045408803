<div id="modal-content-wrapper">
  <header id="modal-header" fxLayoutAlign="center start">
    <h1>
      {{ 'easy-trade.monitoring.modal.edit-process.attention' | translate }}
    </h1>
  </header>

  <mat-divider></mat-divider>

  <section id="modal-body" class="body">
    <p class="send-data">{{ 'easy-trade.monitoring.modal.edit-process.send-data' | translate }}</p>
    <div class="error" *ngIf="awbNumberControl.touched && awbNumberControl.invalid">{{ 'form.required' | translate }}</div>
    <div>
      <mat-form-field fxFlex="15" color="accent" appearance="outline">
        <mat-label>{{ 'easy-trade.new-trade.stepper.shipment-information.awbNumber' | translate }}</mat-label>
        <input matInput [formControl]="awbNumberControl" OnlyNumber="true" />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field fxFlex="15" (click)="shippingDate.open()" color="accent" appearance="outline">
        <mat-label>{{ 'easy-trade.new-trade.stepper.shipment-information.shipping-date' | translate }}</mat-label>
        <input
          matInput
          readonly
          required
          [matDatepicker]="shippingDate"
          [formControl]="shippingDateControl"
          [placeholder]="'easy-trade.new-trade.stepper.shipment-information.shipping-date-placeholder' | translate"
        />
        <mat-datepicker-toggle matSuffix [for]="shippingDate"></mat-datepicker-toggle>
        <mat-datepicker #shippingDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="shippingDateControl.touched && shippingDateControl.invalid">{{ 'form.required' | translate }}.</div>
  </section>

  <mat-divider></mat-divider>

  <footer id="modal-footer">
    <div mat-dialog-actions fxLayoutAlign="end start">
      <button mat-raised-button class="button btn-actions" (click)="dialogRef.close(null)">{{ 'easy-trade.monitoring.modal.edit-process.cancel' | translate }}</button>
      <button
        mat-raised-button
        class="button btn-actions btn-confirm"
        [disabled]="(shippingDateControl.touched && shippingDateControl.invalid) || (awbNumberControl.touched && awbNumberControl.invalid)"
        (click)="updateCertificate()"
      >
        {{ 'easy-trade.monitoring.modal.edit-process.confirm' | translate }}
      </button>
    </div>
  </footer>
</div>
