<mat-toolbar class="mat-elevation-z8">
  <mat-toolbar-row>
    <img class="home-button" src="../../../assets/img/ellos-logo-colorful.png" alt="ellos-logo" />

    <span class="example-spacer"></span>

    <app-translate class="spacer" *ngIf="ShowTranslationDropdown || modules.includes(modulePermission.AlterarIdiomaHome)"></app-translate>
    <div class="company-selector spacer">
      <app-company-selector (notificationChange)="onCompanyChange($event)" *ngIf="showCompanySelector" tourAnchor="company"></app-company-selector>
    </div>

    <div *ngIf="showTimeZone">
      <div class="timezone spacer" (click)="navigateSettings()" *ngIf="validateTimeZone()" [hidden]="validateHiddenTimeZone()">
        <div class="button-menu" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px">
          <span class="menu-item"> <fa-icon [icon]="faGlobeAfrica"></fa-icon>{{ 'toolbar.timezone' | translate }} </span>

          <span class="menu-item"
            >GMT&nbsp;
            <span *ngIf="company?.timezone.offset > 0">+</span>
            {{ company?.timezone.offset }}&nbsp; {{ company?.timezone.name.replace('_', ' ') }}</span
          >
        </div>
      </div>
    </div>
    <!-- BOTÃO DE PAGAMENTO (COMENTADO ATÉ ESTRUTURA DE PAGAMENTO ESTAR PRONTA E VALIDADA) -->
    <button (click)="payment()" id="payment-button">
      <mat-icon class="payment-icon-size">payment</mat-icon>
    </button>

    <button type="button" id="notification-button" #toggleButton (click)="drawer.toggle()" [disabled]="!notificationValidation">
      <mat-icon [matBadge]="notificationsCount" matBadgeColor="accent" matBadgePosition="below after" [matBadgeHidden]="hidden" class="notification-icon-size">
        notifications
      </mat-icon>
    </button>

    <mat-drawer-container class="notification-container" [hasBackdrop]="true">
      <mat-drawer #drawer class="notification-sidenav" mode="side" position="end">
        <mat-list
          *ngIf="notificationsCount > 0"
          infinite-scroll
          [infiniteScrollDisabled]="companyExportationNotification?.length < 5 || loadingMore || !canLoadMore"
          [infiniteScrollDistance]="scrollDistance"
          [scrollWindow]="false"
          (scrolled)="onScrollDown()"
        >
          <mat-list-item class="notification-list-item" *ngFor="let notification of companyExportationNotification">
            <mat-icon id="notification-close" (click)="clearNotificationById(notification?.idNotification)">close</mat-icon>
            <div>
              <mat-icon id="notification-compare-arrows">compare_arrows</mat-icon>
              <h4 id="notification-easy-trade" matListItemTitle>Easy Trade</h4>
            </div>
            <div class="text-notification">
              <h1 matListItemTitle>{{ notification?.exportationNotification?.idInvoice }}</h1>
              <p id="status-notification">Status: {{ notification?.exportationNotification?.status }}</p>
              <p>{{ notification?.menssage?.text }}</p>
            </div>
            <h3 matListItemTitle>{{ notification?.language?.name }}</h3>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
        <span fxLayout="row" class="loader" fxLayoutAlign="center start" *ngIf="loadingMore">
          <mat-spinner align="center" color="accent" diameter="30"></mat-spinner>
        </span>
        <mat-divider></mat-divider>
        <div class="clear-all" (click)="clearAllNotifications()">Limpar todos</div>
      </mat-drawer>
    </mat-drawer-container>
    <ng-container *ngIf="user">
      <app-profile-image class="profile-image"></app-profile-image>

      <button mat-button [matMenuTriggerFor]="menu" tourAnchor="profile">{{ user?.name }}<mat-icon mat-line>arrow_drop_down</mat-icon></button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/Settings" id="my-profile-button"><mat-icon>person</mat-icon>{{ 'toolbar.my-profile' | translate }}</button>
        <button mat-menu-item (click)="changeTheme()" id="change-theme-button" *ngIf="modules.includes(modulePermission.AlterarTemaHome)">
          <mat-icon>wb_sunny</mat-icon>{{ currentThemeName | translate }}
        </button>
        <button mat-menu-item (click)="logout()" id="logout-button"><mat-icon>exit_to_app</mat-icon>{{ 'toolbar.logout' | translate }}</button>
      </mat-menu>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
<ng-template #jsonTemplate let-step="step">
  {{ step | json }}
</ng-template>
