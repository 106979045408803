import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeCompany } from 'src/app/core/actions/company.action';
import { DataLayerService } from '../../services/data-layer.service';
import { CompanyProfileEnum } from '../../utils/company.profile.enum';
import { CompanyService } from 'src/app/core/services/company.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { Company } from 'src/app/modules/easy-trade/actions/monitoring-query.action';
import { MonitoringService } from 'src/app/modules/easy-trade/services/monitoring.service';
import { MonitoringQuery } from 'src/app/modules/easy-trade/interfaces/monitoring-query.interface';
import { companyOriginCertificate } from 'src/app/modules/easy-trade/actions/origin-certificate-query.action';
import { OriginCertificateQuery } from 'src/app/modules/easy-trade/interfaces/OriginCertificateQuery.interface';
import { CompanyExportationNotification } from 'src/app/modules/certificates/interfaces/exportation-notification.interface';
import { EasyTradeService } from 'src/app/modules/easy-trade/services/easy-trade.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {
  companies: CompanyPreview[];
  selectedCompany: CompanyPreview;
  destroy$ = new Subject<any>();
  sessionCompanies$: Observable<CompanyPreview[]>;
  company$: Observable<CompanyPreview>;
  companiesLoaded = false;
  scrollDistance = 1;
  pageIndex: number = 0;
  pageSize: number = 15;
  menuClosed: boolean = true;
  loadingMore = false;
  canLoadMore: boolean = true;
  companyProfiles = CompanyProfileEnum;
  scrollTop: number = 0;
  isScrolled: boolean;
  public query: MonitoringQuery;
  public notifications: CompanyExportationNotification[] = [];
  @Output() notificationChange = new EventEmitter<CompanyExportationNotification[]>();

  constructor(
    private readonly companyService: CompanyService,
    private readonly companyStore: Store<CompanyPreview>,
    private readonly monitoringQueryStore: Store<{
      monitoringQuery: MonitoringQuery;
    }>,
    private readonly originCertificateQueryStore: Store<{
      originCertificateQuery: OriginCertificateQuery;
    }>,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>,
    private readonly companyState: Store<{ company: CompanyPreview }>,
    private permissionsService: PermissionsService,
    private __router: Router,
    public readonly dialog: MatDialog,
    public readonly dataLayerService: DataLayerService,
    private readonly monitoringService: MonitoringService,
    public readonly easyTradeService: EasyTradeService
  ) {
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
    this.company$ = this.companyState.pipe(select('company'));
  }

  async ngOnInit() {
    try {
      this.companiesLoaded = true;

      this.sessionCompanies$.pipe(takeUntil(this.destroy$)).subscribe(async (companies) => {
        this.companies = companies ? [...companies] : undefined;
        if (this.companies) this.verifyCompanies();
        this.companiesLoaded = true;
      });
      this.company$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
        if (company) {
          localStorage.setItem('haveExportation', JSON.stringify(false));
          this.changeCompany(company);
          this.monitoringService.haveExportations(company?.id, company?.profiles);
        }
      });
    } catch (e) {
      this.companies = [];
      this.changeCompany(this.companies[0]);
      this.monitoringService.haveExportations(this.companies[0]?.id, this.companies[0]?.profiles);
      this.companiesLoaded = true;
    }
  }

  async changeCompany(company: CompanyPreview) {
    this.selectedCompany = { ...company };
    let index = this.companies.findIndex((c) => c.id === company.id);
    let profile = this.getCompanyProfileToMonitoring();
    this.monitoringQueryStore.dispatch(Company({ IdCompany: this.selectedCompany.id, Profile: profile }));
    this.originCertificateQueryStore.dispatch(companyOriginCertificate({ idCompany: this.selectedCompany.id }));
    await this.companyService.EntityCompany(company.id);
    if (index >= 0) {
      this.selectedCompany.logo = this.companies[index].logo;

      this.selectedCompany.logo = this.getLogo(this.selectedCompany?.logo);

      this.dataLayerService.InitCompany();
      this.notifications = await this.easyTradeService.listCompanyExportationNotifications(company.id);
      this.notificationChange.emit(this.notifications);
    }
  }
  dispatchCompany(company: CompanyPreview) {
    let comp = { ...company };

    comp.logo = undefined;
    this.companyStore.dispatch(ChangeCompany(comp));
    this.changePermissions(company.id);
  }
  verifyCompanies() {
    if (this.selectedCompany) {
      let index = this.companies.findIndex((c) => c.id === this.selectedCompany.id);
      if (index < 0) {
        this.dispatchCompany(this.companies[0]);
      }
    }
  }
  async getCompanies() {
    let query = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize
    };
    this.loadingMore = true;

    let response = await this.companyService.getCompanies(query);
    if (response.length === 0) {
      this.pageIndex--;
      this.canLoadMore = false;
    }
    this.loadingMore = false;
  }

  async onScrollDown() {
    var objDiv = document.getElementById('companyList');

    if (objDiv.scrollTop === objDiv.scrollHeight - 170 && this.canLoadMore) {
      this.pageIndex++;
      await this.getCompanies();
      this.isScrolled = true;
      this.scrollTop = objDiv.scrollTop;
    }
    if (objDiv.scrollTop == 0 && this.isScrolled) {
      objDiv.scrollTop = this.scrollTop;
      this.isScrolled = false;
    }
  }
  getLogo(logo: string): string {
    if (logo === '' || !logo || logo == null) {
      return '../../../../assets/img/empty-company-logo.png';
    } else if (logo === '../../../../assets/img/empty-company-logo.png') {
      return logo;
    } else {
      try {
        let split = logo.split('data:image');

        return split.length === 1 ? 'data:image/png;base64,' + logo : logo;
      } catch (e) {
        return logo;
      }
    }
  }

  validateCompany(company: any) {
    if (company?.profiles[0] == 'IMP') {
      return false;
    } else {
      return true;
    }
  }

  getCompanyProfileToMonitoring(): CompanyProfileEnum {
    if (this.selectedCompany.profiles.includes(CompanyProfileEnum.Certifier)) return CompanyProfileEnum.Certifier;

    if (this.selectedCompany.profiles.includes(CompanyProfileEnum.Customs)) return CompanyProfileEnum.Customs;

    if (this.selectedCompany.profiles.includes(CompanyProfileEnum.BrazilianAuthority)) return CompanyProfileEnum.BrazilianAuthority;

    return CompanyProfileEnum.Exporter;
  }

  get exporterAndCertifier() {
    const hasCertifier = this.selectedCompany.profiles.includes(this.companyProfiles.Certifier);
    const hasExporter = this.selectedCompany.profiles.includes(this.companyProfiles.Exporter);
    return hasCertifier && hasExporter;
  }

  get isCertifier() {
    return this.selectedCompany.profiles.includes(this.companyProfiles.Certifier);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }

  public async changePermissions(id: number) {
    const dataUser = JSON.parse(localStorage.getItem('user'));
    if (id && dataUser) {
      try {
        const res = await this.permissionsService.getPermissions({
          idCompany: id,
          userEmail: dataUser.email
        });

        this.permissionsService.setPermissionStorage(res);
        if (this.selectedCompany?.profiles.includes('SHP')) {
          this.__router.navigate(['/EasyTrade/Monitoring/Exportations']);
        }
      } catch (error) {}
    }
  }
}
