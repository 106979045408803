import EllosAction from '../interfaces/ellosAction.interface';
import Theme from '../interfaces/theme.interface';
import { ActionTypes } from '../models/actionTypes';

export function themeReducer(state: Theme, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.ChangeTheme: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
