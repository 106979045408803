<div fxLayout="column" fxLayoutAlign="start start" fxFill>
  <h1 class="mat-h1 title" fxFill>
    {{ 'settings.companies.new-company' | translate }}
  </h1>

  <div class="container">
    <app-register-company
      class="content"
      (cnpjInvite)="cnpjInvite = $event"
      (emailInvite)="emailInvite = $event"
      (isRequestCompany)="isRequestCompany = $event"
      (isRequested)="requestAcessDialog($event)"
      (existingCompanyRequest)="existingCompanyRequest = $event"
      (requestInvite)="requestInvite = $event"
      (company)="company = $event"
      (finished)="requestAcessDialog($event)"
      (isAdmin)="isAdmin = $event"
      (companyFound)="companyFound = $event"
      (searchedCompany)="searchedCompany = $event"
    ></app-register-company>
  </div>
</div>
