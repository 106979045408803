import { Component, OnInit } from '@angular/core';
import Language from 'src/app/core/interfaces/language.interface';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Company } from 'src/app/core/interfaces/company.interface';

import { CompanyService } from 'src/app/core/services/company.service';
@Component({
  selector: 'app-certificate-navbar',
  templateUrl: './certificate-navbar.component.html',
  styleUrls: ['./certificate-navbar.component.scss']
})
export class certificateNavbarComponent implements OnInit {
  language$: Observable<Language>;
  destroy$ = new Subject();
  company$: Observable<Company>;
  currentCompany: Company;
  countOrders: number;

  constructor(
    private readonly companyStore: Store<{ company: Company }>,
    private readonly langStore: Store<{ language: Language }>,

    private readonly companyService: CompanyService
  ) {
    this.language$ = langStore.pipe(select('language'));
    this.company$ = companyStore.pipe(select('company'));
  }

  async ngOnInit(): Promise<any> {
    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {});

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((comp) => {
      this.currentCompany = comp;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
