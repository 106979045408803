<h2>
  {{ 'settings.contact-and-help.faq' | translate }}
</h2>

<mat-card>
  <mat-card-header fxLayout="row" fxLayoutAlign="center start">
    <mat-card-title>
      <h2 class="header">
        {{ 'settings.contact-and-help.description' | translate }}
      </h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-accordion class="headers-align">
      <mat-expansion-panel *ngFor="let question of questions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'settings.contact-and-help.question-and-answer.' + question.ask | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ 'settings.contact-and-help.question-and-answer.' + question.answer | translate }}
        </p>

        <p class="observation" *ngIf="question?.observation">
          {{ 'settings.contact-and-help.question-and-answer.' + question?.observation | translate }}
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
