<div class="conversation-area" *ngIf="idOrder > 0 || idMatchmaking > 0">
  <div fxLayout="column" fxLayoutAlign="space-around stretch" fxFill>
    <div fxFlex="100" style="padding: 10px">
      <app-ellos-spinner *ngIf="loadingMsg"></app-ellos-spinner>
      <mat-list fxFill fxLayout="column" fxLayoutAlign="start start" class="chat-messages">
        <div class="message-container" fxLayout="row" [fxLayoutAlign]="m?.idCompany === idCompany ? 'end start' : 'start start'" *ngFor="let m of messages">
          <div class="avatar" fxLayout="column" fxLayoutAlign="center center" *ngIf="m?.idCompany !== idCompany">
            <ngx-avatars [src]="images[m.idAssociate]"></ngx-avatars>
            <span class="name" [title]="m?.associateName">{{ m?.associateName | truncate: 15 }}</span>
          </div>
          <mat-list-item class="msg" fxFlex="70" [class.msg-from-me]="m?.idCompany === idCompany">
            <p [class.break-word]="m?.message.length > 36">{{ m?.message }}</p>
          </mat-list-item>
          <div class="avatar" fxLayout="column" fxLayoutAlign="center center" *ngIf="m?.idCompany === idCompany">
            <ngx-avatars [src]="images[m.idAssociate]"></ngx-avatars>
            <span class="name" [title]="m?.associateName">{{ m?.associateName | truncate: 12 }}</span>
          </div>
        </div>
        <mat-list-item class="msg-loading" *ngIf="sending">
          <mat-spinner [diameter]="30"></mat-spinner>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
<div style="padding: 10px" fxLayout="column" fxLayoutAlign="end end" *ngIf="idOrder > 0 || idMatchmaking > 0">
  <mat-form-field class="message-area" appearance="outline">
    <textarea
      matInput
      #input
      cdkFocusInitial
      [disabled]="sending"
      [readonly]="readonly"
      [disabled]="readonly"
      [placeholder]="'chat.message' | translate"
      [(ngModel)]="message"
      (keyup.enter)="send()"
    ></textarea>
    <button mat-icon-button matSuffix color="accent" [disabled]="sending || readonly" (click)="send()">
      <mat-icon>send</mat-icon>
    </button>
  </mat-form-field>
</div>
