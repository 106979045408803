<section>
  <form [formGroup]="subscribeForm" autocomplete="off" #form="ngForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start start">
    <div fxLayout="row" fxLayoutGap="15px" fxFill fxLayoutAlign="start start">
      <mat-form-field appearance="outline" color="accent" fxFlex="auto">
        <mat-label>{{ 'settings.companies.event.event-type' | translate }}</mat-label>
        <mat-select [disabled]="submiting" formControlName="event" required>
          <mat-option *ngIf="eventsType && eventsType.length == 0">{{ 'settings.companies.event.event-type-found' | translate }}</mat-option>
          <mat-option *ngFor="let event of eventsType" [value]="event.id">{{ event.description }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="select-http" color="accent">
        <mat-label>{{ 'settings.companies.event.http-method' | translate }} </mat-label>
        <mat-select [disabled]="submiting" formControlName="method" [disabled]="true">
          <mat-option value="POST">POST</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="15px" fxFill fxLayoutAlign="start start">
      <mat-form-field appearance="outline" color="accent" fxFlex="100">
        <mat-label>URL</mat-label>
        <input matInput type="url" [disabled]="submiting" appPreventEmoji required formControlName="apiUrl" />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
      <mat-form-field appearance="outline" color="accent" fxFlex="50">
        <mat-label>{{ 'settings.companies.event.auth-header' | translate }}</mat-label>
        <input matInput [disabled]="submiting" appPreventEmoji formControlName="authHeader" />
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent" fxFlex="50">
        <mat-label>{{ 'settings.companies.event.auth-header-value' | translate }}</mat-label>
        <input [disabled]="submiting" appPreventEmoji matInput formControlName="authHeaderValue" [type]="showPassword ? 'text' : 'password'" />

        <button mat-icon-button matSuffix type="button" (click)="toggleInput()">
          <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
</section>
