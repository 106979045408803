import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CompanyInvolved } from '../../Models/company-involved.interface';
@Component({
  selector: 'app-companies-involved',
  templateUrl: './companies-involved.component.html',
  styleUrls: ['./companies-involved.component.scss']
})
export class CompaniesInvolvedComponent implements OnInit {
  @Input() company: CompanyInvolved;
  constructor() {}

  ngOnInit(): void {}
}
