import { QueryCompanies } from '../models/query-companies';
import { ActionTypes } from '../../../core/models/actionTypes';
import EllosAction from '../../../core/interfaces/ellosAction.interface';

export function queryCompaniesReducer(state: QueryCompanies, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.QueryCompanies: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
