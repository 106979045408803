import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Country } from 'src/app/shared/models/country';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'src/app/core/services/company.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CompanyType, RegisterCompany } from 'src/app/shared/models/register-company';
import { allCountries } from 'src/app/core/models/all-countries';

@Component({
  selector: 'app-add-not-ellos-company-manager-dialog',
  templateUrl: './add-not-ellos-company-manager-dialog.component.html',
  styleUrls: ['./add-not-ellos-company-manager-dialog.component.scss']
})
export class AddNotEllosCompanyManagerDialogComponent implements OnInit {
  companyForm = this.fb.group({
    name: ['', [Validators.required, this.noWhitespaceValidator]],
    city: ['', [this.noWhitespaceValidator]],
    street: ['', [this.noWhitespaceValidator]],
    complement: ['', [this.noWhitespaceValidator]],
    poBox: ['', [Validators.required, this.noWhitespaceValidator]],
    vatNumber: [null, [this.noWhitespaceValidator]],
    countryAlpha3: [null as Country, [Validators.required]]
  });
  submiting: boolean;
  isEditMode: boolean = false;
  predefinedDestinationCountries: Country[] = [];

  constructor(
    private readonly dialogRef: MatDialogRef<AddNotEllosCompanyManagerDialogComponent>,
    private readonly companyService: CompanyService,
    private readonly countryService: CountryService,
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    if (this.data?.dataCompanyToEdit) {
      this.companyForm = this.fb.group({
        name: [this.data?.dataCompanyToEdit?.name, [Validators.required, this.noWhitespaceValidator]],
        city: [this.data?.dataCompanyToEdit?.city, [this.noWhitespaceValidator]],
        street: [this.data?.dataCompanyToEdit?.street, [this.noWhitespaceValidator]],
        complement: [this.data?.dataCompanyToEdit?.complement, [this.noWhitespaceValidator]],
        poBox: [this.data?.dataCompanyToEdit?.poBox, [Validators.required, this.noWhitespaceValidator]],
        vatNumber: [this.data?.dataCompanyToEdit?.vatNumber, [this.noWhitespaceValidator]],
        countryAlpha3: [this.data?.dataCompanyToEdit?.country, [Validators.required]]
      });
      this.isEditMode = true;
    }

    this.predefinedDestinationCountries = allCountries;
  }

  limitNumbers() {
    if (this.companyForm.controls.poBox.value.length > 10) {
      this.companyForm.controls.poBox.setValue(this.companyForm.controls.poBox.value.slice(0, 10));
    }
    return this.companyForm.value;
  }

  createPayload(): RegisterCompany {
    const currentCompany = JSON.parse(localStorage.getItem('current-company'));
    let payload: RegisterCompany = {
      countryNumericCode: this.companyForm.controls.countryAlpha3.value.numericCode,
      companyName: this.companyForm.controls.name.value,
      isForwardingAgent: false,
      isExporter: false,
      isImporter: true,
      companyType: CompanyType.ELLOS,
      addressDetails: {
        city: this.companyForm.controls.city.value,
        street: this.companyForm.controls.street.value,
        poBox: this.companyForm.controls.poBox.value,
        complement: this.companyForm.controls.complement.value
      },
      vatNumber: this.companyForm.controls.vatNumber.value,
      exporterId: currentCompany?.id
    };
    return payload;
  }

  async submit() {
    if (this.isEditMode) {
      this.editCompany();
    } else {
      try {
        this.submiting = true;
        await this.cleanForm(this.companyForm);
        if (this.companyForm.valid) {
          let payload = this.createPayload();
          let companyCreated = await this.companyService.registerCompany(payload);
          if (companyCreated) {
            this.notificationService.notifyDialog({
              success: true,
              closeAll: true,
              timeout: 3000
            });
          } else {
            this.notificationService.notifyDialog({
              success: false,
              closeAll: false,
              timeout: 3000
            });
          }
          this.submiting = false;
          if (companyCreated) {
            this.dialogRef.close();
          }
        }
      } catch (e) {
        this.notificationService.notifyDialog({
          success: false,
          closeAll: false,
          timeout: 3000
        });
      } finally {
        this.submiting = false;
      }
    }
  }

  async editCompany() {
    this.submiting = true;
    const idCountry = this.companyForm.get('countryAlpha3').value?.id;
    const companyToUpdate = {
      idCompany: this.data.dataCompanyToEdit?.id,
      name: this.companyForm.get('name').value,
      street: this.companyForm.get('street').value,
      city: this.companyForm.get('city').value,
      complement: this.companyForm.get('complement').value,
      poBox: this.companyForm.get('poBox').value,
      vatNumber: this.companyForm.get('vatNumber').value,
      idCountry: idCountry
    };
    let response = await this.companyService.updateInvolvedCompanie(companyToUpdate);
    this.submiting = false;
    if (response.success) {
      this.dialogRef.close();
    }
  }

  get requiredAddress() {
    return !this.companyForm.controls.poBox?.value;
  }
  get requiredPoBox() {
    return !this.companyForm.controls.street?.value;
  }
  get requiredVatNumber() {
    return this.companyForm?.get('countryAlpha3')?.value?.alpha3Code === 'EGY';
  }
  async cleanForm(formGroup: FormGroup) {
    for (let index = 0; index < Object.keys(formGroup.controls).length; index++) {
      let key = Object.keys(formGroup.controls)[index];
      if (typeof formGroup.get(key)?.value == 'string') {
        formGroup.get(key).setValue(formGroup.get(key)?.value?.trim());
      }
    }
    return this.validForm;
  }

  get validForm() {
    return this.companyForm.controls.name.value.trim;
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = control.value?.trim()?.length === 0;
    const isValid = !isWhitespace || control.value == '';
    return isValid ? null : { whitespace: true };
  }
}
