import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { Invite } from '../../models/invite';
import { InviteResponse } from '../../models/invite-response.interface';
import { InviteService } from '../../services/invite.service';
import { InviteActionDialogComponent } from '../invite-action-dialog/invite-action-dialog.component';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';

@Component({
  selector: 'app-recieved-invitations',
  templateUrl: './recieved-invitations.component.html',
  styleUrls: ['./recieved-invitations.component.scss']
})
export class RecievedInvitationsComponent implements OnInit {
  public modules = [];
  public modulePermission = PermissionsEnum;
  response: InviteResponse;
  pageIndex = 0;
  pageSize = 10;
  loading: boolean = false;
  faBuilding = faBuilding;
  dataSource: MatTableDataSource<Invite> = new MatTableDataSource<Invite>();
  displayedColumns: string[] = ['companyName', 'date', 'myRole', 'country', 'actions'];

  constructor(private readonly inviteService: InviteService, private readonly dialog: MatDialog, private readonly permissions: PermissionsService) {}

  async ngOnInit() {
    this.modules = this.permissions.getPermissionStorage();
    await this.getMyInvites();
  }
  async pageChange(event) {
    this.pageIndex = event.pageIndex;
    await this.getMyInvites();
  }

  async getMyInvites() {
    this.loading = true;
    try {
      this.response = await this.inviteService.getReceivedInvites(this.pageIndex, this.pageSize);
      this.dataSource.data = this.response.invites;
    } catch (e) {
      this.dataSource.data = [];
    } finally {
      this.loading = false;
    }
  }

  openAction(action: string, invite: Invite) {
    let dialog$ = this.dialog.open(InviteActionDialogComponent, {
      data: {
        action: action,
        invite: invite
      }
    });

    dialog$
      .afterClosed()
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.removeInvite(invite.id);
        }
      });
  }

  removeInvite(idInvite: number) {
    let data = [...this.dataSource.data];
    let index = data.findIndex((i) => i.id === idInvite);
    if (index >= 0) {
      data.splice(index, 1);
      this.response.invitesCount -= 1;
      this.dataSource.data = data;
    }
  }
}
