import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ScheduleMeetingDialogComponent } from '../schedule-meeting-dialog/schedule-meeting-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyService } from 'src/app/core/services/company.service';
import { Company } from 'src/app/core/interfaces/company.interface';
import { MeetingDetails } from 'src/app/modules/matchmaking/models/meeting-details';
import { MeetingService } from 'src/app/modules/matchmaking/services/meeting.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Meeting } from '../../../matchmaking/interfaces/meeting.interface';
import { NewMeeting } from '../../../matchmaking/actions/meeting.action';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { PermissionsService } from 'src/app/core/services/permissions.service';
@Component({
  selector: 'app-confirm-meeting-dialog',
  templateUrl: './confirm-meeting-dialog.component.html',
  styleUrls: ['./confirm-meeting-dialog.component.scss']
})
export class ConfirmMeetingDialogComponent implements OnInit, OnDestroy {
  unsb$: Subject<any> = new Subject();
  date;
  time;

  currentCompany: Company;
  meeting: MeetingDetails;

  editing = false;

  public modulePermission = PermissionsEnum;
  public modules = [];

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmMeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private readonly companyService: CompanyService,
    private readonly meetingService: MeetingService,
    private readonly meetingState: Store<Meeting>,
    private readonly router: Router,
    private permissionsService: PermissionsService
  ) {
    this.currentCompany = this.companyService.getCurrentCompany();
  }

  async ngOnInit(): Promise<any> {
    try {
      this.meeting = await this.meetingService.meetingDetails(this.data?.meetingPreview?.id || this.data?.id);
      this.hourAdjusts();
    } catch (e) {
      this.dialogRef.close();
    }
    this.modules = this.permissionsService.getPermissionStorage();
  }
  onNoClick() {
    this.dialogRef.close();
  }

  scheduleMeetingDialog(element): void {
    const dialogRef = this.dialog.open(ScheduleMeetingDialogComponent, {
      width: '60%',
      disableClose: true,

      data: { ...element, idCompanyGuest: this.meeting.idCompanyGuest }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsb$))
      .subscribe((result) => {
        if (result) {
          this.dialogRef.close(result);
        }
      });
  }

  ngOnDestroy() {
    this.unsb$.next(null);
  }

  async attendMeeting(status: boolean): Promise<any> {
    try {
      this.editing = true;
      this.meeting = await this.meetingService.attendMeeting({
        idCompany: this.currentCompany.id,
        idMeeting: this.meeting.id,
        willAttend: status
      });

      if (this.data.meetingPreview?.meetingStatus) {
        this.data.meetingPreview.meetingStatus = this.meeting.lastStatus;
      } else {
        this.data.lastStatus = this.meeting.lastStatus;
      }

      this.hourAdjusts();
      this.editing = false;
    } catch (e) {
      this.onNoClick();
    }
  }
  async cancelSchedule() {
    try {
      this.editing = true;
      this.meeting = await this.meetingService.cancelSchedule(this.meeting.id);

      if (this.data.meetingPreview?.meetingStatus) {
        this.data.meetingPreview.meetingStatus = this.meeting.lastStatus;
      } else {
        this.data.lastStatus = this.meeting.lastStatus;
      }
      this.hourAdjusts();
      this.editing = false;
    } catch (e) {
      this.onNoClick();
    }
  }

  hourAdjusts() {
    const offset = new Date().getTimezoneOffset();
    const dataLocal = new Date(new Date(this.meeting.date).getTime() - offset * 60 * 1000);
    this.meeting.date = new Date(new Date(this.meeting.date).getTime() - offset * 60 * 1000);
    this.date = new Date(dataLocal).toString();
    this.time = new Date(dataLocal).toLocaleTimeString(navigator.language);
  }

  joinMeeting() {
    this.meetingState.dispatch(
      NewMeeting({
        id: this.meeting.roomId,
        order: this.data
      })
    );

    this.router.navigateByUrl('/Matchmaking/Meetings/MeetingRoom');
    this.onNoClick();
  }
}
