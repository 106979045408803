import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsGuard } from './core/guard/settings.guard';
import { AuthGuard } from './modules/auth/auth-guard.service';
import { EasyTradeGuard } from './core/guard/easy-trade.guard';
import { MatchmakingGuard } from './core/guard/matchmaking.guard';
import { MarketplaceGuard } from './core/guard/marketplace.guard';

import { CompanyResolver } from './core/resolver/company.resolver';
import { LoginComponent } from './modules/auth/pages/login/login.component';
import { ManualComponent } from './shared/components/manual/manual.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { S3FilesComponent } from './shared/components/s3-files/s3-files.component';
import { RegisterComponent } from './modules/auth/pages/register/register.component';
import { NotAuthLayoutComponent } from './layout/not-auth-layout/not-auth-layout.component';
import { AnonymousLayoutComponent } from './layout/anonymous-layout/anonymous-layout.component';
import { ConfirmAccountComponent } from './modules/auth/pages/confirm-account/confirm-account.component';
import { ForgotPasswordComponent } from './modules/auth/pages/forgot-password/forgot-password.component';
import { NotAuthLayoutSocialMediaComponent } from './layout/not-auth-layout-social-media/not-auth-layout-social-media.component';

import { LoginGuard } from './modules/auth/login-guard.service';
import { NotCompaniesGuard } from './modules/auth/not-companies-guard.service';
import { DevEnvironmentGuard } from './modules/auth/dev-environmet-guard.service';
import { CreateAccountCompany } from './modules/auth/pages/create-account-company/create-account-company.component';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    resolve: [CompanyResolver],
    children: [
      {
        path: '',
        redirectTo: '/Home',
        pathMatch: 'full'
      },
      {
        path: 'Home',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'Marketplace',
        data: {
          breadcrumb: 'marketplace.self'
        },
        loadChildren: () => import('./modules/marketplace/marketplace.module').then((m) => m.MarketplaceModule),
        canActivate: [AuthGuard, NotCompaniesGuard, DevEnvironmentGuard, MarketplaceGuard]
      },
      {
        path: 'EasyTrade',
        data: {
          breadcrumb: 'easy-trade.self'
        },
        loadChildren: () => import('./modules/easy-trade/easy-trade.module').then((m) => m.EasyTradeModule),
        canActivate: [AuthGuard, NotCompaniesGuard, EasyTradeGuard]
      },
      {
        path: 'Certificates',
        data: {
          breadcrumb: 'easy-trade.self'
        },
        loadChildren: () => import('./modules/certificates/certificates.module').then((m) => m.CertificatesModule),
        canActivate: []
      },
      {
        path: 'Matchmaking',
        loadChildren: () => import('./modules/matchmaking/matchmaking.module').then((m) => m.MatchmakingModule),
        canActivate: [AuthGuard, NotCompaniesGuard, DevEnvironmentGuard, MatchmakingGuard]
      },
      {
        path: 'Payment',
        data: {
          breadcrumb: 'payment.base'
        },
        loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule),
        canActivate: []
      },
      {
        path: 'Settings',
        data: {
          breadcrumb: 'settings.self'
        },
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard, SettingsGuard]
      }
    ]
  },
  {
    path: 'news/:file',
    component: S3FilesComponent
  },
  {
    path: 'Manual/pt',
    component: ManualComponent
  },
  {
    path: 'Step-by-step/pt',
    component: ManualComponent
  },
  {
    path: 'Manual/en',
    component: ManualComponent
  },
  {
    path: 'Step-by-step/en',
    component: ManualComponent
  },
  {
    path: '',
    component: AnonymousLayoutComponent,
    children: [
      {
        path: 'Login',
        component: LoginComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'Register',
        component: CreateAccountCompany
      },
      {
        path: 'Invite/:inviteCode',
        component: RegisterComponent
      },
      {
        path: 'Confirm-account/:email',
        component: ConfirmAccountComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'Forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [LoginGuard]
      }
    ]
  },
  {
    path: '',
    component: NotAuthLayoutComponent
  },
  {
    path: '',
    component: NotAuthLayoutSocialMediaComponent,
    children: [
      {
        path: 'authenticity',
        loadChildren: () => import('./modules/authenticity/authenticity.module').then((m) => m.AuthenticityModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/Home'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
