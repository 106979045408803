import { Action, createReducer, on } from '@ngrx/store';
import * as MonitoringAction from '../actions/monitoring-query.action';
import { MonitoringQuery } from '../interfaces/monitoring-query.interface';

export const initialState: MonitoringQuery = {
  PageIndex: 0,
  PageSize: 10,
  IdCompany: undefined,
  EndDate: undefined,
  InitDate: undefined,
  InvoiceId: [],
  OriginCertificateNumber: [],
  Status: [],
  Profile: undefined,
  Repeat: false
};

const monitoringQuery = createReducer(
  initialState,
  on(MonitoringAction.date, (state, action) => {
    return {
      ...state,
      InitDate: action.InitDate,
      EndDate: action.EndDate,
      PageIndex: 0
    };
  }),
  on(MonitoringAction.invoiceNumber, (state, action) => {
    return { ...state, InvoiceId: action.InvoiceId, PageIndex: 0 };
  }),
  on(MonitoringAction.originCertificateNumber, (state, action) => {
    return {
      ...state,
      OriginCertificateNumber: action.OriginCertificateNumber,
      PageIndex: 0
    };
  }),
  on(MonitoringAction.pageIndex, (state, action) => {
    return {
      ...state,
      PageIndex: action.PageIndex
    };
  }),
  on(MonitoringAction.pageConfig, (state, action) => {
    return { ...state, PageIndex: action.PageIndex, PageSize: action.PageSize };
  }),
  on(MonitoringAction.statusAction, (state, action) => {
    return { ...state, Status: action.Status, PageIndex: 0 };
  }),
  on(MonitoringAction.Company, (state, action) => {
    return {
      ...initialState,
      IdCompany: action.IdCompany,
      Profile: action.Profile,
      PageIndex: 0
    };
  }),
  on(MonitoringAction.companyProfile, (state, action) => {
    return {
      ...initialState,
      IdCompany: state.IdCompany,
      Profile: action.Profile,
      PageIndex: 0
    };
  }),
  on(MonitoringAction.clearFilter, (state, action) => {
    return {
      ...initialState,
      IdCompany: state.IdCompany,
      Profile: state.Profile
    };
  }),
  on(MonitoringAction.repeatQuery, (state, action) => {
    return { ...state };
  })
);

export function monitoringQueryReducer(state: MonitoringQuery | undefined, action: Action) {
  return monitoringQuery(state, action);
}
