import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Theme from 'src/app/core/interfaces/theme.interface';
import { QueryNewMatchmaking } from '../../actions/matchmaking.action';
import { ScheduleMatchmakingDialogComponent } from '../../components/schedule-matchmaking-dialog/schedule-matchmaking-dialog.component';
import { SearchComponent } from '../../components/search/search.component';
import { QueryMatchmaking } from '../../interfaces/queryMatchmaking.interface';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {
  public modules = [];
  theme$: Observable<Theme>;
  destroy$ = new Subject();
  themeName: string;

  search: SearchComponent;
  queryMatchmaking: QueryMatchmaking;
  queryMatchmaking$: Observable<QueryMatchmaking>;
  public modulePermission = PermissionsEnum;

  constructor(
    private readonly companyStore: Store<{ theme: Theme }>,
    private readonly dialog: MatDialog,
    private readonly matchmakingStore: Store<{
      queryMatchmaking: QueryMatchmaking;
    }>,
    private readonly matchmakingState: Store<QueryMatchmaking>,
    private readonly router: Router,
    private readonly permissions: PermissionsService
  ) {
    this.queryMatchmaking$ = matchmakingStore.pipe(select('queryMatchmaking'));
  }

  ngOnInit(): void {
    this.modules = this.permissions.getPermissionStorage();
    this.theme$ = this.companyStore.pipe(select('theme'));
    this.theme$.pipe(takeUntil(this.destroy$)).subscribe(async (theme: Theme) => {
      this.themeName = theme.name;
    });

    this.queryMatchmaking$.pipe(takeUntil(this.destroy$)).subscribe(async (queryMatch: QueryMatchmaking) => {
      this.queryMatchmaking = queryMatch;
    });
  }

  scheduleMatchmakingDialog(): void {
    const dialogRef = this.dialog.open(ScheduleMatchmakingDialogComponent, {
      width: '862px',
      height: 'fit-content',
      maxHeight: '100vh',
      disableClose: true,
      data: {}
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.reload();
        }
      });
  }

  reload() {
    let query: QueryMatchmaking = {
      IdCompany: this.queryMatchmaking.IdCompany,
      LanguageCode: this.queryMatchmaking.LanguageCode,
      MainFilter: this.queryMatchmaking.MainFilter,
      EndDate: this.queryMatchmaking.EndDate,
      InitDate: this.queryMatchmaking.InitDate,
      HistoricalData: this.queryMatchmaking.HistoricalData,
      PageSize: this.queryMatchmaking.PageSize,
      PageIndex: 0,
      filter: this.queryMatchmaking.filter
    };

    this.matchmakingState.dispatch(QueryNewMatchmaking(query));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
