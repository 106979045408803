import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  injectScript(gtmId: string, gtmEnabled: boolean) {
    if (gtmEnabled) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s) as HTMLScriptElement,
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmId);
    }
  }

  gtmScriptExists(): boolean {
    var scripts = document.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') === 'https://www.googletagmanager.com/gtm.js?id=GTM-PKZLWQ3') {
        return true;
      }
      return false;
    }
  }
}
