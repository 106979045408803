<div fxLayout="row" fxFlexFill class="register-container ccab-container">
  <!-- ccab image container  -->
  <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" fxFlex.lt-md="none" class="ccab-background-image">
    <img class="img" src="../../../assets/img/login-ccab-logo.png" alt="ccab logo" />
  </div>

  <!-- form container -->
  <div flxLayout="column" class="space" fxFlex="50" fxFlex.lt-md="100" class="ccab-form">
    <!-- header row -->
    <div fxLayout="row" fxLayoutAlign="end start">
      <div fxLayout="column" fxLayoutAlign="start start" fxFlex="50">
        <button mat-icon-button routerLink="/Login" *ngIf="!isLogginPage">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div fxLayout="column" fxLayoutAlign="start end" fxFlex="50">
        <app-translate class="translate-menu"></app-translate>
      </div>
      <br />
    </div>

    <!-- form -->
    <router-outlet></router-outlet>

    <!-- footer image -->
    <div fxLayout="column" fxLayoutAlign="start center">
      <br />
      <img class="logo-image" src="assets/img/ellos-logo-white.png" alt="Ellos logo" />
      <br />
      <ng-container *ngIf="isLogginPage">
        <ng-container *ngIf="isPt">
          <a mat-button color="accent" routerLink="/Manual/pt">
            {{ 'registration.download-manual' | translate }}
          </a>

          <a mat-button color="accent" routerLink="/Step-by-step/pt">
            {{ 'registration.step-by-step' | translate }}
          </a>
        </ng-container>
        <ng-container *ngIf="!isPt">
          <a mat-button color="accent" routerLink="/Manual/en">
            {{ 'registration.download-manual' | translate }}
          </a>

          <a mat-button color="accent" routerLink="/Step-by-step/en">
            {{ 'registration.step-by-step' | translate }}
          </a>
        </ng-container>
      </ng-container>
      <br />
    </div>
  </div>
</div>
