import { QueryProposal } from '../interfaces/queryProposal.interface';
import EllosAction from '../../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../../core/models/actionTypes';

export function queryProposalReducer(state: QueryProposal, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.QueryNewProposal: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
