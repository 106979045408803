import { Ncm } from 'src/app/shared/models/ncm.interface';
import { moveElementOfArray } from 'src/app/shared/utils/move-element-of-array';
import { UnitMeasure } from '../../marketplace/interfaces/unitMeasure.interface';
import * as ProductToCertificateAction from '../actions/products-certificate.action';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export interface ProductToCertificate {
  id?: number;
  ncm: Ncm;
  unitMeasure: UnitMeasure;
  unitMeasureWeight: UnitMeasure;
  certificateId?: number;
  quantity: number;
  description: string;
  expirationDate: string;
  grossWeight: number;
  idUnitMeasure?: number;
  idUnitMeasureWeight?: number;
  manufactoringDate: string;
  marksAndNumbers?: string;
  netWeight: number;
  sif?: number;
  productValue?: number;
  currency: number;
  order?: number;
}

export interface ProductToCertificateState {
  productToCertificate: ProductToCertificate[];
}

export const initialState: ProductToCertificateState = { productToCertificate: [] };

const _productToCertificate = createReducer(
  initialState,
  on(ProductToCertificateAction.AddProductToCertificate, (state, { _productToCertificate }) => {
    return {
      ...state,
      productToCertificate: [...state.productToCertificate, _productToCertificate].sort((a) => a?.order)
    };
  }),
  on(ProductToCertificateAction.AddAllProductToCertificate, (state, { _productToCertificate }) => {
    return {
      ...state,
      productToCertificate: _productToCertificate
    };
  }),
  on(ProductToCertificateAction.DeleteproductToCertificateByIndex, (state, { _indexOfProductToCertificate }) => ({
    ...state,
    productToCertificate: orderToCertificateReducer([...state.productToCertificate], _indexOfProductToCertificate)
  })),
  on(ProductToCertificateAction.UpProductToCertificate, (state, { _indexOfProductToCertificate }) => ({
    ...state,
    productToCertificate: moveElementOfArray([...state.productToCertificate], _indexOfProductToCertificate, _indexOfProductToCertificate - 1).sort((a) => a?.order)
  })),
  on(ProductToCertificateAction.DownProductToCertificate, (state, { _indexOfProductToCertificate }) => ({
    ...state,
    productToCertificate: moveElementOfArray([...state.productToCertificate], _indexOfProductToCertificate, _indexOfProductToCertificate + 1).sort((a) => a?.order)
  })),
  on(ProductToCertificateAction.CleanProductsToCertificate, (state) => ({
    ...state,
    productToCertificate: []
  })),
  on(ProductToCertificateAction.ValidateSifProductsToCertificate, (state) => ({
    ...state,
    productToCertificate: validateSif([...state.productToCertificate])
  }))
);

export function productToCertificateReducer(state = initialState, action: Action) {
  return _productToCertificate(state, action);
}

export function validateSif(productToCertificate: ProductToCertificate[]): ProductToCertificate[] {
  productToCertificate.forEach((element) => {
    element.sif = element.sif != null ? element.sif : undefined;
  });
  return productToCertificate;
}

export function orderToCertificateReducer(productToCertificate: ProductToCertificate[], index): ProductToCertificate[] {
  productToCertificate.splice(index, 1);
  for (let i = 0; i < productToCertificate.length; i++) {
    const order = i + 1;
    const updatedProduct = { ...productToCertificate[i], order: order };
    productToCertificate[i] = updatedProduct;
  }
  return productToCertificate;
}

const getProductToCertificateState = createFeatureSelector<ProductToCertificateState>('productToCertificate');

export const getProductToCertificate = createSelector(getProductToCertificateState, (state: ProductToCertificateState) => state.productToCertificate);
