import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-request-access-admin-dialog',
  templateUrl: './request-access-admin-dialog.component.html',
  styleUrls: ['./request-access-admin-dialog.component.scss']
})
export class RequestAccessAdminDialogComponent implements OnInit {
  constructor(private readonly dialogRef: MatDialogRef<RequestAccessAdminDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}
}
