import { Injectable } from '@angular/core';

import { EllosApiService } from '../../core/http/ellosApi.service';
import EllosRequest from '../../core/interfaces/ellosRequest.interface';
import { EllosApi } from '../../core/interfaces/ellosApi.interface';
import { Message } from '../models/message';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async sendMessage(message: { idOrder: number; message: string; idCompany: number }): Promise<boolean> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/Message`,
      body: message
    };
    let response;
    response = await this.ellosApi.post<boolean>(request);

    return response.success;
  }
  async getMessage(idOrder: number): Promise<Message[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Order/Message/${idOrder}`,
      body: null
    };
    let response;
    response = await this.ellosApi.get<Message[]>(request);

    return response.data == null ? [] : response.data.messages;
  }
  async sendMessageMatchmaking(message: { idMatchmaking: number; message: string; idCompany: number }): Promise<boolean> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Matchmaking/message`,
      body: message
    };
    let response;
    response = await this.ellosApi.post<boolean>(request);

    return response.success;
  }
  async getMessageMatchmaking(idMatchmaking: number): Promise<Message[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Meeting,
      resource: `Matchmaking/message/${idMatchmaking}`,
      body: null
    };
    let response;
    response = await this.ellosApi.get<Message[]>(request);

    return response.data == null ? [] : response.data.messages;
  }
}
