export const parseJwt = (token): TokenModel => {
  if (token) {
    const base64 = token?.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    const objJson = JSON.parse(jsonPayload);
    const objReturn = new TokenModel();

    objReturn.authTime = objJson?.auth_time ? new Date(objJson.auth_time * 1000) : null;
    objReturn.expTime = objJson?.exp ? new Date(objJson.exp * 1000) : null;
    objReturn.cognitoUsername = objJson?.cognito_username;
    objReturn.aud = objJson?.aud;
    objReturn.name = objJson?.name;
    objReturn.email = objJson?.email;
    objReturn.emailVerified = objJson?.email_verified;

    return objReturn;
  }
};

export class TokenModel {
  cognitoUsername? = '';
  authTime?: Date;
  aud? = '';
  expTime?: Date;
  name? = '';
  email? = '';
  emailVerified? = false;
}
