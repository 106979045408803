import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { SelectCard } from 'src/app/shared/models/select-card.interface';

@Component({
  selector: 'app-card-option',
  templateUrl: './card-option.component.html',
  styleUrls: ['./card-option.component.scss']
})
export class CardOptionComponent implements OnInit {
  @Output() changed = new EventEmitter<boolean>();
  @Input() checked: boolean;
  @Input() option: SelectCard<any>;
  @ViewChild('check') check: MatCheckbox;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  emitValue(checked: boolean) {
    this.changed.emit(checked);
  }
}
