<div class="fixed-field" fxLayout="column">
  <div fxLayoutGap="20px" fxLayoutGap.lt-sm="0" fxLayout="row wrap" fxLayoutAlign="start">
    <mat-form-field class="search-input" color="accent" fxFlex="50" fxFlex.lt-sm="100" fxFlex.lt-md="60" appearance="outline">
      <mat-label>{{ 'settings.companies.search-label' | translate }}</mat-label>
      <input matInput name="searchInput" autocomplete="off" #searchInput />
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-sm="100" fxFlex.lt-md="30">
      <mat-label>{{ 'settings.companies.filter' | translate }}</mat-label>
      <mat-select [(value)]="profile" (selectionChange)="profileFilter($event)">
        <mat-option selected>{{ 'settings.companies.all' | translate }}</mat-option>
        <mat-option value="Exporter">{{ 'settings.companies.exporter' | translate }}</mat-option>
        <mat-option value="ForwardingAgent">{{ 'settings.companies.forwarding-agent' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
