import { stringify } from 'query-string';
import { Injectable } from '@angular/core';
import { EllosApiService } from '../../../core/http/ellosApi.service';
import { EllosApi } from '../../../core/interfaces/ellosApi.interface';
import EllosRequest from '../../../core/interfaces/ellosRequest.interface';
import { MonitoringQuery } from '../interfaces/monitoring-query.interface';
import { Exportation } from '../interfaces/Exportation/Exportation.interface';
import { CompanyProfileEnum } from 'src/app/shared/utils/company.profile.enum';
import { ForwardingAgent } from './../interfaces/Exportation/ForwardingAgent.interface';
import { ExportationPreviewResponse } from '../Models/exportation-preview-response.interface';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  private exportationPreview: ExportationPreviewResponse;

  constructor(private readonly __ellosApi: EllosApiService) {}

  public async getDocumentType(monitoringId, type) {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `Monitoring/${monitoringId}/DocumentType/${type}/`,
        body: null
      };

      const response = await this.__ellosApi.get(request);

      return response.data;
    } catch (exception) {
      throw this.__ellosApi.checkStatus(exception);
    }
  }

  async getBlockchainData(invoiceNumber: String): Promise<Exportation> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.EasyTrade,
        resource: `Exportation/${invoiceNumber}`,
        body: null
      };

      const response = await this.__ellosApi.get<Exportation>(request);

      return response.data;
    } catch (exception) {
      throw this.__ellosApi.checkStatus(exception);
    }
  }

  async getForwardingAgentByExportationId(idExportation: string): Promise<ForwardingAgent[]> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        resource: `ForwardingAgent/getAllByExportation?exportationId=${idExportation}`,
        body: null
      };
      const response = await this.__ellosApi.get<any>(request);
      return response.data;
    } catch (exception) {
      throw this.__ellosApi.checkStatus(exception);
    }
  }

  async addForwardingAgentToExportation(idExportation: string, emailAgent: string): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        resource: `ForwardingAgent/addToExportation?exportationId=${idExportation}&email=${emailAgent}`,
        body: null
      };

      const response = await this.__ellosApi.post<any>(request);
      return response.data;
    } catch (exception) {
      throw this.__ellosApi.checkStatus(exception);
    }
  }

  async removeForwardingAgentToExportation(idExportation: string, emailAgent: string): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        resource: `ForwardingAgent/removeToExportation?exportationId=${idExportation}&email=${emailAgent}`,
        body: null
      };
      const response = await this.__ellosApi.delete<any>(request);
      return response.data;
    } catch (exception) {
      throw this.__ellosApi.checkStatus(exception);
    }
  }

  async getExportations(query: MonitoringQuery): Promise<ExportationPreviewResponse> {
    if (!!query.IdCompany) {
      const queryString = stringify(query);
      const requestedApi = this.GetApiByProfile(query.Profile);
      try {
        const request: EllosRequest = {
          ellosApi: requestedApi,
          resource: `Exportation?${queryString}`,
          body: null
        };

        const response = await this.__ellosApi.get<ExportationPreviewResponse>(request);
        this.exportationPreview = response.data;

        return response.data;
      } catch (exception) {
        this.exportationPreview = { count: 0, items: [], destinationPoint: [] };
        return this.exportationPreview;
      }
    } else {
      return this.exportationPreview;
    }
  }

  async haveExportations(companyId: number, profile: string[]) {
    let haveExportation = false;
    const requestedApi = this.GetApiByProfileByArray(profile);
    let query: string = `IdCompany=${companyId}&PageIndex=0&PageSize=1&Physical=2&Profile=${profile}&Repeat=false`;
    try {
      const request: EllosRequest = {
        ellosApi: requestedApi,
        resource: `Exportation?${query}`,
        body: null
      };
      const response = await this.__ellosApi.get<ExportationPreviewResponse>(request);
      if (response.data?.items?.length > 0) {
        haveExportation = true;
        localStorage.setItem('haveExportation', JSON.stringify(haveExportation));
      }
      return haveExportation;
    } catch (exception) {
      return haveExportation;
    }
  }

  private GetApiByProfileByArray(profile: string[]): EllosApi {
    if (profile.length >= 0) {
      switch (profile[0]) {
        case CompanyProfileEnum.Certifier:
          return EllosApi.EasyTradeCertifier;
        case CompanyProfileEnum.Customs:
          return EllosApi.EasyTradeCustoms;
        default:
          return EllosApi.Exportation;
          break;
      }
    }
  }

  private GetApiByProfile(profile: CompanyProfileEnum): EllosApi {
    switch (profile) {
      case CompanyProfileEnum.Certifier:
        return EllosApi.EasyTradeCertifier;
      case CompanyProfileEnum.Customs:
        return EllosApi.EasyTradeCustoms;
      default:
        return EllosApi.Exportation;
        break;
    }
  }

  async getExportationInvoiceFile(hash: string) {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        resource: `Invoice/download/${hash}`,
        body: null
      };

      const response = await this.__ellosApi.get<any>(request);

      return response.data;
    } catch (exception) {
      throw this.__ellosApi.checkStatus(exception);
    }
  }
  async getExportationCustomsInvoiceFile(queryInvoice: { idExportation: string; idCompany: number }) {
    const query = stringify(queryInvoice);
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.EasyTradeCustoms,
        resource: `Invoice/download?${query}`,
        body: null
      };

      const response = await this.__ellosApi.get<any>(request);

      return response.data;
    } catch (exception) {
      throw this.__ellosApi.checkStatus(exception);
    }
  }
}
