<div
  class="content border mat-elevation-z8"
  [style.width.px]="size"
  [style.height.px]="size"
  (dragover)="onDragOverEvent($event)"
  (dragleave)="isDragOver = false"
  (mouseleave)="isDragOver = false"
>
  <app-avatar-image [size]="size" [url]="url"></app-avatar-image>
  <label
    fxLayout="column"
    fxLayoutAlign="center center"
    for="image"
    (dragover)="onDragOverEvent($event)"
    (dragleave)="isDragOver = false"
    (drop)="onFileDrop($event)"
    (mouseleave)="isDragOver = false"
    *ngIf="edit && !updating"
    [ngClass]="isDragOver && edit ? 'input-cover dropzone' : 'input-cover'"
  >
  </label>
  <label fxLayout="column" fxLayoutAlign="center center" *ngIf="updating" class="updating-cover">
    <mat-spinner diameter="30"> </mat-spinner>
  </label>
  <input type="file" class="input-file" id="image" *ngIf="edit" accept="image/*" (change)="imageAdd($event.target.files)" />
</div>
