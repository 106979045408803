import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { MatList, MatListItem } from '@angular/material/list';
import { Subscription, Subject, Observable } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import { Message } from '../../models/message';
import { AuthService } from 'src/app/core/services/auth.service';
import { take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { SessionUser } from '../../models/session-user';
import { AssociateService } from '../../services/associate.service';
import { MatInput } from '@angular/material/input';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  images: { [key: number]: string } = {};
  @Input() idOrder: number;
  @Input() idCompany: number;
  @Input() idMatchmaking: number;
  @Input() readonly: boolean = false;
  message: string;

  messages: Message[] = [];
  sending: boolean = false;
  user: SessionUser;
  destroy$: Subject<any> = new Subject();
  loadingMsg: boolean = false;
  @Output() messageCount: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(MatList) list: any;
  @ViewChild('input') input: ElementRef;
  @ViewChildren(MatListItem) listItems: QueryList<MatListItem>;
  sessionUser$: Observable<SessionUser>;
  profileImage$: Observable<string>;
  userImage: string;
  private subscriptionList: Subscription;
  constructor(
    private readonly chatService: ChatService,
    private readonly authService: AuthService,
    private associateService: AssociateService,
    private readonly profileImageStore: Store<{ profileImage: string }>,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>
  ) {
    this.sessionUser$ = this.sessionUserStore.pipe(select('sessionUser'));
    this.profileImage$ = this.profileImageStore.pipe(select('profileImage'));
  }

  async ngOnInit(): Promise<any> {
    this.loadingMsg = true;
    await this.getMessage();
    this.loadingMsg = false;
    this.sessionUser$.pipe(take(1)).subscribe((user) => {
      this.user = user;
    });
    this.profileImage$.pipe(takeUntil(this.destroy$)).subscribe(async (url: string) => {
      this.userImage = url;
    });
  }

  ngAfterViewInit() {
    this.subscriptionList = this.listItems.changes.subscribe((e) => {
      this.list._elementRef.nativeElement.scrollTop = this.list._elementRef.nativeElement.scrollHeight;
    });
    this.focusChat();
  }

  async send() {
    if (this.message.trim() !== '') {
      this.sending = true;
      try {
        this.sending = true;
        if (!this.idMatchmaking) {
          await this.chatService.sendMessage({
            idOrder: this.idOrder,
            message: this.message.trim(),
            idCompany: this.idCompany
          });
        } else if (this.idMatchmaking > 0) {
          await this.chatService.sendMessageMatchmaking({
            idMatchmaking: this.idMatchmaking,
            message: this.message.trim(),
            idCompany: this.idCompany
          });
        }
        this.messages.push({
          message: this.message.trim(),
          associateName: this.user.name,
          idCompany: this.idCompany,
          idAssociate: 0
        });

        if (!this.images[0]) this.images[0] = this.userImage;

        this.clearField();
        this.sending = false;
        this.focusChat();
        this.messageCount.emit(this.messages.length);
      } catch (e) {
        this.sending = false;
      }
    } else {
      this.clearField();
    }
  }

  async getMessage() {
    try {
      if (!this.idMatchmaking) {
        this.messages = await this.chatService.getMessage(this.idOrder || 0);
      } else if (this.idMatchmaking) {
        this.messages = await this.chatService.getMessageMatchmaking(this.idMatchmaking || 0);
      }
      this.messageCount.emit(this.messages.length);
    } catch (e) {
      this.messages = [];
    } finally {
      this.getImages();
    }
  }

  getImages() {
    this.messages.forEach(async (m) => {
      if (!this.images[m.idAssociate]) {
        this.images[m.idAssociate] = '../../../../assets/img/profile-placeholder.png';
        let image = await this.associateService.getProfileImageById(m.idAssociate);
        this.images[m.idAssociate] = this.renderImage(image);
      }
    });
  }
  renderImage(base64: string) {
    base64 === '' || !base64 ? (base64 = '../../../../assets/img/profile-placeholder.png') : (base64 = 'data:image/png;base64,' + base64);

    return base64;
  }

  ngOnDestroy() {
    this.subscriptionList?.unsubscribe();
    this.destroy$.next(true);
  }
  clearField() {
    this.message = undefined;
  }
  focusChat() {
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      this.input.nativeElement.focus();
    }, 0);
  }
}
