import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SubscriptionResponse } from '../../models/subscription-response.interface';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-delete-event-dialog',
  templateUrl: './delete-event-dialog.component.html',
  styleUrls: ['./delete-event-dialog.component.scss']
})
export class DeleteEventDialogComponent implements OnInit {
  waitingResponse: boolean;
  constructor(
    private readonly dialogRef: MatDialogRef<DeleteEventDialogComponent>,
    private readonly eventService: EventService,
    private readonly notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionResponse
  ) {}

  ngOnInit(): void {}
  async deleteEvent() {
    this.waitingResponse = true;
    try {
      let response = await this.eventService.removeSubscription(this.data.id);
      if (response) {
        this.notificationService.notifyDialog({
          success: true,
          timeout: 3000,
          text: 'settings.companies.event.sucess-unsubscribed'
        });

        this.dialogRef.close(true);
      }
    } catch (e) {
      this.notificationService.notifyDialog({
        success: false,
        timeout: 3000,
        text: 'settings.companies.event.error-unsubscribed'
      });
      this.waitingResponse = false;
    }
  }
}
