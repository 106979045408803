import EllosAction from '../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../core/models/actionTypes';
import { Timezone } from '../models/timezone';

export function timezonesReducer(state: Timezone[], action: EllosAction) {
  switch (action.type) {
    case ActionTypes.Timezones: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
