import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/shared/models/country';
import { environment } from 'src/environments/environment';
import { CompanyTypeEnum } from './invoice-form-type.enum';
import { MatTableDataSource } from '@angular/material/table';
import { Companies } from './../../../../core/models/companies';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { allCountries } from './../../../../core/models/all-countries';
import { CompanyService } from 'src/app/core/services/company.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { debounceTime, switchMap, takeUntil, shareReplay } from 'rxjs/operators';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { pageConfig } from 'src/app/modules/easy-trade/actions/query-list-involved-companies.action';
import { QueryListInvolvedCompanies } from './../../../easy-trade/Models/query-list-involved-companies';
import { AddNotEllosCompanyManagerDialogComponent } from './../../../easy-trade/components/add-not-ellos-company-manager-dialog/add-not-ellos-company-manager-dialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {
  public modules = [];
  public modulePermission = PermissionsEnum;
  displayedColumns: string[] = ['select', 'favorite', 'id', 'name', 'street', 'idCountry', 'poBox', 'actions'];
  destroy$ = new Subject<any>();
  inputSearch: FormControl;
  @Output() companySelected = new EventEmitter<any>();
  @Output() closeTableEvent = new EventEmitter<any>();
  dataSource: MatTableDataSource<Companies> = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  queryListInvolvedCompanies$: Observable<QueryListInvolvedCompanies>;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  radioStatus: boolean;
  query: QueryListInvolvedCompanies;
  dialogRefEditCompany;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  count: number;
  @Input() typeCompany: CompanyTypeEnum;
  @Input() countries: Country[] = [];

  loading: boolean = false;
  companiesSession: CompanyPreview[] = [];
  faSignOutAlt = faSignOutAlt;

  constructor(
    private readonly queryListInvolvedCompaniesStore: Store<{
      queryListInvolvedCompanies: QueryListInvolvedCompanies;
    }>,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly companyService: CompanyService,
    private readonly permissions: PermissionsService,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>
  ) {
    this.queryListInvolvedCompanies$ = queryListInvolvedCompaniesStore.pipe(select('queryListInvolvedCompanies'));
  }

  async ngOnInit() {
    this.inputSearch = new FormControl('');
    this.inputSearch.valueChanges
      .pipe(
        debounceTime(400),
        switchMap(async (value) => {
          await this.getListInvolvedCompanies(value);
        })
      )
      .subscribe((result) => {});
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((t) => {
      if (!environment.production) {
        let itemsPerPageLabel = this.translateService.instant(`mat-table.itemsPerPageLabel`);
        let nextPageLabel = this.translateService.instant(`mat-table.nextPageLabel`);
        let firstPageLabel = this.translateService.instant(`mat-table.firstPageLabel`);
        let lastPageLabel = this.translateService.instant(`mat-table.lastPageLabel`);
        let previousPageLabel = this.translateService.instant(`mat-table.previousPageLabel`);

        this.paginator._intl.itemsPerPageLabel = itemsPerPageLabel;
        this.paginator._intl.nextPageLabel = nextPageLabel;
        this.paginator._intl.firstPageLabel = firstPageLabel;
        this.paginator._intl.lastPageLabel = lastPageLabel;
        this.paginator._intl.previousPageLabel = previousPageLabel;

        this.paginator._intl.changes.next(itemsPerPageLabel);
        this.paginator._intl.changes.next(nextPageLabel);
        this.paginator._intl.changes.next(lastPageLabel);
        this.paginator._intl.changes.next(previousPageLabel);
      }
    });

    this.queryListInvolvedCompanies$.pipe(takeUntil(this.destroy$), shareReplay(1)).subscribe(async (query) => {
      if (query) {
        this.query = query;
        this.pageIndex = query.PageIndex;
        if (this.typeCompany === this.companyTypeEnum.IMPORTER) {
          await this.getListInvolvedCompanies();
        } else {
          await this.getListInvolvedCompanies();
        }
      }
    });

    this.modules = this.permissions.getPermissionStorage();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.empTbSort;
  }

  get companyTypeEnum() {
    return CompanyTypeEnum;
  }

  async getListInvolvedCompanies(nameCompany?: string) {
    try {
      if (nameCompany) {
        this.loading = true;
        let response = await this.companyService.getListInvolvedCompaniesByName(this.query, nameCompany);
        if (response.companyCount > 0 && response.companies.length > 0) {
          response.companies.forEach((element) => {
            element.country = allCountries.find((idCountry) => idCountry.id === element.idCountry);
          });
          this.count = response.companyCount;
          this.dataSource.data = response.companies;
        } else {
          this.count = 0;
          this.dataSource.data = [];
        }
      } else {
        this.loading = true;
        let response = await this.companyService.getListInvolvedCompanies(this.query);
        if (response.companyCount > 0 && response.companies.length > 0) {
          response.companies.forEach((element) => {
            element.country = allCountries.find((idCountry) => idCountry.id === element.idCountry);
          });
          this.count = response.companyCount;
          this.dataSource.data = response.companies;
        } else {
          this.count = 0;
          this.dataSource.data = [];
        }
      }
    } catch {
      this.count = 0;
      this.dataSource.data = [];
    } finally {
      this.loading = false;
    }
  }

  async getListAll() {
    try {
      this.loading = true;
      let response = await this.companyService.getListAll(this.query);
      if (response.companyCount > 0 && response.companies.length > 0) {
        response.companies.forEach((element) => {
          element.country = allCountries.find((idCountry) => idCountry.id === element.idCountry);
        });
        this.count = response.companyCount;
        this.dataSource.data = response.companies;
      } else {
        this.count = 0;
        this.dataSource.data = [];
      }
    } catch {
      this.count = 0;
      this.dataSource.data = [];
    } finally {
      this.loading = false;
    }
  }

  sameQuery(newQuery: QueryListInvolvedCompanies): boolean {
    return (
      newQuery &&
      this.query?.exporterId === newQuery?.exporterId &&
      this.query?.profileCode === newQuery?.profileCode &&
      this.query?.PageIndex === newQuery?.PageIndex &&
      this.query?.PageSize === newQuery?.PageSize
    );
  }

  async pageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    await this.getListInvolvedCompanies();
  }

  radioChange(event) {
    event.value.typeCompany = this.typeCompany;
    this.onChangeRadioTable();
    this.companySelected.emit(event.value);
  }

  selectCompany(company: any) {
    company.typeCompany = this.typeCompany;
    this.onChangeRadioTable();
    this.companySelected.emit(company);
  }

  onChangeRadioTable() {
    this.radioStatus = false;
  }

  closeTable() {
    this.closeTableEvent.emit(this.typeCompany);
  }

  filterPage(page: any) {
    this.queryListInvolvedCompaniesStore.dispatch(pageConfig({ PageIndex: page.pageIndex, PageSize: page.pageSize }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  openDialogNewCompany(companyToEdit?: any) {
    if (companyToEdit) {
      this.handleModalEditCompany(companyToEdit);
    } else {
      this.handleModalEditCompany(companyToEdit);
    }
  }

  async handleModalEditCompany(companyToEdit: any) {
    this.dialogRefEditCompany = this.dialog
      .open(AddNotEllosCompanyManagerDialogComponent, {
        width: '500px',
        disableClose: true,
        data: {
          dataCompanyToEdit: companyToEdit
        }
      })
      .afterClosed()
      .subscribe(async (res) => {
        await this.getListInvolvedCompanies();
      });
  }

  async removeCompany(companyToRemove: any) {
    this.loading = true;
    let response = await this.companyService.deleteCompanyImpoter(companyToRemove?.id);
    if (response?.success) {
      await this.getListInvolvedCompanies();
    }
    this.loading = false;
  }

  async favoriteCompany(company: any, favorite: boolean) {
    this.loading = true;
    let response = await this.companyService.favoriteCompanie(company?.id, favorite);
    if (response?.success) {
      this.loading = false;
      this.dataSource.data?.find((obj) => {
        if (obj.id === company?.id) {
          if (favorite) {
            obj.favorite = true;
          } else {
            obj.favorite = false;
          }
        }
      });
    }

    this.loading = false;
  }
}
