import { PaymentProcessSimpleItem } from './paymentProcessSimpleItem.interface';
import { Type } from './type.enum';
import { Status } from './status.enum';
import { Currency } from './currency.enum';

export class PaymentListPay {
  Id: string;
  Date: string;
  Type: Type;
  Importer: string;
  Destination: string;
  Description?: string;
  Itens?: PaymentProcessSimpleItem[];
  LimitDate: string;
  Price: number;
  Currency: Currency;
  Status: Status;

  constructor(
    Id: string,
    Date: string,
    Type: Type,
    Importer: string,
    Destination: string,
    Description: string,
    Itens: PaymentProcessSimpleItem[],
    LimitDate: string,
    Price: number,
    Currency: Currency,
    Status: Status
  ) {
    this.Id = Id;
    (this.Date = Date), (this.Type = Type);
    this.Importer = Importer;
    this.Destination = Destination;
    this.Description = Description;
    this.Itens = Itens;
    this.LimitDate = LimitDate;
    this.Price = Price;
    this.Currency = Currency;
    this.Status = Status;
  }
}
