import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
  filePath: SafeResourceUrl;
  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly sanitizer: DomSanitizer) {
    let type = this.router.url;
    let language = this.route.snapshot.paramMap.get('language');

    if (type == '/Manual/en') {
      this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/manual/Manual_Ellos_EN.pdf');
    } else if (type == '/Manual/pt') {
      this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/manual/Manual_Ellos_PT BR.pdf');
    } else if (type === '/Step-by-step/en') {
      this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/manual/New_User_Ellos.pdf');
    } else if (type === '/Step-by-step/pt') {
      this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/manual/Novo_Usuario_Ellos.pdf');
    } else {
      this.router.navigateByUrl('/Login');
    }
  }

  ngOnInit(): void {}
  getUrl() {}
}
