<app-certificate-exportation-product-form
  [useProductValue]="useProductValue"
  [editProduct]="productToEdit"
  [isEditProduct]="productEdition"
  [isOriginCertificate]="isOriginCertificate"
  [defaultNcm]="defaultNcm"
  [defaultquantity]="defaultquantity"
  [defaultUnitMeasure]="defaultUnitMeasure"
  [defaultUnitMeasureId]="defaultUnitMeasureId"
  [defaultDescriptionProduct]="defaultDescriptionProduct"
  [defaultNetWeight]="defaultNetWeight"
  [defaultGrossWeight]="defaultGrossWeight"
  (productAddedEvent)="productAdded($event)"
>
</app-certificate-exportation-product-form>

<app-certificate-product-exportation-grid [useProductValue]="useProductValue" [canEdit]="canEdit" [isEditProduct]="productEdition" (editProductEvent)="editProdct($event)">
</app-certificate-product-exportation-grid>
