<br />
<br />
<br />
<div fxLayout="row" fxLayoutAlign="center center">
  <p class="mat-h1" *ngIf="floatLabelControl == 'email'; else whats">
    {{ 'confirm-account.title-email' | translate }}
  </p>
  <ng-template #whats>
    <p class="mat-h1">
      {{ 'confirm-account.title-whats' | translate }}
    </p>
  </ng-template>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <p class="mat-h2">
    {{ floatLabelControl == 'email' ? email : tel }}
  </p>
</div>
<br />
<form *ngIf="!waitingConfirmation" [formGroup]="accountValidation" (ngSubmit)="onSubmit()">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="80">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-around center" fxLayoutGap="10px">
        <!-- #1 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb1" id="1" autocomplete="off" (ngModelChange)="onInputEntry($event, 2)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #2 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" style="border-color: #fff" appearance="outline">
            <input formControlName="nmb2" id="2" autocomplete="off" (ngModelChange)="onInputEntry($event, 3)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb3" id="3" autocomplete="off" (ngModelChange)="onInputEntry($event, 4)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #4 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb4" id="4" autocomplete="off" (ngModelChange)="onInputEntry($event, 5)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #5 number -->
        <div class="column" flxLayout="column w-75">
          <mat-form-field class="input-number w-75" appearance="outline">
            <input formControlName="nmb5" id="5" autocomplete="off" (ngModelChange)="onInputEntry($event, 6)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #6 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb6" id="6" autocomplete="off" (ngModelChange)="onInputEntry($event, 7)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>
      </div>
      <div class="msgCodeError" *ngIf="msgCodeError">
        <p>{{ msgCodeError | translate }}</p>
      </div>
      <button
        class="button mb-4 mt-5 p-1"
        [class.btnResendCodeDisable]="count != 0"
        type="button"
        [disabled]="count != 0"
        (click)="ResendCounter()"
        id="7"
        mat-raised-button
        color="accent"
      >
        {{ 'auth.validation.resend-code' | translate }}{{ count }}S
      </button>
      <button class="button p-1" [class.btnDisable]="!accountValidation.valid" [disabled]="!accountValidation.valid" id="7" mat-raised-button color="accent">
        {{ 'auth.validation.Create-button' | translate }}
      </button>
    </div>
  </div>
</form>

<app-ellos-spinner *ngIf="waitingConfirmation" [text]="'confirm-account.confirm-loader' | translate"> </app-ellos-spinner>
