import { Action } from '@ngrx/store';
import { CompanyPreview } from '../interfaces/company-preview.interface';
import { StoreKey } from '../interfaces/storeKey.interface';
import { ActionTypes } from '../models/actionTypes';

export const ChangeCompany = (company: CompanyPreview) => {
  if (company) {
    localStorage.setItem(StoreKey.company, JSON.stringify(company));
  }

  return <Action>{ type: ActionTypes.ChangeCompany, payload: company };
};

export const CompanyChangeFailValidation = () => {
  return <Action>{ type: ActionTypes.CompanyChangeFailValidation };
};

export const CompanyChangeSuccessValidation = () => {
  return <Action>{ type: ActionTypes.CompanyChangeSuccessValidation };
};
