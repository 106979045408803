import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { clearFilter, date, repeatQuery } from '../../actions/origin-certificate-query.action';
import { OriginCertificateQuery } from '../../interfaces/OriginCertificateQuery.interface';

@Component({
  selector: 'search-origin-certificate',
  templateUrl: './search-origin-certificate.component.html',
  styleUrls: ['./search-origin-certificate.component.scss']
})
export class SearchOriginCertificateComponent implements OnInit {
  query$: Observable<OriginCertificateQuery>;
  destroy$ = new Subject();

  rangeDate = this.fb.group({
    start: [''],
    final: ['']
  });
  certificateNumbers: string[] = [];
  @Output() certificateNum = new EventEmitter<string[]>();
  @Output() invoiceNumber = new EventEmitter<string[]>();
  invoiceNumbers: string[] = [];

  constructor(
    private readonly originCertificateQueryStore: Store<{
      originCertificateQuery: OriginCertificateQuery;
    }>,
    private readonly fb: FormBuilder
  ) {
    this.query$ = originCertificateQueryStore.pipe(select('originCertificateQuery'));
  }

  ngOnInit(): void {
    this.query$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((query: OriginCertificateQuery) => {
      if (query) {
        this.certificateNumbers = [...query?.certificateNumber];
        this.invoiceNumbers = [...query?.invoiceNumber];
        this.rangeDate.controls.start.setValue(query?.startDate ? new Date(query?.startDate).toISOString() : '');
        this.rangeDate.controls.final.setValue(query?.finalDate ? new Date(query?.finalDate).toISOString() : '');
        this.rangeDate.updateValueAndValidity();
      }
    });
  }

  filterByCertificaNumber(certificates: string[]) {
    certificates = certificates.map((x) => x.trim());
    this.certificateNum.emit(certificates);
  }

  filterByInvoiceNumber(invoices: string[]) {
    invoices = invoices.map((x) => x.trim());
    this.invoiceNumber.emit(invoices);
  }

  filterDate() {
    if (
      (this.rangeDate.controls.start.value && this.rangeDate.controls.final.value) ||
      (this.rangeDate.controls.start.value == null && this.rangeDate.controls.final.value == null)
    ) {
      let FinalDate = undefined;
      if (this.rangeDate.controls.final.value && this.rangeDate.controls.final.value !== null) {
        const finalDate = new Date(this.rangeDate.controls.final.value);
        const day = finalDate.getDate()?.toString();
        const month = (finalDate.getMonth() + 1)?.toString();
        const year = finalDate.getFullYear()?.toString();
        FinalDate = year + '-' + month + '-' + day;
      }

      let StartDate = undefined;
      if (this.rangeDate.controls.start.value && this.rangeDate.controls.start.value !== null) {
        const startDate = new Date(this.rangeDate.controls.start.value);
        const day = startDate.getDate()?.toString();
        const month = (startDate.getMonth() + 1)?.toString();
        const year = startDate.getFullYear()?.toString();
        StartDate = year + '-' + month + '-' + day;
      }

      this.originCertificateQueryStore.dispatch(date({ finalDate: FinalDate, startDate: StartDate }));
    }
  }

  resetCalendar() {
    this.rangeDate.controls.start.setValue(undefined);
    this.rangeDate.controls.final.setValue(undefined);
  }

  resendQuery() {
    this.originCertificateQueryStore.dispatch(repeatQuery({ repeat: true }));
  }

  hasFilter() {
    return this.certificateNumbers.length > 0 || this.invoiceNumbers.length > 0 || this.rangeDate.controls.start?.value != '' || this.rangeDate.controls.final?.value != '';
  }

  clearFilter() {
    this.originCertificateQueryStore.dispatch(clearFilter());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
