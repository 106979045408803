import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-result-not-found',
  templateUrl: './result-not-found.component.html',
  styleUrls: ['./result-not-found.component.scss']
})
export class ResultNotFoundComponent implements OnInit {
  @Input() titleMSG;
  @Input() subtitleMSG;
  constructor() {}

  ngOnInit(): void {}
}
