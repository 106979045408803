import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SessionUser } from 'src/app/shared/models/session-user';
import { RequestAccessAdminDialogComponent } from '../../components/request-access-admin-dialog/request-access-admin-dialog.component';
import { RequestAccessCompanyDialogComponent } from '../../components/request-access-company-dialog/request-access-company-dialog.component';
import { RequestAccessDialogComponent } from '../../components/request-access-dialog/request-access-dialog.component';
import { RequestAccessErrorDialogComponent } from '../../components/request-access-error-dialog/request-access-error-dialog.component';
import { RequestAccessInviteDialogComponent } from '../../components/request-access-invite-dialog/request-access-invite-dialog.component';
import { RequestAccessNotFoundDialogComponent } from '../../components/request-access-not-found-dialog/request-access-not-found-dialog.component';

@Component({
  selector: 'app-request-company',
  templateUrl: './request-company.component.html',
  styleUrls: ['./request-company.component.scss']
})
export class RequestCompanyComponent implements OnInit {
  email: Subject<string> = new Subject<string>();
  user: SessionUser;
  destroy$: Subject<any> = new Subject<any>();
  sessionUser$: Observable<SessionUser>;
  findCompany: boolean = false;
  searchedCompany: boolean = false;
  isAdmin: boolean;
  companyFound: boolean;
  existingCompanyRequest: boolean;
  finished: boolean;
  isRequested: boolean;
  requestInvite: boolean;
  cnpjInvite: any;
  emailInvite: any;
  isRequestCompany: boolean;
  company: any;
  constructor(private readonly dialog: MatDialog) {}

  ngOnInit(): void {}

  requestAcessDialog(finished): void {
    if (!this.isAdmin && this.companyFound && finished) {
      const dialogRef = this.dialog.open(RequestAccessDialogComponent, {
        width: '830px',
        height: '270px',
        data: this.company,
        disableClose: true
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
          }
        });
    } else if (this.isAdmin && this.companyFound && finished) {
      const dialogRef = this.dialog.open(RequestAccessAdminDialogComponent, {
        width: '830px',
        height: '270px',
        data: this.company,
        disableClose: true
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
          }
        });
    } else if (this.isAdmin && this.requestInvite && !this.existingCompanyRequest) {
      const dialogRef = this.dialog.open(RequestAccessInviteDialogComponent, {
        width: '830px',
        height: '270px',
        data: this.company,
        disableClose: true
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
          }
        });
    } else if (this.isRequestCompany && this.existingCompanyRequest && this.cnpjInvite.length == 18) {
      const data = {
        cnpj: this.cnpjInvite,
        email: this.emailInvite,
        newUser: false
      };
      const dialogRef = this.dialog.open(RequestAccessCompanyDialogComponent, {
        width: '830px',
        height: '270px',
        data: data,
        disableClose: true
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
          }
        });
    } else if (this.isAdmin && !this.companyFound && finished) {
      const dialogRef = this.dialog.open(RequestAccessNotFoundDialogComponent, {
        width: '830px',
        height: '270px',
        data: this.company,
        disableClose: true
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
          }
        });
    } else if (!finished && this.companyFound) {
      const dialogRef = this.dialog.open(RequestAccessErrorDialogComponent, {
        width: '830px',
        height: '270px',
        disableClose: true
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
