<div mat-dialog-title align="center">
  <h1>
    {{ (data.action === 'accept' ? 'settings.companies.accept-title' : 'settings.companies.decline-title') | translate }}
  </h1>

  <mat-divider class="divider"></mat-divider>
</div>
<mat-dialog-content class="content" align="center" *ngIf="loading">
  <app-ellos-spinner [text]="'settings.profile.loading' | translate"></app-ellos-spinner>
</mat-dialog-content>
<mat-dialog-content class="content" align="center" *ngIf="!loading">
  <p *ngIf="data.action === 'accept'">
    {{ 'settings.companies.sure-accept' | translate }}
  </p>

  <p *ngIf="data.action === 'decline'">
    {{ 'settings.companies.sure-decline' | translate }}
  </p>

  <div fxLayout="column" fxLayoutAlign="start start">
    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
      <b>{{ 'registration.company-name' | translate }}: </b>
      <span>{{ data.invite.companyName }}</span>
    </p>
    <p fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" *ngIf="data.invite.role">
      <b>{{ 'settings.companies.role' | translate }}: </b>
      <span>{{ 'settings.companies.' + data.invite.role.toLowerCase() | translate }}</span>
    </p>
  </div>
</mat-dialog-content>
<mat-divider class="divider"></mat-divider>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] [disabled]="loading">
    {{ 'settings.companies.button-cancel' | translate }}
  </button>
  <button mat-button (click)="executeAction()" [disabled]="loading">
    {{ 'settings.companies.button-confirm' | translate }}
  </button>
</mat-dialog-actions>
