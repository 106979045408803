import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TourService } from 'ngx-ui-tour-md-menu';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../core/services/auth.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { MonitoringQuery } from 'src/app/modules/easy-trade/interfaces/monitoring-query.interface';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  faYoutube = faYoutube;
  faLinkedin = faLinkedin;
  faInstagram = faInstagram;
  faGlobeAfrica = faGlobeAfrica;
  faTwitterSquare = faTwitterSquare;
  faQuestionCircle = faQuestionCircle;
  faFacebookSquare = faFacebookSquare;

  profile: string;
  disabled = true;
  year = new Date().getFullYear();
  loggedIn$: Observable<boolean>;
  isProduction = environment.production;
  sessionCompanies$: Observable<CompanyPreview[]>;
  destroy$ = new Subject();

  @Input() socialFooter: boolean;

  public modules = [];
  public modulePermission = PermissionsEnum;

  public subscription: any;

  constructor(
    private authService: AuthService,
    private readonly router: Router,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>,
    private readonly monitoringQueryStore: Store<{
      monitoringQuery: MonitoringQuery;
    }>,
    public tourService: TourService,
    public permissionsService: PermissionsService
  ) {
    this.loggedIn$ = authService.isLoggedIn();
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
  }

  ngOnInit(): void {
    this.sessionCompanies$.subscribe((companies) => {
      this.disabled = companies === undefined || companies.length === 0;
    });

    this.subscription = this.permissionsService.getPermissionschangeEmitter().subscribe((item) => {
      this.modules = item;
    });
  }

  navigateSettings(): void {
    this.router.navigateByUrl('/Settings/MyProfile');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
  }

  openedTour() {
    return this.tourService.getStatus();
  }

  companyIsSharedProcess() {
    const currentCompany = JSON.parse(localStorage.getItem('current-company'));
    return currentCompany?.profiles.includes('SHP');
  }

  isExportcompany() {
    const currentCompany = JSON.parse(localStorage.getItem('current-company'));
    return currentCompany?.profiles.includes('CTS');
  }

  redirectToUrl() {
    const haveExportation = JSON.parse(localStorage.getItem('haveExportation'));
    if (haveExportation) {
      this.router.navigate(['/EasyTrade/Monitoring/Exportations']);
    } else {
      this.router.navigate(['/EasyTrade/Trade/Home']);
    }
  }
}
