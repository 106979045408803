export enum EllosApi {
  Associate,
  Showcase,
  EasyTrade,
  Meeting,
  Ncm,
  Company,
  System,
  Exportation,
  EasyTradeCertifier,
  OriginCertificate,
  EasyTradeCustoms,
  Profile,
  Payment,
  Pricing
}
