import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { TourService } from 'ngx-ui-tour-md-menu';
import { CookieService } from 'ngx-cookie-service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import Theme from './core/interfaces/theme.interface';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalizeCalendar } from './globalize-calendar';
import { TranslateService } from '@ngx-translate/core';
import { AcceptCookiesComponent } from '@ccab/components-lib';
import { Platform } from './modules/home/enum/platform';
import Language from './core/interfaces/language.interface';
import { ThemeService } from './core/services/theme.service';
import { AutoLogoutService } from './core/services/auto-logout';
import { GtmService } from './modules/home/services/gtm.service';
import { PolicyVersion } from './modules/home/enum/policy-version';
import { DataLayerService } from './shared/services/data-layer.service';
import { UserConsensus } from './modules/home/interfaces/user-consensus';
import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NavigationStart, Event as NavigationEvent } from '@angular/router';
import { UserConsensusService } from './modules/home/services/user-consensus-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends GlobalizeCalendar implements AfterViewInit {
  appliedTheme: string;
  theme$: Observable<Theme>;
  themeSubscription$: Subscription;
  acceptCookiesModalIsOpened: boolean = false;
  userHasConsensus: UserConsensus = null;
  userLogged: string;

  constructor(
    private readonly themeService: ThemeService,
    private readonly translateService: TranslateService,
    public tourService: TourService,
    private readonly themeStore: Store<{ theme: Theme }>,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private autoLogout: AutoLogoutService,
    private readonly router: Router,
    dateAdapter: DateAdapter<Date>,
    private dataLayerService: DataLayerService,
    langStore: Store<{ language: Language }>,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialog,
    private cookieService: CookieService,
    private gtmService: GtmService,
    private userConsensusService: UserConsensusService
  ) {
    super(dateAdapter, langStore);
    this.appliedTheme = themeService.getDarkTheme().name;
    this.theme$ = themeStore.pipe(select('theme'));
    matIconRegistry.addSvgIcon('badge', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/badge.svg'));
  }

  async verifyUserConsent() {
    await this.userConsensusService.getUserConsentInfo(this.userLogged, Platform.ELLOS, PolicyVersion.v1).then((res) => {
      this.userHasConsensus = res;
    });
  }

  async openDialog() {
    if (this.userHasConsensus == null || this.userHasConsensus.MandatoryCookies == false) {
      this.dialogRef
        .open(AcceptCookiesComponent, {
          disableClose: true,
          data: { platform: Platform.ELLOS, userConsensusService: this.userConsensusService, userEmail: this.userLogged, version: PolicyVersion.v1 }
        })
        .afterClosed()
        .subscribe(async (res) => {
          this.acceptCookiesModalIsOpened = false;
          this.injectGtm();
        });
    }
  }

  injectGtm() {
    if (!this.gtmService.gtmScriptExists()) {
      this.gtmService.injectScript('GTM-PKZLWQ3', true);
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        this.dataLayerService.setPageName(event);

        this.userLogged = JSON.parse(localStorage.getItem('user'))?.email;
        this.verifyUserConsent().then(() => {
          this.openDialog().then(() => {
            this.injectGtm();
          });
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.themeSubscription$ = this.theme$.pipe(takeUntil(this.destroy$)).subscribe((newTheme: Theme) => {
      if (newTheme) this.appliedTheme = newTheme.name;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.themeSubscription$.unsubscribe();
  }
}
