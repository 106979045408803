import { UserPreferencesEnum } from 'src/app/modules/settings/enums/user-preferences.enum';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AssociateService } from 'src/app/shared/services/associate.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-user-preferences-form',
  templateUrl: './user-preferences-form.component.html',
  styleUrls: ['./user-preferences-form.component.scss']
})
export class UserPreferencesFormComponent implements OnInit {
  @Output() savedPreferences = new EventEmitter<boolean>();
  saving: boolean = false;
  preferencesForm = this.fb.group({
    preferencesCode: this.fb.array(['' as UserPreferencesEnum]),
    interests: this.fb.array([''])
  });

  constructor(private readonly fb: FormBuilder, private readonly associateService: AssociateService, private readonly notificationService: NotificationService) {}

  ngOnInit(): void {}
  async submit() {
    try {
      this.saving = true;
      const response = await this.associateService.savePreferences(this.preferencesForm.value);
      this.notificationService.notifyDialog({
        success: response,
        timeout: 2000
      });
    } catch (e) {
      this.notificationService.notifyDialog({
        success: false,
        timeout: 2000
      });
    } finally {
      this.saving = false;
      this.savedPreferences.emit(true);
    }
  }
  skip() {
    this.savedPreferences.emit(true);
  }
}
