import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QueryMatchmaking } from '../../interfaces/queryMatchmaking.interface';
import { Meet } from '../../models/meet';
import { MatchmakingService } from '../../services/matchmaking.service';
import { MeetingService } from '../../services/meeting.service';

@Component({
  selector: 'app-next-meetings',
  templateUrl: './next-meetings.component.html',
  styleUrls: ['./next-meetings.component.scss']
})
export class NextMeetingsComponent implements OnInit {
  matchmakings: Meet[] = [];
  meetings: Meet[] = [];
  queryMatchmaking$: Observable<QueryMatchmaking>;
  destroy$ = new Subject();
  isLoading: boolean = false;
  pageIndex: number = 0;
  scrollDistance = 4;
  queryMatchmaking: QueryMatchmaking;
  loadingMore: boolean = false;

  constructor(
    private readonly matchmakingStore: Store<{
      queryMatchmaking: QueryMatchmaking;
    }>,
    private readonly matchmakingService: MatchmakingService,
    private readonly meetingService: MeetingService
  ) {
    this.queryMatchmaking$ = matchmakingStore.pipe(select('queryMatchmaking'));
  }

  ngOnInit(): void {
    this.queryMatchmaking$.pipe(takeUntil(this.destroy$));

    this.queryMatchmaking$.pipe(takeUntil(this.destroy$)).subscribe(async (queryMatch: QueryMatchmaking) => {
      this.isLoading = true;
      this.pageIndex = 0;
      this.queryMatchmaking = queryMatch;

      await this.filter(queryMatch);
      this.isLoading = false;
    });
  }

  async onScrollDown() {
    this.loadingMore = true;
    this.pageIndex++;
    let query: QueryMatchmaking = {
      IdCompany: this.queryMatchmaking.IdCompany,
      LanguageCode: this.queryMatchmaking.LanguageCode,
      MainFilter: this.queryMatchmaking.MainFilter,
      EndDate: this.queryMatchmaking.EndDate,
      InitDate: this.queryMatchmaking.InitDate,
      HistoricalData: this.queryMatchmaking.HistoricalData,
      PageSize: this.queryMatchmaking.PageSize,
      PageIndex: this.pageIndex,
      filter: this.queryMatchmaking.filter
    };

    await this.filterConcat(query);
    this.isLoading = false;
    this.loadingMore = false;
  }

  async filterConcat(queryMatch) {
    if (queryMatch?.filter == 'all') {
      this.matchmakings = this.matchmakings.concat(await this.matchmakingService.getMatchmakings(queryMatch));
      this.matchmakings = this.matchmakings.concat(await this.matchmakingService.getMatchmakingMeetings(queryMatch));
      this.meetings = this.meetings.concat(await this.meetingService.getMeetings(queryMatch));
    } else if (queryMatch?.filter == 'matchmaking') {
      this.matchmakings = this.matchmakings.concat(await this.matchmakingService.getMatchmakings(queryMatch));
      this.matchmakings = this.matchmakings.concat(await this.matchmakingService.getMatchmakingMeetings(queryMatch));
      this.meetings = [];
    } else if (queryMatch?.filter == 'negotiation') {
      this.meetings = this.meetings.concat(await this.meetingService.getMeetings(queryMatch));

      this.matchmakings = [];
    }
    this.isLoading = false;
  }

  async filter(queryMatch) {
    if (queryMatch?.filter == 'all') {
      this.matchmakings = await this.matchmakingService.getMatchmakings(queryMatch);
      this.matchmakings = this.matchmakings.concat(await this.matchmakingService.getMatchmakingMeetings(queryMatch));
      this.meetings = await this.meetingService.getMeetings(queryMatch);
    } else if (queryMatch?.filter == 'matchmaking') {
      this.matchmakings = await this.matchmakingService.getMatchmakings(queryMatch);
      this.matchmakings = this.matchmakings.concat(await this.matchmakingService.getMatchmakingMeetings(queryMatch));

      this.meetings = [];
    } else if (queryMatch?.filter == 'negotiation') {
      this.meetings = await this.meetingService.getMeetings(queryMatch);

      this.matchmakings = [];
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
