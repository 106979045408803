import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';

@Component({
  selector: 'app-contact-and-help',
  templateUrl: './contact-and-help.component.html',
  styleUrls: ['./contact-and-help.component.scss']
})
export class ContactAndHelpComponent implements OnInit {
  public modules = [];
  public modulePermission = PermissionsEnum;

  constructor(private readonly permissions: PermissionsService) {}

  ngOnInit(): void {
    this.modules = this.permissions.getPermissionStorage();
  }
}
