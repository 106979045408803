import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClosedMeetingComponent } from './pages/closed-meeting/closed-meeting.component';
import { MatchmakingDetailsComponent } from './pages/matchmaking-details/matchmaking-details.component';
import { MatchmakingComponent } from './pages/matchmaking/matchmaking.component';
import { MeetingRoomComponent } from './pages/meeting-room/meeting-room.component';
import { MeetingsComponent } from './pages/meetings/meetings.component';
import { NextMeetingsComponent } from './pages/next-meetings/next-meetings.component';
import { PastMeetingsComponent } from './pages/past-meetings/past-meetings.component';

const routes: Routes = [
  {
    path: '',
    component: MatchmakingComponent,
    children: [
      {
        path: '',
        redirectTo: 'Meetings/Nexts',
        pathMatch: 'full'
      },
      {
        path: 'Meetings',
        redirectTo: 'Meetings/Nexts',
        pathMatch: 'full'
      },
      {
        path: 'Meetings/Nexts',
        component: MeetingsComponent,
        children: [{ path: '', component: NextMeetingsComponent }]
      },
      {
        path: 'Meetings/Pasts',
        component: MeetingsComponent,
        children: [{ path: '', component: PastMeetingsComponent }]
      },
      {
        path: 'Meetings/Details',
        component: MatchmakingDetailsComponent,
        children: []
      },
      {
        path: 'Meetings/MeetingRoom',
        component: MeetingRoomComponent,
        children: []
      },
      {
        path: 'Meetings/ClosedMeeting',
        component: ClosedMeetingComponent,
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MatchmakingRoutingModule {}
