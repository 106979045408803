import { Injectable } from '@angular/core';
import { stringify } from 'query-string';
import { BehaviorSubject } from 'rxjs';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { UnitMeasure } from '../interfaces/unitMeasure.interface';

@Injectable({
  providedIn: 'root'
})
export class UnitMeasureService {
  private currentUnits = new BehaviorSubject<UnitMeasure[]>([]);
  constructor(private readonly ellosApi: EllosApiService) {}

  async getUnitsMeasure(languageCode: string, unitTypeCode: 'WEI' | 'VOL' | 'UNI' | 'ARE' | 'LEN' | 'TIM' = null): Promise<UnitMeasure[]> {
    const query = stringify({ languageCode, unitTypeCode });
    const request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `UnitMeasure?${query}`,
      body: null
    };
    try {
      const response = await this.ellosApi.get<UnitMeasure[]>(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
}
