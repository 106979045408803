import { DateAdapter } from '@angular/material/core';
import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import Language from './core/interfaces/language.interface';
import { takeUntil } from 'rxjs/operators';
@Component({
  template: ''
})
export abstract class GlobalizeCalendar {
  language$: Observable<Language>;
  destroy$: Subject<any> = new Subject<any>();
  constructor(protected dateAdapter: DateAdapter<Date>, protected langStore: Store<{ language: Language }>) {
    this.language$ = langStore.select('language');
    this.language$.pipe(takeUntil(this.destroy$)).subscribe((l) => {
      if (l) {
        this.dateAdapter.setLocale(l.code);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
