import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { CompanyPreview } from '../../core/interfaces/company-preview.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotCompaniesGuard implements CanActivate {
  sessionCompanies$: Observable<CompanyPreview[]>;

  constructor(
    private router: Router,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>
  ) {
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | import('@angular/router').UrlTree | import('rxjs').Observable<boolean | import('@angular/router').UrlTree> | Promise<boolean | import('@angular/router').UrlTree> {
    this.sessionCompanies$.subscribe((companies) => {
      if (companies !== undefined && companies.length === 0) {
        this.router.navigateByUrl('/Home');
      }
    });
    return this.sessionCompanies$.pipe(
      map((companies) => companies?.length > 0 || companies === undefined),
      tap((hasCompanies) => {
        if (!hasCompanies) {
          this.router.navigateByUrl('/Home');
        }
      })
    );
  }
}
