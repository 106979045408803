import { Store } from '@ngrx/store';
import { Ncm } from 'src/app/shared/models/ncm.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductToCertificate } from '../../reducers/products-certificate.reducer';
import * as fromAddProductToCertificate from '../../actions/products-certificate.action';
import * as fromProductToCertificateSelector from '../../reducers/products-certificate.reducer';

@Component({
  selector: 'app-certificate-exportation-products',
  templateUrl: './certificate-exportation-products.component.html',
  styleUrls: ['./certificate-exportation-products.component.scss']
})
export class CertificateExportationProductsComponent implements OnInit {
  productEdition: boolean = false;
  productToEdit: ProductToCertificate;
  alreadyParseProducts: boolean = false;
  productToCertificate: ProductToCertificate[] = [];

  @Input() defaultNcm: Ncm;
  @Input() defaultquantity: any;
  @Input() defaultNetWeight: any;
  @Input() defaultUnitMeasure: any;
  @Input() defaultGrossWeight: any;
  @Input() canEdit: boolean = true;
  @Input() defaultUnitMeasureId: any;
  @Input() isOriginCertificate = false;
  @Input() defaultDescriptionProduct: any;
  @Input() useProductValue: boolean = false;

  @Output('isEditProduct') isEditProduct = new EventEmitter<boolean>();

  constructor(private readonly productCertificateStore: Store<{ productToCertificate: ProductToCertificate[] }>) {}

  ngOnInit(): void {
    this.productCertificateStore.select(fromProductToCertificateSelector.getProductToCertificate).subscribe((_productToCertificate: ProductToCertificate[]) => {
      this.productToCertificate = _productToCertificate;
    });
  }

  productAdded(productAdded: boolean) {
    this.productEdition = false;
  }

  editProdct(index) {
    if (index > -1) {
      const productToEdition = this.productToCertificate[index];
      this.productToEdit = productToEdition;
      this.productCertificateStore.dispatch(fromAddProductToCertificate.DeleteproductToCertificateByIndex({ _indexOfProductToCertificate: index }));
      this.emitIsEditProd();
    }
  }

  emitIsEditProd() {
    this.isEditProduct.emit(true);
    this.productEdition = true;
  }
}
