import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectCard } from '../../models/select-card.interface';
import { object_equals } from '../../utils/object-equals';

@Component({
  selector: 'app-select-card-options',
  templateUrl: './select-card-options.component.html',
  styleUrls: ['./select-card-options.component.scss']
})
export class SelectCardOptionsComponent implements OnInit {
  @Input() options: SelectCard<any>[] = [];
  @Input() value: any[] = [];
  @Output() valueChanges = new EventEmitter<any[]>();
  @Input() align: string = 'start start';
  constructor() {}

  ngOnInit(): void {
    this.emitValue();
  }

  updateValue(checked, value) {
    if (checked) {
      this.value.push(value);
      this.emitValue();
    } else {
      let index = this.value.findIndex((v) => object_equals(v, value));
      this.value.splice(index, 1);
      this.emitValue();
    }
  }
  existsValue(value) {
    return !!this.value.find((v) => object_equals(v, value));
  }

  emitValue() {
    this.valueChanges.emit(this.value);
  }
}
