import { Injectable } from '@angular/core';
import { stringify } from 'query-string';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { TransshipmentResponse } from 'src/app/shared/models/transshipment-reponse.interface';
import { Transshipment } from 'src/app/shared/models/transshipment.interface';
import { TransshipmentMethodEnum } from '../enums/new-trade.enum';

@Injectable({
  providedIn: 'root'
})
export class PortService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getSeaPorts(querySeaPorts: { PageIndex: number; PageSize: number; Name: string }): Promise<TransshipmentResponse> {
    let query = stringify(querySeaPorts);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `SeaPort?${query}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<TransshipmentResponse>(request);
      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: [],
        destinationPoint: []
      };
    }
  }

  async getSeaPortsByIds(ids: number[]): Promise<Transshipment[]> {
    let res = 'SeaPort/GetSeaPortsByIds';
    ids.forEach((element, i) => {
      if (i == 0) res += `?ids=${element}`;
      else res += `&ids=${element}`;
    });

    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: res,
      body: null
    };

    try {
      let response = await this.ellosApi.get<Transshipment[]>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getSeaPortsById(id: number): Promise<Transshipment> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `SeaPort/GetSeaPortsByIds?id=${id}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<Transshipment>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getAirports(queryAirports: { PageIndex: number; PageSize: number; Name: string }): Promise<TransshipmentResponse> {
    let query = stringify(queryAirports);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Airport?${query}`,
      body: null
    };
    console.log(request);
    try {
      let response = await this.ellosApi.get<TransshipmentResponse>(request);
      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: [],
        destinationPoint: []
      };
    }
  }

  async getAirportsByIds(ids: number[]): Promise<Transshipment[]> {
    let res = 'Airport/GetAirPortsByIds';
    ids.forEach((element, i) => {
      if (i == 0) res += `?ids=${element}`;
      else res += `&ids=${element}`;
    });

    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: res,
      body: null
    };

    try {
      let response = await this.ellosApi.get<Transshipment[]>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getAirportsById(id: number): Promise<Transshipment> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Airport/GetAirPortsByIds?id=${id}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<Transshipment>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getGenericPortById(id: number, type: TransshipmentMethodEnum): Promise<Transshipment> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: type == TransshipmentMethodEnum.plane ? `Airport/GetAirPortsById?id=${id}` : `SeaPort/GetSeaPortsById?id=${id}`,
      body: null
    };

    try {
      let response = await this.ellosApi.get<Transshipment>(request);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}
