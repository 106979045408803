<!-- title -->
<div fxLayout="row" fxLayoutAlign="center start">
  <div fxLayout="column">
    <p class="mat-h1">{{ 'auth.forgot-password.title' | translate }}</p>
  </div>
</div>
<br />
<!-- stepper -->
<div *ngIf="!resetingPassword" fxLayout="row" fxLayoutAlign="center start">
  <div fxLayout="column" fxFlex="70">
    <mat-horizontal-stepper linear #stepper>
      <!-- #1 step - Send code -->

      <mat-step [stepControl]="forgotPwdForm" [editable]="true">
        <form [formGroup]="forgotPwdForm">
          <ng-template matStepLabel>{{ 'auth.forgot-password.send-code' | translate }}</ng-template>

          <div fxLayout="row" fxLayoutAlign="center start">
            <p class="mat-h2">
              {{ 'auth.forgot-password.send-code-title' | translate }}
            </p>
          </div>
          <div fxLayout="row" fxLayoutAlign="center start">
            <p class="mat-subheading-2">
              {{ 'auth.forgot-password.send-code-subtitle' | translate }}
            </p>
          </div>

          <!-- email -->
          <div fxLayout="row" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>E-mail</mat-label>
              <input matInput autocomplete="off" formControlName="email" placeholder="example@example.com" required />
            </mat-form-field>
          </div>

          <app-recaptcha (valueGrecaptcha)="handleValueRecaptcha($event)" formControlName="recaptcha"></app-recaptcha>

          <br />

          <!-- next button -->
          <div fxLayout="row" fxLayoutAlign="center start">
            <button (click)="onSubmitCode(stepper)" [disabled]="!forgotPwdForm.valid" fxFlexFill mat-raised-button color="accent">
              {{ 'auth.forgot-password.send-code-button' | translate }}
            </button>
          </div>
        </form>
      </mat-step>

      <!-- #2 step - reset password -->

      <mat-step [stepControl]="resetPwdForm" [editable]="true">
        <form [formGroup]="resetPwdForm" (ngSubmit)="onResetPassword()">
          <ng-template matStepLabel>{{ 'auth.forgot-password.reset-password' | translate }}</ng-template>

          <div fxLayout="row" fxLayoutAlign="center start">
            <p class="mat-h2">
              {{ 'auth.forgot-password.reset-password-title' | translate }}
            </p>
          </div>
          <div fxLayout="row" fxLayoutAlign="center start">
            <p class="mat-subheading-2">
              {{ 'auth.forgot-password.reset-password-subtitle' | translate }}
            </p>
          </div>

          <!-- Code -->
          <div fxLayout="row" fxLayoutAlign="center start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'auth.forgot-password.code' | translate }}</mat-label>
              <input matInput autocomplete="off" formControlName="code" required />
            </mat-form-field>
          </div>

          <!-- password -->
          <div fxLayout="column" fxLayoutAlign="start start">
            <mat-form-field fxFlexFill appearance="outline">
              <mat-label>{{ 'auth.forgot-password.newpassword' | translate }}</mat-label>
              <input matInput autocomplete="off" formControlName="newPassword" matInput type="password" required />
              <mat-error *ngIf="resetPwdForm.controls.newPassword.hasError('required')">
                {{ 'error.password-required' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-error *ngIf="resetPwdForm.controls.newPassword.hasError('pattern')"> {{ 'registration.password-hint' | translate }}</mat-error>
          </div>

          <!--Reset Button -->
          <div fxLayout="row" fxLayoutAlign="center start">
            <button [disabled]="!resetPwdForm.valid" fxFlexFill mat-raised-button color="accent">
              {{ 'auth.forgot-password.reset-password-button' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>

<!-- auth loader -->
<app-ellos-spinner *ngIf="resetingPassword" [text]="'auth.forgot-password.reset-password-loader' | translate"></app-ellos-spinner>
