import { createAction, props } from '@ngrx/store';

export const certificateNumber = createAction('[Origin Certificate] Certificate Number', props<{ certificateNumber?: string[] }>());
export const invoiceNumber = createAction('[Origin Certificate] Invoice Number', props<{ invoiceNumber?: string[] }>());
export const date = createAction('[Origin Certificate] Filter by Date', props<{ finalDate: string; startDate: string }>());
export const pageIndex = createAction('[Origin Certificate] Page Index', props<{ pageIndex?: number }>());
export const pageConfig = createAction('[Origin Certificate] Page Config', props<{ pageIndex?: number; pageSize?: number }>());
export const statusAction = createAction('[Origin Certificate] Filter by Status', props<{ status?: [] }>());
export const companyOriginCertificate = createAction('[Origin Certificate] Filter by Company Selected', props<{ idCompany: number }>());
export const clearFilter = createAction('[Origin Certificate] Clear Filter');
export const repeatQuery = createAction('[Origin Certificate] Repeate Query', props<{ repeat?: boolean }>());
