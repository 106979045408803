import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SessionCompanies } from 'src/app/core/actions/session-companies.action';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { AssociateCompany } from 'src/app/core/models/associate-company';
import { CompanyService } from 'src/app/core/services/company.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { QueryCompaniesAction } from '../../actions/query-companies.action';
import { DeleteCompanyDialogComponent } from '../../components/delete-company-dialog/delete-company-dialog.component';
import { InviteUserDialogComponent } from '../../components/invite-user-dialog/invite-user-dialog.component';
import { LeaveCompanyDialogComponent } from '../../components/leave-company-dialog/leave-company-dialog.component';
import { QueryCompanies } from '../../models/query-companies';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  public modules = [];
  public modulePermission = PermissionsEnum;
  displayedColumns: string[] = ['id', 'companyName', 'myRole', 'country', 'status', 'actions'];
  dataSource = new MatTableDataSource<AssociateCompany>([]);
  queryCompanies$: Observable<QueryCompanies>;
  destroy$ = new Subject<any>();
  query: QueryCompanies;
  count: number;
  showRequestNew = true;
  loading = false;
  companiesSession: CompanyPreview[] = [];
  faSignOutAlt = faSignOutAlt;
  @ViewChild('paginator') paginator: MatPaginator;
  private sessionCompanies$: Observable<CompanyPreview[]>;
  constructor(
    private readonly queryCompaniesStore: Store<{
      queryCompanies: QueryCompanies;
    }>,
    private readonly dialog: MatDialog,
    private readonly companyService: CompanyService,
    private readonly permissions: PermissionsService,
    private readonly queryCompaniesState: Store<QueryCompanies>,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>
  ) {
    this.queryCompanies$ = this.queryCompaniesStore.pipe(select('queryCompanies'));
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));
  }

  ngOnInit(): void {
    this.modules = this.permissions.getPermissionStorage();
    this.queryCompanies$.pipe(takeUntil(this.destroy$)).subscribe(async (newQuery) => {
      if (!this.sameQuery(newQuery) && newQuery) {
        this.query = newQuery;
        if (this.query.pageIndex == 0) {
          this.paginator?.firstPage();
        }
        await this.getCompanies();
      }
    });
    this.sessionCompanies$.subscribe((companies) => {
      this.companiesSession = companies ? [...companies] : [];
    });
    this.showRequestNewCompany();
  }

  showRequestNewCompany() {
    const currentCompany = JSON.parse(localStorage.getItem('current-company'));
    if (currentCompany?.id === 1003) {
      this.showRequestNew = false;
    }
  }

  async getCompanies() {
    try {
      this.loading = true;
      const response = await this.companyService.getAssociateCompanies(this.query);
      if (response.companyCount > 0 && response.companies.length == 0) {
      } else {
        this.count = response.companyCount;
        this.dataSource.data = response.companies;
      }
    } catch {
      this.count = 0;
      this.dataSource.data = [];
    } finally {
      this.loading = false;
    }
  }

  sameQuery(newQuery: QueryCompanies): boolean {
    return (
      newQuery &&
      this.query?.companyName === newQuery?.companyName &&
      this.query?.profileCode === newQuery?.profileCode &&
      this.query?.pageIndex === newQuery?.pageIndex &&
      this.query?.pageSize === newQuery?.pageSize
    );
  }

  pageChange(event) {
    const query: QueryCompanies = {
      companyName: this.query.companyName,
      profileCode: this.query.profileCode,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    };
    this.queryCompaniesState.dispatch(QueryCompaniesAction(query));
  }

  inviteUser(idCompany: number): void {
    const dialogRef = this.dialog.open(InviteUserDialogComponent, {
      data: idCompany,
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
        }
      });
  }
  leaveCompanyDialog(company: AssociateCompany) {
    const dialogRef = this.dialog.open(LeaveCompanyDialogComponent, {
      data: { idCompany: company.id, companyName: company.companyName },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: boolean) => {
        if (result) {
          this.removeCompanyFromList(company);
          this.removeCompanyFromState(company);
        }
      });
  }
  DeleteCompanyDialog(company: AssociateCompany) {
    const dialogRef = this.dialog.open(DeleteCompanyDialogComponent, {
      data: {
        idCompany: company.id,
        companyName: company.companyName,
        role: company.role
      },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: boolean) => {
        if (result) {
          this.removeCompanyFromList(company);
          this.removeCompanyFromState(company);
        }
      });
  }

  removeCompanyFromList(company: AssociateCompany) {
    const index = this.dataSource.data.findIndex((c) => c.id === company.id);
    if (index >= 0) {
      const data = [...this.dataSource.data];
      data.splice(index, 1);
      this.dataSource.data = data;
      if (data.length === 0) {
        this.pageChange(0);
      }
    }
  }

  removeCompanyFromState(company: AssociateCompany) {
    const index = this.companiesSession.findIndex((c) => c.id === company.id);
    if (index >= 0) {
      this.companiesSession.splice(index, 1);
      this.sessionCompaniesState.dispatch(SessionCompanies(this.companiesSession));
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
