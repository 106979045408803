import { QueryCompanies } from '../models/query-companies';
import { Action } from '@ngrx/store';
import { ActionTypes } from '../../../core/models/actionTypes';
import { QueryCompanyMember } from '../models/query-company-member';

export const QueryCompanyMembersAction = (queryCompaniesMembers: QueryCompanyMember) => {
  return <Action>{
    type: ActionTypes.QueryCompanyMembers,
    payload: queryCompaniesMembers
  };
};
