import { Action } from '@ngrx/store';
import { ActionTypes } from '../../../core/models/actionTypes';
import { QueryMatchmaking } from '../interfaces/queryMatchmaking.interface';

export const QueryNewMatchmaking = (queryMatchmaking: QueryMatchmaking) => {
  return <Action>{
    type: ActionTypes.QueryNewMatchmaking,
    payload: queryMatchmaking
  };
};
