import { Injectable } from '@angular/core';
import { ErrorHandleDialogComponent } from '@ccab/components-lib';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EllosSpinnerComponent } from 'src/app/shared/components/ellos-spinner/ellos-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class ModalManagerService {
  private loadingModalRef: MatDialogRef<EllosSpinnerComponent> = null;
  private dialogErrorModalRef: MatDialogRef<ErrorHandleDialogComponent> = null;
  public _isLoadingOpen: boolean = true;

  constructor(private readonly _dialog: MatDialog) {}

  public get getIsloadingOpen(): boolean {
    return this._isLoadingOpen;
  }

  public set setIsloadingOpen(state: boolean) {
    this._isLoadingOpen = state;
  }

  /**
   * Modal with loading animation. Used when some HTTP request is occurring.
   */
  openLoadingModal(): void {
    if (!this.loadingModalRef) {
      this._isLoadingOpen = true;
      this.loadingModalRef = this._dialog.open(EllosSpinnerComponent, {
        panelClass: 'custom-modalbox',
        disableClose: true
      });
    }
  }

  closeLoadingModal(): void {
    if (this.loadingModalRef) {
      this._isLoadingOpen = false;
      this.loadingModalRef.close();
      this.loadingModalRef = null;
    }
  }

  closeDialogError(): void {
    if (this.dialogErrorModalRef) {
      this.dialogErrorModalRef.close();
      this.dialogErrorModalRef = null;
    }
  }
}
