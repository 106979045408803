import EllosAction from '../interfaces/ellosAction.interface';
import Language from '../interfaces/language.interface';
import { ActionTypes } from '../models/actionTypes';
import { StoreKey } from '../interfaces/storeKey.interface';

export function languageReducer(state: Language, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.ChangeLanguage: {
      state = action.payload;
      localStorage.setItem(StoreKey.language, state.code);
      return state;
    }

    default:
      return state;
  }
}
