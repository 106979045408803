import { PaymentProcessSimpleItem } from './paymentProcessSimpleItem.interface';
import { Currency } from './currency.enum';
import { StatusCheckoutEasytrade } from './statusCheckoutEasytrade';

export class PaymentEasytradeListPaid {
  Id: string;
  Services: PaymentProcessSimpleItem[];
  Price: number;
  Currency: Currency;
  Status: StatusCheckoutEasytrade;

  constructor(Id: string, Itens: PaymentProcessSimpleItem[], Price: number, Currency: Currency, Status: StatusCheckoutEasytrade) {
    this.Id = Id;
    this.Services = Itens;
    this.Price = Price;
    this.Currency = Currency;
    this.Status = Status;
  }
}
