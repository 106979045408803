import { createAction, props } from '@ngrx/store';
import { ProductToExportation } from '../reducers/products-exportation.reducer';

export const enum productsToExportationTypeAction {
  ADD_PRODUCT = '[PRODUCT_OF_EXPORTATION] ADD PRODUCT',
  ADD_ALL_PRODUCT = '[PRODUCT_OF_EXPORTATION] ADD ALL PRODUCT',
  REMOVE_PRODUCT_BY_INDEX = '[PRODUCT_OF_EXPORTATION] REMOVE PRODUCT BY INDEX',
  UP_PRODUCT_BY_INDEX = '[PRODUCT_OF_EXPORTATION] UP PRODUCT BY INDEX',
  DOWN_PRODUCT_BY_INDEX = '[PRODUCT_OF_EXPORTATION] DOWN PRODUCT BY INDEX',
  CLEAN_PRODUCTS = '[PRODUCT_OF_EXPORTATION]  CLEAN PRODUCTS',
  SET_PRODUCTS = '[PRODUCT_OF_EXPORTATION]  SET PRODUCTS',
  VALIDATE_SIF_PRODUCTS = '[PRODUCT_OF_EXPORTATION]  VALIDATE SIF'
}

export const AddAllProductToExportation = createAction(productsToExportationTypeAction.ADD_ALL_PRODUCT, props<{ _productToExportation: ProductToExportation[] }>());

export const AddProductToExportation = createAction(productsToExportationTypeAction.ADD_PRODUCT, props<{ _productToExportation: ProductToExportation }>());

export const DeleteproductToExportationByIndex = createAction(productsToExportationTypeAction.REMOVE_PRODUCT_BY_INDEX, props<{ _indexOfProductToExportation: number }>());

export const UpProductToExportation = createAction(productsToExportationTypeAction.UP_PRODUCT_BY_INDEX, props<{ _indexOfProductToExportation: number }>());

export const DownProductToExportation = createAction(productsToExportationTypeAction.DOWN_PRODUCT_BY_INDEX, props<{ _indexOfProductToExportation: number }>());

export const CleanProductsToExportation = createAction(productsToExportationTypeAction.CLEAN_PRODUCTS);

export const SetProductsToExportation = createAction(productsToExportationTypeAction.SET_PRODUCTS, props<{ _productsToExportation: ProductToExportation[] }>());

export const ValidateSifProductsToExportation = createAction(productsToExportationTypeAction.VALIDATE_SIF_PRODUCTS);
