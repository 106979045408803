import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[trimInput]'
})
export class TrimInputDirective {
  constructor(private readonly _el: ElementRef) {}

  @HostListener('focusout', ['$event.target.value'])
  onBlur(value) {
    const isWhitespace = value.trim().length === 0;
    const isValid = !isWhitespace || value == '';
    isValid ? (this._el.nativeElement.value = value.toString().trim()) : (this._el.nativeElement.value = null);
  }
}
