import EllosAction from '../../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../../core/models/actionTypes';
import { Meeting } from '../interfaces/meeting.interface';

export function meetingReducer(state: Meeting, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.Meeting: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
