import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-notification-template',
  templateUrl: './modal-notification-template.component.html',
  styleUrls: ['./modal-notification-template.component.scss']
})
export class ModalNotificationTemplateComponent implements OnInit {
  public message1: string;
  public message2: string;
  public title: string;

  constructor(
    public readonly dialogRef: MatDialogRef<ModalNotificationTemplateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { message1: string; message2: string; title?: string }
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.message1 = this.data.message1;
    this.message2 = this.data.message2;
  }
}
