import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Timezone } from '../../models/timezone';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { TimezonesAction } from '../../actions/timezone.action';
import { TimezoneService } from '../../services/timezone.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent implements OnInit {
  destroy$: Subject<any> = new Subject<any>();
  timezones: Timezone[] = [];
  filteredTimezones: Observable<Timezone[]>;
  @Input() required: boolean = false;
  @Output() timezone = new EventEmitter<Timezone>();
  @Input() companyTimezone: Timezone;

  inputSearch: FormControl;
  selectedTimezone: FormControl;
  currentTheme: string;

  private timezones$: Observable<Timezone[]>;

  constructor(
    private readonly timezonesState: Store<{ timezones: Timezone[] }>,
    private readonly timezoneStore: Store<Timezone[]>,
    private readonly timezoneService: TimezoneService
  ) {
    this.timezones$ = this.timezonesState.pipe(select('timezones'));
  }

  async ngOnInit() {
    this.inputSearch = this?.required == true ? new FormControl(null, [Validators.required]) : new FormControl();
    this.selectedTimezone = this?.required == true ? new FormControl(null, [Validators.required]) : new FormControl();
    this.selectedTimezone.markAllAsTouched();

    try {
      this.timezones$.pipe(takeUntil(this.destroy$)).subscribe((timezones) => {
        if (timezones) {
          this.timezones = timezones;
          let index = this.timezones.findIndex((t) => t.name == this.companyTimezone?.name);
          if (index > -1) {
            this.setTimezone(this.timezones[index]);
          }

          this.filteredTimezones = this.inputSearch.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value))
          );
        } else {
          this.dispatchTimezones();
        }
      });
    } catch (e) {}
  }
  async dispatchTimezones() {
    let response = await this.timezoneService.getTimeZone();
    this.timezoneStore.dispatch(TimezonesAction(response));
  }

  resetInput() {
    this.inputSearch.setValue('');
  }
  private _filter(value: string): Timezone[] {
    const filterValue = value.toLowerCase();

    return this.timezones.filter((timezone) => timezone.name.toLowerCase().indexOf(filterValue) > -1 || timezone.offset === +filterValue);
  }
  cancelEvent(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  setTimezone(timezone: Timezone) {
    this.selectedTimezone.setValue(timezone);
    this.timezone.emit(timezone);
    this.resetInput();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
