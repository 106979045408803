import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-not-auth-layout',
  templateUrl: './not-auth-layout.component.html',
  styleUrls: ['./not-auth-layout.component.scss']
})
export class NotAuthLayoutComponent implements OnInit {
  isCertificate = true;
  destroy$: Subject<any> = new Subject();

  faYoutube = faYoutube;
  faLinkedin = faLinkedin;
  faInstagram = faInstagram;
  faGlobeAfrica = faGlobeAfrica;
  faTwitterSquare = faTwitterSquare;
  faQuestionCircle = faQuestionCircle;
  faFacebookSquare = faFacebookSquare;

  year = new Date().getFullYear();

  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.isCertificate = this.router.url === '/Certificate';
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        this.isCertificate = event.url === '/Certificate';
      });
  }
}
