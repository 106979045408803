import { Injectable } from '@angular/core';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { InviteInformations } from '../../auth/models/invite-informations';
import { InviteDetail } from '../models/invite-detail.interface';
import { InviteRequest } from '../models/invite-request.interface';
import { InviteResponse } from '../models/invite-response.interface';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getReceivedInvites(pageIndex: number, pageSize: number): Promise<InviteResponse> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Invite?PageIndex=${pageIndex}&PageSize=${pageSize}`,
      body: null
    };

    let response = await this.ellosApi.get<InviteResponse>(request);
    return response.data;
  }

  async getInviteDetail(idInvitation: number): Promise<InviteDetail> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Invite/detail?idInvitation=${idInvitation}`,
      body: null
    };

    let response = await this.ellosApi.get<InviteDetail>(request);
    return response.data;
  }
  async sendInvite(newInvite: InviteRequest) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: 'Invite',
      body: newInvite
    };

    try {
      let response = await this.ellosApi.post<any>(request);
      return response.success;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
  async declineInvite(idInvite: number) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Invite/${idInvite}`,
      body: null
    };

    try {
      let response = await this.ellosApi.delete<any>(request);
      return response.success;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  async acceptInvite(idInvite: number) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Invite/accept/${idInvite}`,
      body: null
    };

    try {
      let response = await this.ellosApi.put<any>(request);
      return response.success;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
  async checkInviteCode(inviteCode: string) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Invite/${inviteCode}`,
      body: null
    };

    let response = await this.ellosApi.get<InviteInformations>(request);
    return response.data;
  }
}
