import EllosAction from '../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../core/models/actionTypes';

export function sessionUserReducer(state: string, action: EllosAction) {
  switch (action.type) {
    case ActionTypes.SessionUser: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
