import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from '../../../../core/services/company.service';

@Component({
  selector: 'app-delete-company-dialog',
  templateUrl: './delete-company-dialog.component.html',
  styleUrls: ['./delete-company-dialog.component.scss']
})
export class DeleteCompanyDialogComponent implements OnInit {
  waitingResponse: boolean = false;

  constructor(
    private readonly dialogRef: MatDialogRef<DeleteCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { idCompany: number; companyName: string; role: string },
    private readonly companyService: CompanyService
  ) {}

  ngOnInit(): void {}

  async DeleteCompany() {
    try {
      this.waitingResponse = true;
      await this.companyService.DeleteCompany(this.data.idCompany, this.data.role);
      this.dialogRef.close(true);
    } catch (e) {
    } finally {
      this.waitingResponse = false;
    }
  }
}
