import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dot-loader',
  templateUrl: './dot-loader.component.html',
  styleUrls: ['./dot-loader.component.scss']
})
export class DotLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
