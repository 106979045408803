import * as AddressToExportationAction from '../actions/exportation-delivery-address.action';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export interface AddressToExportation {
  idExportationDeliveryAddressForReturning: number;
  idExportation: string;
  idAssociate: number;
  idCompany: number;
  lastChangeDate: string;
  fullName: string;
  countryCode: string;
  zipCode: string;
  city: string;
  state: string;
  addressLine: string;
  number: string;
  neighborhood: string;
  complement: string;
  poBox: string;
  trackingCode: null | string;
  carrierName: null | string;
  isActive: boolean;
}

export interface AddressToExportationState {
  addressToExportation: AddressToExportation[];
}

export const initialState: AddressToExportationState = { addressToExportation: [] };

const _addressToExportation = createReducer(
  initialState,
  on(AddressToExportationAction.AddAddressToExportation, (state, { _addressToExportation }) => {
    return {
      ...state,
      addressToExportation: [...state.addressToExportation, _addressToExportation]
    };
  }),
  on(AddressToExportationAction.AddAllAddressToExportation, (state, { _addressToExportation }) => {
    return {
      ...state,
      addressToExportation: _addressToExportation
    };
  }),
  on(AddressToExportationAction.CleanAddresssToExportation, (state) => ({
    ...state,
    addressToExportation: []
  })),
  on(AddressToExportationAction.DeleteAddressToExportationById, (state, { _idAddress }) => ({
    ...state,
    addressToExportation: removeItemById(state.addressToExportation, _idAddress)
  })),
  on(AddressToExportationAction.SetAddresssByIdToExportation, (state, { _addresssToExportation }) => ({
    ...state,
    addressToExportation: newAddress(_addresssToExportation, state.addressToExportation)
  }))
);

export function addressToExportationReducer(state = initialState, action: Action) {
  return _addressToExportation(state, action);
}

const getAddressToExportationState = createFeatureSelector<AddressToExportationState>('addressToExportation');

export const getAddressToExportation = createSelector(getAddressToExportationState, (state: AddressToExportationState) => state.addressToExportation);

export function removeItemById(arr: AddressToExportation[], id: number): AddressToExportation[] {
  const index = arr.findIndex((item) => item.idExportationDeliveryAddressForReturning === id);
  if (index !== -1) {
    const newArr = [...arr.slice(0, index), ...arr.slice(index + 1)];
    return newArr;
  }
  return arr;
}

export function newAddress(_addressToExportation, addressToExportationState: AddressToExportation[]): AddressToExportation[] {
  const updatedAddressArray = addressToExportationState.map((address) => {
    if (address.idExportationDeliveryAddressForReturning === _addressToExportation.idExportationDeliveryAddressForReturning) {
      return { ..._addressToExportation };
    }
    return address;
  });

  return updatedAddressArray;
}
