<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px" fxFlex="auto">
  <p class="mat-h2">
    {{ 'settings.profile.preferences.what-your-interests' | translate }}
  </p>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'settings.profile.preferences.interests' | translate }}</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip *ngFor="let interest of values" selectable="true" [removable]="true" (removed)="removeInterest(interest)" color="accent" selected>
        {{ interest }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        [placeholder]="'settings.profile.preferences.interests-placeholder' | translate"
        [matChipInputFor]="chipList"
        autocomplete="off"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addInterest($event)"
      />
    </mat-chip-list>
  </mat-form-field>
</div>
