import { FormGroup } from '@angular/forms';
export function checkGrossValidator(formGroup: FormGroup) {
  const grossweight = formGroup.get('grossWeight')?.value ?? 0;
  const netweight = formGroup.get('netWeight')?.value ?? 0;

  if (grossweight + netweight) {
    return netweight <= grossweight ? null : { checkGrossValidator: true };
  } else {
    return null;
  }
}
