import { Injectable } from '@angular/core';
import { Country } from '../models/country';

import { EllosApiService } from '../../core/http/ellosApi.service';

import { EllosApi } from '../../core/interfaces/ellosApi.interface';
import EllosRequest from '../../core/interfaces/ellosRequest.interface';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countries: Country[];
  isLoaded: boolean = false;
  constructor(private readonly ellosApi: EllosApiService) {}

  async getCountries(): Promise<Country[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Showcase,
      resource: `Country`,
      body: null
    };

    if (!this.isLoaded) {
      let response;
      response = await this.ellosApi.get<Country[]>(request);
      this.countries = response.data;
      this.isLoaded = true;

      return this.countries;
    }
    this.isLoaded = true;
    return this.countries;
  }
}
