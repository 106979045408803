import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Contact } from '../../models/contact';
import { SocialMedia } from '../../models/social-media';
import { CompanyDetail } from '../../models/company-detail';
import { EllosEvent } from '../../models/ellos-event.interface';

import { CompanyService } from 'src/app/core/services/company.service';
import { CrmService } from 'src/app/shared/services/crm-service.service';
import { select, Store } from '@ngrx/store';

import { Sector } from 'src/app/shared/models/sector';

import Language from 'src/app/core/interfaces/language.interface';

import { PrimaryChapter } from 'src/app/shared/models/primary-chapter';

import { faFacebook, faFacebookSquare, faInstagram, faLinkedin, faTwitter, faTwitterSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobeAfrica, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit {
  classePaisBandeira: string;
  companyId: number;
  companyDetail: CompanyDetail;
  companyLogo: string;
  companyCover: string;
  socialMedia: SocialMedia[];
  products = [];
  contacts: Contact[];
  language$: Observable<Language>;
  destroy$ = new Subject();
  faYoutube = faYoutube;
  faLinkedin = faLinkedin;
  faInstagram = faInstagram;
  faGlobeAfrica = faGlobeAfrica;
  faTwitterSquare = faTwitterSquare;
  faQuestionCircle = faQuestionCircle;
  faFacebookSquare = faFacebookSquare;
  currentView: number = 0;
  language: string;
  sector: Sector;
  primaryChapter: PrimaryChapter;
  events: EllosEvent[] = [];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly langStore: Store<{ language: Language }>,
    private readonly crmService: CrmService,
    private readonly router: Router,
    private readonly companyService: CompanyService
  ) {
    this.language$ = langStore.pipe(select('language'));
  }

  renderProducts() {
    return this.products.join(', ');
  }

  getIcon(media) {
    switch (media) {
      case 'facebook':
        return this.faFacebookSquare;
      case 'instagram':
        return this.faInstagram;
      case 'linkedin':
        return this.faLinkedin;
      case 'twitter':
        return this.faTwitterSquare;
      default:
        break;
    }
  }

  renderLogo() {
    if (this.companyLogo === '' || !this.companyLogo || this.companyLogo == null) {
      this.companyLogo = '../../../../assets/img/empty-company-logo.png';
    } else {
      this.companyLogo = 'data:image/png;base64,' + this.companyLogo;
    }
  }

  renderCover() {
    if (this.companyCover === '' || !this.companyCover || this.companyCover === null) {
      this.companyCover = '../../../../assets/img/store-cover.jpg';
    } else {
      this.companyCover = 'data:image/png;base64,' + this.companyCover;
    }
  }

  redirectToCompanies() {
    this.router.navigateByUrl('/Settings/Companies');
  }

  async loadContent(event: number) {
    this.currentView = event;
    switch (event) {
      case 2:
        if (!this.socialMedia || !this.contacts) {
          this.socialMedia = await this.companyService.getSocialMedia(this.companyId);
          this.contacts = await this.companyService.getCompanyContacts(this.companyId);
        }
        break;
    }
  }

  returnLanguage(codeLang: string): string {
    switch (codeLang) {
      case 'en':
        return 'English';
      case 'pt':
        return 'Portuguese';
      default:
        break;
    }
    return '';
  }

  checkTypeCompany(): boolean {
    let retorno = true;
    if (this.companyDetail.profiles?.length > 0) {
      if (this.companyDetail.profiles?.includes('SHP')) {
        retorno = false;
      }
    }
    return retorno;
  }

  async ngOnInit() {
    this.companyId = +this.route.snapshot.paramMap.get('id');

    if (isNaN(this.companyId) || this.companyId < 1) {
      this.redirectToCompanies();
    }

    try {
      this.companyDetail = await this.companyService.companyDetail(this.companyId);
      this.companyLogo = await this.companyService.companyLogo(this.companyId);
      this.companyCover = await this.companyService.companyCover(this.companyId);
      this.products = await this.companyService.getProductsTag(this.companyId);

      this.classePaisBandeira = 'fi fi-' + this.companyDetail.country.name.substring(0, 2).toLowerCase();
      this.renderLogo();
      this.renderCover();
      this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
        if (lang) {
          this.language = lang.code;
          if (this.companyDetail.sectorId) {
            try {
              this.sector = await this.crmService.getSectorById(this.companyDetail.sectorId, this.returnLanguage(this.language));
            } catch (e) {}
          }
          if (this.companyDetail.primaryChapterId) {
            try {
              this.primaryChapter = await this.crmService.getPrimaryChapterById(
                this.companyDetail.sectorId,
                this.companyDetail.primaryChapterId,
                this.returnLanguage(this.language)
              );
            } catch (e) {}
          }
        }
      });

      if (!this.companyDetail) this.redirectToCompanies();
    } catch (e) {
      this.redirectToCompanies();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
