<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title class="mat-dialog-title">
    <div fxLayout="row" fxLayoutAlign="space-between center ">
      <p></p>
      <h1 fxFlexAlign="center">{{ 'matchmaking.dialog.title' | translate }}</h1>
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-divider></mat-divider>
  </div>

  <form [formGroup]="newMatchmaking" fxLayout="column" autocomplete="off" fxFlex="grow" fxLayoutAlign="start start " *ngIf="!loading">
    <div class="main-content" fxLayout="column" fxLayoutAlign="start start ">
      <h4>{{ 'matchmaking.dialog.description' | translate }}</h4>

      <div fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="start start ">
        <mat-form-field color="accent" appearance="outline" class="category p-0">
          <mat-label>{{ 'marketplace.dialog.category' | translate }}</mat-label>
          <mat-select required (valueChange)="getSubcategories($event)" formControlName="category">
            <mat-option *ngFor="let c of category" [value]="c.id">
              {{ c.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="accent" appearance="outline" class="subcategory p-0">
          <mat-label>{{ 'marketplace.dialog.subcategory' | translate }}</mat-label>
          <mat-select required (selectionChange)="subcategoryChange($event.value)" formControlName="idSubCategory">
            <mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id">
              {{ subcategory.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" appearance="outline" class="profile">
          <mat-label>{{ 'matchmaking.dialog.company-profile' | translate }}</mat-label>
          <mat-select required formControlName="companyProfile">
            <mat-option value="importer">
              {{ 'matchmaking.dialog.importer' | translate }}
            </mat-option>
            <mat-option value="exporter">
              {{ 'matchmaking.dialog.exporter' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field class="input-100 mt-10" appearance="outline" color="accent">
        <mat-label>{{ 'matchmaking.dialog.products-interest' | translate }}</mat-label>
        <mat-chip-list #chipListProducts required>
          <mat-chip
            *ngFor="let interestProduct of interestProducts.value"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(interestProduct, 'chipListProducts')"
            selected
            color="accent"
          >
            {{ interestProduct }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            (blur)="newMatchmaking.controls.products.markAsTouched()"
            [matChipInputFor]="chipListProducts"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event, 'chipListProducts')"
            [matChipInputAddOnBlur]="true"
            [formArrayName]="'products'"
          />
          <mat-error *ngIf="newMatchmaking.controls.products.touched && !newMatchmaking.controls.products.valid"> {{ 'matchmaking.dialog.chip-required' | translate }}</mat-error>
        </mat-chip-list>
      </mat-form-field>

      <mat-form-field class="input-100 mt-10" appearance="outline" color="accent">
        <mat-label>{{ 'matchmaking.dialog.countries-interest' | translate }}</mat-label>
        <mat-chip-list #chipListCountries required>
          <mat-chip
            *ngFor="let interestCountry of interestCountries.value"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(interestCountry, 'chipListCountries')"
            selected
            [selectable]="true"
            color="accent"
            class="chip-accent"
          >
            {{ interestCountry }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            (blur)="interestCountries.markAsTouched()"
            [formControl]="countriesCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipListCountries"
            [matChipInputAddOnBlur]="true"
            #countriesInput
          />
          <mat-error *ngIf="interestCountries.touched && !interestCountries.valid"> {{ 'matchmaking.dialog.chip-required' | translate }}</mat-error>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, 'chipListCountries')">
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.id">
            {{ country.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="15px" class="mt-10">
        <mat-form-field class="input" appearance="outline" color="accent">
          <mat-label>{{ 'matchmaking.dialog.number-meetings' | translate }}</mat-label>
          <mat-select formControlName="numberOfMeetings" required>
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" class="options" fxLayoutGap="50px">
        <app-ellos-option-toggle
          [display]="translateToggle"
          (value)="translatorNeeded = $event"
          [title]="'matchmaking.dialog.need-translator' | translate"
        ></app-ellos-option-toggle>
        <app-ellos-option-toggle [display]="meetTypeToggle" (value)="meetingType = $event" [title]="'matchmaking.dialog.meeting-type' | translate"></app-ellos-option-toggle>
        <app-ellos-option-toggle [display]="meetTimeToggle" (value)="bestTime = $event" [title]="'matchmaking.dialog.best-time' | translate"></app-ellos-option-toggle>
      </div>
      <mat-form-field class="input-100 mt-10" appearance="outline" color="accent">
        <mat-label>{{ 'matchmaking.dialog.restrictions' | translate }}</mat-label>
        <input matInput formControlName="restrictions" />
      </mat-form-field>

      <mat-form-field class="input-100 mt-10" appearance="outline" color="accent">
        <mat-label>{{ 'matchmaking.dialog.coments' | translate }}</mat-label>
        <input matInput formControlName="comments" />
      </mat-form-field>

      <section class="example-section">
        <mat-checkbox class="example-margin" (change)="acceptTerms = !acceptTerms">
          {{ 'matchmaking.dialog.declare-term-start' | translate }}
          <a class="term">{{ 'matchmaking.dialog.declare-term-conditions' | translate }}</a
          >{{ 'matchmaking.dialog.declare-term-final' | translate }}
        </mat-checkbox>
      </section>
    </div>
  </form>
  <app-ellos-spinner [text]="'marketplace.details.loading' | translate" *ngIf="loading"></app-ellos-spinner>
  <mat-divider class="divider"></mat-divider>
  <div mat-dialog-actions class="dialog-content" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="onNoClick()" [disabled]="loading">
      {{ ('matchmaking.dialog.cancel-button' | translate).toUpperCase() }}
    </button>
    <button
      mat-button
      type="submit"
      (click)="onSubmit()"
      [disabled]="
        !acceptTerms ||
        !newMatchmaking.valid ||
        interestCountriesId.value.length <= 0 ||
        interestProducts.value.length <= 0 ||
        loading ||
        translatorNeeded == null ||
        !meetingType ||
        !bestTime
      "
    >
      {{ ('matchmaking.dialog.confirm-button' | translate).toUpperCase() }}
    </button>
  </div>
</div>
