import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EllosSpinnerComponent } from 'src/app/shared/components/ellos-spinner/ellos-spinner.component';
import { CompanyPreview } from '../interfaces/company-preview.interface';
import { CompanyService } from '../services/company.service';
import { PermissionsService } from '../services/permissions.service';

@Injectable({ providedIn: 'root' })
export class CompanyResolver implements Resolve<CompanyPreview[]> {
  constructor(private readonly companyService: CompanyService, private readonly dialog: MatDialog, private permissionsService: PermissionsService) {}
  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const spinner = this.dialog.open(EllosSpinnerComponent, {
      disableClose: true,
      panelClass: 'custom-modalbox'
    });

    return this.companyService
      .getCompanies({ pageIndex: 0, pageSize: 5 })
      .then((res: any) => {
        const dataUser = JSON.parse(localStorage.getItem('user'));

        const currentCompany = JSON.parse(localStorage.getItem('current-company'));

        if (res.length && dataUser.email) {
          // busca as permissões do usuario
          this.permissionsService
            .getPermissions({
              idCompany: currentCompany.id ? currentCompany.id : res[0].id,
              userEmail: dataUser.email
            })
            .then((response) => {
              // salva as permissões do usuario no localStorage
              this.permissionsService.setPermissionStorage(response);
              spinner.close();
            })
            .finally(() => spinner.close());
        } else {
          spinner.close();
        }
      })
      .catch(() => {
        spinner.close();
      });
  }
}
