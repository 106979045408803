import { Company } from '../interfaces/company.interface';
import EllosAction from '../interfaces/ellosAction.interface';
import { ActionTypes } from '../models/actionTypes';
import { StoreKey } from '../interfaces/storeKey.interface';
import { CompanyPreview } from '../interfaces/company-preview.interface';

export function sessionCompaniesReducer(state: CompanyPreview[], action: EllosAction) {
  switch (action.type) {
    case ActionTypes.SessionCompanies: {
      state = action.payload;
      return state;
    }

    default:
      return state;
  }
}
