<mat-form-field appearance="outline" fxFill floatLabel="always">
  <mat-label>{{ label | translate }}</mat-label>
  <input
    type="text"
    aria-label="Number"
    matInput
    autocomplete="off"
    #searchInput
    [required]="!readonlyField"
    [placeholder]="'shared.port-autocomplete.placeholder' | translate"
    [formControl]="inputSearch"
    [matAutocomplete]="auto"
    [matAutocompleteDisabled]="autocompletePanelOpen"
    (input)="autocompletePanelOpen = true"
  />

  <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete" (optionSelected)="setTransship($event.option.value)">
    <ng-container *ngFor="let transship of transshipments$ | async">
      <mat-option *ngIf="shouldShow(transship)" [value]="transship"> {{ transship.name }} ({{ transship.code }}) </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
