import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { tap } from 'rxjs/operators';
import { PermissionsEnum } from '../enums/permissions.enum';
import { PermissionsService } from '../services/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsGuard implements CanActivate {
  constructor(private router: Router, private permissionsService: PermissionsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | import('@angular/router').UrlTree | import('rxjs').Observable<boolean | import('@angular/router').UrlTree> | Promise<boolean | import('@angular/router').UrlTree> {
    if (!this.permissionsService.validatePermission(PermissionsEnum.AcessarSettingsHome)) {
      this.router.navigateByUrl('/Home');
    }

    return this.permissionsService.validatePermission(PermissionsEnum.AcessarSettingsHome);
  }
}
