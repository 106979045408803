import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-access-invite-dialog',
  templateUrl: './request-access-invite-dialog.component.html',
  styleUrls: ['./request-access-invite-dialog.component.scss']
})
export class RequestAccessInviteDialogComponent implements OnInit {
  CompanyFound: boolean;
  newUser: boolean = false;

  constructor(private readonly router: Router, private readonly dialogRef: MatDialogRef<RequestAccessInviteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  async routerLink() {
    if (!this.data.newUser) {
      await this.router.navigateByUrl('/Settings/Companies');
    }
  }
}
