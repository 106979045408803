import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/core/interfaces/company.interface';
import Language from 'src/app/core/interfaces/language.interface';
import { CompanyService } from 'src/app/core/services/company.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { QueryNewMatchmaking } from '../../actions/matchmaking.action';
import { QueryMatchmaking } from '../../interfaces/queryMatchmaking.interface';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  rangeDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  company$: Observable<Company>;
  language$: Observable<Language>;

  destroy$ = new Subject();

  selectedCompany: Company;
  firstLoad: boolean = true;
  side: string;
  today = new Date();
  selectedFilter = 'all';
  currentCompany: Company;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private readonly companyService: CompanyService,
    private readonly languageStore: Store<{ language: Language }>,
    private readonly companyStore: Store<{ company: Company }>,
    private readonly languageService: LanguageService,
    private readonly matchmakingState: Store<QueryMatchmaking>,
    private readonly router: Router
  ) {
    this.company$ = companyStore.pipe(select('company'));
    this.language$ = languageStore.pipe(select('language'));
  }

  async ngOnInit(): Promise<any> {
    this.company$.pipe(takeUntil(this.destroy$)).subscribe((comp: Company) => {
      if (comp) {
        this.resetCalendar();

        this.selectedFilter = 'all';
        this.currentCompany = comp;
        this.sendNewMatchmakingQuery();
      }
    });

    this.language$.pipe(takeUntil(this.destroy$)).subscribe((lang: Language) => {
      if (lang && this.currentCompany) {
        this.resetCalendar();

        this.selectedFilter = 'all';

        this.sendNewMatchmakingQuery();
      }
    });
  }
  ngAfterViewInit() {
    this.inputFilter();
  }

  inputFilter() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(800),
        switchMap(async () => this.sendNewMatchmakingQuery())
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  filterDate() {
    if ((this.rangeDate.controls.start.value && this.rangeDate.controls.end.value) || (this.rangeDate.controls.start.value == null && this.rangeDate.controls.end.value == null)) {
      if (this.rangeDate.controls.end.value !== null) {
        let date = this.rangeDate.controls.end.value;
        date.setHours(this.rangeDate.controls.end.value.getHours() + 23, 59);
        this.rangeDate.controls.end.setValue(date);
      }
      this.sendNewMatchmakingQuery();
    }
  }
  resetCalendar() {
    this.rangeDate.controls.start.setValue(undefined);
    this.rangeDate.controls.end.setValue(undefined);
    if (this.searchInput?.nativeElement) this.searchInput.nativeElement.value = '';
  }

  sendNewMatchmakingQuery(): void {
    if (this.currentCompany) {
      let query: QueryMatchmaking = {
        IdCompany: this.currentCompany.id,
        LanguageCode: this.languageService.getCurrentLanguage(),
        MainFilter: this.searchInput?.nativeElement.value,
        EndDate:
          this.rangeDate.controls.end.value == null
            ? undefined
            : this.rangeDate.controls.end.value.toLocaleDateString('en') + ' ' + this.rangeDate.controls.end.value.toLocaleTimeString('pt-BR'),
        InitDate: this.rangeDate.controls.start.value == null ? undefined : this.rangeDate.controls.start.value.toLocaleDateString('en'),
        HistoricalData: this.router.url === '/Matchmaking/Meetings/Pasts',
        PageSize: 20,
        PageIndex: 0,
        filter: this.selectedFilter
      };

      this.matchmakingState.dispatch(QueryNewMatchmaking(query));
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
