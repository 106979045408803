<div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="25px">
  <div fxFlex="row wrap" fxFill fxLayoutAlign="space-between start ">
    <app-search-company-member [idCompany]="companyId" class="search"></app-search-company-member>
    <div class="invite-button-content">
      <button color="accent" class="invite-button" mat-stroked-button fxFlexAlign="start end" (click)="inviteUser()" *ngIf="userRole?.toLowerCase() === 'admin'">
        <span class="button-text">{{ 'settings.companies.invite-user' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<div class="content-table">
  <div class="loading-shade" *ngIf="loading">
    <app-ellos-spinner [text]="'marketplace.details.loading' | translate"></app-ellos-spinner>
  </div>
  <table mat-table [dataSource]="members" class="table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-mail</th>
      <td mat-cell title="{{ element.email }}" *matCellDef="let element">
        {{ element.email | truncate: 35 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="signupDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.signup-date' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.joinDate | ellosDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="userType">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.user-type' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <p class="forward">
          {{ 'settings.companies.' + element.roleName.toLowerCase() | translate }}
        </p>
        <p
          class="forward"
          *ngIf="
            element.roleName.toLowerCase() === 'forwarding-agent' || element.roleName.toLowerCase() === 'forwarding agent' || element.roleName.toLowerCase() === 'forwardingagent'
          "
        >
          <fa-icon matPreFix [icon]="faBuilding" class="build-icon"></fa-icon>

          {{ element?.forwardingCompanyName }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.status' | translate }}
      </th>

      <td mat-cell *matCellDef="let element" class="padding-right">
        <div *ngIf="element.status == 1" class="ACTIVE status mat-elevation-z4" fxLayout="row" fxLayoutAlign="center center">
          {{ 'settings.companies.active' | translate }}
        </div>

        <div *ngIf="element.status != 1" class="INACTIVE status mat-elevation-z4" fxLayout="row" fxLayoutAlign="center center">
          {{ 'settings.companies.inactive' | translate }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.country' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.country }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.actions' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
          <button
            mat-icon-button
            routerLink="/Settings/MyProfile"
            *ngIf="userRole?.toLowerCase() === 'admin' && user.email === element.email; else viewProfile"
            [matTooltip]="'settings.companies.details' | translate"
          >
            <mat-icon> visibility </mat-icon>
          </button>

          <ng-template #viewProfile>
            <button
              mat-icon-button
              routerLink="/Settings/Companies/User/{{ companyId }}/{{ element.idAssociate }}/{{ companyName }}"
              [matTooltip]="'settings.companies.details' | translate"
            >
              <mat-icon> visibility </mat-icon>
            </button>
          </ng-template>
          <button
            mat-icon-button
            (click)="editMemberRoleDialog(element)"
            *ngIf="userRole?.toLowerCase() === 'admin'"
            [matTooltip]="'settings.companies.edit-member-role' | translate"
          >
            <mat-icon> create </mat-icon>
          </button>

          <button
            mat-icon-button
            (click)="changeMemberStatus(element)"
            *ngIf="userRole?.toLowerCase() === 'admin'"
            [matTooltip]="'settings.companies.edit-member-status' | translate"
          >
            <mat-icon> change_circle </mat-icon>
          </button>

          <button
            mat-icon-button
            (click)="deleteCompanyMemberDialog(element)"
            *ngIf="userRole?.toLowerCase() === 'admin' && user.email !== element.email"
            [matTooltip]="'settings.companies.delete-member' | translate"
          >
            <mat-icon> delete </mat-icon>
          </button>
          <button mat-icon-button *ngIf="user.email === element.email" color="warn" [matTooltip]="'settings.companies.leave-company' | translate" (click)="leaveCompanyDialog()">
            <fa-icon class="icone-leave" [icon]="faSignOutAlt"></fa-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [length]="count" [pageSize]="10" (page)="pageChange($event)"> </mat-paginator>
</div>
