import { Injectable } from '@angular/core';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { Sector } from '../models/sector';
import { PrimaryChapter } from '../models/primary-chapter';

@Injectable({
  providedIn: 'root'
})
export class CrmService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getSectors(language: string): Promise<Sector[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Crm/sector?language=${language}`,
      body: null
    };

    let response;
    response = await this.ellosApi.get<Sector[]>(request);

    return response.data === null ? [] : response.data;
  }
  async getPrimaryChapters(idSector: number, language: string): Promise<PrimaryChapter[]> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Crm/primaryChapter?IdSector=${idSector}&Language=${language}`,
      body: null
    };

    let response;
    response = await this.ellosApi.get<PrimaryChapter[]>(request);

    return response.data === null ? [] : response.data;
  }
  async getSectorById(sectorId: number, language: string): Promise<Sector> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Crm/sector?IdSector=${sectorId}&Language=${language}`,
      body: null
    };

    let response;
    response = await this.ellosApi.get<Sector>(request);

    return response.data ? response.data[0] : undefined;
  }
  async getPrimaryChapterById(sectorId: number, primaryChapterId: number, language: string): Promise<PrimaryChapter> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Crm/primaryChapter?IdSector=${sectorId}&IdChapter=${primaryChapterId}&Language=${language}`,
      body: null
    };

    let response;
    response = await this.ellosApi.get<PrimaryChapter>(request);

    return response.data ? response.data[0] : undefined;
  }
}
