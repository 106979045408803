import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import Language from 'src/app/core/interfaces/language.interface';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { OriginCertificateService } from '../../services/origin-certificate.service';
import { SubmitCertificate, Uploadflies } from '../../Models/submit-certificates.interface';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ModalManagerService } from 'src/app/core/services/modal-manager.service';
import { EllosSpinnerComponent } from 'src/app/shared/components/ellos-spinner/ellos-spinner.component';

@Component({
  selector: 'app-modal-import-origin-certificate',
  templateUrl: './modal-import-origin-certificate.component.html',
  styleUrls: ['./modal-import-origin-certificate.component.scss']
})
export class ModalImportOriginCertificateComponent implements OnInit {
  _change: SubmitCertificate = {
    requirementCode: '',
    requirementName: ''
  };
  private loadingModalRef: MatDialogRef<EllosSpinnerComponent> = null;
  UploadFiles: Uploadflies[] = [];
  UploadFilesSpan: boolean = false;
  amountOfCertificates: boolean = false;
  isDragOver: boolean;

  destroy$: Subject<any> = new Subject<any>();
  appliedTheme: string;
  backgroundCard: string;
  fontColor: string;
  isOriginCertificate: boolean;
  loading: boolean = false;
  isFinished: boolean = false;
  currentCompany: CompanyPreview;
  sessionCompany$: Observable<CompanyPreview>;
  language$: Observable<Language>;
  code: string;
  constructor(
    public dialogRef: MatDialogRef<ModalImportOriginCertificateComponent>,
    private readonly companySessionState: Store<{ company: CompanyPreview }>,
    public originCertificateService: OriginCertificateService,
    private readonly dialog: MatDialog,
    private readonly _dialogLoader: MatDialog,
    private readonly langStore: Store<{ language: Language }>,
    private readonly _modalManagerService: ModalManagerService
  ) {
    this.language$ = langStore.pipe(select('language'));
    this.sessionCompany$ = this.companySessionState.pipe(select('company'));
  }

  resultFile: any;
  linesArray: string[] = [];
  certificados: any[] = [];
  fileErrors: any = [];
  filesSuccess: any = [];
  fileContent: string | undefined;

  ngOnInit() {
    this.loading = false;
    this.sessionCompany$.subscribe(async (company) => {
      this.currentCompany = company;
    });
    this.language$.pipe(takeUntil(this.destroy$)).subscribe((lang: Language) => {
      if (lang?.code) {
        this.code = lang.code;
      }
    });
  }

  async fileLoaded(event) {
    this.isFinished = false;
    this.fileErrors = [];
    this.filesSuccess = [];
    this._change.file = undefined;
    this._change.file = event.item(0);

    if (this.UploadFiles.length == 5) {
      this.UploadFilesSpan = true;
    } else {
      this.UploadFilesSpan = false;

      this.UploadFiles = [...this.UploadFiles, { certificate: event.item(0) }];
    }
    this.certificados = [];
    this.linesArray = [];
    for (let file of this.UploadFiles) {
      if (file) {
        try {
          const fileContent = await this.readAsText(file?.certificate as unknown as File);
          this.linesArray = fileContent.split(/[\r\n]+/).slice(0, 600);
          this.separateCertificatesOfOrigin();
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  onDragOverEvent(event: DragEvent) {
    this.isFinished = false;
    this.fileErrors = [];
    this.filesSuccess = [];
    event.stopPropagation();
    event.preventDefault();
    this.isDragOver = true;
  }

  onFileDrop(event) {
    this.isFinished = false;
    this.fileErrors = [];
    this.filesSuccess = [];
    event.stopPropagation();
    event.preventDefault();
    this._change.file = event.dataTransfer.files[0];
    this.isDragOver = false;
  }

  async removeFile(event, date) {
    this.isFinished = false;
    this.fileErrors = [];
    this.filesSuccess = [];
    event.preventDefault();
    event.stopPropagation();
    this._change.file = undefined;
    let file = this.UploadFiles.indexOf(date);
    this.UploadFiles.splice(file, 1);
    this.UploadFilesSpan = false;

    if (this.UploadFiles == undefined || this.UploadFiles.length == 0) {
      this.certificados = [];
    } else {
      this.certificados = [];
      for (let file of this.UploadFiles) {
        try {
          const fileContent = await this.readAsText(file.certificate as unknown as File);
          this.linesArray = fileContent.split(/[\r\n]+/).slice(0, 600);
          this.separateCertificatesOfOrigin();
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  setchangefile(props) {
    this._change.file = props;
  }

  readAsText(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader?.readAsText(file);
    });
  }

  separateCertificatesOfOrigin() {
    let cabecalho = this.linesArray[0];
    let totalCertificados: number = Math.trunc((this.linesArray.length - 1) / 10);
    let certificado: string[] = [];
    certificado.push(cabecalho);
    let contador = 0;
    let contadorCertificados = 0;
    for (let index = 0; index < this.linesArray.length; index++) {
      if (contadorCertificados < totalCertificados) {
        if (index > 0) {
          contador++;
          certificado.push(this.linesArray[index]);
          if (contador == 10) {
            contadorCertificados++;
            this.certificados.push(certificado);
            contador = 0;
            certificado = [];
            certificado.push(cabecalho);
          }
        }
      } else {
        break;
      }
    }
  }

  async save() {
    this.isFinished = false;
    try {
      let dialogRef = this.dialog.open(ConfirmDialogComponent);
      const rest = await dialogRef.afterClosed().toPromise();
      this.fileErrors = [];
      this.filesSuccess = [];
      if (rest) {
        for (let file of this.UploadFiles) {
          if (this._change.file) {
            let promises: Promise<any>[] = [];
            try {
              this.loading = true;
              this.loadingModalRef = this._dialogLoader.open(EllosSpinnerComponent, {
                panelClass: 'custom-modalbox',
                disableClose: true
              });
              this.certificados.forEach(async (element, index) => {
                try {
                  promises.push(this.originCertificateService.ImportFileCOByArrayLinesAsync(this.currentCompany.id, element));
                } catch (exception) {
                  this.fileErrors.push({ error: exception?.error?.errors, index });
                }
              });
              const results = await Promise.allSettled(promises);
              results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                  this.filesSuccess.push({ error: null, index });
                } else {
                  this.fileErrors.push({ error: result, index });
                }
              });
            } catch (error) {
              this.isFinished = true;
              this.dialogRef.close(false);
              console.error(error);
            } finally {
              this.loadingModalRef.close();
              this.loading = false;
              this.isFinished = true;
              if (this.fileErrors.length == 0) {
                this.dialogRef.close(true);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      this.loadingModalRef.close();
      this.loading = false;
      this.dialogRef.close(false);
    }
  }

  checkRequestByIndex(index: number) {
    if (this.isFinished) {
      return this.fileErrors.find((item) => item.index === index);
    }
  }

  getErrorMessage(index: number): string[] {
    const errors = this.fileErrors.find((item) => item.index === index);
    if (errors) {
      let error = errors?.error?.reason?.error?.errors;
      console.log(error);
      let errorsArray: string[] = error[0].split(',');
      return errorsArray;
    } else {
      return [];
    }
  }
}
