import { Injectable, isDevMode } from '@angular/core';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { stringify } from 'query-string';

import { Requirement } from '../Models/requirement.interface';
import { CertifierResponse } from '../Models/certifier-response.interface';
import { TransshipmentResponse } from '../../../shared/models/transshipment-reponse.interface';
import { ExportationData } from '../Models/exportation-data.interface';
import { HttpClient } from '@angular/common/http';
import { ExportationDetail } from '../Models/exportation-detail.interface';
import { lastValueFrom } from 'rxjs';
import { PaymentListPay } from '../../payment/interfaces/paymentListPay.interface';
import { PaymentListPayHelper } from '../../payment/interfaces/paymentListPayHelper';
import { PaymentEasytradeListPaid } from '../../payment/interfaces/paymentEasytradeListPaid';
import { PaymentListPaidHelper } from '../../payment/interfaces/paymentListPaidHelper';
import { checkHaveCheckout } from '../../payment/interfaces/checkHaveCheckout';
import { DueResponse } from '../Models/due-response.interface';
import { AddressExportationRequestPost, AddressExportationRequestPut } from '../../payment/interfaces/addressExportation.interface';
import { ExportationDeliveryAddress } from '../../payment/interfaces/exportationDeliveryAddress.interface';
import UrlResponse from '../Models/url-response';
@Injectable({
  providedIn: 'root'
})
export class ExportationService {
  constructor(private readonly ellosApi: EllosApiService, private readonly http: HttpClient) {}

  async getConsularApprovalRequirement(consularApprovalQuery: { CountryCode: string }): Promise<number> {
    let query = consularApprovalQuery.CountryCode;
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Exportation/ConsularApprovalOption/${query}`
    };
    try {
      let response = await this.ellosApi.get<number>(request);

      return response.data;
    } catch (e) {
      return;
    }
  }

  async getPhysicalSealingRequirement(consularApprovalQuery: { CountryCode: string }): Promise<any[]> {
    let query = consularApprovalQuery.CountryCode;
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Exportation/PhysicalSealingOption/${query}`
    };
    try {
      let response = await this.ellosApi.get<any[]>(request);

      return response.data;
    } catch (e) {
      return;
    }
  }

  async getCertificateConsularApprovalRequirement(consularApprovalQuery: { CountryId: string }): Promise<any[]> {
    let query = consularApprovalQuery.CountryId;
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Exportation/DocumentNeedsConsularApproval/${query}`
    };
    try {
      let response = await this.ellosApi.get<any[]>(request);
      return response.data;
    } catch (e) {
      return;
    }
  }

  async getCertificatePhysicalSealingRequirement(consularApprovalQuery: { CountryId: string }): Promise<any[]> {
    let query = consularApprovalQuery.CountryId;
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Exportation/GetDocumentExportationPhysicallyByIdCountry?idCountry=${query}`
    };
    try {
      let response = await this.ellosApi.get<any[]>(request);
      return response.data;
    } catch (e) {
      return;
    }
  }

  async getRequirements(requirementQuery: { CountryCode: string; NcmCode: string; LanguageCode: string }): Promise<Requirement[]> {
    let query = stringify(requirementQuery);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Requirement?${query}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<Requirement[]>(request);

      return response.data;
    } catch (e) {
      return [];
    }
  }

  async getCertificates(requirementQuery: { LanguageCode: string }): Promise<Requirement[]> {
    let query = stringify(requirementQuery);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Certificate?${query}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<Requirement[]>(request);

      return response.data;
    } catch (e) {
      return [];
    }
  }
  async getRequirementsCertifers(requirementQuery: { IdRestriction: number; IdCertifier: number }): Promise<boolean> {
    let query = stringify(requirementQuery);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `TradeGroupRestrictionsCertifiers/getrestrictionscertifiers?${query}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<boolean>(request);

      return response.data;
    } catch (e) {
      return false;
    }
  }
  async getCertifiers(certifierQuery: { IdCertificate: number; PageIndex: number; PageSize: number; CertifierName: string }): Promise<CertifierResponse> {
    let query = stringify(certifierQuery);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Certifier/certifiers?${query}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<CertifierResponse>(request);

      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: []
      };
    }
  }

  async getFambrasCertifiers(certificateCode: any): Promise<CertifierResponse> {
    let query = certificateCode;
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Certificate/fambras?IdCertificate=${certificateCode}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<CertifierResponse>(request);
      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: []
      };
    }
  }

  async getCdialCertifiers(certificateCode: any): Promise<CertifierResponse> {
    let query = certificateCode;
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Certificate/cdial?IdCertificate=${certificateCode}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<CertifierResponse>(request);
      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: []
      };
    }
  }

  async getMapaCertifiers(certificateCode: any): Promise<CertifierResponse> {
    let query = certificateCode;
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Certificate/mapa?IdCertificate=${certificateCode}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<CertifierResponse>(request);
      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: []
      };
    }
  }

  async getInvoiceUrl(transactionId: string, invoiceFileExtension: string): Promise<string> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Invoice/sign?idTransaction=${transactionId}&fileExtension=${invoiceFileExtension}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<string>(request);

      return response.data;
    } catch (e) {
      return undefined;
    }
  }
  async getExportationUrl(transactionId: string, certificateFileExtension: string, idCertificate: number): Promise<string> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Certificate/sign?idTransaction=${transactionId}&fileExtension=${certificateFileExtension}&idCertificate=${idCertificate}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<string>(request);

      return response.data;
    } catch (e) {
      return undefined;
    }
  }

  async getSeaPorts(querySeaPorts: { PageIndex: number; PageSize: number; Name: string }): Promise<TransshipmentResponse> {
    let query = stringify(querySeaPorts);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `SeaPort?${query}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<TransshipmentResponse>(request);

      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: [],
        destinationPoint: []
      };
    }
  }
  async getAirports(queryAirports: { PageIndex: number; PageSize: number; Name: string }): Promise<TransshipmentResponse> {
    let query = stringify(queryAirports);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Airport?${query}`,
      body: null
    };
    try {
      let response = await this.ellosApi.get<TransshipmentResponse>(request);

      return response.data;
    } catch (e) {
      return {
        count: 0,
        items: [],
        destinationPoint: []
      };
    }
  }
  async commitTransaction(transactionId: string): Promise<boolean> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      body: null,
      resource: `Exportation/${transactionId}/commit`
    };

    let response = await this.ellosApi.post<boolean>(request);
    return response.success;
  }

  async submitExportationCertificate(exportationUrl: string, file: File): Promise<boolean> {
    let formData: FormData = new FormData();
    formData.append('file', file);
    try {
      await lastValueFrom(this.http.put<boolean>(exportationUrl, { formData }));
      return true;
    } catch (e) {
      return false;
    }
  }

  async submitExportationInvoice(invoiceUrl: string, invoiceFile: File): Promise<boolean> {
    let formData: FormData = new FormData();
    formData.append('file', invoiceFile);

    try {
      await lastValueFrom(this.http.put<boolean>(invoiceUrl, { formData }));
      return true;
    } catch (e) {
      return false;
    }
  }
  async createExportation(exportationData: ExportationData, dueType: boolean): Promise<string> {
    const resourceURI = dueType ? 'Exportation?dueType=true' : 'Exportation';
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      body: exportationData,
      resource: resourceURI
    };

    try {
      let response = await this.ellosApi.post<string>(request);
      return response.data;
    } catch (e) {
      throw new Error(e);
    }
  }
  async getSearchDueDetail(acessKey: any, dueNumber: any): Promise<DueResponse> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        resource: `Exportation/getDueInfo?number=${dueNumber}&accessKey=${acessKey}&dev=true`,
        body: null
      };

      const response = await this.ellosApi.get<DueResponse>(request);
      return response.data;
    } catch (exception) {
      return exception;
    }
  }

  async getExportationDetail(idExportation: any): Promise<ExportationDetail> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        resource: `Exportation/details/${idExportation}`,
        body: null
      };

      let response = await this.ellosApi.get<ExportationDetail>(request);
      return response.data;
    } catch (exception) {
      return null;
    }
  }

  async getByIdReference(idReference: string) {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        resource: `Exportation/getByIdReference?IdReference=${idReference}`,
        body: null
      };

      let response = await this.ellosApi.get<any>(request);
      return response;
    } catch (exception) {
      console.error(exception);
    }
  }

  async getCustomsExportationDetail(idExportation: string, idCompany: number): Promise<ExportationDetail> {
    let query = stringify({ idExportation, idCompany });
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTradeCustoms,
        resource: `Exportation/details?${query}`,
        body: null
      };

      let response = await this.ellosApi.get<ExportationDetail>(request);
      return response.data;
    } catch (exception) {
      return null;
    }
  }

  async putCustomsAcceptExportation(idExportation: string, idCompany: number, status: string, message: string, email: string, certificatesReproved: string[]): Promise<boolean> {
    let inviteLink;
    if (isDevMode()) inviteLink = 'https://ellos.dev.casaarabe.org.br';
    else inviteLink = 'https://ellos.org.br';

    let query = stringify({
      idExportation,
      idCompany,
      status: status,
      message: message,
      inviteLink: inviteLink,
      email: email,
      certificatesReproved: certificatesReproved
    });

    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTradeCustoms,
        resource: `Status/Accepted?${query}`,
        body: null
      };

      let response = await this.ellosApi.put<boolean>(request);
      return response.success;
    } catch (exception) {
      return null;
    }
  }

  async getCertifierExportationDetail(idExportation: string, idCertifier: number): Promise<ExportationDetail> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTradeCertifier,
        resource: `Exportation/details?IdExportation=${idExportation}&IdCertifier=${idCertifier}`,
        body: null
      };

      let response = await this.ellosApi.get<ExportationDetail>(request);
      return response.data;
    } catch (exception) {
      return null;
    }
  }

  async getProcessFileUrl(idExportation: string): Promise<string> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `StampedDoc/download/${idExportation}`,
      body: null
    };
    let response = await this.ellosApi.get<string>(request);

    return response.data;
  }

  async requiredConsularapproval(destinationCountryCode: string): Promise<boolean> {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `CountryConsularApproval?countryCode=${destinationCountryCode}`,
      body: null
    };
    let response = await this.ellosApi.get<boolean>(request);

    return response.data;
  }

  async getExportationAssociate(idExportation: string): Promise<any> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.EasyTradeCustoms,
        resource: `Exportation/associate?idExportation=${idExportation}`,
        body: null
      };

      let response = await this.ellosApi.get<ExportationDetail>(request);
      return response.data;
    } catch (exception) {
      return null;
    }
  }
  async checkCheckoutExportation(idExportation: any): Promise<checkHaveCheckout> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.Pricing,
        resource: `Pricing/Check/${idExportation}`,
        body: null
      };

      let response = await this.ellosApi.get<checkHaveCheckout>(request);
      return response.data;
    } catch (exception) {
      return null;
    }
  }
  async getExportationCheckout(idExportation: any): Promise<PaymentListPay> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.Pricing,
        resource: `Pricing/CalculationMethod/${idExportation}`,
        body: null
      };

      let response = await this.ellosApi.get<PaymentListPayHelper>(request);
      var payment = new PaymentListPay(
        response.data.id,
        response.data.date,
        response.data.type,
        response.data.importer,
        response.data.destination,
        response.data.description,
        response.data.itens,
        response.data.limitDate,
        response.data.price,
        response.data.currency,
        response.data.status
      );
      return payment;
    } catch (exception) {
      return null;
    }
  }

  async getPricingCheckout(idExportation: any): Promise<PaymentEasytradeListPaid> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.Pricing,
        resource: `Pricing/PricingMethod/${idExportation}`,
        body: null
      };

      let response = await this.ellosApi.get<PaymentListPaidHelper>(request);
      let paid = new PaymentEasytradeListPaid(response.data.id, response.data.services, response.data.price, response.data.currency, response.data.status);
      return paid;
    } catch (exception) {
      return null;
    }
  }

  async changeStatusCheckoutExportation(idExportation: any): Promise<any> {
    try {
      let request: EllosRequest = {
        ellosApi: EllosApi.Pricing,
        resource: `Pricing/ChangeStatus/${idExportation}`,
        body: null
      };

      let response = await this.ellosApi.get<any>(request);
      return response.data;
    } catch (exception) {
      return null;
    }
  }

  async getAddresByIdCompanyAndIdUser(IdCompany: number): Promise<ExportationDeliveryAddress[]> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: null,
        resource: `ExportationDeliveryAddressForReturning/getAdressByIdCompanyAndIdUser?IdCompany=${IdCompany}`
      };
      let response = await this.ellosApi.get<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async editAddresByIdCompanyAndIdUser(addressExportationRequestPost: AddressExportationRequestPut): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: addressExportationRequestPost,
        resource: `ExportationDeliveryAddressForReturning/edit`
      };
      let response = await this.ellosApi.put<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async addAddresByIdCompanyAndIdUser(addressExportationRequestPost: AddressExportationRequestPost): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: addressExportationRequestPost,
        resource: `ExportationDeliveryAddressForReturning/add`
      };
      let response = await this.ellosApi.post<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async relateExportToAddress(idExportation: string, IdAdress: number): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: null,
        resource: `ExportationDeliveryAddressForReturning/relateExportToAddress?idExportation=${idExportation}&IdAdress=${IdAdress}`
      };
      let response = await this.ellosApi.post<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async relateExportToAddressDefault(idExportation: string): Promise<any> {
    const currentCompany = JSON.parse(localStorage.getItem('current-company'));
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: null,
        resource: `ExportationDeliveryAddressForReturning/relateExportToAddressDefault?idExportation=${idExportation}&IdComapany=${currentCompany.id}`
      };
      let response = await this.ellosApi.post<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async disableAddressAddresByIdAddress(idAdress: number): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: null,
        resource: `ExportationDeliveryAddressForReturning/disableAddress?idAdress=${idAdress}`
      };
      let response = await this.ellosApi.post<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async activateAddressById(idAdress: number): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: null,
        resource: `ExportationDeliveryAddressForReturning/activateAddress?idAdress=${idAdress}`
      };
      let response = await this.ellosApi.post<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async relateExportToAddressByIdAddress(idExportation: string, IdAdress: number): Promise<any> {
    try {
      const request: EllosRequest = {
        ellosApi: EllosApi.Exportation,
        body: null,
        resource: `ExportationDeliveryAddressForReturning/relateExportToAddress?idExportation=${idExportation}&IdAdress=${IdAdress}`
      };
      let response = await this.ellosApi.post<any>(request);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}
