import EllosAction from '../../../core/interfaces/ellosAction.interface';
import { ActionTypes } from '../../../core/models/actionTypes';
import { UnitMeasure } from '../interfaces/unitMeasure.interface';

export function unitsMeasureReducer(state: UnitMeasure[], action: EllosAction) {
  switch (action.type) {
    case ActionTypes.UnitsMeasure: {
      state = action.payload;

      return state;
    }

    default:
      return state;
  }
}
