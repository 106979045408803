import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss']
})
export class TourComponent implements OnInit {
  destroy$: Subject<any> = new Subject<any>();
  constructor(private readonly translateService: TranslateService, public tourService: TourService) {}

  ngOnInit(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((t) => {
      if (environment.production) {
        this.tourService.initialize([
          {
            anchorId: 'profile',
            content: this.translateService.instant(`tour.content.profile`),
            title: this.translateService.instant(`tour.title.profile`),
            route: '/Home',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          },
          {
            anchorId: 'company',
            content: this.translateService.instant(`tour.content.company`),
            title: this.translateService.instant(`tour.title.company`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'easy-trade',
            content: this.translateService.instant(`tour.content.easy-trade`),
            title: this.translateService.instant(`tour.title.easy-trade`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'showroom',
            content: this.translateService.instant(`tour.content.showroom`),
            title: this.translateService.instant(`tour.title.showroom`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'matchmaking',
            content: this.translateService.instant(`tour.content.matchmaking`),
            title: this.translateService.instant(`tour.title.matchmaking`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'chamber-club',
            content: this.translateService.instant(`tour.content.chamber-club`),
            title: this.translateService.instant(`tour.title.chamber-club`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'content',
            content: this.translateService.instant(`tour.content.content`),
            title: this.translateService.instant(`tour.title.content`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'settings',
            content: this.translateService.instant(`tour.content.settings`),
            title: this.translateService.instant(`tour.title.settings`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'learn-more',
            content: this.translateService.instant(`tour.content.learn-more-easy-trade`),
            title: this.translateService.instant(`tour.title.learn-more`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.later-button`)
          },
          {
            anchorId: 'easy-trade.blockchain-technology',
            content: this.translateService.instant(`tour.content.blockchain-technology`),
            title: this.translateService.instant(`tour.title.blockchain-technology`),
            route: '/EasyTrade/Trade/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'easy-trade.monitor-your-transactions',
            content: this.translateService.instant(`tour.content.monitor-your-transactions`),
            title: this.translateService.instant(`tour.title.monitor-your-transactions`),
            route: '/EasyTrade/Trade/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'learn-more',
            content: this.translateService.instant(`tour.content.learn-more-settings`),
            title: this.translateService.instant(`tour.title.learn-more`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.later-button`)
          },
          {
            anchorId: 'settings.my-profile',
            content: this.translateService.instant(`tour.content.my-profile`),
            title: this.translateService.instant(`tour.title.my-profile`),
            route: '/Settings/MyProfile',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          },
          {
            anchorId: 'settings.my-companies',
            content: this.translateService.instant(`tour.content.companies`),
            title: this.translateService.instant(`tour.title.companies`),
            route: '/Settings/MyProfile',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          },
          {
            anchorId: 'settings.invitations',
            content: this.translateService.instant(`tour.content.invitations`),
            title: this.translateService.instant(`tour.title.invitations`),
            route: '/Settings/MyProfile',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          }
        ]);
      } else {
        this.tourService.initialize([
          {
            anchorId: 'profile',
            content: this.translateService.instant(`tour.content.profile`),
            title: this.translateService.instant(`tour.title.profile`),
            route: '/Home',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          },
          {
            anchorId: 'company',
            content: this.translateService.instant(`tour.content.company`),
            title: this.translateService.instant(`tour.title.company`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'easy-trade',
            content: this.translateService.instant(`tour.content.easy-trade`),
            title: this.translateService.instant(`tour.title.easy-trade`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'showroom',
            content: this.translateService.instant(`tour.content.showroom`),
            title: this.translateService.instant(`tour.title.showroom`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'matchmaking',
            content: this.translateService.instant(`tour.content.matchmaking`),
            title: this.translateService.instant(`tour.title.matchmaking`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'chamber-club',
            content: this.translateService.instant(`tour.content.chamber-club`),
            title: this.translateService.instant(`tour.title.chamber-club`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'content',
            content: this.translateService.instant(`tour.content.content`),
            title: this.translateService.instant(`tour.title.content`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'settings',
            content: this.translateService.instant(`tour.content.settings`),
            title: this.translateService.instant(`tour.title.settings`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'learn-more',
            content: this.translateService.instant(`tour.content.learn-more-easy-trade`),
            title: this.translateService.instant(`tour.title.learn-more`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.later-button`)
          },
          {
            anchorId: 'easy-trade.blockchain-technology',
            content: this.translateService.instant(`tour.content.blockchain-technology`),
            title: this.translateService.instant(`tour.title.blockchain-technology`),
            route: '/EasyTrade/Trade/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'easy-trade.monitor-your-transactions',
            content: this.translateService.instant(`tour.content.monitor-your-transactions`),
            title: this.translateService.instant(`tour.title.monitor-your-transactions`),
            route: '/EasyTrade/Trade/Home',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'learn-more',
            content: this.translateService.instant(`tour.content.learn-more-showrom`),
            title: this.translateService.instant(`tour.title.learn-more-showrom`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.later-button`)
          },
          {
            anchorId: 'showroom.supply-and-demand',
            content: this.translateService.instant(`tour.content.supply-and-demand`),
            title: this.translateService.instant(`tour.title.supply-and-demand`),
            route: '/Marketplace/Search/Product',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'showroom.my-store',
            content: this.translateService.instant(`tour.content.my-store`),
            title: this.translateService.instant(`tour.title.my-store`),
            route: '/Marketplace/Search/Product',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'showroom.negotiation',
            content: this.translateService.instant(`tour.content.negotiation`),
            title: this.translateService.instant(`tour.title.negotiation`),
            route: '/Marketplace/Search/Product',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'showroom.categories',
            content: this.translateService.instant(`tour.content.categories`),
            title: this.translateService.instant(`tour.title.categories`),
            route: '/Marketplace/Search/Product',
            endBtnTitle: this.translateService.instant(`tour.end-button`),
            enableBackdrop: true
          },
          {
            anchorId: 'learn-more',
            content: this.translateService.instant(`tour.content.learn-more-matchmacking`),
            title: this.translateService.instant(`tour.title.learn-more-matchmacking`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.later-button`)
          },
          {
            anchorId: 'matchmaking.find-your-meetings',
            content: this.translateService.instant(`tour.content.find-your-meetings`),
            title: this.translateService.instant(`tour.title.find-your-meetings`),
            route: '/Matchmaking',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          },
          {
            anchorId: 'learn-more',
            content: this.translateService.instant(`tour.content.learn-more-settings`),
            title: this.translateService.instant(`tour.title.learn-more`),
            route: '/Home',
            endBtnTitle: this.translateService.instant(`tour.later-button`)
          },
          {
            anchorId: 'settings.my-profile',
            content: this.translateService.instant(`tour.content.my-profile`),
            title: this.translateService.instant(`tour.title.my-profile`),
            route: '/Settings/MyProfile',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          },
          {
            anchorId: 'settings.my-companies',
            content: this.translateService.instant(`tour.content.companies`),
            title: this.translateService.instant(`tour.title.companies`),
            route: '/Settings/MyProfile',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          },
          {
            anchorId: 'settings.invitations',
            content: this.translateService.instant(`tour.content.invitations`),
            title: this.translateService.instant(`tour.title.invitations`),
            route: '/Settings/MyProfile',
            enableBackdrop: true,
            endBtnTitle: this.translateService.instant(`tour.end-button`)
          }
        ]);
      }
    });
    let firstAccess = localStorage.getItem('firstAccess');
    if (firstAccess !== null) {
      this.tourService.start();
      localStorage.removeItem('firstAccess');
    }
  }
}
