<div id="modal-content-wrapper">
  <header id="modal-header" fxLayoutAlign="center start">
    <h1 id="modal-title" *ngIf="title">
      {{ title }}
    </h1>
    <h1 *ngIf="!title">
      {{ 'easy-trade.monitoring.modal.edit-process.title' | translate }}
    </h1>
  </header>

  <mat-divider></mat-divider>

  <section id="modal-body" class="body">
    <div mat-dialog-content>
      <pre> {{ message1 | translate }} </pre>
    </div>
    <div mat-dialog-content>
      <pre> {{ message2 | translate }} </pre>
    </div>
  </section>

  <mat-divider></mat-divider>

  <footer id="modal-footer">
    <div mat-dialog-actions fxLayoutAlign="end start">
      <button mat-raised-button class="button" (click)="dialogRef.close(true)">Ok</button>
    </div>
  </footer>
</div>
