import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Theme from 'src/app/core/interfaces/theme.interface';
import { LanguageService } from 'src/app/core/services/language.service';
import { ConfirmMeetingDialogComponent } from 'src/app/modules/marketplace/components/confirm-meeting-dialog/confirm-meeting-dialog.component';
import { QueryNewMatchmaking } from '../../actions/matchmaking.action';
import { MatchmakingIdAction } from '../../actions/matchmaking-id.action';
import { MatchmackingId } from '../../interfaces/matchmaking-id';
import { QueryMatchmaking } from '../../interfaces/queryMatchmaking.interface';
import { Meet } from '../../models/meet';

@Component({
  selector: 'app-matchmaking-card',
  templateUrl: './matchmaking-card.component.html',
  styleUrls: ['./matchmaking-card.component.scss']
})
export class MatchmakingCardComponent implements OnInit {
  @Input() matchmaking: Meet;
  @Input() isMatchmaking: boolean = false;
  day;
  month;
  time;
  hour;
  date;
  unsb$: Subject<any> = new Subject();
  theme$: Observable<Theme>;

  themeName: string;
  queryMatchmaking$: Observable<QueryMatchmaking>;
  queryMatchmaking: QueryMatchmaking;
  constructor(
    private readonly dialog: MatDialog,
    private readonly companyStore: Store<{ theme: Theme }>,
    private languageService: LanguageService,
    private readonly matchmakingStore: Store<{
      queryMatchmaking: QueryMatchmaking;
    }>,
    private readonly matchmakingState: Store<QueryMatchmaking>,
    private readonly matchmankigIdState: Store<MatchmackingId>,
    private readonly router: Router
  ) {
    this.queryMatchmaking$ = matchmakingStore.pipe(select('queryMatchmaking'));
  }

  ngOnInit(): void {
    this.theme$ = this.companyStore.pipe(select('theme'));
    this.theme$.pipe(takeUntil(this.unsb$)).subscribe(async (theme: Theme) => {
      this.themeName = theme.name;
    });

    if (this.matchmaking?.meetDate) {
      this.hourAdjusts();
    }

    this.queryMatchmaking$.pipe(takeUntil(this.unsb$)).subscribe(async (queryMatch: QueryMatchmaking) => {
      this.queryMatchmaking = queryMatch;
    });
  }

  confirmMeetingDialog(): void {
    const dialogRef = this.dialog.open(ConfirmMeetingDialogComponent, {
      width: '862px',
      disableClose: true,
      data: this.matchmaking
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsb$))
      .subscribe((result) => {
        if (result) {
          this.reload();
        }
      });
  }
  details(id: number) {
    this.matchmankigIdState.dispatch(MatchmakingIdAction({ id: id }));
    this.router.navigateByUrl(`Matchmaking/Meetings/Details`);
  }

  hourAdjusts() {
    let offset = new Date().getTimezoneOffset();
    let dataLocal = new Date(new Date(this.matchmaking.meetDate).getTime() - offset * 60 * 1000);
    this.matchmaking.meetDate = new Date(new Date(this.matchmaking.meetDate).getTime() - offset * 60 * 1000);

    let locale = (this.date = new Date(dataLocal).toLocaleDateString());
    this.time = new Date(dataLocal).toLocaleTimeString(navigator.language);

    this.hour = new Date(dataLocal).getHours();
    let date = new Date(this.matchmaking.meetDate);

    this.day = date.getDate();
    this.month = date.toLocaleString(this.languageService.getCurrentLanguage() == 'en' ? 'en-US' : 'pt-BR', { month: 'long' }).substr(0, 3);
  }

  reload() {
    let query: QueryMatchmaking = {
      IdCompany: this.queryMatchmaking.IdCompany,
      LanguageCode: this.queryMatchmaking.LanguageCode,
      MainFilter: this.queryMatchmaking.MainFilter,
      EndDate: this.queryMatchmaking.EndDate,
      InitDate: this.queryMatchmaking.InitDate,
      HistoricalData: this.queryMatchmaking.HistoricalData,
      PageSize: this.queryMatchmaking.PageSize,
      PageIndex: 0,
      filter: this.queryMatchmaking.filter
    };

    this.matchmakingState.dispatch(QueryNewMatchmaking(query));
  }
  ngOnDestroy() {
    this.unsb$.next(null);
  }
}
